import React, { Component } from 'react'
import { subYears } from 'date-fns'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import {
  TextField,
  RadioField,
  FormGroup,
  FormFooter,
  Base64FileInput,
  gPlacesAutocomplete,
  TextArea,
  DateField,
} from '../../components/form'
import { validateRequiredFields, digitsOnly, setMaxCharLimit } from '../../config/util'
import { RADIO_OPTIONS } from '../../config/constants'
import { getApplicationName } from 'config/util/applications'

class ProfileForm extends Component {
  deletePhoto = () => {
    this.props.change('profile_picture', null)
  }

  render() {
    const {
      handleSubmit,
      loading,
      initialValues,
      handleFormSubmit,
      invalid,
      submitFailed,
      profilePicture,
      config,
      change: onFieldChange,
    } = this.props

    const user = initialValues
    const occupations = Object.values(config?.user?.OCCUPATIONS || {})

    return (
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        className="flex-fill page-form well form-horizontal">
        <FormGroup label="ID">
          <span>{user.id}</span>
        </FormGroup>

        <FormGroup label="Signup Source">
          <span>{getApplicationName(user.signup_source_application_id)}</span>
        </FormGroup>

        <FormGroup label="Email" editable>
          <Field component={TextField} type="text" name="email" />
        </FormGroup>

        <FormGroup label="First Name" editable>
          <Field component={TextField} type="text" name="first_name" />
        </FormGroup>

        <FormGroup label="Last Name" editable>
          <Field component={TextField} type="text" name="last_name" />
        </FormGroup>

        <FormGroup label="Phone Number" editable>
          <Field component={TextField} type="tel" parse={digitsOnly} name="phone" />
        </FormGroup>

        <FormGroup label="Phone Country" editable>
          <Field name="phone_country" component={TextField} />
        </FormGroup>

        <FormGroup label="Permission Type" editable>
          <Field
            name="role_name"
            component={({ input }) => (
              <select className="form-select" {...input}>
                <option value="admin">Admin</option>
                <option value="registered-user">Registered User</option>
                <option value="guest">Guest</option>
              </select>
            )}
          />
        </FormGroup>

        <FormGroup label="Date of Birth" editable>
          <Field
            component={DateField}
            minDate={subYears(new Date(), 95)}
            maxDate={subYears(new Date(), 18)}
            yearDropdownItemNumber={100}
            showTimeSelect={false}
            name="dob"
          />
        </FormGroup>

        <FormGroup label="Gender" editable>
          <Field
            name="gender"
            component={({ input }) => (
              <select className="form-select" {...input}>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="non-binary">Non-Binary</option>
              </select>
            )}
          />
        </FormGroup>

        <FormGroup label="Is Verified" editable>
          <Field component={RadioField} name="is_verified" items={RADIO_OPTIONS} />
        </FormGroup>

        <FormGroup label="Is Active?" editable>
          <Field component={RadioField} name="is_active" items={RADIO_OPTIONS} />
        </FormGroup>

        <FormGroup label="Profile Picture" editable>
          {profilePicture && user && user.profile_picture_url && (
            <span className="delete-image" onClick={this.deletePhoto}>
              <img src={user.profile_picture_url} className="pic-preview" alt="profile pic" />
              <p className="delete">X</p>
            </span>
          )}
          <Field component={Base64FileInput} name="profile_picture" type="file" />
        </FormGroup>

        <FormGroup label="Private Notes" editable>
          <Field component={TextArea} name="private_notes" placeholder="Private Notes" />
        </FormGroup>

        <FormGroup label="Has had a procedure?" editable>
          <Field component={RadioField} name="has_had_procedure" items={RADIO_OPTIONS} />
        </FormGroup>

        <FormGroup label="Occupation" editable>
          <Field
            name="occupation.id"
            component={({ input }) => (
              <select className="form-select" {...input}>
                <option value=""></option>
                {occupations.map(({ id, display_name }) => (
                  <option key={id} value={id}>
                    {display_name}
                  </option>
                ))}
              </select>
            )}
          />
        </FormGroup>

        <FormGroup label="Procedure history" editable>
          <Field
            component={TextArea}
            name="procedure_history_description"
            placeholder="Procedure history"
          />
        </FormGroup>

        <FormGroup label="Address" editable>
          <Field
            component={gPlacesAutocomplete}
            type="text"
            name="location.long_name"
            change={onFieldChange}
          />
        </FormGroup>

        <FormGroup label="Employer" editable>
          <Field component={TextField} type="text" name="employer" />
        </FormGroup>

        <FormGroup
          label="Override Subscription"
          helper="Turn on if you want to give people access to paid features even if users haven't paid.">
          <Field component={RadioField} name='override_subscription' items={RADIO_OPTIONS} />
        </FormGroup>

        <FormGroup label="Completed Signup">
          <span>{user.completed_signup ? 'Yes' : 'No'}</span>
        </FormGroup>

        <FormGroup label="Last Profile Update">
          <span>{user.date_updated}</span>
        </FormGroup>

        <FormGroup label="Last Activity">
          <span>{user.last_activity_date}</span>
        </FormGroup>

        <FormGroup label="Date Created">
          <span>{user.date_created}</span>
        </FormGroup>

        <FormFooter loading={loading} showFailure={invalid && submitFailed} />
      </form>
    )
  }
}

const validate = values => {
  const requiredFields = [['first_name', 100], ['last_name', 100], ['email', 100]]
  const optionalFields = [['private_notes', 500], ['procedure_history_description', 300]]

  return {
    ...validateRequiredFields(values, requiredFields),
    ...setMaxCharLimit(values, optionalFields),
  }
}

ProfileForm = reduxForm({
  validate: validate,
  form: 'userProfileForm',
  enableReinitialize: true,
})(ProfileForm)

const selector = formValueSelector('userProfileForm')

ProfileForm = connect(state => {
  const profilePicture = selector(state, 'profile_picture')
  return {
    profilePicture,
  }
})(ProfileForm)

export default ProfileForm
