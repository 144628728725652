const defaultState = {
  all: [],
  current: {},
  count: 0,
  totalPages: 0,
}

export default function(state = defaultState, action) {
  switch(action.type) {
    case 'FETCHED_STEP_ALL':
      // more than 0 results
      if (action.payload.meta.paging) {
        const { total_results, total_pages } = action.payload.meta.paging
        return {
          ...state,
          all: action.payload.data,
          count: total_results,
          totalPages: total_pages
        }
      } else { // no results
        return { ...state, ...defaultState }
      }
    case 'FETCHED_STEP':
      return {
        ...state,
        current: action.payload
      }
    case 'UPDATED_STEP':
      return {
        ...state,
        current: action.payload
      }
    case 'CREATED_STEP':
      return {
        ...state,
        current: action.payload
      }
    case 'DELETED_STEP':
      return {
        ...state,
        all: state.all.filter(step => step.id !== action.payload)
      }
    default:
      return state
  }
}
