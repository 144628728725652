import React, { useState } from 'react'
import { Table as TableContainer } from 'components/table'
import dayjs from 'dayjs'
import { validateFaq } from './_util'

const ProviderFaqTable = ({ items, loading, edit, remove }) => {
  return (
    <TableContainer
      items={items}
      loading={loading}
      headers={['Question', 'Answer', 'Date Created', 'Date Updated']}>
      {items.map((item, i) => (
        <Row
          key={i}
          item={item}
          edit={edit}
          remove={remove}
        />
      ))}
    </TableContainer>
  )
}

const Row = ({ item, edit, remove }) => {
  const [state, setState] = useState(item)
  const [errors, setErrors] = useState({})
  const isValid = !Object.keys(errors).length

  const onChange = ({ target: { name, value } }) => {
    const newState = {
      ...state,
      [name]: value,
    }

    setState(newState)

    const errors = validateFaq(newState)
    setErrors(errors)
  }

  return (
    <tr>
      <td>
        <input
          name="question"
          type="text"
          value={state.question}
          onChange={onChange}
          className="author-form-fields"
        />
      </td>

      <td>
        <input
          name="answer"
          type="text"
          value={state.answer}
          onChange={onChange}
          className="author-form-fields"
        />
      </td>

      <td>
        {dayjs(state.date_created).format('YYYY-MM-DD HH:mm')}
      </td>

      <td>
        {dayjs(state.date_updated).format('YYYY-MM-DD HH:mm')}
      </td>

      <td>
        <span
          data-tooltip="Edit Publication"
          style={isValid ? { cursor: 'pointer' } : { opacity: '.5' }}
          onClick={isValid ? () => edit(state) : undefined}>
          <i className="fa fa-save" />
        </span>
      </td>

      <td>
        <span
          data-tooltip="Remove Publication"
          onClick={() => isValid && remove(state)}>
          <i className="fas fa-trash" />
        </span>
      </td>
    </tr>
  )
}

export default ProviderFaqTable
