import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { fetchItem, fetchFlagsByAccountPlan, createAccountPlanFlag } from 'actions'
import store from 'reducers'
import FlagForm from '../_form'
import { createEntitiesFromProps } from '../_util'

const ProvidersFlagNew = ({
  createAccountPlanFlag,
  currentFlag: { id: flagId },
  fetchFlagsByAccountPlan,
  fetchItem,
  history,
  loading,
  match,
  provider: { plan_id: planId },
}) => {
  const providerId = match.params.id

  useEffect(() => {
    if (!planId) {
      fetchItem('provider', providerId)
    }
  }, [fetchItem, providerId, planId])

  useEffect(() => {
    if (flagId) {
      history.push(`/providers/${providerId}/flags`)
    }

    // clear out the currentFlag object so that its properties don't potentially override
    // another flag's
    return () => {
      store.dispatch({
        type: 'UPDATED_PROVIDER_FLAG',
        payload: {},
      })
      store.dispatch({
        type: 'UPDATED_FLAG',
        payload: {},
      })
    }
  }, [history, providerId, flagId])

  const onFormSubmit = async data => {
    const entities = createEntitiesFromProps(data)
    await createAccountPlanFlag('provider', providerId, planId, entities)
    await fetchFlagsByAccountPlan('provider', providerId, planId)
  }

  return (
    <div className="primary-content">
      <div className="controls">
        <h4>Add a New Flag</h4>
      </div>
      <FlagForm onFormSubmit={onFormSubmit} loading={loading} />
    </div>
  )
}

const mapStateToProps = ({ providers, ui }) => {
  return {
    provider: providers.current,
    currentFlag: providers.currentFlag,
    loading: ui.loading,
  }
}

export default connect(mapStateToProps, {
  fetchItem,
  fetchFlagsByAccountPlan,
  createAccountPlanFlag,
})(ProvidersFlagNew)
