import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { fetchItems } from '../../actions'
import { Table } from '../../components/table'
import ReactPaginate from 'react-paginate'

const HelpfulStatistics = ({ helpful, loading, fetchItems }) => {
  useEffect(() => {
    fetchItems('helpful', { _page: 1, order_by: 'sum', order_by_direction: 'desc' })
  }, [fetchItems])

  const onPageChange = ({ selected }) => {
    fetchItems('helpful', { _page: selected + 1, order_by: 'sum', order_by_direction: 'desc' })
  }

  const tableHeaders = ['Entity Type', 'Entity Name', 'Total', 'Helpful', 'Not Helpful']

  return (
    <div className="primary-content">
      <h4 className="stats-table-header">Helpful</h4>

      <Table items={helpful.all} loading={loading} headers={tableHeaders}>
        {helpful.all.map(item => (
          <tr key={`${item.entity_type}-${item.entity_id}`}>
            <td>{item.entity_type}</td>
            <td>{item.name}</td>
            <td>{item.total}</td>
            <td>{item.helpful}</td>
            <td>{item.not_helpful}</td>
          </tr>
        ))}
      </Table>
      <ReactPaginate
        pageCount={helpful.totalPages}
        marginPagesDisplayed={1}
        pageRangeDisplayed={5}
        onPageChange={onPageChange}
        containerClassName="pagination"
        activeClassName="active"
        pageClassName="page-item"
        previousClassName="page-item"
        nextClassName="page-item"
      />
    </div>
  )
}

function mapStateToProps({ helpful, ui }) {
  return {
    helpful,
    loading: ui.loading,
  }
}

export default connect(
  mapStateToProps,
  { fetchItems }
)(HelpfulStatistics)
