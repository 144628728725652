import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchItems } from 'actions'
import { Checkmark } from 'components/checkmark'
import { Table } from 'components/table'
import FlagValue from 'containers/plans/_flag_value'
import { getDisplayNameForFlagType, formatDate } from 'config/util'

const FlagList = ({ plan, flags, definitions, loading, fetchItems }) => {
  const { account_id, account_type } = plan

  useEffect(() => {
    fetchItems('flag') // loads definitions
  }, [fetchItems])

  const headers = [
    'ID',
    'Slug',
    'Type',
    'Description',
    'Value',
    'Active',
    'Date Created',
    'Date Updated',
  ]

  return (
    <Table items={flags} headers={headers} loading={loading}>
      {flags.map(flag => {
        const { id, value, is_active, date_created, date_updated } = flag
        const { slug, type, description } = findFlagDefinition(flag, definitions) || {}

        return (
          <tr key={id}>
            <td>{id}</td>
            <td><Link to={`/${account_type}s/${account_id}/flags/${id}`}>{slug}</Link></td>
            <td>{getDisplayNameForFlagType(type)}</td>
            <td>{description}</td>
            <td><FlagValue value={value} type={type} /></td>
            <td><Checkmark bool={!!is_active} /></td>
            <td>{formatDate(date_created)}</td>
            <td>{formatDate(date_updated)}</td>
          </tr>
        )
      })}
    </Table>
  )
}

const findFlagDefinition = (flag, definitions) => {
  return definitions.find(({ id }) => id === flag.flag_definition_id)
}

const mapStateToProps = ({ flags, ui }) => {
  return {
    definitions: flags.all,
    loading: ui.loading,
  }
}

export default connect(mapStateToProps, {
  fetchItems,
})(FlagList)
