import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { validateRequiredFields, setMaxCharLimit, validateUrls } from 'config/util'
import { TextField, FormGroup, FormFooter, MediaField } from 'components/form'

const ProviderNewsForm = ({
  change,
  handleSubmit,
  initialValues,
  invalid,
  loading,
  onSubmit,
  submitFailed,
}) => {
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="page-form form-horizontal">
      <FormGroup label="Name" editable>
        <Field name="name" component={TextField} type="text" />
      </FormGroup>

      <FormGroup label="URL" editable>
        <Field name="url" component={TextField} type="text" />
      </FormGroup>

      <FormGroup label="Image" editable>
        <MediaField
          name="image"
          initialValue={initialValues.image_url}
          type="image"
          onChange={() => change('image_url', null)}
          style={{ maxHeight: '200px', maxWidth: '500px' }}
        />
      </FormGroup>

      <FormFooter loading={loading} showFailure={invalid && submitFailed} />
    </form>
  )
}

const validate = values => {
  const requiredFields = [
    ['name', 100],
  ]

  if (!values.image_url && !values.image) {
    requiredFields.push(['image'])
  }

  const optionalFields = [
    ['url', 100],
  ]

  const urls = [
    'url',
  ]

  return {
    ...validateRequiredFields(values, requiredFields),
    ...setMaxCharLimit(values, optionalFields),
    ...validateUrls(values, urls),
  }
}

export default reduxForm({
  validate: validate,
  form: 'addProviderNewsForm',
  enableReinitialize: true,
})(ProviderNewsForm)
