import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { TextField, FormGroup, FormFooter } from 'components/form'
import { validateFaq } from './_util'

const ProviderFaqForm = ({
  handleSubmit,
  invalid,
  loading,
  onSubmit,
  reset,
  submitFailed,
}) => {
  const onFormSubmit = async data => {
    await onSubmit(data)
    reset()
  }

  return (
    <form
      onSubmit={handleSubmit(onFormSubmit)}
      style={{ overflow: 'visible' }}
      className="mt-2 page-form well form-horizontal">
      <FormGroup label="Add FAQ" editable>
        <div className="form-vertical link-form-left">
          <Field
            name="question"
            type="text"
            component={TextField}
            placeholder="Question"
            showLength={false}
          />

          <Field
            name="answer"
            type="text"
            component={TextField}
            placeholder="Answer"
            showLength={false}
          />
        </div>

        <FormFooter loading={loading} showFailure={invalid && submitFailed} />
      </FormGroup>
    </form>
  )
}

export default reduxForm({
  validate: validateFaq,
  form: 'addProviderFaqForm',
  enableReinitialize: true,
})(ProviderFaqForm)
