import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { login } from '../actions/auth'
import GoogleLogin from 'react-google-login'
const clientId = process.env.REACT_APP_OAUTH_KEY

class Login extends Component {
  googleResponse = res => {
    if (res.tokenId) {
      this.props.login(res.tokenId)
    }
  }

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/users' } }

    if (this.props.authenticated) {
      return <Redirect to={from} />
    }

    return (
      <div className="container mt-2">
        <div className="columns">
          <div className="empty col-7 col-mx-auto">
            <div className="empty-icon">
              <i className="icon icon-3x icon-people"></i>
            </div>
            <p className="empty-title h5">Welcome</p>
            <p className="empty-subtitle">Please login using your Google account.</p>
            <div className="empty-action">
              <GoogleLogin
                render={renderProps => (
                  <button
                    className="btn btn-primary"
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}>
                    Login
                  </button>
                )}
                clientId={clientId}
                buttonText="Login"
                onSuccess={this.googleResponse}
                onFailure={this.googleResponse}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    authenticated: auth.authenticated,
  }
}

export default connect(
  mapStateToProps,
  { login }
)(Login)
