import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {Field, reduxForm, formValueSelector} from 'redux-form'
import {FormGroup, TextField, TextArea, RadioField, Base64FileInput, SelectAsync} from '../../components/form'
import {validateRequiredFields, digitsOnly} from '../../config/util'
import {VALID_URL, RADIO_OPTIONS} from '../../config/constants'
import _ from 'lodash'

class BeautyBoardMemberForm extends Component {
  constructor() {
    super()

    this.fetchProviders = _.debounce(this.fetchProviders, 400)
  }

  deletePhoto = () => {
    this.props.change('profile_picture', null)
  }

  fetchProviders = (query) => {
    this.props.fetchItems('provider', { searching: true, term: query, _limit: 100 })
  }

  render() {
    const { loading, submitFailed, invalid, handleSubmit, onFormSubmit, initialValues, profileImage, providers, isProvider, onMemberDelete } = this.props
    const editingRecord = initialValues && initialValues.date_created
    return (
      <form onSubmit={handleSubmit(onFormSubmit)} className="flex-fill page-form well form-horizontal">

        <FormGroup label="Is Provider?" editable>
          <Field component={RadioField} name="is_provider" items={RADIO_OPTIONS} />
        </FormGroup>

        {
          isProvider > 0 ?
          <FormGroup label="Provider" editable>
            <Field
              name="provider"
              component={SelectAsync}
              options={providers.map(provider => ({...provider, full_name: provider.first_name + ' ' + provider.last_name}))}
              loading={loading}
              getItems={this.fetchProviders}
              labelKey="full_name"
            />
          </FormGroup>
        :
          <Fragment>
            <FormGroup label="Salutation" editable>
              <Field component={TextField} type="text" name="salutation" placeholder="Salutation" />
            </FormGroup>

            <FormGroup label="First Name" editable>
              <Field component={TextField} type="text" name="first_name" placeholder="First Name" />
            </FormGroup>

            <FormGroup label="Last Name" editable>
              <Field component={TextField} type="text" name="last_name" placeholder="Last Name" />
            </FormGroup>

            <FormGroup label="Profile Picture" editable>
              {
                profileImage && editingRecord && initialValues.profile_picture_url &&
                <span className="delete-image" onClick={this.deletePhoto}>
                  <img src={initialValues.profile_picture_url} className="pic-preview" alt="profile pic" />
                  <p className="delete">X</p>
                </span>
              }
              <Field name="profile_picture" component={Base64FileInput} type="file" />
            </FormGroup>

            <FormGroup label="Location" editable>
              <Field name="location" component={TextArea} placeholder="City, State Abbreviation" />
            </FormGroup>

            <FormGroup label="Title" editable>
              <Field name="title" component={TextArea} placeholder="Title" />
            </FormGroup>

            <FormGroup label="External URL" editable>
              <Field name="external_url" component={TextField} placeholder="External Url" />
            </FormGroup>
          </Fragment>
        }

        <FormGroup label="Order" editable>
          <Field name="order" component={TextField} type="number" parse={digitsOnly} />
        </FormGroup>

        <FormGroup label="Is Active?" editable>
          <Field component={RadioField} name="is_active" items={RADIO_OPTIONS} />
        </FormGroup>

        <FormGroup label="Short Blurb" editable>
              <Field name="short_blurb" component={TextArea} placeholder="Short Blurb" />
       </FormGroup>

        {
          editingRecord &&
          <Fragment>
            <FormGroup label="Last Updated">
              <span>{initialValues.date_updated}</span>
            </FormGroup>
            <FormGroup label="Date Created">
              <span>{initialValues.date_created}</span>
            </FormGroup>
          </Fragment>
        }

        <footer className="form-footer">
          {
            editingRecord &&
            <span className="btn delete-member" onClick={onMemberDelete}>Delete</span>
          }
          <button
            className={`btn btn-primary ${loading ? 'loading' : ''}`}
            type="submit"
            disabled={loading}>
            Save
          </button>
          <div>
            {invalid && submitFailed && <span className="invalid-hint">Oops, looks like there is an invalid field!</span>}
          </div>
        </footer>
      </form>
    )
  }
}

const validate = (values) => {
  const errors = {}
  const {external_url, is_provider} = values

  const requiredFields = [
    ['order'],
    ['is_active'],
    ['is_provider']
  ]

  const providerRequiredFields = [
    ['provider'],
    ...requiredFields
  ]

  const memberRequiredFields = [
    ['salutation'],
    ['first_name', 25],
    ['last_name', 25],
    ['short_blurb', 500],
    ['profile_picture'],
    ['external_url'],
    ['location'],
    ['title', 255],
    ...requiredFields
  ]

  if (external_url && !VALID_URL.test(external_url)) {
    errors.external_url = 'Url must start with http(s)'
  }

  if (is_provider > 0) {
    return {...validateRequiredFields(values, providerRequiredFields)}
  } else {
    return {...validateRequiredFields(values, memberRequiredFields), ...errors}
  }
}


BeautyBoardMemberForm = reduxForm({
  validate: validate,
  form: 'beautyBoardMemberForm',
  enableReinitialize: true
})(BeautyBoardMemberForm)

const selector = formValueSelector('beautyBoardMemberForm')

BeautyBoardMemberForm = connect(
  state => {
    const profileImage = selector(state, 'profile_picture')
    const isProvider = selector(state, 'is_provider')
    return {
      profileImage,
      isProvider
    }
  }
)(BeautyBoardMemberForm)

export default BeautyBoardMemberForm
