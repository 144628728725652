import React, {Component} from 'react'
import {connect} from 'react-redux'
import PracticeForm from './_practice_form'
import {fetchItem, fetchItems, updateItem} from '../../actions'
import {Link} from 'react-router-dom'

class PracticesShow extends Component {
  onFormSubmit = data => {
    const {
      location: {
        place_id,
        suite_number,
      },
      provider_ids = [],
      providers,
      logo_image_url,
      logo_image_relative_url,
      hero_image_url,
      hero_image_relative_url,
      ...submitData
    } = data

    this.props.updateItem('practice', {
      ...submitData,
      place_id,
      suite_number: suite_number || null,
      provider_ids: provider_ids.map(obj => obj.id),
    })
  }

  componentDidMount() {
    const id = this.props.match.params.id
    this.props.fetchItem('practice', id)
  }

  render() {
    const { practice, loading, providers, fetchItems, practiceTypeOptions } = this.props

    const practiceProviders = practice.providers || []
    const providerFullNames = practiceProviders.map(prac => ({...prac, full_name: `${prac.first_name} ${prac.last_name}`}))

    const practiceTypeId = practice.practice_type ? practice.practice_type.id : ''

    const initialValues = {...practice, provider_ids: providerFullNames, practice_type: practiceTypeId}

    return (
      <div className="primary-content">
        <div className="controls">
          <Link to="/practices/new" className="btn btn-primary pull-right">Add New Practice</Link>
          <h4>{practice.name}</h4>
        </div>
        <PracticeForm
          onFormSubmit={this.onFormSubmit}
          loading={loading}
          fetchItems={fetchItems}
          providers={providers}
          initialValues={initialValues}
          practiceTypeOptions={practiceTypeOptions}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ practices, ui, providers, config }) => {
  return {
    practice: practices.current,
    loading: ui.loading,
    providers: providers.all,
    practiceTypeOptions: config.practice.PRACTICE_TYPES
  }
}

export default connect(mapStateToProps, { fetchItem, fetchItems, updateItem })(PracticesShow)
