import { useState, useEffect } from 'react'
import { authedRequest } from '../../../config/config'

export default function useVoucher(consultationId) {
  const [voucher, setVoucher] = useState(null)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    authedRequest
      .get(`voucher/search?consultation_id=${consultationId}`)
      .then(res => {
        const voucher = res.data.data[0]
        setVoucher(voucher)
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }, [consultationId])

  return { voucher, loading }
}
