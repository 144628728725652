import React from 'react'
import {Link} from 'react-router-dom'
import {FormGroup} from '../../components/form'

export const EntityByConcern = ({label, entity, count = 0, url}) => (
  <div className="flex-fill page-form well form-horizontal">
    <FormGroup label={`Associated ${label}: ${count > 0 ? count : ''}`}>
      <ul>
        {
          count > 0 ?
          entity.map(({id, name}) => <li key={id}><Link to={`/procedures${url}/${id}`}>{name}</Link></li>)
          :
          'None'
        }
      </ul>
    </FormGroup>
  </div>
)


EntityByConcern.defaultProps = {
  url: ''
}
