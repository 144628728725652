import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import _ from 'lodash'
import { convertObjectsToIds } from 'config/util'
import { FormGroup, SelectAsync, FormFooter } from 'components/form'
import { updateProviderPractices, fetchItems } from 'actions'

class ProviderPracticeForm extends Component {
  constructor() {
    super()
    this.fetchPractices = _.debounce(this.fetchPractices, 400)
  }

  fetchPractices = query => {
    if (query.length) {
      this.props.fetchItems('practice', { searching: true, term: query, _limit: 7 })
    }
  }

  onSubmit = values => {
    const converted = convertObjectsToIds(values, ['practice_ids'])
    this.props.updateProviderPractices(this.props.providerId, { ...values, ...converted })
  }

  render() {
    const { loading, handleSubmit, invalid, submitFailed, practices } = this.props
    const activePractices = practices.filter(practice => practice.is_active)

    return (
      <form
        onSubmit={handleSubmit(this.onSubmit)}
        className="flex-fill page-form well form-horizontal">
        <FormGroup label="Practice(s)" editable>
          <Field
            name="practice_ids"
            component={SelectAsync}
            options={activePractices}
            loading={loading}
            getItems={this.fetchPractices}
            labelKey="name"
            multi
          />
        </FormGroup>

        <FormFooter loading={loading} showFailure={invalid && submitFailed} />
      </form>
    )
  }
}

ProviderPracticeForm = reduxForm({
  form: 'providerPracticeForm',
  enableReinitialize: true,
})(ProviderPracticeForm)

function mapStateToProps({ ui }) {
  return {
    loading: ui.loading,
  }
}

export default connect(mapStateToProps, { updateProviderPractices, fetchItems })(
  ProviderPracticeForm
)
