import React, {Component} from 'react'
import {connect} from 'react-redux'
import ReferralCodeForm from './_code_form'
import {Link} from 'react-router-dom'
import {fetchItem, fetchItems, updateItem} from '../../actions'

class ReferralCodesShow extends Component {

  onFormSubmit = ({name, code, blurb, is_active, id, referral_program_id, ...rest}) => {

    this.props.updateItem('referral', {
      ...{},
      name,
      code,
      blurb,
      is_active,
      id,
      referral_program_id: parseInt(referral_program_id, 10)})
  }

  componentDidMount() {
    const id = this.props.match.params.id
    this.props.fetchItem('referral', id)
    this.props.fetchItems('program')
  }

  render() {
    const {referral, loading, programs} = this.props
    return (
      <div className="primary-content">
        <div className="controls">
          <Link to="/referrals/new" className="btn btn-primary pull-right">Add New Code</Link>
          <h4>{referral.name}</h4>
        </div>
        <ReferralCodeForm
          onFormSubmit={this.onFormSubmit}
          initialValues={referral}
          referral={referral}
          programs={programs}
          loading={loading}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ referrals, ui }) => {
  return {
    referral: referrals.currentCode,
    programs: referrals.programs,
    loading: ui.loading
  }
}

export default connect(mapStateToProps, {fetchItem, updateItem, fetchItems})(ReferralCodesShow)
