import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

export default function NavItem({ to, text, icon }) {
  return (
    <div className="accordion">
      <NavLink to={to} className="accordion-header c-hand">
        <i className={`fas fa-${icon}`} /> {text}
      </NavLink>
      <div className="accordion-body" />
    </div>
  )
}

NavItem.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.oneOf(['users', 'calendar-check', 'chart-line', 'heartbeat', 'user-circle']),
}
