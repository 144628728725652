import React from 'react'
import { subYears } from 'date-fns'
import { Field, reduxForm } from 'redux-form'
import { TextField, FormGroup, FormFooter, RadioField, DateField } from '../../components/form'
import { validateRequiredFields, digitsOnly, setMaxCharLimit } from '../../config/util'
import { RADIO_OPTIONS } from '../../config/constants'

const NewProfileForm = props => {
  const { handleSubmit = () => undefined, loading, handleFormSubmit, invalid, submitFailed } = props

  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      className="flex-fill page-form well form-horizontal">
      <FormGroup label="Email*" editable>
        <Field component={TextField} type="text" name="email" />
      </FormGroup>

      <FormGroup label="Password*" editable>
        <Field component={TextField} type="text" name="password" />
      </FormGroup>

      <FormGroup label="First Name*" editable>
        <Field component={TextField} type="text" name="first_name" />
      </FormGroup>

      <FormGroup label="Last Name*" editable>
        <Field component={TextField} type="text" name="last_name" />
      </FormGroup>

      <FormGroup label="Date of Birth" editable>
        <Field
          component={DateField}
          minDate={subYears(new Date(), 95)}
          maxDate={subYears(new Date(), 18)}
          yearDropdownItemNumber={100}
          showTimeSelect={false}
          name="dob"
        />
      </FormGroup>

      <FormGroup label="Phone Number" editable>
        <Field component={TextField} type="tel" parse={digitsOnly} name="phone" />
      </FormGroup>

      <FormGroup label="Phone Country" editable>
        <Field name="phone_country" component={TextField} />
      </FormGroup>

      <FormGroup label="Subscribe To Newsletter" editable>
        <Field component={RadioField} name="subscribed_newsletter" items={RADIO_OPTIONS} />
      </FormGroup>

      <FormGroup label="Gender" editable>
        <Field
          name="gender"
          component={({ input }) => (
            <select className="form-select" {...input}>
              <option value=""></option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="non-binary">Non-Binary</option>
            </select>
          )}
        />
      </FormGroup>

      <FormFooter loading={loading} showFailure={invalid && submitFailed} />
    </form>
  )
}

const validate = values => {
  const requiredFields = [
    ['first_name', 100],
    ['last_name', 100],
    ['email', 100],
    ['password', 100],
  ]
  const optionalFields = [
    ['dob_year', 4],
    ['phone', 10],
  ]

  return {
    ...validateRequiredFields(values, requiredFields),
    ...setMaxCharLimit(values, optionalFields),
  }
}

const NewProfileReduxForm = reduxForm({
  validate: validate,
  form: 'newUserProfileForm',
  enableReinitialize: true,
  initialValues: {
    subscribed_newsletter: 1,
  },
})(NewProfileForm)

export default NewProfileReduxForm
