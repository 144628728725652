import React, {Component} from 'react'
import {connect} from 'react-redux'
import TimelineForm from './_timeline_form'
import {fetchItem, updateItem, removeStepFromTimeline, fetchItems, addStepToTimeline} from '../../actions'
import {Tabs, Tab, TabPanels, TabList} from '../../components/tabs'
import StepTable from './_step_table'
import AddStepForm from './_add_step_form'

class TimelinesShow extends Component {

  onFormSubmit = (submitData) => {
    this.props.updateItem('timeline', submitData)
  }

  componentDidMount() {
    const id = this.props.match.params.id
    this.props.fetchItem('timeline', id)
    this.props.fetchItems('step', { _limit: 9999 })
  }

  onStepDelete = (stepId) => {
    const {removeStepFromTimeline, match} = this.props
    const timelineId = match.params.id

    if (window.confirm('You are removing a step from this timeline. Do you wish to proceed?')) {
      removeStepFromTimeline(timelineId, stepId)
    }
  }

  onStepAdd = ({step, order}) => {
    const timelineId = this.props.match.params.id


    this.props.addStepToTimeline(timelineId, [{
      order: order,
      id: step.id
    }])
  }

  render() {
    const { timeline, loading, steps } = this.props
    const existingStepIds = timeline.steps ? timeline.steps.map(s => s.id) : []
    const disallowedOrderNumbers = timeline.steps ? timeline.steps.map(s => s.order) : []

    return (
      <Tabs>
        <TabList>
          <Tab title="General Info" />
          <Tab title={`Steps (${timeline.steps ? timeline.steps.length : 0})`} />
        </TabList>
        <TabPanels>
          <TimelineForm
            onFormSubmit={this.onFormSubmit}
            timeline={timeline}
            initialValues={timeline}
            loading={loading}
          />
          <div className="primary-content">
            <StepTable
              steps={timeline.steps || []}
              loading={false}
              onDelete={this.onStepDelete}
            />
            <AddStepForm
              onFormSubmit={this.onStepAdd}
              loading={false}
              steps={steps}
              disallowed={disallowedOrderNumbers}
              existingSteps={existingStepIds}
            />
          </div>
        </TabPanels>
      </Tabs>
    )
  }
}

const mapStateToProps = ({timelines, ui, steps}) => {
  return {
    timeline: timelines.current,
    loading: ui.loading,
    steps: steps.all
  }
}

export default connect(mapStateToProps, {fetchItem, updateItem, removeStepFromTimeline, fetchItems, addStepToTimeline})(TimelinesShow)
