const applicationIds = new Map([
  [1, 'API'],
  [2, 'Web'],
  [3, 'iOS'],
  [4, 'Contentful'],
  [5, 'Data Science'],
  [6, 'Twilio'],
  [7, 'Intercom'],
  [8, 'Labs'],
  [9, 'Admin'],
  [10, 'Mobot'],
  [11, 'Slack'],
  [12, 'Cronofy'],
  [13, 'Stripe'],
  [14, 'Voucherify'],
  [15, 'TokBox'],
  [16, 'Unbounce'],
  [17, 'Privy'],
  [18, 'Hubspot'],
])

export const getApplicationName = id => {
  return applicationIds.get(id)
}
