import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {fetchItems} from '../../actions'
import {connect} from 'react-redux'
import {Table} from '../../components/table'
import {Checkmark} from '../../components/checkmark'
import ReactPaginate from 'react-paginate'
import PropTypes from 'prop-types'
import moment from 'moment'

class AreasIndex extends Component {

  componentDidMount() {
    this.props.fetchItems('area', { order_by: 'name', order_by_direction: 'asc' })
  }

  onPageChange = ({selected}) => {
    this.props.fetchItems('area', { _page: selected + 1 })
  }

  render() {
    const { areas, loading } = this.props
    const tableHeaders = ['Name', 'ID', 'Active', 'Region', 'Date Created']

    return (
      <div className="primary-content">
        <div className="controls">
          <Link to="/procedures/areas/new" className="btn btn-primary pull-right">Add New Area</Link>
          <h4>Procedure Areas</h4>
        </div>
        <Table items={areas.all} headers={tableHeaders} loading={loading}>
          {areas.all.map(area => (
            <tr key={area.id}>
              <td><Link to={`/procedures/areas/${area.id}`}>{area.name}</Link></td>
              <td>{area.id}</td>
              <td><Checkmark bool={!!area.is_active} /></td>
              <td>{area.procedure_region.name}</td>
              <td>{moment(area.date_created).format('MM-DD-YYYY - h:mm:ss A')}</td>
            </tr>
          ))}
        </Table>
        <ReactPaginate
          pageCount={areas.totalPages}
          marginPagesDisplayed={1}
          pageRangeDisplayed={5}
          onPageChange={this.onPageChange}
          containerClassName="pagination"
          activeClassName="active"
          pageClassName="page-item"
          previousClassName="page-item"
          nextClassName="page-item"
        />
      </div>
    )
  }

  static propTypes = {
    areas: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired
  }
}

const mapStateToProps = ({ areas, ui }) => {
  return {
    areas: areas,
    loading: ui.loading
  }
}

export default connect(mapStateToProps, { fetchItems })(AreasIndex)
