import React, {Component} from 'react'
import {connect} from 'react-redux'
import RegionForm from './_region_form'
import {Link} from 'react-router-dom'
import {fetchItem, updateItem} from '../../actions'

class RegionsShow extends Component {

  onFormSubmit = ({procedure_areas, ...submitData}) => {
    this.props.updateItem('region', submitData)
  }

  componentDidMount() {
    const id = this.props.match.params.id
    this.props.fetchItem('region', id)
  }

  render() {
    const { region, loading } = this.props
    return (
      <div className="primary-content">
        <div className="controls">
          <Link to="/procedures/regions/new" className="btn btn-primary pull-right">Add New Region</Link>
          <h4>{region.name} Region</h4>
        </div>
        <RegionForm
          onFormSubmit={this.onFormSubmit}
          region={region}
          initialValues={region}
          loading={loading}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ regions, ui }) => {
  return {
    region: regions.current,
    loading: ui.loading
  }
}

export default connect(mapStateToProps, { fetchItem, updateItem })(RegionsShow)
