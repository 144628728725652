import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import dayjs from 'dayjs'
import { sortableContainer, sortableElement, arrayMove } from 'react-sortable-hoc'
import Modal from 'simple-react-modal'
import { Table } from '../../components/table'
import ProceduresAddBeforeAfterForm from './before_afters'
import styles from './before_afters/index.scss'
import {
  fetchProcedureBeforeAfters,
  createProcedureBeforeAfter,
  updateProcedureBeforeAfter,
  deleteProcedureBeforeAfter,
} from '../../actions'

const ProcedureBeforeAfters = ({
  beforeAfters,
  procedureId,
  loading,
  fetchProcedureBeforeAfters,
  createProcedureBeforeAfter,
  updateProcedureBeforeAfter,
  deleteProcedureBeforeAfter,
}) => {
  beforeAfters = [...beforeAfters].sort((a, b) => a.order - b.order)

  const [initialValues, setInitialValues] = useState(beforeAfters)
  const [showModal, setShowModal] = useState(false)
  const [sorting, setSorting] = useState(false)

  useEffect(() => {
    fetchProcedureBeforeAfters(procedureId)
  }, [fetchProcedureBeforeAfters, procedureId])

  const startEditing = (values = {}) => {
    setInitialValues(values)
    setShowModal(true)
  }

  const deleteBeforeAfter = async data => {
    if (window.confirm('Are you sure?')) {
      await deleteProcedureBeforeAfter(data.id)
      await fetchProcedureBeforeAfters(procedureId)
    }
  }

  // This needs to be extracted out as a util function
  const fetchImageAndConvertToFile = async (url, original_filename) =>
    await fetch(url)
      .then(res => res.blob())
      .then(res => new File([res], original_filename))
      .catch(err => console.error(err))

  const onFormSubmit = async props => {
    let { id, before_image, after_images, meta } = props
    let { candidate_name, candidate_age, candidate_gender } = props // Candidate info

    before_image = Array.isArray(before_image) ? before_image : Array.of(before_image)

    const form = new FormData()

    if (!before_image[0].file)
      before_image[0].file = await fetchImageAndConvertToFile(
        before_image[0].url,
        before_image[0].original_filename
      )
    // form.append values have two possibilities
    // First value is what frontend modifies
    // Second ternary is what backend processes
    // If we modify a value, we use the first. If we use the existing value, use the second value
    await Promise.all(
      after_images.map(async after_image => {
        if (!after_image.file)
          after_image.file = await fetchImageAndConvertToFile(
            after_image.url,
            after_image.original_filename
          )

        form.append('after_images', after_image.file)
        form.append(
          'after_images_meta',
          JSON.stringify({
            after_count: after_image.meta['after_count'],
            order: after_image.meta['order'],
            after_length_of_time: after_image.meta['after_length_of_time'],
            caption: after_image.meta['caption'],
            alt_caption: (after_image.meta && after_image.meta['alt_caption']) || '',
          })
        )
      })
    )
    form.append('before_image', before_image[0].file)
    form.append('candidate_name', candidate_name)
    form.append('candidate_age', candidate_age)
    form.append(
      'candidate_gender',
      candidate_gender.value ? candidate_gender.value : candidate_gender
    )
    form.append('caption', before_image[0].caption || props.caption)
    form.append('meta', JSON.stringify(meta || {}))

    id
      ? await updateProcedureBeforeAfter(id, form)
      : await createProcedureBeforeAfter(procedureId, form)

    await fetchProcedureBeforeAfters(procedureId)
  }

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) {
      return // nothing is changing, no need to update
    }

    setSorting(true)

    const newArray = arrayMove(beforeAfters, oldIndex, newIndex)

    const promises = newArray.map((item, index) => {
      const newOrder = index + 1
      if (Number(item.order) !== newOrder) {
        return updateProcedureBeforeAfter(item.id, { order: newOrder })
      }
      return null
    })

    await Promise.all(promises.filter(Boolean))
    await fetchProcedureBeforeAfters(procedureId)
    setSorting(false)
  }

  return (
    <div className="primary-content">
      <ImageList
        pressDelay={100}
        onSortEnd={onSortEnd}
        items={beforeAfters}
        headers={['Total Afters', 'Before / Afters', 'Is Active?', 'Created', 'Last Updated', '']}
        loading={loading || sorting}>
        {beforeAfters.map((set, i) => (
          <Image
            key={set.id}
            index={i}
            set={set}
            onEdit={() => startEditing(set)}
            onDelete={() => deleteBeforeAfter(set)}
          />
        ))}
      </ImageList>

      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        containerClassName="modal-content procedures-before-and-after-form">
        <ProceduresAddBeforeAfterForm
          initialValues={initialValues}
          onClose={() => setShowModal(false)}
          onFormSubmit={onFormSubmit}
          onSubmitSucceeded={() => setShowModal(false)}
        />
      </Modal>

      <div className="text-right mb-2 mt-2">
        <button
          onClick={() => startEditing()}
          disabled={loading}
          className={`btn btn-primary ${loading ? 'loading' : ''}`}>
          Upload New Image Set
        </button>
      </div>
    </div>
  )
}

export const byMetaOrder = (first, second) => {
  const firstOrder = Number(first?.meta?.order)
  const secondOrder = Number(second?.meta?.order)

  return firstOrder - secondOrder
}

const ImageList = sortableContainer(Table)
const Image = sortableElement(({ set, onEdit, onDelete }) => {
  const deleted = !!set.date_deleted

  return (
    <tr>
      <td>{set.after_images.length || '?'}</td>
      <td>
        <div className="image-collection-wrapper">
          <span className="image-wrapper" style={{ zIndex: (set.after_images?.length || 0) + 1 }}>
            <img src={set.before_image.url} alt="" />
          </span>

          {set.after_images
            .sort(byMetaOrder)
            .slice(0, 6)
            .map((image, index) => (
              <span
                className={`image-wrapper number-${index + 1}`}
                key={index}
                style={{ zIndex: set.after_images.length - index }}>
                <img src={image.url} alt="" />
              </span>
            ))}
        </div>
      </td>
      <td>{deleted ? 'No' : 'Yes'}</td>
      <td>{set.date_created ? dayjs(set.date_created).format('MM/DD/YY') : '?'}</td>
      <td>{set.date_updated ? dayjs(set.date_update).format('MM/DD/YY') : '?'}</td>
      <td>
        <button className="btn btn-link btn-action btn-lg" disabled={deleted} onClick={onEdit}>
          <i className="icon icon-edit" />
        </button>

        <button className="btn btn-link btn-action btn-lg" disabled={deleted} onClick={onDelete}>
          <i className="icon icon-delete" />
        </button>
      </td>
    </tr>
  )
})

const mapStateToProps = ({ ui, procedures }) => {
  return {
    loading: ui.loading,
    beforeAfters: procedures.beforeAfters,
  }
}

export default connect(
  mapStateToProps,
  {
    fetchProcedureBeforeAfters,
    createProcedureBeforeAfter,
    updateProcedureBeforeAfter,
    deleteProcedureBeforeAfter,
  }
)(ProcedureBeforeAfters)
