const defaultState = {
  all: [],
  currentPlan: {},
  currentFlags: [],
  currentFlag: {},
}

export default function(state = defaultState, action) {
  switch (action.type) {

    // Plan definitions

    case 'FETCHED_PLAN_ALL':
      return {
        ...state,
        all: action.payload.data,
      }
    case 'FETCHED_PLAN':
      return {
        ...state,
        currentPlan: action.payload,
      }
    case 'UPDATED_PLAN':
      return {
        ...state,
        currentPlan: action.payload,
      }
    case 'CREATED_PLAN':
      return {
        ...state,
        currentPlan: action.payload,
      }

    // Flags attached to currentPlan

    case 'FETCHED_PLAN_FLAG_ALL':
      return {
        ...state,
        currentFlags: action.payload,
      }
    case 'FETCHED_PLAN_FLAG':
      return {
        ...state,
        currentFlag: action.payload,
      }
    case 'UPDATED_PLAN_FLAG':
      return {
        ...state,
        currentFlag: action.payload,
      }
    case 'CREATED_PLAN_FLAG':
      return {
        ...state,
        currentFlag: action.payload,
      }

    default:
      return state
  }
}
