import { FLAG_TYPES } from 'config/constants'

/**
 * Converts API entities into FlagForm props.
 *
 * @param definition Flag definition (shared by all plan definitions)
 * @param allAccountTypesFlag Flag attached to a plan definition and no account type
 *        (shared by all accounts who have this plan activated)
 * @param flag Flag attached to plan definition and a non-null account type
 *        (shared by all users/providers who have this plan activated)
 */
export const createPropsFromEntities = ({
  definition,
  allAccountTypesFlag,
  sameAccountTypeFlag,
  accountFlag,
}) => {
  const { slug, description, type } = definition
  const { is_active, value, date_created, date_updated } = accountFlag
  const { value_value, value_min, value_max } = createValuePropsFromEntities({ type, value })

  return {
    slug,
    description,
    is_active,
    type,
    value,
    value_value,
    value_min,
    value_max,
    date_created,
    date_updated,
    definition,
    allAccountTypesFlag,
    sameAccountTypeFlag,
  }
}

/**
 * Converts FlagForm props into API entities.
 */
export const createEntitiesFromProps = ({
  slug,
  description,
  is_active,
  type,
  value_value,
  value_min,
  value_max,
}) => {
  const value = createEntityValueFromProps({ type, value_value, value_min, value_max })

  return {
    definition: {
      slug,
      type,
      description,
      is_active: false,
      value,
    },
    flag: {
      value,
      is_active,
    },
  }
}

/**
 * Converts API field "value" into FlagForm props.
 */
export const createValuePropsFromEntities = ({ type, value }) => {
  if (type === FLAG_TYPES.BOOL.id) {
    // value is a bool
    return { value_value: `${+value}` }
  } else if (type === FLAG_TYPES.INT_RANGE.id) {
    // min, max are numbers
    const { min, max } = value || {}
    return {
      value_min: `${isset(min) ? min : ''}`,
      value_max: `${isset(max) ? max : ''}`,
    }
  } else {
    return {}
  }
}

/**
 * Converts FlagForm props into API field "value".
 */
export const createEntityValueFromProps = ({ type, value_value, value_min, value_max }) => {
  if (type === FLAG_TYPES.BOOL.id) {
    // value is a string with a single digit ("0", "1")
    return isset(value_value) ? !!parseInt(value_value) : null
  } else if (type === FLAG_TYPES.INT_RANGE.id) {
    // min, max are strings with numbers (e.g. "123")
    return {
      min: isset(value_min) && value_min.length > 0 ? parseInt(value_min) : null,
      max: isset(value_max) && value_max.length > 0 ? parseInt(value_max) : null,
    }
  }
}

export function isset(val) {
  return typeof val !== 'undefined' && val !== null
}
