import React from 'react'
import classnames from 'classnames'

export const TrainingButton = props => {
  const { toggleTrainingStatus, image = {}, lightBoxOpen } = props
  const { meta = {}, training_status: status = null } = image

  return (
    meta?.name === 'original_image' &&
    image?.flagged_reason && (
      <div
        onClick={() => toggleTrainingStatus(image)}
        className={classnames('training-button', {
          'training-button-red': status === 'pending',
          'training-button-green': status === 'completed',
          'training-button-modal': lightBoxOpen,
        })}>
        {status === 'pending'
          ? 'In training set'
          : status === 'completed'
          ? 'Completed'
          : 'Ready to train'}
      </div>
    )
  )
}
