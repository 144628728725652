import { authedRequest } from '../config/config'

export const removeStepFromTimeline = (timelineId, stepIds) => async dispatch => {
  try {
    const res = await authedRequest.delete(`/procedure/timeline/${timelineId}/step`, {
      params: {
        step_ids: stepIds,
      },
    })

    if (res.data.error) {
      throw new Error(res.data.error.message)
    }

    dispatch({ type: 'UPDATED_TIMELINE', payload: res.data.data })
  } catch (err) {
    dispatch({ type: 'FAILURE', payload: err })
  }
}

export const addStepToTimeline = (timelineId, arr) => async dispatch => {
  try {
    const res = await authedRequest.post(`/procedure/timeline/${timelineId}/step`, {
      steps: arr,
    })

    if (res.data.error) {
      throw new Error(res.data.error.message)
    }

    dispatch({ type: 'UPDATED_TIMELINE', payload: res.data.data })
  } catch (err) {
    dispatch({ type: 'FAILURE', payload: err })
  }
}
