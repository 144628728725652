import React, {Component} from 'react'
import {Table} from '../../components/table'
import {Link} from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import {connect} from 'react-redux'
import SearchBar from '../../components/searchbar'
import {Checkmark} from '../../components/checkmark'
import {fetchItems} from '../../actions'

class ConcernsIndex extends Component {

  componentDidMount() {
    this.props.fetchItems('concern', { order_by: 'name', order_by_direction: 'asc' })
  }

  onPageChange = ({selected}) => {
    this.props.fetchItems('concern', { _page: selected + 1 })
  }

  render() {
    const { concerns, loading, fetchItems } = this.props
    const tableHeaders = ['ID', 'Name', 'Active']

    return (
      <div className="primary-content">
        <div className="controls">
          <Link className="pull-right btn btn-primary" to="/concerns/new">Add New Concern</Link>
          <SearchBar fetchItems={fetchItems} type="concern" />
        </div>
        <Table items={concerns.all} loading={loading} headers={tableHeaders}>
          { concerns.all.map(concern => (
            <tr key={concern.id}>
              <td>{concern.id}</td>
              <td><Link to={`/concerns/${concern.id}`}>{concern.name}</Link></td>
              <td><Checkmark bool={!!concern.is_active} /></td>
            </tr>
          ))}
        </Table>
        <ReactPaginate
          pageCount={concerns.totalPages}
          marginPagesDisplayed={1}
          pageRangeDisplayed={5}
          onPageChange={this.onPageChange}
          containerClassName="pagination"
          activeClassName="active"
          pageClassName="page-item"
          previousClassName="page-item"
          nextClassName="page-item"
        />
      </div>
    )
  }
}


const mapStateToProps = ({concerns, ui}) => {
  return {
    concerns: concerns,
    loading: ui.loading
  }
}

export default connect(mapStateToProps, { fetchItems })(ConcernsIndex)
