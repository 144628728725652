import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { authedRequest } from 'config/config'
import ReviewForm from '../_form'

const ProviderReviewsShow = () => {
  const { provider_id, review_id } = useParams()
  const [review, _setReview] = useState()
  const [reviews, _setReviews] = useState(null)

  const fetchReviews = async () => {
    const data = {
      params: {
        provider_id,
      },
    }

    const res = await authedRequest.get(`/provider/reviews`, data)

    _setReview(res?.data?.data?.find(review => review.id == review_id))
    _setReviews(res?.data?.data?.filter(review => review.id != review_id))
  }

  // Fetch Reviews
  useEffect(() => {
    fetchReviews()
  }, [])

  return (
    <div className="t__products-new">
      <h3>Edit Review {review_id}</h3>

      {reviews ? <ReviewForm review={review} reviews={reviews} providerId={provider_id} /> : <></>}
    </div>
  )
}

export default ProviderReviewsShow
