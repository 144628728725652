import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createItem, fetchItems } from 'actions'
import moment from 'moment'
import { convertObjectsToIds } from 'config/util'
import ProviderForm from '../_form'

class ProvidersNew extends Component {
  onFormSubmit = ({
    location,
    date_verified_by_aedit,
    education_graduation_institution,
    education_undergrad_institution,
    has_consultation_fee,
    consultation_fee,
    licenses,
    ...submitData
  }) => {
    const convertables = [
      'degrees',
      'types',
      'specialties',
      'practice_ids',
      'area_ids',
      'fellowship_trained_in',
      'board_certifications',
      'gender',
    ]

    licenses = licenses ? licenses.filter(license => license.state && license.state !== '') : ''
    licenses = licenses
      ? licenses.map(
          license =>
            (license = {
              state: license.state.abbreviation ? license.state.abbreviation : license.state,
              license_start: license.license_start,
              license_end: license.license_end,
            })
        )
      : ''
    licenses = licenses.length > 0 ? licenses : ''

    const converted = convertObjectsToIds(submitData, convertables)

    if (submitData.is_verified) {
      date_verified_by_aedit = moment()
    }

    const education_undergrad_institution_id = education_undergrad_institution
      ? education_undergrad_institution.id
      : null
    const education_graduation_institution_id = education_graduation_institution
      ? education_graduation_institution.id
      : null

    const consultationFee = has_consultation_fee > 0 ? consultation_fee : 0

    this.props.createItem('provider', {
      ...submitData,
      ...converted,
      licenses,
      education_undergrad_institution_id,
      education_graduation_institution_id,
      date_verified_by_aedit,
      has_consultation_fee,
      place_id: location && location.place_id ? location.place_id : '',
      consultation_fee: consultationFee,
    })
  }

  componentWillReceiveProps(nextProps) {
    const currentId = this.props.provider.id
    const nextId = nextProps.provider.id

    if (nextId && nextId !== currentId) {
      // transition to the show/edit view for newly created record
      this.props.history.push(`/providers/${nextId}`)
    }
  }

  componentDidMount() {
    this.props.fetchItems('area', { _limit: 9999 })
  }

  render() {
    const { fetchItems, loading, practices, areas, campuses, states, config } = this.props
    const defaultSalutation = Object.values(config.SALUTATION).length
      ? Object.values(config.SALUTATION)[0].id
      : null

    return (
      <div className="primary-content">
        <div className="controls">
          <h4>Add a New Provider</h4>
        </div>
        <ProviderForm
          onFormSubmit={this.onFormSubmit}
          loading={loading}
          fetchItems={fetchItems}
          practices={practices}
          areas={areas}
          campuses={campuses}
          states={states}
          configOptions={config}
          initialValues={{
            salutation: defaultSalutation,
            has_voucher: 1,
            phone_country: 'US',
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ ui, providers, practices, areas, campuses, config }) => {
  return {
    loading: ui.loading,
    provider: providers.current,
    practices: practices.all,
    areas: areas.all,
    campuses: campuses.all,
    config: config.provider,
    states: config.location.US_STATE_ABBREVIATIONS,
    locations: config.location,
  }
}

export default connect(mapStateToProps, { createItem, fetchItems })(ProvidersNew)
