import React, { useState, useEffect, useMemo } from 'react'
import _ from 'lodash'
import { FormGroup } from '../form.js'
import './index.scss'

const TranscriptionEditor = props => {
  const {
    input,
    className = '',
    meta: { touched, error, active },
  } = props

  const showError = error && (touched || active)

  const initialData = useMemo(() => unpackValue(input).data, [])
  const [data, setData] = useState(initialData)

  useEffect(() => {
    // insert new empty item at the end of the list
    if (!data.some(item => item.draft)) {
      setData([
        ...data,
        {
          draft: true,
          group: '',
          offset: '',
          value: '',
        },
      ])
    }
  }, [data])

  const onChange = (index, item) => {
    item = { ...item }
    delete item.draft

    const newData = [...data]
    newData[index] = item

    setData(newData)
    input.onChange(packValue(input, newData))
  }

  const onRemove = index => {
    const newData = [...data]
    newData.splice(index, 1)

    setData(newData)
    input.onChange(packValue(input, newData))
  }

  const onBlur = () => {
    input.onBlur(packValue(input, data))
  }

  const onFocus = () => {
    input.onFocus(packValue(input, data))
  }

  return (
    <>
      <div className={`transcription-editor ${className}`}>
        {data.map((item, index) => (
          <div key={index} className="d-flex">
            <FormGroup label="Group" editable>
              <input
                type="text"
                className="form-input"
                placeholder="Group"
                value={item.group}
                onChange={event => onChange(index, { ...item, group: event.target.value })}
                onBlur={onBlur}
                onFocus={onFocus}
              />
            </FormGroup>
            <FormGroup label="Offset" editable>
              <input
                type="text"
                className="form-input"
                placeholder="Offset"
                value={item.offset}
                onChange={event => onChange(index, { ...item, offset: event.target.value })}
                onBlur={onBlur}
                onFocus={onFocus}
              />
            </FormGroup>
            <FormGroup label="Text" editable className="transcription-editor__text">
              <textarea
                className="form-input"
                placeholder="Text"
                value={item.value}
                onChange={event => onChange(index, { ...item, value: event.target.value })}
                onBlur={onBlur}
                onFocus={onFocus}
              />
            </FormGroup>
            <span className="transcription-editor__action" onClick={() => onRemove(index)}>
              <i className="fa fa-times" />
            </span>
          </div>
        ))}
      </div>
      {showError && <span className="field-error">{error}</span>}
    </>
  )
}

const unpackValue = input => {
  // converts form input from redux form format into our internal format
  // (opposite of packValue)
  const raw = _.get(input.value, 'data', [])
  const unpacked = unpackTranscript(raw)
  return {
    ...input.value,
    data: unpacked,
  }
}

const unpackTranscript = data => {
  // ungroups transcript lines and flattens the result array
  // (opposite of packTranscript)
  const result = []
  for (const obj1 of data) {
    for (const [group, items] of Object.entries(obj1)) {
      for (const obj2 of items) {
        for (const [offset, value] of Object.entries(obj2)) {
          result.push({ group, offset, value })
        }
      }
    }
  }
  return result
}

const packValue = (input, data) => {
  // converts data in our internal format into a format that is expected by redux form
  // (opposite of unpackValue)
  const packed = packTranscript(data)
  return {
    ...input.value,
    data: packed,
  }
}

const packTranscript = data => {
  // groups transcript lines and nests the result arrays
  // (opposite of unpackTranscript)
  const sorted = [...data].sort((a, b) => +a.offset - +b.offset)
  const result = {}
  for (const { group, offset, value } of sorted) {
    if (group.length || offset.length || value.length) {
      appendString(result, [group, offset], value)
    }
  }
  return objectToArray(result, ([a], [b]) => +a - +b, 2)
}

const appendString = (object, path, value, trim = true, separator = ' ') => {
  const previousValue = _.get(object, path)
  const newValue = [
    previousValue,
    value,
  ].filter(Boolean).map(v => trim ? v.trim() : v).join(separator)

  return _.set(object, path, newValue)
}

const objectToArray = (object, compareFn, depth = 1) => {
  if (depth <= 0) {
    return object
  }

  const sorted = Object.entries(object).sort(compareFn)
  const result = []

  for (const [key, value] of sorted) {
    result.push({
      [key]: objectToArray(value, compareFn, depth - 1),
    })
  }

  return result
}

export const validateTranscription = (name, input) => {
  const data = _.get(input, 'data', [])
  const errors = {}

  for (const obj1 of data) {
    for (const [group, items] of Object.entries(obj1)) {
      if (!isValidTime(group)) {
        return _.set(errors, name, `Group ${group} not formatted properly. Must be in mm:ss format.`)
      }

      for (const obj2 of items) {
        for (const offset of Object.keys(obj2)) {
          if (!isValidOffset(offset)) {
            return _.set(errors, name, `Offset ${offset} not formatted properly. Must be in ss.mmm format.`)
          }
        }
      }
    }
  }

  return errors
}

const isValidTime = (time, separator = ':') => {
  time = `${time}`
  const parts = time.split(separator)
  return parts.length === 2 && parts.every(pt => /^\d+$/.test(pt)) && +parts[1] < 60
}

const isValidOffset = (offset, separator = '.') => {
  offset = `${offset}`
  const parts = offset.split(separator)
  return parts.length > 0 && parts.length <= 2 && parts.every(pt => /^\d+$/.test(pt))
}

export default TranscriptionEditor
