const defaultState = {
  users: {},
  providers: {},
  newsletter: {}
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case 'FETCHED_STATISTICS_ALL':
      return {
        ...state,
        users: action.payload.data.users.by_platform,
        providers: action.payload.data.providers,
        newsletter: action.payload.data.newsletter
      }
    default:
      return state
  }
}
