const defaultState = {
  topAnalytics: {
    provider: [],
    procedure: [],
    concern: [],
  },
  current: {
    totalViews: null,
  },
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case 'FETCHED_ENTITY_ANALYTICS':
      return {
        ...state,
        current: {
          totalViews:
            action.payload.length === 0
              ? 0 // kind of a sentinel. a 0 means zero views. null means not loaded
              : action.payload[0].analytics.total_views,
        },
      }
    case 'FETCHED_TOP_ANALYTICS':
      return {
        ...state,
        topAnalytics: action.payload,
      }
    case 'CLEAR_ENTITY_ANALYTICS':
      return { ...state, current: { ...defaultState.current } }
    default:
      return { ...state }
  }
}
