import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { createItem } from '../../actions'
import store from '../../reducers'
import PlanForm from './_plan_form'

const PlansNew = ({ createItem, currentPlan: { id: planId }, history, loading }) => {
  useEffect(() => {
    if (planId) {
      history.push('/plans')
    }

    // clear out the currentPlan object so that its properties don't potentially override
    // another plan's
    return () => {
      store.dispatch({
        type: 'UPDATED_PLAN',
        payload: {},
      })
    }
  }, [history, planId])

  return (
    <div className="primary-content">
      <div className="controls">
        <h4>Add a New Plan</h4>
      </div>
      <PlanForm
        onFormSubmit={data => createItem('plan', data)}
        loading={loading}
      />
    </div>
  )
}

const mapStateToProps = ({ plans, ui }) => {
  return {
    currentPlan: plans.currentPlan,
    loading: ui.loading,
  }
}

export default connect(mapStateToProps, { createItem })(PlansNew)