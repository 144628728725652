import React, { useState } from 'react'
import { Form, Input, Checkbox, Reset } from '@aedit/styleguide/dist/form.js'

const Filters = props => {
  const { Morph, defaultValues } = props
  const { Nose, Filter, EyebrowErase, Constants } = Morph
  const [showMorph, _setShowMorph] = useState(false)

  const checkboxOptions = [
    {
      label: showMorph ? 'Show Morph' : 'Show Original',
      value: "",
    },
  ]

  const applyUpperLip = val => {
    let lipval = parseInt(val[0]) / 100

    Constants.WASM._set_upper_lip_width_cpp(lipval)
    Constants.WASM._set_lip_gloss_level_cpp(0)
    Constants.WASM._set_upper_lip_sheen_level_cpp(-1)
  }

  const applyLowerLip = val => {
    let lipval = parseInt(val[0]) / 100

    Constants.WASM._set_lower_lip_width_cpp(lipval)
    Constants.WASM._set_lip_gloss_level_cpp(0)
    Constants.WASM._set_lower_lip_sheen_level_cpp(-1)
  }

  const applyLipLift = val => {
    let lipval = parseInt(val[0]) / 100

    Constants.WASM._set_lip_lift_level_cpp(lipval)
  }

  const setJawline = val => {
    let jawline = parseInt(val[0]) / 100

    Constants.WASM._set_jawline_reduction_level_cpp(jawline)
  }

  const setNoseBridgeRange = val => {
    let noseval = parseInt(val[0]) / 100

    Constants.WASM._set_nose_bridge_width_cpp(noseval)
  }

  const setNoseStraightener = val => {
    let noseval = parseInt(val[0]) / 100

    Constants.WASM._set_nose_curve_move_cpp(noseval)
  }

  const setNoseAlarBase = val => {
    let noseval = parseInt(val[0]) / 100

    Constants.WASM._set_nose_alar_cpp(noseval)
  }

  const setTipReduction = val => {
    let noseval = parseInt(val[0]) / 100

    Constants.WASM._set_nose_tip_size_cpp(noseval)
  }

  const setNoseElevation = val => {
    let noseval = parseInt(val[0]) / 100

    Constants.WASM._set_nose_tip_move_y_cpp(noseval)
  }

  const setBumpRemoval = val => {
    let noseval = parseInt(val[0]) / 100

    Constants.WASM._set_nose_midvault_reduce_cpp(noseval)
  }

  const setChin = val => {
    let chinval = parseInt(val[0]) / 100

    Constants.WASM._set_chin_augmentation_level_cpp(chinval)
  }

  const setLeftEyebrowHorizontal = val => {
    let value = parseInt(val[0]) / 100

    Constants.WASM._set_brow_left_move_x_cpp(value)
    Constants.WASM._set_eyebrow_left_move_x_cpp(value)
  }

  const setRightEyebrowHorizontal = val => {
    let value = parseInt(val[0]) / 100

    Constants.WASM._set_brow_right_move_x_cpp(value)
    Constants.WASM._set_eyebrow_right_move_x_cpp(value)
  }

  const setLeftEyebrowVertical = val => {
    let value = parseInt(val[0]) / 100

    Constants.WASM._set_brow_left_move_y_cpp(value)
    Constants.WASM._set_eyebrow_left_move_y_cpp(value)
  }

  const setRightEyebrowVertical = val => {
    let value = parseInt(val[0]) / 100

    Constants.WASM._set_brow_right_move_y_cpp(value)
    Constants.WASM._set_eyebrow_right_move_y_cpp(value)
  }

  const browLift = val => {
    let value = parseInt(val[0]) / 100

    Constants.WASM._set_brow_lift_up_cpp(value)
  }

  const setEyebagRemoval = val => {
    let eyebagVal = parseInt(val[0]) / 100

    // Filter.set_smoothing_filter(1)
    // Filter.set_eyebag_level(eyebagVal)

    EyebrowErase.eyebrow_erase() // HACK: this has to be called here...
  }

  const setOriginal = e => {
    _setShowMorph(e.target.checked)

    Constants.WASM._set_original_face_disp_cpp(showMorph ? 0 : 1)
  }

  return (
    <Form defaultValues={defaultValues}>
      <h3>Chin</h3>
      <Input name="chin" type="range" min={0} max={100} onUpdate={setChin} />

      <h3>Nose</h3>
      <Input
        name="nose_straightener"
        type="range"
        min={-100}
        max={100}
        onUpdate={setNoseStraightener}
      />
      <Input name="bridge" type="range" min={-35} max={100} onUpdate={setNoseBridgeRange} />
      <Input name="alar_base" type="range" min={-100} max={100} onUpdate={setNoseAlarBase} />
      <Input name="tip_reduction" type="range" min={-100} max={100} onUpdate={setTipReduction} />
      <Input name="nose_elevation" type="range" min={-100} max={100} onUpdate={setNoseElevation} />
      <Input name="bump_removal" type="range" min={-100} max={100} onUpdate={setBumpRemoval} />
      <h3>Lips</h3>
      <Input name="upper_lip" type="range" min={-25} max={50} onUpdate={applyUpperLip} />
      <Input name="lower_lip" type="range" min={-25} max={50} onUpdate={applyLowerLip} />
      <Input name="lip_lift" type="range" min={-25} max={50} onUpdate={applyLipLift} />
      {/* <h3>Bag Removal</h3> */}
      {/* <Input name="eyebags" type="range" min={0} max={100} onUpdate={setEyebagRemoval} /> */}
      <h3>Eyebrow</h3>
      <Input name="brow_lift" type="range" min={-25} max={50} onUpdate={browLift} />
      <Input
        name="left_eyebrow_vertical"
        type="range"
        min={-25}
        max={50}
        onUpdate={setLeftEyebrowVertical}
      />
      <Input
        name="right_eyebrow_vertical"
        type="range"
        min={-25}
        max={50}
        onUpdate={setRightEyebrowVertical}
      />
      <Input
        name="left_eyebrow_horizontal"
        type="range"
        min={-25}
        max={50}
        onUpdate={setLeftEyebrowHorizontal}
      />
      <Input
        name="right_eyebrow_horizontal"
        type="range"
        min={-25}
        max={50}
        onUpdate={setRightEyebrowHorizontal}
      />
      <h3>Jawline</h3>
      <Input name="jawline" type="range" min={-100} max={100} onUpdate={setJawline} />
      <span className="morph-buttons">
        <Checkbox
          className="original-button"
          name="original"
          options={checkboxOptions}
          onClick={setOriginal}
        />
        <Reset />
      </span>
    </Form>
  )
}

export default Filters
