import React, { useEffect, useState } from 'react'
import { toastr } from 'react-redux-toastr'
import { Tabs, TabList, Tab, TabPanels } from 'components/tabs'
import { callApi } from 'config/config'
import AppointmentLeadsForm from './_form'

const AppointmentLeadsShow = props => {
  const { match } = props
  const id = match.params.id

  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)
  const { lead, user, provider, procedure } = data

  useEffect(() => {
    fetchData()
  }, [id])

  const fetchData = async () => {
    try {
      setLoading(true)

      const lead = await callApi('get', `/appointment_leads/${id}`)
      const { user_id, provider_id, procedure_id } = lead

      const promises = [
        user_id && callApi('get', `/user/${user_id}`),
        provider_id && callApi('get', `/provider/${provider_id}`),
        procedure_id && callApi('get', `/procedure/${procedure_id}`),
      ].filter(Boolean)
      const [user, provider, procedure] = await Promise.all(promises)

      setData({ lead, user, provider, procedure })
    } catch (e) {
      toastr.error(e.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="primary-content">
      <Tabs>
        <TabList>
          <Tab title="General" />
        </TabList>

        <TabPanels loading={loading}>
          {!loading && (
            <AppointmentLeadsForm
              lead={lead}
              user={user}
              provider={provider}
              procedure={procedure}
            />
          )}
        </TabPanels>
      </Tabs>
    </div>
  )
}

export default AppointmentLeadsShow
