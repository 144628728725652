import dayjs from 'dayjs'
import React, { useState, useContext, createContext, useEffect } from 'react'
import { toastr } from 'react-redux-toastr'
import { BEURO__Icon, BEURO__P, Portal } from '@aedit/styleguide/dist/components.js'
import { classes } from '@aedit/styleguide/dist/util.js'
import { Loading } from 'components/loading'
import { downloadZippedMorphStills, getMorphStills } from 'config/util/media'
import { MorphApp } from './app'
import { MorphCarousel } from './carousel'
import Filters from './filters'
import FLAMEFilters from './filters/flame'
import Playback from './playback'
import './index.scss'

const TOOLS = {
  NONE: 0,
  ROTATE: 1,
  ZOOM_IN: 2,
  ZOOM_OUT: 3,
  STILLS: 4,
  MEASUREMENTS: 5,
}

const MorphPortalContext = createContext({
  activeTool: TOOLS.NONE,
  setActiveTool: () => {},
})

/**
 * Morph portal for 3D assets
 * @constructor
 * @param {Object} props
 * @param {Object} props.currentAedit
 * @param {Object} props.Morph - The Morphing component that contains all the tools
 */
const MorphPortal = props => {
  const {
    currentAedit: _media,
    Morph,
    showRotate = true,
    showZoom = true,
    showFlame = true,
    showFlameSimulation = true,
    showStills = true,
    showMeasurements = true,
    showFilters = true,
    onClose = () => {},
  } = props

  const defaultFilterValues = {
    nose_straightener: [0],
    bridge: [0],
    alar_base: [0],
    tip_reduction: [0],
    nose_elevation: [0],
    bump_removal: [0],
    upper_lip: [0],
    lower_lip: [0],
    lip_lift: [0],
    jawline: [0],
    horizontal: [0],
    brow_lift: [0],
    left_eyebrow_vertical: [0],
    left_eyebrow_horizontal: [0],
    right_eyebrow_vertical: [0],
    right_eyebrow_horizontal: [0],
    eyebags: [0],
    chin: [0],
    original: [],
  }

  const defaultPlaybackValues = {
    playing: [],
     " " : [0]
  }

  const [media, _setMedia] = useState(_media)
  const [activeButton, _setActiveButton] = useState(TOOLS.NONE)
  const [canvasLoaded, _setCanvasLoaded] = useState(false)
  const [measurement, _setMeasurement] = useState(2)
  const [zoomLevel, _setZoomLevel] = useState(0)
  const [downloadingStills, _setDownloadingStills] = useState(false)
  const [filtersValues, _setFiltersValues] = useState(defaultFilterValues)
  const [playbackValues, _setPlaybackValues] = useState(defaultPlaybackValues)
  const [hasLandmarks, _setHasLandmarks] = useState(false)

  // FLAME
  if (media?.meta?.flame_parameters && !media?.meta?.flame?.params) {
    media.meta.flame = media.meta.flame || {}
    media.meta.flame.params = media.meta.flame_parameters
  }
  const [FLAMEMode, _setFLAMEMode] = useState(0)
  const [FLAMEParams, _setFLAMEParams] = useState()
  const [gender, _setGender] = useState()
  const isStaticFLAME = FLAMEMode === Morph.Constants.FLAMEModeTypes.STATIC
  const isDynamicFLAME = FLAMEMode === Morph.Constants.FLAMEModeTypes.DYNAMIC
  const [isDynamicFLAMEReady, _setIsDynamicFLAMEReady] = useState(false)

  const stills = getMorphStills(media)

  const showTools =
    showRotate || showZoom || (showStills && stills.length) || showMeasurements || showFlame

  const showControls = [TOOLS.STILLS, TOOLS.MEASUREMENTS].includes(activeButton) || showFilters

  const init = media => {
    reset()
    checkLandmarks(media)
    _setMedia(media)
  }

  const close = () => {
    _setMedia(null)
    reset()
    onClose?.()
  }

  const reset = () => {
    Morph.Interaction.clearCanvasInteraction()
    _setActiveButton(TOOLS.NONE)
    _setFLAMEMode(0)
    _setMeasurement(2)
    _setZoomLevel(0)
    _setDownloadingStills(false)
    _setFiltersValues(defaultFilterValues)
    _setPlaybackValues(defaultPlaybackValues)
    _setHasLandmarks(false)
    _setFLAMEParams(null)
    _setGender(null)
    _setIsDynamicFLAMEReady(false)
  }

  const handleTool = newButton => {
    // deactivate current tool
    switch (activeButton) {
      case TOOLS.ROTATE:
        Morph.Interaction.clearCanvasInteraction()
        break
    }

    // activate new tool
    switch (newButton) {
      case activeButton:
        newButton = TOOLS.NONE
        break

      case TOOLS.ROTATE:
        Morph.Interaction.setCanvasInteraction()
        break
    }

    _setActiveButton(newButton)
  }

  const handleMorphClick = () => {
    if (activeButton === TOOLS.ZOOM_IN) {
      _setZoomLevel(prev => prev + 1)
    }

    if (activeButton === TOOLS.ZOOM_OUT) {
      _setZoomLevel(prev => prev - 1)
    }
  }

  const downloadStills = async () => {
    try {
      _setDownloadingStills(true)
      const { first_name, last_name } = media?.user ?? {}
      const timestamp = dayjs(media?.date_created).format('MM-DD-YYYY_hh-mm-A')
      const filename = [first_name, last_name, timestamp].filter(Boolean).join('_') + '.zip'
      await downloadZippedMorphStills(media, filename)
    } finally {
      _setDownloadingStills(false)
    }
  }

  const checkLandmarks = media => {
    if (media?.id) {
      const landmarks = media?.meta?.detection_classification_values?.dlib?.data
      const hasLandmarks = Boolean(Array.isArray(landmarks) && landmarks?.length)
      _setHasLandmarks(hasLandmarks)

      if (!hasLandmarks) {
        handleError(new Error('Media does not have landmarks'))
      }
    }
  }

  const handleError = err => {
    toastr.error(err.message)
  }

  const morphClassNames = classes([
    'portal-content--container',
    {
      '--hidden': activeButton === TOOLS.STILLS,
      '--zoom-in': activeButton === TOOLS.ZOOM_IN,
      '--zoom-out': activeButton === TOOLS.ZOOM_OUT,
      '--wide-tools': isDynamicFLAME,
      // override margins that are meant to make space for the right panel with tools
      'ml-0': !showTools && !showControls,
      'mr-0': !showTools && !showControls,
    },
  ])

  useEffect(() => {
    if (Morph) {
      init(_media)
    }
  }, [Morph, _media?.id])

  useEffect(() => {
    if (!window.__aedit_flame) {
      window.__aedit_flame = {}
    }
  })

  if (!Morph) {
    return null
  }

  return (
    <MorphPortalContext.Provider value={{ activeTool: activeButton, setActiveTool: handleTool }}>
      <Morph.ParentContext.Provider
        value={{
          canvasLoaded,
          _setCanvasLoaded,
          filtersValues,
          playbackValues,
          toggleMeasurements: activeButton === TOOLS.MEASUREMENTS,
          FLAMEMode,
          _setFLAMEMode,
          FLAMEParams,
          _setFLAMEParams,
          gender,
          _setGender,
          zoomLevel,
          _setZoomLevel,
          measurement,
          _setMeasurement,
          _setIsDynamicFLAMEReady,
          FLAMEModels: window.__aedit_flame,
          media,
        }}>
        <Portal.Full
          header={<div>{media?.user?.email}</div>}
          id="morph"
          className="portal-morph"
          onClose={close}>
          <div className={morphClassNames} onClick={handleMorphClick}>
            {hasLandmarks && <MorphApp media={media} Morph={Morph} />}
          </div>

          {Boolean(activeButton === TOOLS.STILLS && stills?.length && media?.id) && (
            <div className="portal-content--container">
              <MorphCarousel files={stills} />
            </div>
          )}

          <div className="morph-tools">
            {Boolean(showTools) && (
              <div className="buttons-wrapper">
                {Boolean(showRotate) && (
                  <ToolButton name="Rotate" icon="move" type={TOOLS.ROTATE} />
                )}

                {Boolean(showZoom) && (
                  <ToolButton name="Zoom In" icon="zoom-in" type={TOOLS.ZOOM_IN} />
                )}

                {Boolean(showZoom) && (
                  <ToolButton name="Zoom Out" icon="zoom-out" type={TOOLS.ZOOM_OUT} />
                )}

                {Boolean(showStills && stills?.length) && (
                  <ToolButton name="Stills" icon="film" type={TOOLS.STILLS} />
                )}

                {Boolean(showMeasurements) && (
                  <ToolButton name="Measurements" icon="compare" type={TOOLS.MEASUREMENTS} />
                )}

                {Boolean(showFlame) && (
                  <button
                    type="button"
                    className={isStaticFLAME ? 'active' : ''}
                    onClick={() => _setFLAMEMode(isStaticFLAME ? 0 : 1)}>
                    <BEURO__P.Body>High Resolution</BEURO__P.Body>
                  </button>
                )}

                {Boolean(showFlameSimulation) && (
                  <button
                    type="button"
                    className={isDynamicFLAME ? 'active' : ''}
                    disabled={!media?.meta?.flame?.params}
                    onClick={() => _setFLAMEMode(isDynamicFLAME ? 0 : 2)}>
                    <BEURO__P.Body>FLAME Simulation</BEURO__P.Body>
                  </button>
                )}
              </div>
            )}

            {Boolean(showControls && canvasLoaded && media?.id) && (
              <div className={`slider-wrapper ${isDynamicFLAME ? '--wide-tools': ''}`}>
                {activeButton === TOOLS.MEASUREMENTS ? (
                  <div className="measurements-wrapper">
                    <button
                      className={measurement === 1 ? 'active' : ''}
                      type="button"
                      onClick={() => _setMeasurement(1)}>
                      <BEURO__P.Body>Three Courts Ratio</BEURO__P.Body>
                    </button>

                    <button
                      className={measurement === 2 ? 'active' : ''}
                      type="button"
                      onClick={() => _setMeasurement(2)}>
                      <BEURO__P.Body>Five Eye Ratio</BEURO__P.Body>
                    </button>

                    <button
                      className={measurement === 5 ? 'active' : ''}
                      type="button"
                      onClick={() => _setMeasurement(5)}>
                      <BEURO__P.Body>Side Angle</BEURO__P.Body>
                    </button>
                  </div>
                ) : activeButton === TOOLS.STILLS ? (
                  <div className="stills-wrapper">
                    <button type="button" disabled={downloadingStills} onClick={downloadStills}>
                      <BEURO__P.Body>
                        <div className="icon-holder">
                          {downloadingStills ? (
                            <Loading />
                          ) : (
                            <BEURO__Icon name="download" size="1.625rem" />
                          )}
                        </div>
                        Download Stills
                      </BEURO__P.Body>
                    </button>
                  </div>
                ) : Boolean(showFilters) ? (
                  <>
                    {isDynamicFLAME ?  (
                      <>
                        {isDynamicFLAMEReady ? (
                          <FLAMEFilters value={FLAMEParams} onChange={_setFLAMEParams} />
                        ) : (
                          <Loading />
                        )}
                      </>
                    ) : (
                      <>
                        <Playback Morph={Morph} media={media} defaultValues={playbackValues} />
                        <Filters Morph={Morph} defaultValues={filtersValues} />
                      </>
                    )}
                  </>
                ) : null}
              </div>
            )}
          </div>
        </Portal.Full>
      </Morph.ParentContext.Provider>
    </MorphPortalContext.Provider>
  )
}

const ToolButton = props => {
  const { name, icon, type } = props
  const { activeTool, setActiveTool } = useContext(MorphPortalContext)

  const className = type === activeTool ? 'active' : ''
  const onClick = () => setActiveTool(type)

  return (
    <button type="button" className={className} onClick={onClick}>
      <BEURO__Icon name={icon} size="1.625rem" />
      <BEURO__P.Body>{name}</BEURO__P.Body>
    </button>
  )
}

export default MorphPortal
