const defaultState = {
  all: [],
  currentFlag: {},
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case 'FETCHED_FLAG_ALL':
      return {
        ...state,
        all: action.payload.data,
      }
    case 'FETCHED_FLAG':
      return {
        ...state,
        currentFlag: action.payload,
      }
    case 'UPDATED_FLAG':
      return {
        ...state,
        currentFlag: action.payload,
      }
    case 'CREATED_FLAG':
      return {
        ...state,
        currentFlag: action.payload,
      }
    default:
      return state
  }
}
