import _ from 'lodash'
import React, { useMemo, useState } from 'react'
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc'
import { Loading } from 'components/loading'

const HeroList = ({ items, loading, remove, onSortEnd: _onSortEnd }) => {
  items = items || []
  const [sorting, setSorting] = useState(false)

  const sortedItems = useMemo(() => {
    return [...items].sort((a, b) => _.get(a, 'meta.order_number') - _.get(b, 'meta.order_number'))
  }, [items])

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setSorting(true)
    try {
      const reordered = arrayMove([...sortedItems], oldIndex, newIndex)
      const newItems = reordered.map((item, index) => ({
        ...item,
        meta: {
          ...item.meta,
          order_number: index + 1,
        },
      }))

      _onSortEnd(newItems)
    } finally {
      setSorting(false)
    }
  }

  return (
    <div className="well flex-fill page-form form-horizontal">
      {(loading || sorting) ? (
        <Loading color="#333333" />
      ) : (
        <SortableList
          items={sortedItems}
          remove={remove}
          axis="xy"
          distance={1}
          onSortEnd={onSortEnd}
        />
      )}
    </div>
  )
}

const SortableList = SortableContainer(({ items, remove }) => {
  return (
    <ul className="sortable-list">
      {items.map((item, index) =>
        <SortableItem
          key={index}
          index={index}
          sortIndex={index}
          item={item}
          remove={remove}
        />,
      )}
    </ul>
  )
})

const SortableItem = SortableElement(({ sortIndex, item, remove }) => {
  return (
    <li className="sortable-item">
      <div className="sortable-item-contents">
        <img src={item.url} alt="" />
        <span className="sort-index">{sortIndex + 1}</span>
        <span className="edit-icon" onClick={() => remove(item)}>
          <i className="fas fa-times" />
        </span>
      </div>
    </li>
  )
})

export default HeroList
