import {Checkmark} from '../../components/checkmark'
import SearchBar from '../../components/searchbar'
import {Table} from '../../components/table'
import ReactPaginate from 'react-paginate'
import {fetchItems} from '../../actions'
import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import moment from 'moment'

class ReferralCodesIndex extends Component {

  componentDidMount() {
    this.props.fetchItems('referral')
  }

  onPageChange = ({selected}) => {
    this.props.fetchItems('referral', { _page: selected + 1 })
  }

  render() {
    const {loading, codes, totalPages, fetchItems} = this.props
    const tableHeaders = ['Code', 'Active', 'Date Created', 'Uses']

    return (
      <div className="primary-content">
        <div className="controls">
          <Link to="/referrals/new" className="btn btn-primary pull-right">Add New Code</Link>
          <SearchBar fetchItems={fetchItems} type="referral" />
        </div>
        <Table items={codes} headers={tableHeaders} loading={loading}>
          {
            codes.map(({id, is_active, date_created, uses, code}) => (
              <tr key={id}>
                <td><Link to={`/referrals/${id}`}>{code}</Link></td>
                <td><Checkmark bool={!!is_active} /></td>
                <td>{moment(date_created).format('MMM DD, YYYY')}</td>
                <td>{uses}</td>
              </tr>
            ))
          }
        </Table>
        <ReactPaginate
          pageCount={totalPages}
          marginPagesDisplayed={1}
          pageRangeDisplayed={5}
          onPageChange={this.onPageChange}
          containerClassName="pagination"
          activeClassName="active"
          pageClassName="page-item"
          previousClassName="page-item"
          nextClassName="page-item"
        />
      </div>
    )
  }
}


const mapStateToProps = ({ui, referrals}) => {
  return {
    loading: ui.loading,
    codes: referrals.codes,
    totalPages: referrals.totalPages
  }
}

export default connect(mapStateToProps, {fetchItems})(ReferralCodesIndex)
