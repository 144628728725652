import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { get as loGet, startCase } from 'lodash'
import { MorphApp as Morph } from 'aeditorlib-3d'
import { getPreviewImageUrl } from 'config/util/media'
import { Flagger, TrainingButton, DropDown, MetaData, MorphPortal } from './components'
import { Portal } from '@aedit/styleguide/dist/components.js'
import { Loading } from 'components/loading'
import { round } from 'config/util/abstract'

const classStructure = Object.freeze({
  ios: {
    name: 'Device Classifiers',
    keys: [
      'lighting',
      'pose',
      'blurry',
      { name: 'Color Temperature (Lumens)', key: 'raw_lighting.color_temperature' },
      { name: 'Intensity (Kelvin)', key: 'raw_lighting.intensity' },
      'raw_pose.pitch',
      'raw_pose.roll',
      'raw_pose.yaw',
    ],
  },
  base: {
    name: 'Base AEDIT Classifiers',
    keys: [
      'glasses',
      'hair_on_brow',
      'hair_on_shoulder',
      'eye_openness',
      'dark_circles',
      'eye_bag',
      'deep_wrinkle',
      'fine_lines',
      'red_brown_acne',
      'facial_hair',
      'smile',
      'wearing_hat',
      'mouth_openness',
    ],
  },
  secondary: {
    name: 'Secondary AEDIT Classifiers',
    keys: ['asian', 'makeup', 'eyelid_full_crease', 'eyelid_partial_crease'],
  },
})

const ImageList = props => {
  const {
    downloadButton = false,
    flagged,
    flaggedReasons,
    items = [],
    isMorph = false,
    onTrainingStatusSubmit,
    onTrainingStatusDelete,
    onSubmit = () => {},
    loading = false,
    onAdd = null,
    onRemove = null,
  } = props
  const [selectedImage, setSelectedImage] = useState(0)
  const [lightBoxOpen, setLightBoxOpen] = useState(false)
  const [currentUser, setCurrentUser] = useState({})

  const depthImageCheck = image => !(image?.meta?.name || '').includes('original_depth_image')
  const images = items.filter(depthImageCheck)
  const currentImage = images[selectedImage]
  const asianCreaseModel = currentImage?.meta?.asian_crease_makeup_model_name
  const fitzpatrickScale = currentImage?.meta?.fitzpatrick_scale
  const is3d = currentImage?.meta?.is_3d === 'true' ? true : false // comes through as a true/false string
  const failureReasons = currentImage?.meta?.detection_failure_reasons?.join(', ')

  const [isShowingFLAME, setIsShowingFLAME] = useState(Array(50).fill(true))

  const classifications = currentImage?.meta?.detection_classification_values || {}
  const withCaptionArea = Boolean(onTrainingStatusSubmit) || Boolean(downloadButton)
  const enableAdding = Boolean(onAdd)
  const enableRemoving = Boolean(onRemove)

  // Check for both current and list images
  const showMetaData = image => {
    const classType = image?.meta?.name
    const isOriginalImage = classType === 'original_image'
    const isDlibImage = classType === 'original_dlib_image'
    const isSharedImage = classType === 'shared_image'
    const isFailure = image?.meta?.detection_failure_reasons?.length

    return isOriginalImage || isDlibImage || isSharedImage || isFailure
  }

  const openLightbox = index => {
    setSelectedImage(index)
    setLightBoxOpen(true)
  }

  const closeLightbox = (index = 0) => {
    setSelectedImage(index)
    setLightBoxOpen(false)
  }

  const toggleIsShowingFLAME = index => {
    let updatedList = isShowingFLAME.map((value, i) => {
      return i === index ? !value : value
    })
    setIsShowingFLAME(updatedList)
  }

  const goToNext = useCallback(() => {
    const last = items.filter(depthImageCheck).length - 1

    return setSelectedImage(Math.min(selectedImage + 1, last))
  }, [selectedImage, items])

  const goToPrevious = useCallback(() => {
    return setSelectedImage(Math.max(0, selectedImage - 1))
  }, [selectedImage])

  const onDropDownChange = (image, value) => {
    if (image.is_flagged) {
      image.flagged_reason = value
      onSubmit(image)
    }
  }

  const toggleFlag = image => {
    image.is_flagged = image.is_flagged ? 0 : 1
    image.flagged_reason = image.is_flagged ? 'other' : null

    // if image is unflagged & it is in a training set, image should be removed from training set,
    // meaning 'ready to train', where training_status should be set to null
    if (!image.is_flagged && image.training_status) {
      image.training_status = null
      onTrainingStatusDelete(image.id)
    }

    onSubmit(image)
  }

  const toggleTrainingStatus = image => {
    image.training_status = image.training_status ? 'pending' : null

    if (!image.training_status) {
      onTrainingStatusSubmit(image.id)
      onSubmit(image)
    } else {
      onTrainingStatusDelete(image.id)
    }
  }

  const handleKeyChange = e => {
    if (!lightBoxOpen) return

    e.preventDefault()

    if (e.key === 'ArrowLeft') {
      return goToPrevious()
    }
    if (e.key === 'ArrowRight') {
      return goToNext()
    }

    if (e.keyCode === 27) {
      return closeLightbox()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyChange)

    return () => document.removeEventListener('keydown', handleKeyChange)
  }, [handleKeyChange])

  return (
    <div className="image-list">
      <div className="image-list--container">
        {enableAdding && (
          <div className="image-list--controls">
            <span className="pull-right btn btn-primary pointer">
              <input
                id="image-upload"
                name="image-upload"
                type="file"
                className="hidden"
                accept="image/*"
                onChange={e => onAdd(e.target.files[0])}
              />
              <label className="pointer" htmlFor="image-upload">
                Add New
              </label>
            </span>
          </div>
        )}

        <ul className="image-list--row">
          {loading ? (
            <Loading />
          ) : (
            images.map((image, index) => (
              <li key={image.id}>
                <div className="image-content">
                  <Flagger
                    image={image}
                    flagged={flagged}
                    toggle={toggleFlag}
                    lightBoxOpen={lightBoxOpen}
                  />

                  {enableRemoving && (
                    <span className="remove-button" onClick={() => onRemove(image)}>
                      <i className="fas fa-times" />
                    </span>
                  )}

                  <section>
                    {isMorph && (
                      <>
                        <div
                          onClick={() => setCurrentUser(image)}
                          className="pointer underline mb-1 text-center"
                          style={{ fontSize: 12 }}>
                          <Portal.ToggleOpen id="morph">{image?.user?.email}</Portal.ToggleOpen>
                        </div>
                      </>
                    )}

                    <img
                      onClick={() => openLightbox(index)}
                      className="image mb-1"
                      src={getPreviewImageUrl(image, isShowingFLAME[index])}
                      alt="aedit"
                    />

                    {isMorph &&
                      image.meta.related_files.find(
                        img => img.original_filename === '0_0_FLAME.png'
                      ) && (
                        <>
                          <div
                            onClick={() => toggleIsShowingFLAME(index)}
                            className="pointer underline text-center"
                            style={{ fontSize: 12 }}>
                            Toggle Image
                          </div>
                        </>
                      )}

                    {withCaptionArea && (
                      <div className="media-caption">
                        {onTrainingStatusSubmit && (
                          <TrainingButton
                            image={image}
                            toggleTrainingStatus={toggleTrainingStatus}
                            lightBoxOpen={lightBoxOpen}
                          />
                        )}

                        {showMetaData(image) && <MetaData image={image} />}

                        {downloadButton && (
                          <a href={image.url} download={image.id} rel="noreferrer">
                            <i className="fa fa-download" />
                            {'Download'}
                          </a>
                        )}
                      </div>
                    )}
                  </section>
                </div>
              </li>
            ))
          )}
        </ul>

        <MorphPortal
          currentAedit={currentUser}
          Morph={Morph}
          onClose={() => setCurrentUser(null)}
        />

        {lightBoxOpen && (
          <>
            <div className="modal-backdrop" onClick={closeLightbox} />
            <div className="modal-image">
              {is3d && Boolean(failureReasons) && <span className="badge" data-badge="3D" />}

              <img src={getPreviewImageUrl(currentImage)} alt="aedit" />

              <Flagger
                image={currentImage}
                flagged={flagged}
                toggle={toggleFlag}
                lightBoxOpen={lightBoxOpen}
              />

              {currentImage.is_flagged && currentImage?.meta?.name === 'original_image' && (
                <DropDown
                  image={currentImage}
                  onDropDownChange={onDropDownChange}
                  flaggedReasons={flaggedReasons}
                />
              )}

              {selectedImage !== 0 && <i className="icon icon-arrow-left" onClick={goToPrevious} />}

              {selectedImage !== images.length - 1 && (
                <i className="icon icon-arrow-right" onClick={goToNext} />
              )}

              <div className="modal-caption">
                {onTrainingStatusSubmit && (
                  <TrainingButton
                    image={currentImage}
                    toggleTrainingStatus={toggleTrainingStatus}
                    lightBoxOpen={lightBoxOpen}
                  />
                )}
                {showMetaData(currentImage) && <MetaData image={currentImage} />}
              </div>

              <div className="detection-classification-data">
                {/* new format: version 2.16 and after */}
                {Boolean(!isMorph && Object.keys(currentImage?.meta?.areas || {}).length) && (
                  <>
                    <h6>Procedures (new format)</h6>
                    {Object.values(currentImage?.meta?.areas).map((area, index) => (
                      <div className="accordion" key={index}>
                        <input type="checkbox" id={index} name="accordion-checkbox" hidden />
                        <label className="accordion-header" htmlFor={index}>
                          {area?.name}
                        </label>

                        <div className="accordion-body">
                          {Object.values(area?.procedures).map((procedure, i) => (
                            <>
                              {procedure.try_ons?.map(try_on => (
                                <div className="accordion-section">Try-on: {try_on.name}</div>
                              ))}

                              {!Boolean(procedure?.try_ons) && (
                                <div className="accordion-section">{procedure?.name}</div>
                              )}

                              {/* if subprocedure exists */}
                              {Boolean(procedure?.sub_procedures) && (
                                <>
                                  {procedure?.sub_procedures.map((sub_procedure, i) => (
                                    <div className="accordion-section">
                                      Sub-procedure: {sub_procedure.name}
                                      <>
                                        {/* if no matching effect exists, list all of them */}
                                        {/* this handles the Brow Transplant case */}
                                        {!Boolean(
                                          procedure?.controls?.filter(
                                            control => control.effect_id == sub_procedure.procedure
                                          )?.length
                                        ) && (
                                          <>
                                            {procedure?.controls?.map(control => (
                                              <li key={i}>
                                                {control?.name}:{' '}
                                                {round(control.value, 2) ?? control.value}
                                              </li>
                                            ))}
                                          </>
                                        )}

                                        {/* this handles the Skin cases */}
                                        {procedure?.controls
                                          ?.filter(
                                            control => control.effect_id == sub_procedure.procedure
                                          )
                                          .map(control => (
                                            <li key={i}>
                                              {control?.name}:{' '}
                                              {round(control.value, 2) ?? control.value}
                                            </li>
                                          ))}
                                      </>
                                    </div>
                                  ))}
                                </>
                              )}

                              {/* if subprocedure does not exist */}
                              {!Boolean(procedure?.sub_procedures) && (
                                <>
                                  {procedure?.controls?.map(control => (
                                    <li key={i}>
                                      {control?.name}: {round(control.value, 2) ?? control.value}
                                    </li>
                                  ))}
                                </>
                              )}
                            </>
                          ))}
                        </div>
                      </div>
                    ))}
                  </>
                )}

                {/* original format: up to version 2.15 */}
                {Boolean(!isMorph && Object.keys(currentImage?.meta?.procedures || {}).length) && (
                  <>
                    <h6>Procedures</h6>
                    {Object.entries(currentImage?.meta?.procedures).map(
                      ([name, content], index) => (
                        <div className="accordion" key={index}>
                          <input type="checkbox" id={name} name="accordion-checkbox" hidden />
                          <label className="accordion-header" htmlFor={name}>
                            {name}
                          </label>
                          <div className="accordion-body">
                            {Object.entries(content).map(([key, val], i) => (
                              <li key={i}>
                                {key}: {round(val, 2) ?? val}
                              </li>
                            ))}
                          </div>
                        </div>
                      )
                    )}
                  </>
                )}

                {showMetaData(currentImage) && currentImage.meta.detection_classification_values && (
                  <>
                    {asianCreaseModel && <div className="mb-2">{asianCreaseModel}</div>}
                    {fitzpatrickScale && (
                      <div className="mb-2">Skin Tone (Fitzpatrick Scale): {fitzpatrickScale}</div>
                    )}

                    {Object.values(classStructure).map(c => {
                      return (
                        <>
                          <h6>{c.name}</h6>
                          <ul>
                            {c.name.includes('Base') && (
                              <li>{currentImage?.meta?.eyebrow_glasses_model_name}</li>
                            )}
                            {c.keys.map(node => {
                              const label = node.name ?? startCase(node.split('.').pop())
                              const path = node.key ?? node
                              const value = loGet(classifications, path)
                              const rounded = round(value, 2) ?? value
                              return (
                                <li>
                                  {label}: {rounded}
                                </li>
                              )
                            })}
                          </ul>
                        </>
                      )
                    })}

                    {Boolean(currentImage.meta.detection_classification_values.skin_analysis) && (
                      <>
                        <h6>AEDIT Skin Classifier</h6>
                        <ul>
                          {currentImage.meta.detection_classification_values.skin_analysis.map(
                            it => {
                              let label = startCase(`${it.feature} ${it.region}`)
                              let value = round(it.probability, 2)
                              return (
                                <li>
                                  {label}: {value}
                                </li>
                              )
                            }
                          )}
                        </ul>
                      </>
                    )}

                    {Boolean(failureReasons) && (
                      <div className="mt-2">Failure reasons: {failureReasons}</div>
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

ImageList.propTypes = {
  items: PropTypes.array,
  flaggedReasons: PropTypes.array,
  onSubmit: PropTypes.func,
  onTrainingStatusDelete: PropTypes.func,
  onTrainingStatusSubmit: PropTypes.func,
  flagged: PropTypes.array,
}

export default ImageList
