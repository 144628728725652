import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import SortableImages from '../../components/sortable_images'
import AddVideoForm from './_add_video_form'
import { deleteItem, fetchMedia } from '../../actions'

const ProcedureVideoListItem = ({ image, sortIndex, onItemClick }) => {
  return (
    <li className="sortable-item">
      <div className="sortable-item-contents">
        <video src={image.url} />
        <span className="sort-index">{sortIndex + 1}</span>
        <span className="edit-icon" onClick={() => onItemClick('edit')}>
          <i className="far fa-edit" />
        </span>
        <span className="delete-icon" onClick={() => onItemClick('delete')}>
          <i className="far fa-times-circle" />
        </span>
      </div>
    </li>
  )
}

const ProcedureVideos = ({ procedureId, deleteItem, fetchMedia, history }) => {
  const onItemClick = ({ item, action }) => {
    switch (action) {
      case 'edit':
        return history.push(`/procedures/${procedureId}/videos/${item.id}`)
      case 'delete':
        return deleteVideo(item)
      default:
        return
    }
  }

  const fetchVideos = async () => {
    await fetchMedia({
      entity_type: 'procedure',
      entity_id: procedureId,
      category: 'procedure_before_after',
    })
  }

  const deleteVideo = async (item) => {
    if (window.confirm('Are you sure?')) {
      await deleteItem('media', item.id)
      await fetchVideos()
    }
  }

  return (
    <div className="primary-content">
      <SortableImages
        entityId={procedureId}
        entityType="procedure"
        category="procedure_before_after"
        type="video"
        component={ProcedureVideoListItem}
        onItemClick={onItemClick}
      />
      <AddVideoForm
        procedureId={procedureId}
        onSubmitSucceeded={fetchVideos}
      />
    </div>
  )
}

export default compose(
  connect(null, { deleteItem, fetchMedia }),
  withRouter,
)(ProcedureVideos)
