import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Switch, Redirect } from 'react-router-dom'
import SideNav from './sidenav/index'
import { Navbar } from './navbar'
import { fetchCurrentUser, logout } from '../actions/auth'
import { PrivateRoute } from './private_route.js'

class Layout extends Component {
  componentDidMount() {
    if (!this.props.user) {
      this.props.fetchCurrentUser()
    }
  }

  render() {
    const { authed, routes, user, logout, location } = this.props

    if (!authed) {
      return (
        <Redirect
          to={{
            pathname: '/',
            state: { from: location },
          }}
        />
      )
    }

    return (
      <div className="flex-holder">
        <SideNav />
        <div className="flex-column">
          <Navbar routes={routes} user={user} logout={logout} />
          <section className="main-section">
            <Switch>
              {routes.map((route, i) => (
                <PrivateRoute key={i} {...route} authed={authed} />
              ))}
            </Switch>
          </section>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    user: auth.currentUser,
    authed: auth.authenticated,
  }
}

export default connect(mapStateToProps, { fetchCurrentUser, logout })(Layout)
