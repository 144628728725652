import React, { useState, useEffect, useCallback } from 'react'
import { Loading } from 'components/loading'
import { roundTo } from 'config/util'
import { MetaData } from 'components/image_list/components'

const PrescanGroup = ({ group = [] }) => {
  const [selectedModalImageIndex, setSelectedModalImageIndex] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)

  const goToNext = useCallback(() => {
    const last = group.length - 1

    return setSelectedModalImageIndex(Math.min(selectedModalImageIndex + 1, last))
  }, [selectedModalImageIndex, group])

  const goToPrevious = useCallback(() => {
    return setSelectedModalImageIndex(Math.max(0, selectedModalImageIndex - 1))
  }, [selectedModalImageIndex])

  const openModal = index => {
    setSelectedModalImageIndex(index)
    setModalOpen(true)
  }

  const closeModal = (index = 0) => {
    setSelectedModalImageIndex(index)
    setModalOpen(false)
  }

  const handleKeyChange = e => {
    if (!modalOpen) return

    if (e.key === 'ArrowLeft') {
      return goToPrevious()
    }
    if (e.key === 'ArrowRight') {
      return goToNext()
    }

    if (e.keyCode === 27) {
      return closeModal()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyChange)

    return () => document.removeEventListener('keydown', handleKeyChange)
  }, [handleKeyChange])

  const coverImage = group[0]
  const current = group[selectedModalImageIndex]

  const cover = (
    <li key={coverImage.name}>
      <div className="image-content">
        <img onClick={() => openModal(0)} className="image mb-1" src={coverImage.url} alt="aedit" />
        <span className="group-total-count">
          <i className="icon icon-photo" />
          {group.length}
        </span>
      </div>
    </li>
  )

  return (
    <>
      {cover}
      {modalOpen && (
        <>
          <div className="modal-backdrop" onClick={closeModal} />
          <i className="icon icon-cross close-icon" onClick={closeModal} />
          <div className="classification-image">
            <div className="parent-info">
              <h5 className="mb-2">
                Image {selectedModalImageIndex + 1} of {group.length}
              </h5>
              <MetaData image={current.parent} />
            </div>
            <span className="icon-container">
              <i
                className={`icon icon-arrow-left ${selectedModalImageIndex === 0 && 'disabled'}`}
                onClick={goToPrevious}
              />
            </span>
            <img src={current.url} alt="aedit" />
            <span className="icon-container">
              <i
                className={`icon icon-arrow-right ${
                  selectedModalImageIndex === group.length - 1 && 'disabled'
                }`}
                onClick={goToNext}
              />
            </span>

            <div className="classification-data">
              <h5 className="mb-1 text-center">Failure reasons</h5>
              <ul className="classifications">
                {current.classifier_results.map((r, i) => (
                  <ClassifierResult type={r.type} value={r.value} key={i} />
                ))}
              </ul>
            </div>
          </div>
        </>
      )}
    </>
  )
}

const ClassifierResult = ({ type, value }) => {
  return (
    <li className="classification-item">
      <div className="c-type">{splitType(type)}</div>
      <div className="c-val">{roundTo(value, 14)}</div>
    </li>
  )
}

const PrescanList = props => {
  const { groups = [] } = props

  // hacky - loads indefinitely when no results
  // this *will* occur after navigating through a certain number of pages
  //
  // since we're fetching ALL 3d_facial_recognition media items
  // then filtering them with js to avoid a backend change
  // our totalResults and totalPages count used in pagination
  // will reflect that higher amount, not the filtered subset
  const loading = !Boolean(groups.length)

  return loading ? (
    <Loading />
  ) : (
    <div className="image-list">
      <div className="image-list--container">
        <ul className="image-list--row">
          {groups.map((group, idx) => (
            <PrescanGroup group={group} key={idx} />
          ))}
        </ul>
      </div>
    </div>
  )
}

function splitType(type = '') {
  return type.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase()
}

export default PrescanList
