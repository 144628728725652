import React, {Component} from 'react'
import {Field, reduxForm} from 'redux-form'
import {FormGroup, TypeAhead, TextField} from '../../components/form'
import {validateRequiredFields, digitsOnly} from '../../config/util'

class AddStepForm extends Component {


  componentDidUpdate(nextProps) {

    const {existingSteps, submitSucceeded} = this.props

    if (existingSteps.length !== nextProps.existingSteps.length && submitSucceeded) {
      // clear form after successful submission
      this.props.reset()
    }
  }

  render() {
    const {loading, handleSubmit, onFormSubmit, invalid, steps, existingSteps} = this.props
    const dropdownOptions = steps.filter(item => !existingSteps.includes(item.id))

    return (
      <form onSubmit={handleSubmit(onFormSubmit)} className="mt-2 page-form well form-horizontal" style={{overflow: 'visible'}}>
        <FormGroup label="Add a Step" editable>

          <Field
            name="step"
            component={TypeAhead}
            options={dropdownOptions}
            loading={loading}
            labelKey="internal_title"
            valueKey="id"
            menuContainerStyle={{top: 'auto', bottom: '100%'}}
          />

          <span className="order-field">
            <Field
              name="order"
              component={TextField}
              placeholder="Order"
              showLength={false}
              parse={digitsOnly}
            />
          </span>

          <button
            className={`btn btn-primary ${loading ? 'loading' : ''}`}
            type="submit"
            disabled={loading || invalid}>
            Add Step
          </button>
        </FormGroup>
      </form>
    )
  }
}

//
// Order number cannot exist already!!
//

const validate = (values, {disallowed}) => {

  let errors = {}

  const fields = [
    ['step'],
    ['order']
  ]

  if (values.order) {
    const order = parseInt(values.order, 10)
    if (order < 1) {
      errors.order = 'Value must be > 0'
    } else if (disallowed.includes(order)) {
      errors.order = 'Order exists'
    }
  }

  return {...validateRequiredFields(values, fields), ...errors}
}

export default reduxForm({
  validate: validate,
  form: 'addStepForm'
})(AddStepForm)
