import algoliasearch from 'algoliasearch'

const algoliaId = process.env.REACT_APP_ALGOLIA_APP_ID
const algoliaKey = process.env.REACT_APP_ALGOLIA_API_KEY
const algoliaIndicies = process.env.REACT_APP_ALGOLIA_INDEX

const algolia = algoliasearch(algoliaId, algoliaKey)
const index = algolia.initIndex(algoliaIndicies)

export const fetchSynonyms = (options = {}) =>
  async dispatch => {
    dispatch({ type: 'LOADING' })

    const defaultParams = {
      query: '',
      type: 'synonym',
      page: 1,
      hitsPerPage: 20
    }
    const params = {...defaultParams, ...options}

    index.searchSynonyms(params, (err, content) => {
      if (err) {
        dispatch({ type: 'FAILURE', payload: err })
      } else {
        dispatch({ type: 'FETCHED_SYNONYM_ALL', payload: content })
        dispatch({ type: 'FETCH_SUCCESS' })
      }
    })
  }

export const updateSynonym = (objectID, synonyms) =>
  async dispatch => {

    const synObject = {
      objectID: objectID,
      type: 'synonym',
      synonyms: synonyms
    }

    index.saveSynonym(synObject, (err, content) => {
      if (err) {
        dispatch({ type: 'FAILURE', payload: err })
      } else {
        dispatch({
          type: 'UPDATED_SYNONYM',
          payload: {
            objectID: content.id,
            type: 'synonym',
            synonyms: synonyms
          }
        })

        dispatch({ type: 'UPDATE_SUCCESS' })
      }
    })
  }

// synonyms should be an array of strings
export const createSynonym = (synonyms) =>
  async dispatch => {

    const randomString = Math.random().toString(36).substring(5) // https://stackoverflow.com/a/8084248/1424568

    const synObject = {
      objectID: randomString,
      type: 'synonym',
      synonyms: synonyms
    }

    index.saveSynonym(synObject, (err, content) => {
      if (err) {
        dispatch({ type: 'FAILURE', payload: err })
      } else {
        dispatch({
          type: 'CREATED_SYNONYM',
          payload: {
            objectID: content.id,
            type: 'synonym',
            synonyms: synonyms
          }
        })

        dispatch({ type: 'POST_SUCCESS' })
      }
    })
  }

export const deleteSynonym = (objectID) =>
  async dispatch => {
    index.deleteSynonym(objectID, { forwardToReplicas: true }, (err, content) => {
      if (err) {
        dispatch({ type: 'FAILURE', payload: err })
      } else {
        dispatch({ type: 'DELETED_SYNONYM', payload: objectID })
        dispatch({ type: 'DELETE_SUCCESS' })
      }
    })
  }

export const deleteSynonyms = () =>
  async dispatch => {
    index.clearSynonyms({ forwardToReplicas: true }, (err, content) => {
      if (err) {
        dispatch({ type: 'FAILURE', payload: err })
      } else {
        dispatch({ type: 'DELETED_SYNONYM_ALL', payload: content })
        dispatch({ type: 'DELETE_SUCCESS' })
      }
    })
  }
