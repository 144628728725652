import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createItem} from '../../actions'
import ReferralProgramForm from './_program_form'

class ReferralProgramsNew extends Component {

  handleFormSubmit = (values) => {
    this.props.createItem('program', {
      ...values,
      created_by_user_id: this.props.currentUserId
    })
  }

  componentWillReceiveProps(nextProps) {
    const currentId = this.props.program.id
    const nextId = nextProps.program.id

    if (nextId && nextId !== currentId) {
      this.props.history.push('/referrals/programs')
    }
  }

  render() {
    const {loading} = this.props

    return (
      <div className="primary-content">
        <div className="controls">
          <h4>Add a New Program</h4>
        </div>
        <ReferralProgramForm
          onFormSubmit={this.handleFormSubmit}
          initialValues={{}}
          loading={loading}
        />
      </div>
    )
  }
}

const mapStateToProps = ({referrals, ui, auth}) => {
  return {
    program: referrals.currentProgram,
    loading: ui.loading,
    currentUserId: auth.currentUser.id
  }
}

export default connect(mapStateToProps, {createItem})(ReferralProgramsNew)
