import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {Field, reduxForm, formValueSelector} from 'redux-form'
import {FormGroup, FormFooter, TextField, TextArea, RadioField, Base64FileInput, SelectAsync} from '../../components/form'
import {validateRequiredFields, setMaxCharLimit} from '../../config/util'
import {
    CONCERN_APPROVED_IMAGES,
    CONCERN_THUMBNAIL_APPROVED_IMAGES,
    RADIO_OPTIONS
       } from '../../config/constants'
import CopyPaste from '../../components/copy_paste'
import {fetchItems} from '../../actions'

class ConcernForm extends Component {

  state = {
    editing: false
  }

  deletePhoto = () => {
    this.props.change('primary_image', null)
    this.props.change('primary_image_thumbnail', null)
  }

  render() {
    const {
      loading,
      submitFailed,
      invalid,
      handleSubmit,
      onFormSubmit,
      initialValues,
      concern,
      primaryImage,
      primaryImageThumbnail,
    } = this.props
    const editingRecord = concern && concern.date_created
    const concernRootName = initialValues && process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOSTNAME + '/concern/' + initialValues.slug

    return (
      <form onSubmit={handleSubmit(onFormSubmit)} className="flex-fill page-form well form-horizontal">
        <FormGroup label="Title" editable>
          <Field component={TextField} type="text" name="name" placeholder="Title" />
        </FormGroup>

        <FormGroup label="Blurb" editable>
          <Field name="blurb" component={TextArea} placeholder="Blurb" />
        </FormGroup>

        <FormGroup label="Short Blurb" editable helper="Short blurbs are used on the searchable concerns index page">
          <Field name="short_blurb" component={TextField} type="text" placeholder="Blurb for cards"/>
        </FormGroup>

        <FormGroup
          editable
          label={
            <div>
              Hero Image <br />
              <a href={CONCERN_APPROVED_IMAGES} target="_blank" rel="noopener noreferrer">
                View Approved Thumbnail Images
              </a>
            </div>
          }>
          {
            primaryImage && editingRecord && concern.primary_image_url &&
            <span className="delete-image" onClick={this.deletePhoto}>
              <img src={concern.primary_image_url} className="pic-preview" alt="profile pic" />
              <p className="delete">X</p>
            </span>
          }
          <Field name="primary_image" component={Base64FileInput} type="file" />
        </FormGroup>
        <FormGroup
          editable
          label={
            <div>
              Thumbnail Image <br />
              <a href={CONCERN_THUMBNAIL_APPROVED_IMAGES} target="_blank" rel="noopener noreferrer">
                View Approved Thumbnail Images
              </a>
            </div>
          }>
          {
            primaryImageThumbnail && editingRecord && concern.primary_image_thumbnail_url &&
            <span className="delete-image" onClick={this.deletePhoto}>
              <img src={concern.primary_image_thumbnail_url} className="pic-preview" alt="profile pic" />
              <p className="delete">X</p>
            </span>
          }
          <Field name="primary_image_thumbnail" component={Base64FileInput} type="file" />
        </FormGroup>

        <FormGroup label="Is Active?" editable>
          <Field component={RadioField} name="is_active" items={RADIO_OPTIONS} />
        </FormGroup>

        <FormGroup label="Try it on?" editable>
          <Field component={RadioField} name="try_it_on" items={RADIO_OPTIONS} />
        </FormGroup>

        {
          editingRecord &&
          <Fragment>
            <FormGroup label="slug">
              <CopyPaste text={initialValues.slug} />
            </FormGroup>
            <FormGroup label="Concern Page Link">
              <a
                href={concernRootName}
                target="_blank"
                rel="noopener noreferrer">
                {initialValues.name}
              </a>
            </FormGroup>
            <FormGroup label="Last Updated">
              <span>{initialValues.date_updated}</span>
            </FormGroup>
            <FormGroup label="Date Created">
              <span>{initialValues.date_created}</span>
            </FormGroup>
          </Fragment>
        }

        <FormFooter loading={loading} showFailure={invalid && submitFailed} />
      </form>
    )
  }
}

const validate = (values) => {
  const requiredFields = [
    ['name', 25],
    ['is_active'],
    ['try_it_on'],
    ['short_blurb', 78],
    ['primary_image'],
    ['primary_image_thumbnail']
  ]

  const optionalFields = [
    ['blurb', 1000]
  ]

  return {...validateRequiredFields(values, requiredFields), ...setMaxCharLimit(values, optionalFields)}
}


ConcernForm = reduxForm({
  validate: validate,
  form: 'concernForm',
  enableReinitialize: true
})(ConcernForm)

const selector = formValueSelector('concernForm')

const mapStateToProps = state => {
  const primaryImage = selector(state, 'primary_image')
  const primaryImageThumbnail = selector(state, 'primary_image_thumbnail')
  return {
    primaryImage,
    primaryImageThumbnail,
    loading: state.ui.loading,
  }
}

ConcernForm = connect(mapStateToProps, {fetchItems})(ConcernForm)

export default ConcernForm
