import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import {
  FormGroup,
  RadioField,
  TextField,
  SelectField,
  TextArea,
  TypeAhead,
  FormFooter,
  Base64FileInput,
  SelectAsync,
} from '../../components/form'
import {
  validateRequiredFields,
  setMaxCharLimit,
  setOnBeforeUnload,
  digitsAndPeriodOnly,
  maxArrayLength,
} from '../../config/util'
import {
  RADIO_OPTIONS,
  PROCEDURE_APPROVED_IMAGES,
  PROCEDURE_THUMBNAIL_APPROVED_IMAGES,
} from '../../config/constants'
import { Link } from 'react-router-dom'
import CopyPaste from '../../components/copy_paste'
import { fetchItems } from '../../actions'

const selector = formValueSelector('procedureForm')

class InfoForm extends Component {
  // using component state for the region field,
  // bc it's not associated with the form data.
  state = {
    selectedRegion: '',
    initialized: false,
  }

  // following is for initializing the options in the region and area dropdowns.
  componentWillReceiveProps({ regions, initialValues }) {
    const hasFetchedRegions = !!regions.length
    const hasFetchedAreas = initialValues && initialValues.procedure_areas

    if (hasFetchedRegions && hasFetchedAreas && !this.state.initialized) {
      // first area associated with procedures should be its default region

      const regionId = initialValues.procedure_areas[0].region_id
      this.setState({
        selectedRegion: regionId,
        initialized: true,
      })

      this.props.fetchAreasByRegion(regionId)
    }
  }

  componentDidUpdate() {
    setOnBeforeUnload(this.props.dirty)
  }

  componentWillUnmount() {
    setOnBeforeUnload(false)
  }

  switchRegion = ({ target }) => {
    this.props.fetchAreasByRegion(target.value)
    this.setState({ selectedRegion: target.value })
    this.props.change('area_ids', []) // clear out area_ids bc each procedure can only be associated with one region's areas
  }

  deletePhoto = field => {
    this.props.change(field, null)
  }

  render() {
    const {
      initialValues,
      loading,
      handleSubmit,
      onFormSubmit,
      regions,
      regionAreas,
      invalid,
      submitFailed,
      concerns,
      procedures,
      procedureTypeOptions,
      primaryImage,
      thumbnailImage,
      timelines,
    } = this.props

    const editingRecord = initialValues && initialValues.date_created
    let isParent = initialValues.parent_id > 0
    let parentName = isParent
      ? procedures.find(procedure => procedure.id === initialValues.parent_id)
      : ''
    const procedureRootName =
      process.env.REACT_APP_PROTOCOL +
      process.env.REACT_APP_HOSTNAME +
      '/procedure/' +
      initialValues.slug

    return (
      <form
        onSubmit={handleSubmit(onFormSubmit)}
        className="flex-fill page-form well form-horizontal">
        <FormGroup label="Name" editable>
          <Field component={TextField} type="text" name="name" placeholder="Name" />
        </FormGroup>

        <FormGroup label="Secondary Name" editable>
          <Field
            component={TextField}
            type="text"
            name="secondary_name"
            placeholder="Secondary Name"
          />
        </FormGroup>

        {editingRecord && (
          <React.Fragment>
            <FormGroup label="Parent Procedure">
              {parentName ? (
                <Link to={`/procedures/${initialValues.parent_id}`}>{parentName.name}</Link>
              ) : (
                'None'
              )}
            </FormGroup>

            <FormGroup label="Is Parent">{initialValues.is_parent ? 'True' : 'False'}</FormGroup>
          </React.Fragment>
        )}

        <FormGroup label="Child Procedures" editable>
          <Field
            component={TypeAhead}
            name="child_ids"
            options={procedures}
            labelKey="name"
            valueKey="id"
            multi
          />
        </FormGroup>

        <FormGroup label="Region" editable>
          <select
            className="form-select"
            onChange={this.switchRegion}
            value={this.state.selectedRegion}>
            <option value="01">Select a Region</option>
            {regions
              .sort((a, b) => a.name.localeCompare(b.name))
              .map(region => (
                <option value={region.id} key={region.id}>
                  {region.name}
                </option>
              ))}
          </select>
        </FormGroup>

        <FormGroup label="Procedure Area" editable>
          <Field
            name="area_ids"
            component={TypeAhead}
            options={regionAreas}
            labelKey="name"
            valueKey="id"
            multi
          />
        </FormGroup>

        <FormGroup label="Concern" editable>
          <Field
            name="concern_ids"
            component={TypeAhead}
            options={concerns}
            labelKey="name"
            valueKey="id"
            multi
          />
        </FormGroup>

        <FormGroup label="Voucher Value" editable>
          <Field
            name="voucher_value"
            component={TextField}
            parse={digitsAndPeriodOnly}
            type="number"
          />
        </FormGroup>

        <FormGroup
          editable
          label={
            <div>
              Primary Photo <br />
              <a href={PROCEDURE_APPROVED_IMAGES} target="_blank" rel="noopener noreferrer">
                View Approved Images
              </a>
            </div>
          }>
          {primaryImage && initialValues && initialValues.primary_image_url && (
            <span className="delete-image" onClick={() => this.deletePhoto('primary_image')}>
              <img
                src={initialValues.primary_image_url}
                className="pic-preview"
                alt="primary pic"
              />
              <p className="delete">X</p>
            </span>
          )}
          <Field component={Base64FileInput} type="file" name="primary_image" />
        </FormGroup>

        <FormGroup
          editable
          label={
            <div>
              Thumbnail Images <br />
              <a
                href={PROCEDURE_THUMBNAIL_APPROVED_IMAGES}
                target="_blank"
                rel="noopener noreferrer">
                View Approved Images
              </a>
            </div>
          }>
          {thumbnailImage && initialValues && initialValues.primary_image_thumbnail_url && (
            <span
              className="delete-image"
              onClick={() => this.deletePhoto('primary_image_thumbnail')}>
              <img
                src={initialValues.primary_image_thumbnail_url}
                className="pic-preview"
                alt="primary pic"
              />
              <p className="delete">X</p>
            </span>
          )}
          <Field component={Base64FileInput} type="file" name="primary_image_thumbnail" />
        </FormGroup>

        <FormGroup label="Timeline" editable>
          <Field name="procedure_timeline_id" component={SelectField} hideDisabledOption>
            <option value={null}>None</option>
            {timelines.map(({ id, title }) => (
              <option value={id} key={id}>
                {title}
              </option>
            ))}
          </Field>
        </FormGroup>

        <FormGroup label="Active?" editable>
          <Field component={RadioField} name="is_active" items={RADIO_OPTIONS} />
        </FormGroup>

        {!editingRecord && (
          <FormGroup label="Is Solution?" editable>
            <Field component={RadioField} name="is_solution" items={RADIO_OPTIONS} />
          </FormGroup>
        )}

        <FormGroup label="Short Description" editable>
          <Field component={TextArea} name="short_description" placeholder="Short Description" />
        </FormGroup>

        <FormGroup label="Long Description" editable>
          <Field component={TextArea} name="long_description" placeholder="Long Description" />
        </FormGroup>

        <FormGroup label="Card Description" editable>
          <Field component={TextArea} name="card_description" placeholder="Card Description" />
        </FormGroup>

        <FormGroup label="Technical Description" editable>
          <Field
            component={TextArea}
            name="technical_description"
            placeholder="Technical Description"
          />
        </FormGroup>

        <FormGroup label="Goal" editable>
          <Field component={TextArea} name="goal" placeholder="Goal" />
        </FormGroup>

        <FormGroup label="Goal Keywords" editable>
          <Field
            component={TextField}
            type="text"
            name="goal_keywords"
            placeholder="Comma separated keywords"
          />
        </FormGroup>

        <FormGroup label="Anesthesia Type" editable>
          <Field
            component={TypeAhead}
            name="anesthesia_types"
            options={Object.values(procedureTypeOptions.ANESTHESIA_TYPES)}
            labelKey="display_name"
            valueKey="id"
            multi
          />
        </FormGroup>

        <FormGroup label="Invasiveness Score" editable>
          <Field
            component={TextField}
            type="number"
            step="0.1"
            name="invasiveness_score"
            placeholder="Score"
          />
        </FormGroup>

        <FormGroup label="Pain Factor" editable>
          <Field name="pain_factor" component={SelectField}>
            {
              // scale frrom 1 - 10
              [...Array(10).keys()].map(n => (
                <option value={n + 1} key={n + 1}>
                  {n + 1}
                </option>
              ))
            }
          </Field>
        </FormGroup>

        <FormGroup label="Application Type" editable>
          <Field name="application_type" component={SelectField}>
            {Object.values(procedureTypeOptions.APPLICATION_TYPES).map(prac => (
              <option value={prac.id} key={prac.id}>
                {prac.display_name}
              </option>
            ))}
          </Field>
        </FormGroup>

        <FormGroup label="Related Procedures" editable>
          <Field
            component={TypeAhead}
            name="related_ids"
            options={procedures}
            labelKey="name"
            valueKey="id"
            multi
          />
        </FormGroup>

        <FormGroup label="Permanent" editable>
          <Field component={RadioField} items={RADIO_OPTIONS} name="permanent" />
        </FormGroup>

        <FormGroup label="Result Notes" editable>
          <Field component={TextArea} name="result_notes" placeholder="Result Notes" />
        </FormGroup>

        <div className="form-group">
          <div className="col-12 madlib">
            <label>
              Recovery takes a <strong>minimum</strong> of
            </label>
            <Field
              name="min_recovery_count"
              component={TextField}
              type="number"
              showLength={false}
            />
            <Field name="min_recovery_frequency" component={SelectField}>
              <option value="days">Days</option>
              <option value="weeks">Weeks</option>
              <option value="months">Months</option>
              <option value="years">Years</option>
            </Field>
          </div>
        </div>

        <div className="form-group">
          <div className="col-12 madlib">
            <label>
              Recovery takes a <strong>average</strong> of
            </label>
            <Field
              name="avg_recovery_count"
              component={TextField}
              type="number"
              showLength={false}
            />
            <Field name="avg_recovery_frequency" component={SelectField}>
              <option value="days">Days</option>
              <option value="weeks">Weeks</option>
              <option value="months">Months</option>
              <option value="years">Years</option>
            </Field>
          </div>
        </div>

        <div className="form-group">
          <div className="col-12 madlib">
            <label>
              Recovery takes a <strong>maximum</strong> of
            </label>
            <Field
              name="max_recovery_count"
              component={TextField}
              type="number"
              showLength={false}
            />
            <Field name="max_recovery_frequency" component={SelectField}>
              <option value="days">Days</option>
              <option value="weeks">Weeks</option>
              <option value="months">Months</option>
              <option value="years">Years</option>
            </Field>
          </div>
        </div>

        <div className="form-group">
          <div className="col-12 madlib">
            <label>
              Procedure will happen a <strong>minimum</strong> of
            </label>
            <Field
              name="min_procedure_count"
              component={TextField}
              type="number"
              showLength={false}
            />
            <label>times every </label>
            <Field
              name="min_frequency_unit"
              component={TextField}
              type="number"
              showLength={false}
            />
            <Field name="min_frequency_period" component={SelectField}>
              <option value="days">Days</option>
              <option value="weeks">Weeks</option>
              <option value="months">Months</option>
              <option value="years">Years</option>
              <option value="lifetime">Lifetime</option>
            </Field>
            <label>over</label>
            <Field
              name="min_completion_unit"
              component={TextField}
              type="number"
              showLength={false}
            />
            <Field name="min_completion_period" component={SelectField}>
              <option value="days">Days</option>
              <option value="weeks">Weeks</option>
              <option value="months">Months</option>
              <option value="years">Years</option>
              <option value="lifetime">Lifetime</option>
            </Field>
          </div>
        </div>

        <div className="form-group">
          <div className="col-12 madlib">
            <label>
              Procedure will happen a <strong>average</strong> of
            </label>
            <Field
              name="avg_procedure_count"
              component={TextField}
              type="number"
              showLength={false}
            />

            <label>times every </label>

            <Field
              name="avg_frequency_unit"
              component={TextField}
              type="number"
              showLength={false}
            />
            <Field name="avg_frequency_period" component={SelectField}>
              <option value="days">Days</option>
              <option value="weeks">Weeks</option>
              <option value="months">Months</option>
              <option value="years">Years</option>
              <option value="lifetime">Lifetime</option>
            </Field>

            <label>over</label>

            <Field
              name="avg_completion_unit"
              component={TextField}
              type="number"
              showLength={false}
            />
            <Field name="avg_completion_period" component={SelectField}>
              <option value="days">Days</option>
              <option value="weeks">Weeks</option>
              <option value="months">Months</option>
              <option value="years">Years</option>
              <option value="lifetime">Lifetime</option>
            </Field>
          </div>
        </div>

        <div className="form-group">
          <div className="col-12 madlib">
            <label>
              Procedure will happen a <strong>maximum</strong> of
            </label>
            <Field
              name="max_procedure_count"
              component={TextField}
              type="number"
              showLength={false}
            />

            <label>times every </label>

            <Field
              name="max_frequency_unit"
              component={TextField}
              type="number"
              showLength={false}
            />
            <Field name="max_frequency_period" component={SelectField}>
              <option value="days">Days</option>
              <option value="weeks">Weeks</option>
              <option value="months">Months</option>
              <option value="years">Years</option>
              <option value="lifetime">Lifetime</option>
            </Field>

            <label>over</label>

            <Field
              name="max_completion_unit"
              component={TextField}
              type="number"
              showLength={false}
            />
            <Field name="max_completion_period" component={SelectField}>
              <option value="days">Days</option>
              <option value="weeks">Weeks</option>
              <option value="months">Months</option>
              <option value="years">Years</option>
              <option value="lifetime">Lifetime</option>
            </Field>
          </div>
        </div>

        <div className="form-group">
          <div className="col-12 madlib">
            <label>
              Procedure upkeep will be necessary to perform an <strong>average</strong> of
            </label>
            <Field name="avg_upkeep_count" component={TextField} type="number" showLength={false} />
            <label>times every</label>
            <Field
              name="avg_upkeep_frequency_count"
              component={TextField}
              type="number"
              showLength={false}
            />
            <Field name="avg_upkeep_frequency_period" component={SelectField}>
              <option value="daily">Day</option>
              <option value="weekly">Week</option>
              <option value="monthly">Month</option>
              <option value="yearly">Year</option>
              <option value="lifetime">Lifetime</option>
            </Field>
          </div>
        </div>

        <div className="form-group">
          <div className="col-12 madlib">
            <label>
              Procedure will cost a <strong>minimum</strong> of $
            </label>
            <Field
              name="min_procedure_cost"
              component={TextField}
              type="number"
              showLength={false}
            />
            <label> and is charged on a(n) </label>
            <Field name="min_procedure_cost_type" component={SelectField}>
              <option value="overall">Overall</option>
              <option value="per_unit">Per Unit</option>
            </Field>
            <label>basis.</label>
            <Field
              component={TextField}
              placeholder="ex: per tooth"
              name="min_procedure_cost_notes"
              showLength={false}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="col-12 madlib">
            <label>
              Procedure will cost a <strong>maximum</strong> of $
            </label>
            <Field
              name="max_procedure_cost"
              component={TextField}
              type="number"
              showLength={false}
            />
            <label> and is charged on a(n) </label>
            <Field name="max_procedure_cost_type" component={SelectField}>
              <option value="overall">Overall</option>
              <option value="per_unit">Per Unit</option>
            </Field>
            <label>basis.</label>
            <Field
              component={TextField}
              placeholder="ex: per tooth"
              name="max_procedure_cost_notes"
              showLength={false}
            />
          </div>
        </div>

        <FormGroup label="Cost Blurb" editable>
          <Field component={TextField} type="text" name="cost_blurb" placeholder="Cost Blurb" />
        </FormGroup>

        <FormGroup label="Cost Short Description" editable>
          <Field
            component={TextField}
            type="text"
            name="cost_short_description"
            placeholder="Cost Short Description"
          />
        </FormGroup>

        <FormGroup label="Cost Long Description" editable>
          <Field
            component={TextArea}
            type="text"
            name="cost_long_description"
            placeholder="Cost Long Description"
          />
        </FormGroup>

        <div className="form-group">
          <div className="col-12 madlib">
            <label>
              Procedure will cost an <strong>average</strong> of $
            </label>
            <Field
              name="avg_procedure_cost"
              component={TextField}
              type="number"
              showLength={false}
            />
            <label> and is charged on a(n) </label>
            <Field name="avg_procedure_cost_type" component={SelectField}>
              <option value="overall">Overall</option>
              <option value="per_unit">Per Unit</option>
            </Field>
            <label>basis.</label>
            <Field
              component={TextField}
              placeholder="ex: per tooth"
              name="avg_procedure_cost_notes"
              showLength={false}
            />
          </div>
        </div>

        <FormGroup label="Before Procedure Notes" editable>
          <Field
            component={TextArea}
            name="before_procedure_notes"
            placeholder="Pre-Procedure Notes"
          />
        </FormGroup>

        <FormGroup label="After Procedure Notes" editable>
          <Field
            component={TextArea}
            name="after_procedure_notes"
            placeholder="Post-Procedure Notes"
          />
        </FormGroup>

        <FormGroup label="Recovery Notes" editable>
          <Field component={TextArea} name="recovery_notes" placeholder="Recovery Notes" />
        </FormGroup>

        <FormGroup label="Side Effects" editable>
          <Field component={TextArea} name="side_effects" placeholder="Side Effects" />
        </FormGroup>

        <FormGroup label="Pros" editable>
          <Field component={TextArea} name="pros" placeholder="Pros" />
        </FormGroup>

        <FormGroup label="Cons" editable>
          <Field component={TextArea} name="cons" placeholder="Cons" />
        </FormGroup>

        <FormGroup label="Keyword Pros" editable>
          <Field component={TextField} name="keyword_pros" placeholder="Keyword Pros" />
        </FormGroup>

        <FormGroup label="Keyword Cons" editable>
          <Field component={TextField} name="keyword_cons" placeholder="Keyword Cons" />
        </FormGroup>

        <FormGroup label="Not Recommended For" editable>
          <Field
            component={TextArea}
            name="not_recommended_for"
            placeholder="Not Recommended For"
          />
        </FormGroup>

        <FormGroup label="Ideal Candidate" editable>
          <Field component={TextArea} placeholder="Ryan Gosling" name="ideal_candidate" />
        </FormGroup>

        <FormGroup label="Fun Fact" editable>
          <Field component={TextArea} placeholder="My favorite color is purple" name="fun_fact" />
        </FormGroup>

        <FormGroup label="Outpatient" editable>
          <Field component={RadioField} items={RADIO_OPTIONS} name="outpatient" />
        </FormGroup>

        <FormGroup label="Surgical" editable>
          <Field component={RadioField} items={RADIO_OPTIONS} name="is_surgical" />
        </FormGroup>

        <FormGroup label="Preventative" editable>
          <Field component={RadioField} items={RADIO_OPTIONS} name="preventative" />
        </FormGroup>

        <FormGroup label="Restorative" editable>
          <Field component={RadioField} items={RADIO_OPTIONS} name="restorative" />
        </FormGroup>

        <FormGroup label="Recovery Step Notes" editable>
          <Field component={TextArea} name="recovery_step_notes" placeholder="Notes" />
        </FormGroup>

        <FormGroup label="Our Take" editable>
          <Field component={TextArea} placeholder="Our Take" name="our_take" />
        </FormGroup>

        <FormGroup label="Explicit" editable>
          <Field component={RadioField} items={RADIO_OPTIONS} name="explicit" />
        </FormGroup>

        <FormGroup label="Try it on?" editable>
          <Field component={RadioField} name="try_it_on" items={RADIO_OPTIONS} />
        </FormGroup>

        {initialValues.date_created && (
          <Fragment>
            <FormGroup label="Slug">
              <CopyPaste text={initialValues.slug} />
            </FormGroup>

            <FormGroup label="Procedure Page Link">
              <a href={procedureRootName} target="_blank" rel="noopener noreferrer">
                {initialValues.name}
              </a>
            </FormGroup>

            <FormGroup label="Created At">
              <span>{initialValues.date_created}</span>
            </FormGroup>

            <FormGroup label="Last Updated">
              <span>{initialValues.date_updated}</span>
            </FormGroup>
          </Fragment>
        )}
        <FormFooter loading={loading} showFailure={invalid && submitFailed} />
      </form>
    )
  }
}

const validate = values => {
  const { child_ids } = values
  const childRequiredFields = [
    ['name', 200],
    ['long_description'],
    ['short_description', 500],
    ['goal', 500],
    ['goal_keywords', 250],
    ['area_ids'],
    ['is_active'],
    ['anesthesia_required'],
    ['invasive'],
    ['anesthesia_types'],
    ['invasiveness_score'],
    ['application_type'],
    ['permanent'],
    ['result_notes', 500],
    ['keyword_pros', 250],
    ['keyword_cons', 250],
    ['recovery_notes', 500],
    ['not_recommended_for', 250],
    ['outpatient'],
    ['ideal_candidate', 500],
    ['recovery_step_notes', 500],
    ['before_procedure_notes'],
    ['after_procedure_notes'],
    ['side_effects', 1000],
    ['min_procedure_count'],
    ['min_frequency_unit'],
    ['min_frequency_period'],
    ['min_completion_unit'],
    ['min_completion_period'],
    ['max_procedure_count'],
    ['max_frequency_unit'],
    ['max_frequency_period'],
    ['max_completion_unit'],
    ['max_completion_period'],
    ['avg_procedure_count'],
    ['avg_frequency_unit'],
    ['avg_frequency_period'],
    ['avg_completion_unit'],
    ['avg_completion_period'],
    ['avg_upkeep_count'],
    ['avg_upkeep_frequency_count'],
    ['avg_upkeep_frequency_period'],
    ['min_recovery_count'],
    ['min_recovery_frequency'],
    ['max_recovery_frequency'],
    ['max_recovery_count'],
    ['avg_recovery_count'],
    ['avg_recovery_frequency'],
    ['min_procedure_cost'],
    ['max_procedure_cost'],
    ['avg_procedure_cost'],
    ['is_surgical'],
    ['primary_image'],
    ['card_description', 120],
    ['try_it_on'],
  ]

  const parentRequiredFields = [
    ['name', 200],
    ['short_description', 500],
    ['area_ids'],
    ['is_active'],
    ['avg_procedure_cost'],
    ['min_procedure_cost'],
    ['max_procedure_cost'],
  ]

  const optionalFields = [
    ['fun_fact', 250],
    ['technical_description', 2000],
  ]

  const limitedArrayFields = [['concern_ids', 5]]

  if (child_ids && child_ids.length) {
    // PARENT
    return {
      ...validateRequiredFields(values, parentRequiredFields),
      ...setMaxCharLimit(values, optionalFields),
      ...maxArrayLength(values, limitedArrayFields),
    }
  } else {
    // CHILD (NOT A PARENT)
    return {
      ...validateRequiredFields(values, childRequiredFields),
      ...setMaxCharLimit(values, optionalFields),
      ...maxArrayLength(values, limitedArrayFields),
    }
  }
}

InfoForm = reduxForm({
  validate: validate,
  form: 'procedureForm',
  enableReinitialize: true,
})(InfoForm)

const mapStateToProps = state => {
  return {
    primaryImage: selector(state, 'primary_image'),
    thumbnailImage: selector(state, 'primary_image_thumbnail'),
  }
}

export default connect(mapStateToProps, { fetchItems })(InfoForm)
