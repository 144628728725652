import React from 'react'
import logo from '../../images/logo-one-line.png'
import CollapsibleNavItem from './collapsible_nav_item'
import NavItem from './nav_item'

const sideNavItems = [
  {
    text: 'Users',
    items: [
      { to: '/users', text: 'All', exact: true },
      { to: '/users/newsletter', text: 'Newsletter only' },
    ],
  },
  {
    text: 'Consultations',
    items: [
      { to: '/consultations', icon: 'calendar-check', text: 'Consultations', exact: true },
      { to: '/consultations/appointment_leads', text: 'Appointment Leads', exact: true },
    ],
  },
  { to: '/statistics', icon: 'chart-line', text: 'Statistics' },
  { to: '/concerns', icon: 'heartbeat', text: 'Concerns' },
  { to: '/beauty-board', icon: 'user-circle', text: 'Beauty Board' },
  // procedureItems
  {
    text: 'Procedures',
    items: [
      { to: '/procedures', text: 'All', exact: true },
      { to: '/procedures/areas', text: 'Areas' },
      { to: '/procedures/regions', text: 'Regions' },
      { to: '/procedures/timelines', text: 'Timelines' },
      { to: '/procedures/steps', text: 'Steps', exact: true },
    ],
  },
  // providerItems
  {
    text: 'Providers',
    items: [
      { to: '/providers', text: 'All', exact: true },
      { to: '/practices', text: 'Practices' },
      { to: '/campus', text: 'Universities' },
    ],
  },
  // planItems
  {
    text: 'Plans',
    items: [{ to: '/plans', text: 'All', exact: true }],
  },
  // synonymItems
  {
    text: 'Synonyms',
    items: [{ to: '/synonyms', text: 'Procedures', exact: true }],
  },
  // mediaItems
  {
    text: 'Multimedia',
    items: [
      { to: '/media', text: '2D Facial Recognition', exact: true },
      { to: '/media/failed', text: 'Failed Facial Detection', exact: true },
      { to: '/media/prescans', text: 'Prescan Classification', exact: true },
      { to: '/media/flagged', text: 'Flagged Aedits', exact: true },
      { to: '/media/models', text: 'Models', exact: true },
      { to: '/media/morphing', text: 'Morphing', exact: true },
      { to: '/media/3d', text: '3D Facial Recognition', exact: true },
      { to: '/media/category/my_aedits_3d', text: 'Lookbook (My Aedits)', exact: true },
    ],
  },
  // referralItems
  {
    text: 'Referrals',
    items: [
      { to: '/referrals', text: 'Codes', exact: true },
      { to: '/referrals/programs', text: 'Programs', exact: true },
    ],
  },
]

const isCollapsible = item => Boolean(item.items)

export default function SideNav() {
  return (
    <div className="side-nav-container">
      <div className="side-nav-contents">
        <div className="logo-holder">
          <img className="logo" alt="Aedit logo" src={logo} />
        </div>
        <div className="accordion-container">
          {sideNavItems.map(item =>
            isCollapsible(item) ? (
              <CollapsibleNavItem key={item.text} {...item} />
            ) : (
              <NavItem key={item.text} {...item} />
            )
          )}
        </div>
      </div>
    </div>
  )
}
