import React, { useState, useEffect } from 'react'
import { get as loGet } from 'lodash'
import dayjs from 'dayjs'
import { toastr } from 'react-redux-toastr'
import { loadStripe } from '@stripe/stripe-js'
import { Elements, useStripe, useElements, CardElement, CardNumberElement } from '@stripe/react-stripe-js'
import { callApi } from 'config/config'
import { MAX_USER_CARDS } from 'config/constants'
import { round } from './abstract'

export const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY)

export const isExpiredCard = card => {
  const { expiration_month, expiration_year } = card

  // midnight after the last day of month
  const expiry = dayjs()
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0)
    .month(+expiration_month) // months are zero-indexed
    .year(+expiration_year)

  const now = dayjs()
  return expiry.isBefore(now)
}

export const formatCardExpiration = card => {
  const { expiration_month, expiration_year } = card
  const month = `${expiration_month}`.padStart(2, '0')
  const year = `${expiration_year}`.slice(-2)
  return `${month}/${year}`
}

export const hasActiveCard = user => {
  const cards = getUserCreditCards(user, true)
  return cards.length > 0
}

export const canAddCard = user => {
  const cards = getUserCreditCards(user)
  return cards.length < MAX_USER_CARDS
}

export const getUserCreditCards = (user, activeOnly = false) => {
  return loGet(user, 'payment_methods.credit', [])
    .filter(card => !card.date_deleted)
    .sort((a, b) => {
      // if date is not set then the card was created recently and we don't have data from api yet
      const x = dayjs(a.date_created || undefined)
      const y = dayjs(b.date_created || undefined)
      return x.isAfter(y) ? +1 : x.isBefore(y) ? -1 : 0
    })
    .filter(card => !activeOnly || !isExpiredCard(card))
}

export const getConsultationFee = provider => {
  const { consultation_fee } = provider
  return round(consultation_fee, 2)
}

export const getVoucherValue = (provider, procedureId) => {
  const { procedures } = provider
  const procedure = procedures?.find(p => p.id === procedureId)
  return round(procedure?.voucher_value ?? 0, 2)
}

export const formatCardName = card => {
  const { card_type, last_4 } = card
  const expired = isExpiredCard(card)
  return [
    card_type.toUpperCase(),
    'ending in',
    last_4,
    expired ? '(Expired)' : `(Exp ${formatCardExpiration(card)})`,
  ].filter(Boolean).join(' ')
}

export const useCards = (user, setUser) => {
  const stripe = useStripe()
  const elements = useElements()
  const [cards, setCards] = useState()
  const [loading, setLoading] = useState(false)
  const [setupObject, setSetupObject] = useState()
  const stripeReady = Boolean(stripe && elements && setupObject)

  useEffect(() => {
    if (user) {
      const cards = getUserCreditCards(user)
      setCards(cards)
    }

    setSetupObject(null)
  }, [user])

  // add card

  const initializeCardSetup = async () => {
    try {
      setLoading(true)

      const user_id = user.id
      const { setup_object } = await callApi('POST', `/user/payment/setup`, { user_id })
      setSetupObject(setup_object)
    } catch (e) {
      toastr.error(e.message)
      throw e
    } finally {
      setLoading(false)
    }
  }

  const addCard = async values => {
    if (!stripeReady || loading) {
      return
    }

    try {
      setLoading(true)

      const { name, zip, is_default } = values
      const cardElement = elements.getElement(CardNumberElement)
      const { error } = await stripe.confirmCardSetup(setupObject.client_secret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name,
            address: {
              postal_code: zip,
            },
          },
        },
      })
      if (error) {
        throw new Error(error.message)
      }

      const newUser = await callApi('POST', `/user/${user.id}/payment/payment-method`, null, {
        setup_object_id: setupObject.id,
        is_default,
      })
      setUser(newUser)

      toastr.success('Card successfully created.')
    } catch (e) {
      toastr.error(e.message)
      throw e
    } finally {
      setLoading(false)
    }
  }

  // update card

  const setDefaultCard = async card => {
    try {
      if (loading) {
        return
      }

      setLoading(true)

      const { payment_source_reference } = card

      // temporarily update the ui until we get results from backend
      const newCards = cards.map(newCard => ({
        ...newCard,
        is_default: newCard.payment_source_reference === payment_source_reference,
      }))
      setCards(newCards)

      const newUser = await callApi('PUT', `/user/${user.id}/payment/payment-method/default`, null, {
        ...card,
        payment_source_reference,
        is_default: true,
      })
      setUser(newUser)

      toastr.success('Card successfully updated.')
    } catch (e) {
      toastr.error(e.message)
      throw e
    } finally {
      setLoading(false)
    }
  }

  // remove card

  const removeCard = async card => {
    try {
      if (loading) {
        return
      }

      setLoading(true)

      const { payment_source_reference } = card
      await callApi('DELETE', `/user/${user.id}/payment/payment-method`, null, {
        payment_source_reference,
      })

      // temporarily update the ui until we get results from backend
      const newCards = cards.filter(c => c.payment_source_reference !== payment_source_reference)
      setCards(newCards)

      const newUser = await callApi('GET', `/user/${user.id}`)
      setUser(newUser)

      toastr.success('Card successfully removed.')
    } catch (e) {
      toastr.error(e.message)
      throw e
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    cards,
    initializeCardSetup,
    addCard,
    setDefaultCard,
    removeCard,
  }
}

export const usePayment = user => {
  const stripe = useStripe()
  const [loading, setLoading] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState()

  useEffect(() => {
    setPaymentMethod(null)
  }, [user])

  const submitPayment = async (provider_id, procedure_id, consultation) => {
    if (!stripe || loading) {
      return
    }

    const params = {
      user_id: user?.id,
      provider_id,
      procedure_id,
      consultation,
      payment_source_reference: paymentMethod,
    }

    _validatePayment(params)
    return await _submitPayment(params)
  }

  const _validatePayment = params => {
    try {
      const { user_id, provider_id, procedure_id, consultation, payment_source_reference } = params

      if (!user_id) {
        throw new Error('Missing required parameter user_id')
      }

      if (!provider_id) {
        throw new Error('Missing required parameter provider_id')
      }

      if (!procedure_id) {
        throw new Error('Missing required parameter procedure_id')
      }

      if (!consultation) {
        throw new Error('Missing required parameter consultation')
      }

      if (!payment_source_reference) {
        throw new Error('Missing required parameter payment_source_reference')
      }
    } catch (e) {
      toastr.error(e.message)
      throw e
    }
  }

  const _submitPayment = async params => {
    try {
      setLoading(true)
      const paymentObject = await callApi('POST', `/user/consultation/payment-object`, null, params)

      const { payment_source_reference } = params
      const { client_secret } = paymentObject.data
      const { error } = await stripe.confirmCardPayment(client_secret, {
        payment_method: payment_source_reference,
      })

      if (error) {
        throw new Error(error.message)
      }

      return paymentObject.data.id
    } catch (e) {
      toastr.error(e.message)
      throw e
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    paymentMethod,
    setPaymentMethod,
    submitPayment,
  }
}

export const withStripe = Component => {
  return props => (
    <Elements stripe={stripePromise}>
      <Component {...props} />
    </Elements>
  )
}
