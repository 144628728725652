const defaultState = {
  all: [],
  current: {},
  regionAreas: [],
  count: 0,
  totalPages: 0
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case 'FETCHED_REGION_ALL':
      if (action.payload.meta.paging) {
        const { total_results, total_pages } = action.payload.meta.paging
        return {
          ...state,
          all: action.payload.data,
          count: total_results,
          totalPages: total_pages
        }
      } else { // no results
        return { ...state, ...defaultState }
      }
    case 'FETCHED_REGION':
      return {
        ...state,
        current: action.payload
      }
    case 'UPDATED_REGION':
      return {
        ...state,
        current: action.payload
      }
    case 'CREATED_REGION':
      return {
        ...state,
        current: action.payload
      }
    default:
      return state
  }
}
