import { authedRequest } from 'config/config'
import { objectToFormData } from 'config/util'
import { uniqBy } from 'lodash'
export const fetchMedia = (options = {}) => async dispatch => {
  const defaultParams = {
    entity_type: 'user',
    order_by: 'date_created',
    order_by_direction: 'desc',
    _limit: 25,
    page: 1,
  }

  try {
    const res = await authedRequest.get('/media/entity', {
      params: { ...defaultParams, ...options },
    })

    if (res.data.error) {
      throw new Error(res.data.error.message)
    } else {
      dispatch({ type: 'FETCHED_MEDIA', payload: res.data })
    }
  } catch (err) {
    dispatch({ type: 'FAILURE', payload: err })
  }
}

// categories: 'my_aedits', 'face_recognition'
export const fetchMediaByCategory = (
  categoryName = 'my_aedits',
  options = {}
) => async dispatch => {
  const defaultParams = {
    order_by: 'date_created',
    order_by_direction: 'desc',
  }

  try {
    const res = await authedRequest.get(`/media/category/${categoryName}`, {
      params: { ...defaultParams, ...options },
    })

    const uniqueEntities = uniqBy(res.data.data, 'entity_id')

    const personalData = await Promise.all(
      uniqueEntities.map(item => {
        return authedRequest.get(`/user/${item.entity_id}`)
      })
    )

    const merged = res.data.data.map(item => {
      const personal = personalData.find(x => x?.data?.data?.id === item.entity_id)
      return { ...item, user: personal?.data?.data }
    })

    if (res.data.error) {
      throw new Error(res.data.error.message)
    } else {
      dispatch({ type: 'FETCHED_MEDIA', payload: { data: merged, meta: res.data.meta } })
    }
  } catch (err) {
    dispatch({ type: 'FAILURE', payload: err })
  }
}

export const batchUpdateMedia = (items = []) => async dispatch => {
  const requests = items.map(item => {
    return authedRequest.put(`/media/${item.id}`, item)
  })

  const [...results] = await Promise.all(requests)
  const sortedResults = results.map(r => r.data.data)

  dispatch({ type: 'REORDERED_IMAGES', payload: sortedResults })
}

export const deleteTrainingStatus = id => async dispatch => {
  dispatch({ type: 'LOADING' })
  try {
    const res = await authedRequest.delete('/media/ml/training', {
      params: {
        media_ids: id,
      },
    })

    if (res.data.error) {
      throw new Error(res.data.error.message)
    } else {
      dispatch({ type: 'DELETED_TRAINING_STATUS', payload: res.data.data[0] })
      dispatch({ type: 'UPDATE_SUCCESS' })
    }
  } catch (err) {
    dispatch({ type: 'FAILURE', payload: err })
  }
}

export const addTrainingStatus = id => async dispatch => {
  dispatch({ type: 'LOADING' })
  try {
    const res = await authedRequest.post('/media/ml/training', { media_ids: id })

    if (res.data.error) {
      throw new Error(res.data.error.message)
    } else {
      dispatch({ type: 'UPDATED_TRAINING_STATUS', payload: res.data.data[0] })
      dispatch({ type: 'UPDATE_SUCCESS' })
    }
  } catch (err) {
    dispatch({ type: 'FAILURE', payload: err })
  }
}

export const updateModel = (id, data = {}) => async dispatch => {
  dispatch({ type: 'LOADING' })

  const formData = objectToFormData(data)

  try {
    const res = await authedRequest.put(`/media/ml/model/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    dispatch({ type: 'UPDATED_MODEL', payload: res.data.data })
    dispatch({ type: 'UPDATE_SUCCESS' })
  } catch (err) {}
}

export const createModel = data => {
  return async dispatch => {
    dispatch({ type: 'LOADING' })

    const formData = objectToFormData(data)
    const res = await authedRequest.post(`/media/ml/model`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    dispatch({ type: 'CREATED_MODEL', payload: res.data.data })
    dispatch({ type: 'POST_SUCCESS' })

    try {
    } catch (err) {}
  }
}
