import React from 'react'
import { FormGroup } from '../../../components/form'
import SendEmailButton from './send_email_button'
import useVoucher from './use_voucher'

const ConsultationTriggers = props => {
  const { consultationId } = props
  const { voucher, loading } = useVoucher(consultationId)

  const voucherId = voucher?.id
  const voucherUrl = `voucher/${voucherId}/send`
  const dossierUrl = `consultation/${consultationId}/send-dossier`

  return (
    <div className="flex-fill page-form well form-horizontal">
      <FormGroup label="Voucher">
        <span>
          {voucherId ? (
            <SendEmailButton name="Send Voucher" disabled={loading} url={voucherUrl}>
              Trigger Voucher Send
            </SendEmailButton>
          ) : (
            'No voucher found!'
          )}
        </span>
      </FormGroup>
      <FormGroup label="Dossier">
        <span>
          <SendEmailButton name="Send Dossier" disabled={loading} url={dossierUrl}>
            Trigger Dossier Send
          </SendEmailButton>
        </span>
      </FormGroup>
    </div>
  )
}

export default ConsultationTriggers
