import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { fetchFlagsByPlanDefinition, fetchItem, fetchPlanDefinitionFlag, updatePlanDefinitionFlag } from '../../actions'
import store from '../../reducers'
import FlagForm, { createEntitiesFromProps, createPropsFromEntities } from './_flag_form'

const PlansFlagShow = ({
  flag,
  definition,
  loading,
  match,
  fetchFlagsByPlanDefinition,
  fetchItem,
  fetchPlanDefinitionFlag,
  updatePlanDefinitionFlag,
}) => {

  const { id: planId, flag_id: flagId } = match.params
  const { flag_definition_id: flagDefId } = flag

  useEffect(() => {
    fetchPlanDefinitionFlag(planId, flagId)
    fetchFlagsByPlanDefinition(planId)
  }, [fetchPlanDefinitionFlag, fetchFlagsByPlanDefinition, planId, flagId])

  useEffect(() => {
    if (flagDefId) {
      fetchItem('flag', flagDefId)
    }
  }, [fetchItem, flagDefId])

  useEffect(() => {
    // clear out the currentFlag object so that its properties don't potentially override
    // another flag's
    return () => {
      store.dispatch({
        type: 'UPDATED_PLAN_FLAG',
        payload: {},
      })
      store.dispatch({
        type: 'UPDATED_FLAG',
        payload: {},
      })
    }
  }, [])

  const onFormSubmit = async (data) => {
    const entities = createEntitiesFromProps(data)
    await updatePlanDefinitionFlag(planId, definition.id, flagId, entities)
  }

  return (
    <div className="primary-content">
      <div className="controls">
        <h4>{definition.slug}</h4>
      </div>
      <FlagForm
        onFormSubmit={onFormSubmit}
        loading={loading}
        initialValues={createPropsFromEntities({ definition, flag })}
      />
    </div>
  )

}

const mapStateToProps = ({ plans, flags, ui }) => {
  return {
    flag: plans.currentFlag,
    definition: flags.currentFlag,
    loading: ui.loading,
  }
}

export default connect(mapStateToProps, {
  fetchFlagsByPlanDefinition,
  fetchItem,
  fetchPlanDefinitionFlag,
  updatePlanDefinitionFlag,
})(PlansFlagShow)
