import React, {Fragment} from 'react'
import {Link} from 'react-router-dom'
import Breadcrumbs from './breadcrumbs'

export const Navbar = ({ user, routes, logout }) => (
  <Fragment>
    <header className="navbar">
      <section className="navbar-section">
        <Breadcrumbs routes={routes} />
      </section>
      <section className="navbar-section">
        {
          user &&
          <Link className="btn btn-link" to={`/users/${user.id}`}>
            { user.first_name }
          </Link>
        }
        |
        <button className="btn btn-link" onClick={() => logout()}>
          <i className="fas fa-sign-out-alt"></i>
        </button>
      </section>
    </header>
    <div className="divider"></div>
  </Fragment>
)
