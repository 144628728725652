import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchTopAnalytics } from '../../actions'
import { shapeTopViewsData } from '../../config/util'

const TopViews = ({ fetchTopAnalytics, topViews }) => {
  useEffect(
    () => {
      fetchTopAnalytics()
    },
    [fetchTopAnalytics]
  )
  const topViewsTuples = shapeTopViewsData(topViews)

  return (
    <div className="top-analytics">
      <h4 className="stats-table-header">Top Metrics By Category</h4>

      <table className="table table-hover">
        <thead>
          <tr>
            <th className="stats-table-header">Provider views</th>
            <th className="stats-table-header">Procedure views</th>
            <th className="stats-table-header">Concern views</th>
          </tr>
        </thead>
        <tbody>
          {topViewsTuples.map((tup, idx) => {
            const meme = '(⌐ ͡■ ͜ʖ ͡■)'
            const [provider, procedure, concern] = tup.map(
              ({ entity_type, entity_id, total_views, value }) => {
                if (entity_id === '-' && idx === 0) {
                  return <>{meme}</>
                }
                if (entity_id === '-') {
                  return <>-</>
                }
                return (
                  <>
                    <Link to={`/${entity_type}s/${entity_id}`}>{value || entity_id}</Link> -{' '}
                    {total_views}
                  </>
                )
              }
            )
            return (
              <tr key={`views-${idx}`}>
                <td>{provider}</td>
                <td>{procedure}</td>
                <td>{concern}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

function mapStateToProps({ analytics }) {
  return {
    topViews: analytics.topAnalytics,
  }
}

export default connect(
  mapStateToProps,
  { fetchTopAnalytics }
)(TopViews)
