import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { FormGroup, TextField, FormFooter, DateField, FileInput } from 'components/form'
import { validateRequiredFields } from 'config/util'
import _ from 'lodash'
import { subYears } from 'date-fns'

class ModelForm extends Component {
  state = {
    updatingFile: false,
  }

  toggleFile = () => {
    this.setState({ updatingFile: !this.state.updatingFile })
  }

  render() {
    const {
      loading,
      handleSubmit,
      onFormSubmit,
      invalid,
      submitFailed,
      initialValues,
      modelTypes,
    } = this.props
    const editing = initialValues && initialValues.date_created
    const { updatingFile } = this.state

    return (
      <form
        onSubmit={handleSubmit(onFormSubmit)}
        className="flex-fill page-form well form-horizontal">
        <FormGroup label="Select File" editable={!editing}>
          {editing && !updatingFile ? (
            <div className="justify-space-between">
              <a href={initialValues.model_file_url} target="_blank" rel="noopener noreferrer">
                {initialValues.model_file}
              </a>
              <span className="btn" onClick={this.toggleFile}>
                Change File
              </span>
            </div>
          ) : (
            <Field component={FileInput} name="model_file" />
          )}
        </FormGroup>

        <FormGroup label="Date Deployed" editable>
          <Field
            name="date_deployed"
            component={DateField}
            minDate={subYears(new Date(), 95)}
            maxDate={new Date()}
            yearDropdownItemNumber={100}
          />
        </FormGroup>

        <FormGroup label="Short Description" editable>
          <Field
            component={TextField}
            type="text"
            name="meta.short_description"
            placeholder="Short description"
          />
        </FormGroup>

        <FormGroup label="Model Type" editable>
          <Field
            name="meta.model_type"
            component={({ input }) => (
              <select className="form-select" {...input}>
                {modelTypes.map(type => (
                  <option key={type.id} value={type.id}>
                    {type.display_name}
                  </option>
                ))}
              </select>
            )}
          />
        </FormGroup>

        <FormGroup label="Minimum iOS Version" editable>
          <Field
            component={TextField}
            type="text"
            name="minimum_ios_version"
            placeholder="Minimum iOS Version"
          />
        </FormGroup>

        <FormFooter loading={loading} showFailure={invalid && submitFailed} />
      </form>
    )
  }
}

const validate = values => {
  const errors = {}
  const missingError = 'Field required'

  const shortDesc = _.get(values, 'meta.short_description')

  if (!shortDesc) {
    errors.meta = { short_description: missingError }
  }

  const fields = [['minimum_ios_version', 10], ['date_deployed'], ['model_file']]

  return { ...errors, ...validateRequiredFields(values, fields) }
}

export default reduxForm({
  validate: validate,
  form: 'modelForm',
  enableReinitialize: true,
})(ModelForm)
