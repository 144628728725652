import React from 'react'

export const Flagger = props => {
  const { image, flagged, toggle, lightBoxOpen } = props
  const showFlag = flagged && /original_dlib_image|original_image/.test(image?.meta?.name)

  if (!showFlag) {
    return null
  }

  return (
    <span
      className={`flag${image.is_flagged === 1 ? '-selected' : ''}`}
      onClick={() => toggle(image)}>
      <i className="fas fa-flag" />
      {!lightBoxOpen && image.meta.name === 'original_image' && (
        <span className="flagged-reason">
          {image.flagged_reason && image.flagged_reason.replace(/_/g, ' ')}
        </span>
      )}
    </span>
  )
}
