import React, { useEffect, useState, useCallback } from 'react'
import Table from './_table'
import { useApi } from 'config/hooks'

const UserNotifications = ({ userId }) => {
  const [items, setItems] = useState([])
  const [api, loading] = useApi(false)

  const fetchItems = useCallback(async () => {
    const res = await api.get(`/user/${userId}/notification-settings`) || []
    await setItems(res)
  }, [api, userId])

  const updateItem = useCallback(async item => {
    await api.put(`/user/${userId}/notification-settings`, item)
    await fetchItems()
  }, [api, fetchItems, userId])

  useEffect(() => {
    fetchItems(userId)
  }, [fetchItems, userId])

  return (
    <div className="flex-fill page-form well form-horizontal">
      <Table
        items={items}
        loading={loading}
        edit={updateItem}
      />
    </div>
  )
}

export default UserNotifications
