import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { validateRequiredFields, setMaxCharLimit, digitsOnly, lastNYears } from 'config/util'
import {
  FormGroup,
  TextField,
  TextArea,
  RadioField,
  FormFooter,
  TypeAhead,
  SelectAsync,
  SelectField,
  DateField,
  CustomTag,
  Base64FileInput,
} from 'components/form'
import { fetchItems } from 'actions'
import { RADIO_OPTIONS, VALID_URL, CONTENTFUL_ENTRY_BASE_URL } from 'config/constants'
import CopyPaste from 'components/copy_paste'
import { subYears } from 'date-fns'
import dayjs from 'dayjs'

import './index.scss'

const providerRoot = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOSTNAME + '/provider/'

class ProviderForm extends Component {
  constructor() {
    super()
    this.fetchCampus = _.debounce(this.fetchCampus, 400)

    this.state = {
      profileChanged: false,
      numOfNewLicenseFields: 1,
    }
  }

  componentDidUpdate() {
    if (this.props.submitSucceeded) {
      this.setState({
        profileChanged: false,
      })

      this.props.reset()
    }
  }

  fetchCampus = query => {
    this.props.fetchItems('campus', { searching: true, name: query, _limit: 7 })
  }

  deletePhoto = () => {
    this.props.change('profile_picture', null)
  }

  addNewLicenseField = id => {
    if (this.state.numOfNewLicenseFields === id + 1) {
      this.setState({ numOfNewLicenseFields: this.state.numOfNewLicenseFields + 1 })
    }
  }

  render() {
    const {
      loading,
      handleSubmit,
      onFormSubmit,
      initialValues,
      provider,
      invalid,
      submitFailed,
      change,
      areas,
      campuses,
      configOptions,
      states,
      profilePicture,
      primaryPractice,
      agreementList = [],
      licensesList = [],
    } = this.props

    const activeAgreement = agreementList.find((a) => a.is_active) || null;
    const latestSigned = agreementList
      .filter((a) => a.agreement_signed && a.agreement_sign_date)
      .reduce((latest, current) => {
        if (!latest || current.agreement_sign_date > latest.agreement_sign_date) {
          return current;
        }

        return null;
      }, null);

    const STATES_ABBR = states ? states : []

    const editingRecord = provider && provider.date_created
    const licensesListTailIndex = licensesList.length > 0 ? licensesList.length : 0

    return (
      <form
        onSubmit={handleSubmit(onFormSubmit)}
        className="flex-fill page-form well form-horizontal">
        <FormGroup label="Salutation" editable>
          <Field name="salutation" hideDisabledOption component={SelectField}>
            {Object.values(configOptions.SALUTATION).map(s => (
              <option value={s.id} key={s.id}>
                {s.display_name}
              </option>
            ))}
          </Field>
        </FormGroup>

        <FormGroup label="First Name" editable>
          <Field name="first_name" component={TextField} />
        </FormGroup>

        <FormGroup label="Middle Initial" editable>
          <Field name="middle_initial" component={TextField} />
        </FormGroup>

        <FormGroup label="Last Name" editable>
          <Field name="last_name" component={TextField} />
        </FormGroup>

        <FormGroup label="Suffix" editable>
          <Field name="suffix" component={SelectField} hideDisabledOption>
            <option value="">Select One</option>
            {Object.values(configOptions.NAME_SUFFIX).map(s => (
              <option value={s.id} key={s.id}>
                {s.display_name}
              </option>
            ))}
          </Field>
        </FormGroup>

        <FormGroup label="Gender" editable>
          <Field
            component={TypeAhead}
            name="gender"
            options={Object.values(configOptions.GENDER)}
            labelKey="display_name"
            valueKey="id"
            multi
          />
        </FormGroup>

        <FormGroup label="Profile Picture" editable>
          {profilePicture && initialValues && initialValues.profile_picture_url && (
            <span className="delete-image" onClick={this.deletePhoto}>
              <img
                src={this.state.profileChanged ? profilePicture : initialValues.profile_picture_url}
                className="pic-preview"
                alt="profile pic"
              />
              <p className="delete">X</p>
            </span>
          )}
          {this.props.profileChanged}
          <Field
            name="profile_picture"
            type="file"
            component={Base64FileInput}
            onChange={val => {
              change('profile_picture', val)
              this.setState({
                profileChanged: true,
              })
            }}
          />
        </FormGroup>

        <FormGroup label="Original Profile Picture">
          {initialValues && initialValues.profile_picture_original_url && (
            <img
              src={initialValues.profile_picture_original_url}
              className="pic-preview"
              alt="original profile pic"
            />
          )}
        </FormGroup>

        <FormGroup label="Email" editable>
          <Field name="email" component={TextField} type="email" />
        </FormGroup>

        <FormGroup label="Active Agreement Version">
          {activeAgreement ? (
            <div className="agreement-version-display">
              <span>Version {activeAgreement.agreement_version_id}</span>
              <a
                href={`${CONTENTFUL_ENTRY_BASE_URL}/${activeAgreement.contentful_id}`}
                target="_blank"
                rel="noopener noreferrer">
                View/edit active agreement in contentful
              </a>
            </div>
          ) : (
            <span>No active agreements published in contentful</span>
          )}
        </FormGroup>
        <FormGroup label="Latest Signed Agreement Version">
          {latestSigned ? (
            <div className="agreement-version-display">
              <span>Version {latestSigned.agreement_version_id}</span>
              <span>{dayjs(latestSigned.agreement_sign_date).format('MM-DD-YYYY @ hh:mm a')}</span>
            </div>
          ) : (
            <span>This provider has not signed any agreements yet</span>
          )}
        </FormGroup>

        <FormGroup label="Google Place ID" editable>
          <Field name="location.place_id" component={TextField} disabled />
        </FormGroup>

        <FormGroup label="Phone" editable>
          <Field name="phone" component={TextField} parse={digitsOnly} type="tel" />
        </FormGroup>

        <FormGroup label="Phone Country" editable>
          <Field name="phone_country" component={TextField} />
        </FormGroup>

        <FormGroup label="Date of Birth" editable>
          <Field
            name="date_of_birth"
            component={DateField}
            minDate={subYears(new Date(), 95)}
            maxDate={subYears(new Date(), 18)}
            yearDropdownItemNumber={100}
            showTimeSelect={false}
          />
        </FormGroup>

        <FormGroup
          label="First year active"
          editable
          helper="Used to determine years of experience">
          <Field name="years_of_experience" component={SelectField}>
            {lastNYears(70)}
          </Field>
        </FormGroup>

        <FormGroup label="Languages Spoken" editable>
          <Field
            component={TypeAhead}
            name="languages_spoken"
            options={Object.values(configOptions.LANGUAGES)}
            labelKey="display_name"
            valueKey="id"
            multi
          />
        </FormGroup>

        <FormGroup label="Professional Title" editable>
          <Field name="professional_title" component={TextField} />
        </FormGroup>

        <FormGroup label="Professional Title (short/cards)" editable>
          <Field name="professional_title_short" component={TextField} />
        </FormGroup>

        <FormGroup label="Professional Title Secondary" editable>
          <Field name="professional_title_secondary" component={TextField} />
        </FormGroup>

        <FormGroup label="Professional Statement" editable>
          <Field name="professional_statement" component={TextArea} />
        </FormGroup>

        <FormGroup label="Professional Quote" editable>
          <Field name="professional_quote" component={TextArea} />
        </FormGroup>

        <FormGroup label="Undergrad Institution" editable>
          <Field
            name="education_undergrad_institution"
            component={SelectAsync}
            options={campuses}
            loading={loading}
            getItems={this.fetchCampus}
            labelKey="name"
          />
        </FormGroup>

        <FormGroup label="Undergrad School Year Graduated" editable>
          <Field name="undergrad_graduation_year" component={SelectField}>
            {lastNYears(70)}
          </Field>
        </FormGroup>

        <FormGroup label="Graduation Institution" editable>
          <Field
            name="education_graduation_institution"
            component={SelectAsync}
            options={campuses}
            loading={loading}
            getItems={this.fetchCampus}
            labelKey="name"
          />
        </FormGroup>

        <FormGroup label="Graduate School Year Graduated" editable>
          <Field name="graduate_graduation_year" component={SelectField}>
            {lastNYears(65)}
          </Field>
        </FormGroup>

        <FormGroup label="Degree" editable>
          <Field
            component={TypeAhead}
            name="degrees"
            options={Object.values(configOptions.DEGREE_TYPES)}
            labelKey="display_name"
            valueKey="id"
            multi
          />
        </FormGroup>

        <FormGroup label="Residency (Hospital)" editable>
          <Field name="education_residency" component={TextField} />
        </FormGroup>

        <FormGroup label="Residency Start Date" editable>
          <Field
            name="residency_date_start"
            component={DateField}
            minDate={subYears(new Date(), 95)}
            yearDropdownItemNumber={50}
            showTimeSelect={false}
          />
        </FormGroup>

        <FormGroup label="Residency End Date" editable>
          <Field
            name="residency_date_end"
            component={DateField}
            minDate={subYears(new Date(), 95)}
            yearDropdownItemNumber={50}
            showTimeSelect={false}
          />
        </FormGroup>

        <FormGroup label="Fellowships Trained In" editable>
          <Field
            component={TypeAhead}
            name="fellowship_trained_in"
            options={Object.values(configOptions.FELLOWSHIP_TRAINED_IN)}
            labelKey="display_name"
            valueKey="id"
            multi
          />
        </FormGroup>

        <FormGroup label="Fellowships (Teaching Hospital)" editable>
          <Field
            component={CustomTag}
            name="education_fellowships"
            filterType="textOnly"
            placeholder="Fellowships (Teaching Hospital)"
            multi
          />
        </FormGroup>

        <FormGroup label="Board Certification(s)" editable>
          <Field
            component={TypeAhead}
            name="board_certifications"
            options={Object.values(configOptions.BOARD_CERTIFICATIONS)}
            labelKey="display_name"
            valueKey="id"
            multi
          />
        </FormGroup>

        {licensesList.map((license, index) => (
          <FormGroup label={`License #${index + 1}`} inputHolderClassName="labeledFields" editable>
            <Field
              name={`licenses[${index}].state`}
              label="State"
              labelKey="abbreviation"
              valueKey="abbreviation"
              component={TypeAhead}
              options={Object.values(STATES_ABBR)}
            />
            <Field name={`licenses[${index}].license_start`} label="Start" component={TextField} />
            <Field name={`licenses[${index}].license_end`} label="End" component={TextField} />
          </FormGroup>
        ))}

        {_.times(this.state.numOfNewLicenseFields, index => (
          <FormGroup
            label={`New License #${index + 1}`}
            inputHolderClassName="labeledFields"
            editable>
            <>
              <Field
                name={`licenses[${licensesListTailIndex + index}].state`}
                label="State"
                labelKey="abbreviation"
                valueKey="abbreviation"
                component={TypeAhead}
                options={Object.values(STATES_ABBR)}
                onChange={() => this.addNewLicenseField(index)}
              />

              <Field
                name={`licenses[${licensesListTailIndex + index}].license_start`}
                label="Start"
                component={TextField}
              />
              <Field
                name={`licenses[${licensesListTailIndex + index}].license_end`}
                label="End"
                component={TextField}
              />
            </>
          </FormGroup>
        ))}

        <FormGroup label="Type of Provider" editable>
          <Field
            component={TypeAhead}
            name="types"
            options={Object.values(configOptions.PROVIDER_TYPES)}
            labelKey="display_name"
            valueKey="id"
            multi
          />
        </FormGroup>

        <FormGroup label="Specialty" editable>
          <Field
            component={TypeAhead}
            name="specialties"
            options={Object.values(configOptions.SPECIALTIES)}
            labelKey="display_name"
            valueKey="id"
            multi
          />
        </FormGroup>

        <FormGroup label="Hospital Affiliations" editable>
          <Field
            component={CustomTag}
            name="hospital_affiliations"
            filterType="textOnly"
            placeholder="Hospital Affiliations"
            multi
          />
        </FormGroup>

        <FormGroup label="Awards" editable>
          <Field
            component={CustomTag}
            name="awards"
            filterType="textOnly"
            placeholder="Awards"
            multi
          />
        </FormGroup>

        <FormGroup label="Memberships/Societies" editable>
          <Field
            component={CustomTag}
            name="professional_memberships"
            filterType="textOnly"
            placeholder="Memberships/Societies"
            multi
          />
        </FormGroup>

        <FormGroup
          label="Allow Instant Booking"
          editable
          helper="Instant Booking is only available for Silver Tier and above Providers">
          <Field component={RadioField} name="allow_instant_booking" items={RADIO_OPTIONS} />
        </FormGroup>

        <FormGroup label="Allow Scheduler" editable>
          <Field component={RadioField} name="allow_schedule" items={RADIO_OPTIONS} />
        </FormGroup>

        <FormGroup label="Has Consultation Fee" editable>
          <Field component={RadioField} name="has_consultation_fee" items={RADIO_OPTIONS} />
        </FormGroup>
        <FormGroup label="Consultation Fee" editable>
          <Field name="consultation_fee" type="text" component={TextField} />
        </FormGroup>

        <FormGroup label="Private Notes" editable>
          <Field component={TextArea} name="private_notes" placeholder="Private Notes" />
        </FormGroup>

        <FormGroup label="Website" editable>
          <Field name="website_url" type="text" component={TextField} />
        </FormGroup>
        <FormGroup label="Instagram" editable>
          <Field name="instagram_name" type="text" component={TextField} />
        </FormGroup>
        <FormGroup label="Snapchat" editable>
          <Field name="snapchat_name" type="text" component={TextField} />
        </FormGroup>
        <FormGroup label="Twitter" editable>
          <Field name="twitter_name" type="text" component={TextField} />
        </FormGroup>
        <FormGroup label="Facebook" editable>
          <Field name="facebook_name" type="text" component={TextField} />
        </FormGroup>
        <FormGroup label="LinkedIn" editable>
          <Field name="linkedin_name" type="text" component={TextField} />
        </FormGroup>
        <FormGroup label="YouTube" editable>
          <Field name="youtube_url" type="text" component={TextField} />
        </FormGroup>

        <FormGroup
          label="Beauty Board Member"
          editable
          helper="Our beauty board members are providers (academic and practitioners) who have elevated status on our platform and help review all our medical content">
          <Field component={RadioField} name="is_beauty_board_member" items={RADIO_OPTIONS} />
        </FormGroup>

        <FormGroup label="Aedit Rating" editable>
          <Field name="aedit_rating" component={TextField} type="number" parse={digitsOnly} />
        </FormGroup>

        {!editingRecord && (
          <Fragment>
            <FormGroup label="Password" editable>
              <Field name="password" component={TextField} type="password" />
            </FormGroup>
            <FormGroup label="Password Confirmation" editable>
              <Field name="password_confirmation" component={TextField} type="password" />
            </FormGroup>
          </Fragment>
        )}

        <FormGroup
          label="Active"
          editable
          helper={`Active providers show up in AEDIT's provider search tool which can be found at ${process.env.REACT_APP_HOSTNAME}/providers`}>
          <Field component={RadioField} name="is_active" items={RADIO_OPTIONS} />
        </FormGroup>

        <FormGroup
          label="Verified By Us"
          editable
          helper="This flag is to be used for our admin team to ensure they have verified providers. Providers can only become active if they are verified">
          <Field component={RadioField} name="is_verified" items={RADIO_OPTIONS} />
        </FormGroup>

        <FormGroup
          label="Publicly Viewable"
          editable
          helper="Publicly viewable providers are providers who show up in Google searches.  These providers include providers the sales team has signed up as well as providers submitted via our data collection team">
          <Field component={RadioField} name="publicly_viewable" items={RADIO_OPTIONS} />
        </FormGroup>
        <FormGroup
          label="Accepts Video Consultations"
          editable
          helper="Providers who accept video consultations will be labeled distinctly on their profile and provider cards">
          <Field component={RadioField} name="accept_video_consultations" items={RADIO_OPTIONS} />
        </FormGroup>

        <FormGroup
          label="Booking Available"
          editable
          helper="Providers who accept booking will have buttons shown on their profile that allow users to book.">
          <Field component={RadioField} name="booking_available" items={RADIO_OPTIONS} />
        </FormGroup>

        <FormGroup
          label="Has Voucher"
          editable
          helper="Whether to create a voucher when booking with this provider.">
          <Field component={RadioField} name="has_voucher" items={RADIO_OPTIONS} />
        </FormGroup>

        {editingRecord && (
          <Fragment>
            <FormGroup label="Associated Practice">
              <span>
                <Link to={`/practices/${primaryPractice?.id}`}>{primaryPractice?.name}</Link>
              </span>
            </FormGroup>

            <FormGroup label="Date Verified By Us">
              <span>
                {dayjs(provider.date_verified_by_aedit).format('YYYY-MM-DD HH:mm') ||
                  'Not yet verified'}
              </span>
            </FormGroup>
            <FormGroup label="Slug">
              <CopyPaste text={initialValues.slug} />
            </FormGroup>
            <FormGroup label="Provider Profile Link">
              <a href={providerRoot + initialValues.slug} target="_blank" rel="noopener noreferrer">
                {initialValues.first_name + ' ' + initialValues.last_name}
              </a>
            </FormGroup>
            <FormGroup label="Completed Signup">
              <span>{initialValues.completed_signup ? 'Yes' : 'No'}</span>
            </FormGroup>
            <FormGroup label="Last Activity Date">
              <span>{dayjs(initialValues.last_activity_date).format('YYYY-MM-DD HH:mm')}</span>
            </FormGroup>
            <FormGroup label="Last Profile Update">
              <span>{dayjs(initialValues.date_updated).format('YYYY-MM-DD HH:mm')}</span>
            </FormGroup>
            <FormGroup label="Date Created">
              <span>{dayjs(initialValues.date_created).format('YYYY-MM-DD HH:mm')}</span>
            </FormGroup>
          </Fragment>
        )}

        <FormFooter loading={loading} showFailure={invalid && submitFailed} />
      </form>
    )
  }
}

const validate = values => {
  const errors = {}
  const { password, password_confirmation, website_url } = values

  const requiredFields = [
    ['first_name', 250],
    ['last_name', 250],
    ['email', 250],
    ['degrees'],
    ['types'],
    ['areas'],
    ['specialties'],
    ['is_active'],
    ['password'],
    ['salutation'],
    ['password_confirmation'],
    ['publicly_viewable'],
    ['professional_title', 250],
    ['professional_title_short', 25],
    ['professional_quote', 255],
    ['accept_video_consultations'],
    ['has_voucher'],
  ]

  const optionalFields = [
    ['professional_statement', 350],
    ['professional_memberships', 5000],
    ['education_residency', 250],
    ['website_url', 250],
    ['education_fellowships', 250],
    ['awards', 250],
    ['google_plus_name', 250],
    ['facebook_name', 250],
    ['twitter_name', 250],
    ['instagram_name', 250],
    ['snapchat_name', 250],
    ['linkedin_name', 250],
    ['youtube_url', 250],
    ['private_notes', 500],
    ['middle_initial', 1],
    ['professional_title_secondary', 250],
  ]

  if (website_url && !VALID_URL.test(website_url)) {
    errors.website_url = 'Url must start with http(s)'
  }

  if (password && password_confirmation && password_confirmation !== password) {
    errors.password_confirmation = 'Passwords do not match'
  }

  return {
    ...validateRequiredFields(values, requiredFields),
    ...setMaxCharLimit(values, optionalFields),
    ...errors,
  }
}

ProviderForm = reduxForm({
  validate: validate,
  form: 'providerForm',
  enableReinitialize: true,
})(ProviderForm)

const selector = formValueSelector('providerForm')

ProviderForm = connect(
  state => {
    return {
      profilePicture: selector(state, 'profile_picture'),
    }
  },
  { fetchItems }
)(ProviderForm)

export default ProviderForm
