import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { createAccountPlan, fetchItems, updateAccountPlan } from 'actions'
import { FormFooter, FormGroup, SelectField } from 'components/form'
import { formatDate } from 'config/util'

const ProviderPlanForm = ({
  createAccountPlan,
  fetchItems,
  handleSubmit,
  initialValues,
  invalid,
  loading,
  onFormSubmit: _onFormSubmit,
  plans,
  provider,
  submitFailed,
  updateAccountPlan,
}) => {
  const editing = !!initialValues.id

  useEffect(() => {
    fetchItems('plan')
  }, [fetchItems])

  const onFormSubmit = async data => {
    let { plan_definition_id } = data

    if (!plan_definition_id || !plan_definition_id.length) {
      plan_definition_id = null // selected plan is "None"
    }

    await upsertPlan(initialValues, plan_definition_id)

    if (_onFormSubmit) {
      _onFormSubmit(data)
    }
  }

  const upsertPlan = async (currentPlan, newPlanDefinitionId) => {
    const { id, is_active, plan_definition_id } = currentPlan

    if (newPlanDefinitionId) {
      if (!is_active || +plan_definition_id !== +newPlanDefinitionId) {
        await createAccountPlan('provider', provider.id, { plan_definition_id: +newPlanDefinitionId })
      }
    } else {
      if (is_active) {
        await updateAccountPlan('provider', provider.id, id, { is_active: false })
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} className="page-form well form-horizontal">
      <FormGroup label="Plan" editable>
        <Field name="plan_definition_id" component={SelectField} hideDisabledOption>
          <option value="">None</option>
          {plans.map(plan => (
            <option key={plan.id} value={plan.id}>{plan.name}</option>
          ))}
        </Field>
      </FormGroup>

      {editing && (
        <>
          <FormGroup label="Created At">
            <span>{formatDate(initialValues.date_created)}</span>
          </FormGroup>
          <FormGroup label="Updated At">
            <span>{formatDate(initialValues.date_updated)}</span>
          </FormGroup>
        </>
      )}

      <FormFooter loading={loading} showFailure={invalid && submitFailed} />
    </form>
  )
}

const ProviderPlanReduxForm = reduxForm({
  form: 'providerPlanForm',
  enableReinitialize: true,
})(ProviderPlanForm)

const mapStateToProps = ({ plans, ui }) => {
  return {
    plans: plans.all,
    loading: ui.loading,
  }
}

export default connect(mapStateToProps, {
  createAccountPlan,
  fetchItems,
  updateAccountPlan,
})(ProviderPlanReduxForm)