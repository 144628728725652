const defaultState = {
  all: [],
  currentProcedure: {},
  count: 0,
  totalPages: 0,
  concernProcedures: [],
  beforeAfters: []
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case 'FETCHED_PROCEDURE_ALL':
      // more than 0 results
      if (action.payload.meta.paging) {
        const { total_results, total_pages } = action.payload.meta.paging
        return {
          ...state,
          all: action.payload.data,
          count: total_results,
          totalPages: total_pages
        }
      } else { // no results
        return { ...state, ...defaultState }
      }
    case 'FETCHED_PROCEDURE':
      return { ...state, currentProcedure: action.payload }
    case 'UPDATED_PROCEDURE':
      return {
        ...state,
        currentProcedure: action.payload
      }
    case 'CREATED_PROCEDURE':
      return {
        ...state,
        currentProcedure: action.payload
      }
    case 'FETCHED_PROCEDURES_CONCERNS':
      return {
        ...state,
        concernProcedures: action.payload
      }
    case 'FETCHED_PROCEDURE_BEFORE_AFTER_ALL':
      return {
        ...state,
        beforeAfters: action.payload
      }
    default:
      return state
  }
}
