import React, { useState, useEffect } from 'react'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'

import { FileInput, TextField, TypeAhead, SelectField, RadioField } from 'components/form'
import { validateRequiredFields, digitsOnly, getBase64, getProfileMaxFlag } from 'config/util'
import Modal from 'simple-react-modal'
import { compose } from 'redux'
import store from 'reducers'
import { authedRequest } from 'config/config'
import { RADIO_OPTIONS } from 'config/constants'

const AddBeforeAfterForm = ({
  after_images,
  before_image,
  change,
  closeModal,
  handleSubmit,
  initialValues,
  invalid,
  loading,
  submitting,
  modalShown,
  procedures,
  provider,
  saveImageSet,
  updateImageSet,
}) => {
  const updating = !!initialValues.id
  const [existingImages, setExistingImages] = useState(updating)
  const [reachedPhotoLimit, setReachedPhotoLimit] = useState(null)
  const [isEditing, setIsEditing] = useState(null)
  const [swapped, setSwapped] = useState(false)
  const [explicitTouched, setExplicitTouched] = useState(false)
  const [childProcedures, setChildProcedures] = useState(false)
  const [selectedProcedure, setSelectedProcedure] = useState(false)

  useEffect(() => {
    if (initialValues) {
      setSelectedProcedure(initialValues.procedure)
    }
  }, [])

  useEffect(() => {
    const _childProcedures = procedures.filter(procedure => procedure.is_parent === 0)

    setChildProcedures(_childProcedures)
  }, [procedures])

  useEffect(() => {
    const initalize = async () => {
      const maxPhotoSets = getProfileMaxFlag(provider, 'before-and-afters') // null if unlimited
      if (maxPhotoSets === null) {
        setReachedPhotoLimit(false)
      } else {
        const {
          data: { meta: { paging: { total_results = 0 } = {} } = {} },
        } = await authedRequest.get(`provider/${provider.id}/before_and_after`, {
          params: { _limit: 1 },
        })
        setReachedPhotoLimit(total_results >= maxPhotoSets)
      }
      setIsEditing(initialValues.isEditing)
    }
    initalize()
  }, [provider])

  const initializeExplicit = procedure => {
    const value = initialValues.meta?.explicit
    const valueProvided = value !== null && value !== undefined
    const shouldInitialize = !updating || !valueProvided
    if (shouldInitialize && !explicitTouched && procedure) {
      change('meta.explicit', procedure.explicit)
    }
  }

  return (
    <>
      <Modal show={modalShown} onClose={closeModal} containerClassName="modal-content">
        {!isEditing && reachedPhotoLimit === true && (
          <div className="m-2 p-2">
            <p>Number of photos will exceed provider's current plan.</p>

            <div className="text-right mt-2">
              <span className="btn mr-2" onClick={closeModal}>
                Back
              </span>
            </div>
          </div>
        )}
        {(isEditing || reachedPhotoLimit === false) && (
          <form onSubmit={handleSubmit(updating ? updateImageSet : saveImageSet)}>
            <div className="dual-fields">
              <div>
                <p>Before Image</p>
                {existingImages ? (
                  <div onClick={() => setExistingImages(false)}>
                    <Image src={before_image} alt="" crossOrigin="anonymous" />
                    <span className="remove-image">Remove</span>
                  </div>
                ) : (
                  <Field component={FileInput} name="before_image" type="file" className="mt-0" />
                )}
              </div>
              <div>
                <p>After Image</p>
                {existingImages ? (
                  <div onClick={() => setExistingImages(false)}>
                    <Image src={after_images[0].url} alt="" crossOrigin="anonymous" />
                    <span className="remove-image">Remove</span>
                  </div>
                ) : (
                  <Field component={FileInput} name="after_images" type="file" className="mt-0" />
                )}
              </div>
            </div>
            <div className="dual-fields">
              <div>
                {/* TEMPORARILY COMMENTING OUT SWAP */}
                {/* CREATE NEW TICKET FOR THIS FIX */}
                <span
                  className={`btn ${loading ? 'loading' : ''}`}
                  style={{ marginTop: '20px' }}
                  onClick={
                    swapped
                      ? () => {
                          toastr.warning('Can only swap once', 'Save or Cancel!')
                        }
                      : async () => {
                          if (!loading) {
                            await store.dispatch({ type: 'LOADING' })
                            await Promise.all([
                              change('after_images', before_image || null),
                              change(
                                'before_image',
                                existingImages ? after_images[0].url : after_images || null
                              ),
                              setSwapped(true),
                            ])
                            await store.dispatch({ type: 'RESET' })
                          }
                        }
                  }>
                  {swapped ? 'Swapped!' : 'Swap images'}
                </span>
              </div>

              <div>
                <Field name="meta.orientation" dropdownText="Orientation" component={SelectField}>
                  <option value="portrait">Side-by-side</option>
                  <option value="landscape">Stacked</option>
                </Field>
                <p>
                  Side-by-side is recommended for portrait images and Stacked for landscape images.
                </p>
              </div>
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <label htmlFor="meta.explicit" className="mr-3">
                Is Explicit?
              </label>
              <Field
                component={RadioField}
                name="meta.explicit"
                items={RADIO_OPTIONS}
                onBlur={() => setExplicitTouched(true)}
              />
            </div>

            <div className="dual-fields">
              <div>
                <Field
                  component={TextField}
                  type="text"
                  name="candidate_name"
                  placeholder="Candidate Name"
                  showLength={false}
                />
              </div>

              <div>
                <Field
                  component={TextField}
                  type="text"
                  parse={digitsOnly}
                  showLength={false}
                  name="candidate_age"
                  placeholder="Candidate Age"
                />
              </div>
            </div>

            <Field name="candidate_gender" dropdownText="Candidate Gender" component={SelectField}>
              <option value="female">Female</option>
              <option value="male">Male</option>
              <option value="non-binary">Non-binary</option>
            </Field>

            <Field
              component={TypeAhead}
              name="procedure"
              options={childProcedures}
              labelKey="name"
              valueKey="id"
              multi={false}
              onChange={procedure => {
                initializeExplicit()
                setSelectedProcedure(procedure)
              }}
              placeholder="Select a Child Procedure"
            />

            <p className="Select">
              <b>Solution: </b>

              {selectedProcedure ? selectedProcedure?.parent_procedure?.name || 'Not found' : null}
            </p>

            <p className="Select">
              <b>Concern(s) Addressed: </b>

              {selectedProcedure
                ? selectedProcedure?.concerns?.map((concern, i) => {
                    if (i < selectedProcedure?.concerns?.length - 1) {
                      return concern.name + `,${' '}`
                    } else return concern.name
                  }) || 'Not found'
                : null}
            </p>

            <div className="dual-fields">
              <div>
                <Field
                  name="meta.after_count"
                  component={TextField}
                  parse={digitsOnly}
                  type="number"
                  showLength={false}
                  placeholder="How long after image was taken?"
                />
              </div>

              <div>
                <Field
                  name="meta.after_length_of_time"
                  dropdownText="Length of Time"
                  component={SelectField}>
                  <option value="days">Days</option>
                  <option value="weeks">Weeks</option>
                  <option value="months">Months</option>
                  <option value="years">Years</option>
                </Field>
              </div>
            </div>

            <Field
              component={TextField}
              type="text"
              showLength={false}
              name="recovery"
              placeholder="Recovery Time"
            />

            <div>
              <Field
                component={TextField}
                type="text"
                showLength={false}
                name="caption"
                placeholder="Image Caption"
              />
            </div>

            <div className="text-right mt-2">
              <span className="btn mr-2" onClick={closeModal}>
                Cancel
              </span>
              <button
                className={`btn btn-primary ${loading || submitting ? 'loading' : ''}`}
                type="submit"
                disabled={loading || submitting || invalid}>
                Save
              </button>
            </div>
          </form>
        )}
      </Modal>
    </>
  )
}

const validate = values => {
  const fields = [
    ['before_image'],
    ['after_images'],
    ['candidate_age'],
    ['before_after_type'],
    ['procedure'],
    ['procedure_id'],
    ['meta.after_count'],
    ['meta.after_length_of_time'],
    ['meta.orientation'],
    ['caption', 255],
    ['meta.explicit'],
  ]
  return validateRequiredFields(values, fields)
}

const selector = formValueSelector('addBeforeAfterForm')

const Image = ({ src, ...props }) => {
  const [image, setImage] = useState()

  useEffect(() => {
    if (typeof src === 'string') {
      setImage(src)
    } else if (src instanceof File) {
      const updateImage = async () => setImage(await getBase64(src))
      updateImage()
    }
  }, [src])

  return <img src={image} {...props} alt="required" />
}

const mapStateToProps = state => ({
  loading: state.ui.loading,
  before_image: selector(state, 'before_image'),
  after_images: selector(state, 'after_images'),
})

export default compose(
  connect(mapStateToProps),
  reduxForm({
    validate: validate,
    form: 'addBeforeAfterForm',
    enableReinitialize: true,
    initialValues: {
      meta: { orientation: 'portrait' },
    },
  })
)(AddBeforeAfterForm)
