import React, { useState } from 'react'
import { Field } from 'redux-form'
import { RadioField } from 'components/form'
import { useCards, withStripe } from 'config/util/payment'
import AddCardModal from './add_card_modal'
import Card from './card'
import RemoveCardDialogue from './remove_card_dialogue'
import './card_list.scss'

const CardList = props => {
  const {
    user,
    setUser,
    name = null,
    setPaymentMethod = null,
    showUserLink = false,
    editable = false,
  } = props
  const selectable = Boolean(name && setPaymentMethod)

  const {
    loading,
    cards,
    initializeCardSetup,
    addCard,
    setDefaultCard,
    removeCard,
  } = useCards(user, setUser)
  const [addCardModalToggle, setAddCardModalToggle] = useState(false)
  const [cardToRemove, setCardToRemove] = useState()

  const items = (cards || []).map(card => ({
    value: card.payment_source_reference,
    labelText: (
      <Card
        card={card}
        setDefaultCard={setDefaultCard}
        setCardToRemove={setCardToRemove}
        loading={loading}
        editable={editable}
      />
    ),
  }))

  return (
    <div className="mt-2 mb-2 credit-card-list">
      {selectable ? (
        <Field
          name={name}
          component={RadioField}
          items={items}
          onChange={e => setPaymentMethod && setPaymentMethod(e.target.value)}
        />
      ) : (
        <ul>
          {items.map(({ value, labelText }) => (
            <li key={value}>
              {labelText}
            </li>
          ))}
        </ul>
      )}

      {Boolean(editable) && (
        <>
          <button
            type="button"
            disabled={loading}
            onClick={() => setAddCardModalToggle(true)}
            className="btn mt-2 mb-2">
            Add a card
          </button>

          <AddCardModal
            modalToggle={addCardModalToggle}
            setModalToggle={setAddCardModalToggle}
            initializeCardSetup={initializeCardSetup}
            addCard={addCard}
            loading={loading}
          />

          <RemoveCardDialogue
            cardToRemove={cardToRemove}
            setCardToRemove={setCardToRemove}
            removeCard={removeCard}
            loading={loading}
          />
        </>
      )}

      {Boolean(showUserLink) && (
        <a
          href={`/users/${user.id}?tab=5`}
          target="_blank"
          rel="noopener noreferrer">
          Manage payment methods{' '}
          <i className="fa fa-external-link-alt" />
        </a>
      )}
    </div>
  )
}

const StripeCardList = withStripe(CardList)

export default StripeCardList

