import React, { useEffect } from 'react'
import Modal from 'simple-react-modal'
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js'
import { Btn } from '@aedit/styleguide/dist/components'
import { AeditForm, AeditButton, AeditField } from '@aedit/styleguide/dist/form'
import { noop } from 'config/util/abstract'
import { composeValidators, required, zip } from 'config/util/validators'
import './add_card_modal.scss'

const AddCardModal = props => {
  const { modalToggle, setModalToggle, initializeCardSetup, addCard, loading } = props

  useEffect(() => {
    if (modalToggle) {
      initializeCardSetup()
    }
  }, [modalToggle])

  const handleAddCard = async values => {
    const is_default = values.is_default.includes('is_default')
    await addCard({ ...values, is_default })
    setModalToggle(false)
  }

  return (
    <Modal show={modalToggle} onClose={noop}>
      <AeditForm
        className="credit-card-form"
        onSubmit={handleAddCard}
        enableReinitialize={true}
        initialValues={{
          is_default: [],
        }}>
        <h4>Add a Credit Card</h4>
        <div className="credit-card-form__main">
          <AeditField.Input
            name="name"
            label="Name on card"
            validate={required}
            data-cy="name-input"
          />

          <div className="NEW__aedit-input-field mt-4">
            <div className="input-wrapper">
              <label htmlFor="card_number" className="V2__overline contents-label">
                Card information
              </label>
              <CardNumberElement />
            </div>
          </div>

          <div className="NEW__aedit-input-field mt-4">
            <div className="input-wrapper input-wrapper--dual">
              <CardExpiryElement />
              <CardCvcElement />
            </div>
          </div>

          <AeditField.Input
            name="zip"
            label="Zip code"
            maxLength={5}
            validate={composeValidators(required, zip)}
            data-cy="zip-input"
          />

          <AeditButton.V2__InputButton
            type="checkbox"
            name="is_default"
            onChange={noop}
            options={[
              {
                value: 'is_default',
                label: 'Use this card as default payment method.',
              },
            ]}
          />
        </div>

        <div className="credit-card-form__buttons">
          <Btn.Light
            type="button"
            content="Cancel"
            onClick={() => setModalToggle(false)}
            isPending={loading}
            className="--small"
          />
          <Btn.Submit
            type="submit"
            content="Add"
            isPending={loading}
            className="--small"
          />
        </div>
      </AeditForm>
    </Modal>
  )
}

export default AddCardModal
