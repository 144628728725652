import React, { Fragment, useState } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Dialogue } from '@aedit/styleguide/dist/components.js'
import {
  FormGroup,
  RadioField,
  DateField,
  DateFieldWithConfigurableTimezone,
  FormFooter,
  TypeAhead,
  TextArea,
} from '../../components/form'
import { validateRequiredFields, noop } from '../../config/util'
import { RADIO_OPTIONS } from '../../config/constants'
import dayjs from 'dayjs'

import './_consultation_detail_form.scss'

const ConsultationDetailForm = props => {
  const {
    loading,
    handleSubmit,
    onFormSubmit,
    invalid,
    submitFailed,
    consultation,
    user,
    provider,
    procedure,
    configOptions,
    change,
    initialValues,
  } = props
  const [deactivatingNeedsConfirmation, setDeactivatingNeedsConfirmation] = useState(false)
  const [consultationBooked, setConsultationBooked] = useState(
    Boolean(parseInt(consultation.consultation_booked, 10))
  )
  const submitDisabled = loading || deactivatingNeedsConfirmation
  const validUser = user ? user : null
  const validProvider = provider ? provider : null

  const practiceTz = provider?.primary_practice?.location?.timezone

  return (
    <form
      onSubmit={handleSubmit(onFormSubmit)}
      className="flex-fill page-form well form-horizontal">
      <FormGroup label="Procedure ID">
        <span>
          <a
            href={`/procedures/${consultation.procedure_id}`}
            target="_blank"
            rel="noopener noreferrer">
            {consultation.procedure_id}
          </a>
        </span>
      </FormGroup>

      <FormGroup label="Procedure Name">
        <span>{procedure.name}</span>
      </FormGroup>

      {validUser && (
        <Fragment>
          <FormGroup label="Candidate ID">
            <span>{user.id}</span>
          </FormGroup>
          <FormGroup label="Candidate Email">
            <span>
              <a href={`/users/${user.id}`} target="_blank" rel="noopener noreferrer">
                {user.email}
              </a>
            </span>
          </FormGroup>
          <FormGroup label="Candidate First Name">
            <span>{user.first_name}</span>
          </FormGroup>
          <FormGroup label="Candidate Last Name">
            <span>{user.last_name}</span>
          </FormGroup>
          <FormGroup label="Candidate Age">
            <span>
              {dayjs()
                .subtract(user.dob_year, 'year')
                .year()}
            </span>
          </FormGroup>
          <FormGroup label="Candidate Gender">
            <span>{user.gender}</span>
          </FormGroup>
          <FormGroup label="Candidate Phone">
            <span>{user.phone ? user.phone : 'N/A'}</span>
          </FormGroup>
        </Fragment>
      )}
      {validProvider && (
        <Fragment>
          <FormGroup label="Provider Salutation">
            <span>{provider.salutation}</span>
          </FormGroup>

          <FormGroup label="Provider First Name">
            <span>{provider.first_name}</span>
          </FormGroup>

          <FormGroup label="Provider Last Name">
            <span>{provider.last_name}</span>
          </FormGroup>
        </Fragment>
      )}

      {provider?.primary_practice?.location && (
        <Fragment>
          <FormGroup label="Address">
            <span>{provider?.primary_practice?.location.address ?? 'N/A'}</span>
          </FormGroup>

          <FormGroup label="City">
            <span>{provider?.primary_practice?.location.city ?? 'N/A'}</span>
          </FormGroup>

          <FormGroup label="Postal Code">
            <span>{provider?.primary_practice?.location.postal_code ?? 'N/A'}</span>
          </FormGroup>

          <FormGroup label="Country">
            <span>{provider?.primary_practice?.location.country ?? 'N/A'}</span>
          </FormGroup>

          <FormGroup label="Latitude">
            <span>{provider?.primary_practice?.location.latitude ?? 'N/A'}</span>
          </FormGroup>

          <FormGroup label="Longitude">
            <span>{provider?.primary_practice?.location.longitude ?? 'N/A'}</span>
          </FormGroup>
        </Fragment>
      )}

      <FormGroup label="Notes for the Provider">
        {consultation.body ? <span>{consultation.body}</span> : <span>N/A</span>}
      </FormGroup>

      <FormGroup label="Internal Notes" editable>
        <Field component={TextArea} name="internal_notes" placeholder="Internal Notes" />
      </FormGroup>

      <FormGroup label="Date Created">
        <span>{dayjs(consultation.date_created).format('MM/DD/YY, h:mm A')}</span>
      </FormGroup>

      <FormGroup label="Is Active?" editable>
        <Field
          component={RadioField}
          name="is_active"
          items={RADIO_OPTIONS}
          onChange={(_, value) => {
            if (!parseInt(value, 10)) {
              setDeactivatingNeedsConfirmation(true)
            }
          }}
        />
      </FormGroup>

      <FormGroup label="Source Application ID">
        <span>{consultation.application_id}</span>
      </FormGroup>

      <FormGroup label="Consultation Date Requested">
        {!!consultation.is_instant_booking || !initialValues.consultation_dates ? (
          <span>N/A</span>
        ) : (
          <span>{dayjs(initialValues.consultation_dates).format('MM/DD/YY, h:mm A')}</span>
        )}
      </FormGroup>

      <FormGroup label="Is Video Consultation">
        <span>{consultation.is_video_consultation ? `Yes` : `No`}</span>
      </FormGroup>

      <FormGroup label="Is Instant Booking">
        <span>{consultation.is_instant_booking ? `Yes` : `No`}</span>
      </FormGroup>

      {!!consultation.is_instant_booking && (
        <>
          <FormGroup label="Instant Booking Start Date (UTC)">
            <span>
              {dayjs(consultation.instant_booking_start_date)
                .utc()
                .format('YYYY-MM-DD HH:mm')}
            </span>
          </FormGroup>
          <FormGroup label="Instant Booking End Date (UTC)">
            <span>
              {dayjs(consultation.instant_booking_end_date)
                .utc()
                .format('YYYY-MM-DD HH:mm')}
            </span>
          </FormGroup>
          <FormGroup label="Practice Timezone">
            <span>{practiceTz?.replace(/[_-]/g, ' ')}</span>
          </FormGroup>
          <FormGroup label="Instant Booking Start Date (Practice Timezone)">
            <span>
              {Boolean(practiceTz) &&
                dayjs(consultation.instant_booking_start_date)
                  .tz(practiceTz)
                  .format('YYYY-MM-DD HH:mm UTCZ')}
            </span>
          </FormGroup>
          <FormGroup label="Instant Booking End Date (Practice Timezone)">
            <span>
              {Boolean(practiceTz) &&
                dayjs(consultation.instant_booking_end_date)
                  .tz(practiceTz)
                  .format('YYYY-MM-DD HH:mm UTCZ')}
            </span>
          </FormGroup>
        </>
      )}

      <FormGroup label="Consultation Date Booked (Practice Timezone)" editable={consultationBooked}>
        <Field
          name="consultation_date_booked"
          component={DateFieldWithConfigurableTimezone}
          showTimeSelect={true}
          dateFormat="yyyy-MM-dd h:mm aa"
          targetTimezone={practiceTz}
          disabled={!consultationBooked}
        />
      </FormGroup>

      <FormGroup label="Consultation Booked" editable>
        <Field
          component={RadioField}
          name="consultation_booked"
          items={RADIO_OPTIONS}
          onChange={(_, value) => {
            const checked = Boolean(parseInt(value, 10))
            setConsultationBooked(checked)
          }}
        />
      </FormGroup>

      <FormGroup label="Preferred Contact Method" editable>
        <Field
          name="preferred_contact_methods[0]"
          component={TypeAhead}
          options={Object.values(configOptions.PREFERRED_CONTACT_METHODS)}
          labelKey="display_name"
          valueKey="id"
        />
      </FormGroup>

      <FormGroup label="Candidate Preferred Contact Times">
        <span>
          {consultation.preferred_contact_times
            ? consultation.preferred_contact_times.join(', ')
            : `NA`}
        </span>
      </FormGroup>

      <FormFooter loading={submitDisabled} showFailure={invalid && submitFailed} />

      <Dialogue.Delete
        dialogueToggle={deactivatingNeedsConfirmation}
        setDialogueToggle={noop}
        header="Inactive Consultation"
        caption={
          'Please note that by marking a consultation as inactive you will remove it from the ' +
          'providers calendar and will not be able to activate the consultation again.'
        }
        firstButton={{
          content: 'Cancel',
          onClick: () => {
            change('is_active', 1)
            setDeactivatingNeedsConfirmation(false)
          },
        }}
        secondButton={{
          content: 'Confirm',
          onClick: () => setDeactivatingNeedsConfirmation(false),
        }}
      />
    </form>
  )
}

const validate = values => {
  const requiredFields = [['preferred_contact_methods']]
  return { ...validateRequiredFields(values, requiredFields) }
}

export default reduxForm({
  validate: validate,
  form: 'consultationDetailForm',
  enableReinitialize: true,
})(ConsultationDetailForm)
