import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createItem} from '../../actions'
import ConcernForm from './_concern_form'

class ConcernsNew extends Component {
  onFormSubmit = ({contentful, ...values}) => {
    this.props.createItem('concern', values)
  }

  componentWillReceiveProps(nextProps) {
    const currentId = this.props.currentConcern.id
    const nextId = nextProps.currentConcern.id

    if (nextId && nextId !== currentId) {
      this.props.history.push('/concerns')
    }
  }

  render() {
    return (
      <div className="primary-content">
        <div className="controls">
          <h4>Add a New Concern</h4>
        </div>
        <ConcernForm
          loading={this.props.loading}
          onFormSubmit={this.onFormSubmit}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ concerns, ui }) => {
  return {
    loading: ui.loading,
    currentConcern: concerns.current
  }
}

export default connect(mapStateToProps, {createItem})(ConcernsNew)
