import React from 'react'
import PropTypes from 'prop-types'

export const Checkmark = ({bool}) => (
  <div>
    {bool ? <i className="fa fa-check" style={{color: '#5AD086'}}></i> : <i className="fa fa-times" style={{color: '#ce6e6c'}}></i>}
  </div>
)

Checkmark.propTypes = {
  bool: PropTypes.bool.isRequired
}
