import React, { useEffect, useState } from 'react'
import { toastr } from 'react-redux-toastr'
import { authedRequest, callApi } from 'config/config'
import ImageList from '../../components/image_list'
import ReactPaginate from 'react-paginate'

const Uploads = props => {
  const { userId } = props
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])
  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState(1)

  useEffect(() => {
    setPage(0)
  }, [userId])

  useEffect(() => {
    fetchMyUploads(userId, page)
  }, [userId, page])

  const fetchMyUploads = async (userId, page) => {
    try {
      setLoading(true)

      const res = await authedRequest.request({
        method: 'get',
        url: `/user/${userId}/my-uploads`,
        params: {
          order_by: 'date_created',
          order_by_direction: 'desc',
          _page: page + 1,
          _limit: 25,
        },
      })
      if (res.data.error) {
        throw new Error(res.data.error.message)
      }

      const items = res.data.data
      const totalPages = res.data.meta?.paging?.total_pages || 1

      setItems(items)
      setTotalPages(totalPages)
    } catch (e) {
      toastr.error(e.message)
    } finally {
      setLoading(false)
    }
  }

  const addMyUpload = async file => {
    try {
      setLoading(true)
      const form = new FormData()
      form.append('media', file)
      await callApi('post', `/user/${userId}/my-uploads`, null, form)
    } catch (e) {
      toastr.error(e.message)
    } finally {
      setLoading(false)
      await fetchMyUploads(userId, page)
    }
  }

  const removeMyUpload = async (media) => {
    try {
      setLoading(true)
      await callApi('delete', `/user/${userId}/my-uploads`, { media_id: media.id })
    } catch (e) {
      toastr.error(e.message)
    } finally {
      setLoading(false)
      await fetchMyUploads(userId, page)
    }
  }

  return (
    <>
      <ImageList
        items={items}
        loading={loading}
        onAdd={addMyUpload}
        onRemove={removeMyUpload}
      />
      <ReactPaginate
        pageCount={totalPages}
        marginPagesDisplayed={1}
        pageRangeDisplayed={5}
        onPageChange={({ selected }) => setPage(selected)}
        containerClassName="pagination"
        activeClassName="active"
        pageClassName="page-item"
        previousClassName="page-item"
        nextClassName="page-item"
      />
    </>
  )
}

export default Uploads
