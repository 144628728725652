import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { createPlanDefinitionFlag } from '../../actions'
import store from '../../reducers'
import FlagForm, { createEntitiesFromProps } from './_flag_form'

const PlansFlagNew = ({
  currentFlag: { id: flagId },
  history,
  loading,
  match,
  createPlanDefinitionFlag,
}) => {

  const planId = match.params.id

  useEffect(() => {
    if (flagId) {
      history.push(`/plans/${planId}/flags`)
    }

    // clear out the currentFlag object so that its properties don't potentially override
    // another flag's
    return () => {
      store.dispatch({
        type: 'UPDATED_PLAN_FLAG',
        payload: {},
      })
    }
  }, [history, planId, flagId])

  const onFormSubmit = data => {
    const { definition, flag } = createEntitiesFromProps(data)
    flag.account_type = 'provider'
    createPlanDefinitionFlag(planId, { definition, flag })
  }

  return (
    <div className="primary-content">
      <div className="controls">
        <h4>Add a New Flag</h4>
      </div>
      <FlagForm
        onFormSubmit={onFormSubmit}
        loading={loading}
      />
    </div>
  )

}

const mapStateToProps = ({ plans, ui }) => {
  return {
    currentFlag: plans.currentFlag,
    loading: ui.loading,
  }
}

export default connect(mapStateToProps, {
  createPlanDefinitionFlag,
})(PlansFlagNew)
