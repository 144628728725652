import React, {Component} from 'react'
import {connect} from 'react-redux'
import {fetchItems, deleteItem} from '../../actions'
import StepTable from './_step_table'
import {Link} from 'react-router-dom'
import ReactPaginate from 'react-paginate'

class TimelinesIndex extends Component {

  componentDidMount() {
    this.props.fetchItems('step')
  }

  onPageChange = ({selected}) => {
    this.props.fetchItems('step', { _page: selected + 1 })
  }

  deleteStep = (id) => {
    if (window.confirm('You are deleting a step permanently and from all timelines it is associated with. Are you sure?')) {
      this.props.deleteItem('step', id)
    }
  }

  render() {
    const { steps, loading, totalPages } = this.props

    return (
      <div className="primary-content">
        <div className="controls">
          <Link className="pull-right btn btn-primary" to="/procedures/steps/new">Create New Step</Link>
          <h4>Steps</h4>
        </div>
        <StepTable
          steps={steps}
          loading={loading}
          onDelete={this.deleteStep}
        />
        <ReactPaginate
          pageCount={totalPages}
          marginPagesDisplayed={1}
          pageRangeDisplayed={5}
          onPageChange={this.onPageChange}
          containerClassName="pagination"
          activeClassName="active"
          pageClassName="page-item"
          previousClassName="page-item"
          nextClassName="page-item"
        />
      </div>
    )
  }
}


const mapStateToProps = ({steps, ui}) => {
  return {
    steps: steps.all,
    loading: ui.loading,
    totalPages: steps.totalPages
  }
}

export default connect(mapStateToProps, {fetchItems, deleteItem})(TimelinesIndex)
