import React, { useEffect, useState } from 'react'
import { fetchItems } from '../../actions'
import ReactPaginate from 'react-paginate'
import UsersTable from './_users_table'
import { connect } from 'react-redux'

function UsersNewsetter({ fetchItems, newsletterUsers, loading }) {
  const [currentPage, setCurrentPage] = useState(1)

  const onPageChange = ({ selected }) => {
    setCurrentPage(selected + 1)
  }

  useEffect(() => {
    fetchItems('user', { endpoint: '/newsletter', _page: currentPage })
  }, [currentPage])
  return (
    <div className="primary-content">
      <div className="controls">
        <h4>Newsletter Users</h4>
        <span>Total: {newsletterUsers.count}</span>
      </div>
      <UsersTable
        users={newsletterUsers.all}
        loading={loading}
        columns={['ID', 'Email']}
        renderOptions={{
          Email: ({ user }) => user.email,
        }}
      />
      <ReactPaginate
        pageCount={newsletterUsers.totalPages}
        marginPagesDisplayed={1}
        pageRangeDisplayed={5}
        onPageChange={onPageChange}
        containerClassName="pagination"
        activeClassName="active"
        pageClassName="page-item"
        previousClassName="page-item"
        nextClassName="page-item"
      />
    </div>
  )
}

function mapStateToProps({ users, ui }) {
  return {
    newsletterUsers: users,
    loading: ui.loading,
  }
}

export default connect(mapStateToProps, { fetchItems })(UsersNewsetter)
