import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { toastr } from 'react-redux-toastr'
import { authedRequest } from 'config/config'
import { Table } from 'components/table'
import './index.scss'

const ProviderProductsIndex = () => {
  const { provider_id } = useParams()
  const [products, _setProducts] = useState([])
  const tableHeaders = ['ID', 'Name', 'Sku', 'Url', 'Description', 'Preview Image', 'Remove']

  const fetchProducts = async () => {
    const data = {
      params: {
        provider_id,
      },
    }

    const res = await authedRequest.get(`/provider/products`, data)

    _setProducts(res?.data?.data)
  }

  const onDelete = async product => {
    const data = {
      params: {
        provider_id,
      },
    }

    if (window.confirm('Are you sure?')) {
      await authedRequest
        .delete(`/provider/products/${product.id}`, data)
        .then(() => _setProducts(products.filter(item => item.id !== product.id)))
        .then(() => toastr.success('Successfully Deleted Product'))
        .catch(err => toastr.error(err))
    }
  }

  // Fetch Products
  useEffect(() => {
    fetchProducts(provider_id)

    return () => {}
  }, [provider_id])

  return (
    <div className="o__products-index">
      <div className="add-product-button">
        <Link to={`/providers/${provider_id}/products/new`} className="btn btn-primary pull-right">
          Add New Product
        </Link>
      </div>

      <Table loading={false} items={products} headers={tableHeaders}>
        {products?.map(product => (
          <tr key={product.id}>
            <td>
              <Link to={`/providers/${provider_id}/products/${product.id}`}>{product.id}</Link>
            </td>
            <td>{product.name}</td>
            <td>{product.sku}</td>
            <td>
              <a href={product.url}>{product.url}</a>
            </td>
            <td>{product.description}</td>
            <td>
              <img src={product.product_image_url} />
            </td>
            <td>
              <button
                className="btn btn-link btn-action btn-lg tooltip tooltip-left"
                data-tooltip="Remove"
                onClick={() => onDelete(product)}>
                <i className="icon icon-cross" />
              </button>
            </td>
          </tr>
        ))}
      </Table>
    </div>
  )
}

export default ProviderProductsIndex
export { default as ProviderProductsNew } from './new'
export { default as ProviderProductsShow } from './show'
