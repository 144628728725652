import { authedRequest, callApi } from '../config/config'
import _ from 'lodash'

/**
 * @param {string} type
 * @param {object} options - { page = 1, limit = 25, query = null, searching = false}
 **/

export const fetchItems = (type = 'user', options = {}) => async dispatch => {
  dispatch({ type: 'LOADING' })

  const defaultParams = {
    _limit: 100,
    _page: 1,
    order_by_direction: 'asc',
    searching: false,
    name: null, // for searching campuses
    term: null, // for searching
  }

  try {
    const params = { ...defaultParams, ...options }
    const url = options.endpoint || getUrlBasedOnType(type)
    const res = await authedRequest.get(`${url}${options.searching ? '/search' : ''}`, {
      params,
    })

    if (res.data.error) {
      throw new Error(res.data.error.message)
    } else {
      dispatch({ type: `FETCHED_${type.toUpperCase()}_ALL`, payload: res.data })
      dispatch({ type: 'FETCH_SUCCESS' })
    }
  } catch (err) {
    dispatch({ type: 'FAILURE', payload: err })
  }
}

// the same as fetchItems, but it bypass redux
export const rawFetchItems = async (type = 'user', options = {}) => {
  const defaultParams = {
    _limit: 100,
    _page: 1,
    order_by_direction: 'asc',
    searching: false,
    name: null, // for searching campuses
    term: null, // for searching
  }

  const params = { ...defaultParams, ...options }
  const url = options.endpoint || getUrlBasedOnType(type)
  return await callApi('get', `${url}${options.searching ? '/search' : ''}`, params)
}

/**
 * @param {string} type
 * @param {object} data
 **/
export const updateItem = (type = 'user', data) => async dispatch => {
  dispatch({ type: 'LOADING' })
  try {
    const url = getUrlBasedOnType(type)
    const res = await authedRequest.put(`${url}/${data.id}`, data)

    if (res.data.error) {
      throw new Error(res.data.error.message)
    } else {
      dispatch({ type: `UPDATED_${type.toUpperCase()}`, payload: res.data.data })
      dispatch({ type: 'UPDATE_SUCCESS' })
    }
  } catch (err) {
    dispatch({ type: 'FAILURE', payload: err })
  }
}

/**
 * @param {string} type
 * @param {object} data
 **/
export const createItem = (type, data = {}) => async dispatch => {
  dispatch({ type: 'LOADING' })

  try {
    const url = getUrlBasedOnType(type)
    const res = await authedRequest.post(url, data)

    if (res.data.error) {
      throw new Error(res.data.error.message)
    } else {
      dispatch({ type: `CREATED_${type.toUpperCase()}`, payload: res.data.data })
      dispatch({ type: 'POST_SUCCESS' })
    }
    return res.data.data
  } catch (err) {
    dispatch({ type: 'FAILURE', payload: err })
    return { error: err }
  }
}

/**
 * @param {string} type
 * @param {number} id
 **/
export const fetchItem = (type, id) => async dispatch => {
  dispatch({ type: 'LOADING' })

  try {
    const url = getUrlBasedOnType(type)
    const res = await authedRequest.get(`${url}/${id}`)

    if (res.data.error) {
      throw new Error(res.data.error.message)
    } else {
      dispatch({ type: `FETCHED_${type.toUpperCase()}`, payload: res.data.data })
      dispatch({ type: 'FETCH_SUCCESS' })
    }
  } catch (err) {
    dispatch({ type: 'FAILURE', payload: err })
  }
}

export const deleteItem = (type, id) => async dispatch => {
  dispatch({ type: 'LOADING' })

  try {
    const url = getUrlBasedOnType(type)
    const res = await authedRequest.delete(`${url}/${id}`)

    if (res.data.error) {
      throw new Error(res.data.error.message)
    } else {
      dispatch({ type: `DELETED_${type.toUpperCase()}`, payload: parseInt(id, 10) })
      dispatch({ type: 'FETCH_SUCCESS' })
    }
  } catch (err) {
    dispatch({ type: 'FAILURE', payload: err })
  }
}

/**
 * @param {string} slug
 **/
export const fetchSlug = slug => async dispatch => {
  dispatch({ type: 'LOADING' })

  try {
    const res = await authedRequest.get(`/cities/slug/${slug}`)

    if (res.data.error) {
      throw new Error(res.data.error.message)
    } else {
      dispatch({ type: `FETCHED_SLUG`, payload: _.isEmpty(res.data.data) ? [] : res.data.data })
      dispatch({ type: 'FETCH_SUCCESS' })
    }
  } catch (err) {
    dispatch({ type: 'FAILURE', payload: err })
  }
}

export const fetchAreasByRegion = regionId => async dispatch => {
  dispatch({ type: 'LOADING' })
  const res = await authedRequest.get(`/procedure/area?region_id=${regionId}`)
  dispatch({ type: 'FETCHED_REGION_AREAS', payload: res.data.data })
  dispatch({ type: 'FETCH_SUCCESS' })
}

export const fetchProviderPractices = id => async dispatch => {
  dispatch({ type: 'LOADING' })

  try {
    const res = await authedRequest.get(`/provider/${id}/practice`)

    if (res.data.error) {
      throw new Error(res.data.error.message)
    } else {
      // dispatch({ type: 'FETCHED_PROVIDER_PRACTICES', payload: res.data.data })
      dispatch({ type: 'FETCH_SUCCESS' })
    }
  } catch (err) {
    dispatch({ type: 'FAILURE', payload: err })
  }
}

export const fetchProceduresByConcern = id => async dispatch => {
  dispatch({ type: 'LOADING' })

  try {
    const res = await authedRequest.get(`/concern/${id}/entity/procedures`)

    if (res.data.error) {
      throw new Error(res.data.error.message)
    } else {
      dispatch({ type: 'FETCHED_CONCERN_PROCEDURES', payload: res.data })
      dispatch({ type: 'FETCH_SUCCESS' })
    }
  } catch (err) {
    dispatch({ type: 'FAILURE', payload: err })
  }
}

export const fetchProceduresByProvider = id => async dispatch => {
  dispatch({ type: 'LOADING' })

  try {
    const res = await authedRequest.get(`/provider/${id}/procedures`)

    if (res.data.error) {
      throw new Error(res.data.error.message)
    } else {
      dispatch({ type: 'FETCHED_PROVIDER_PROCEDURES', payload: res.data.data })
      dispatch({ type: 'FETCH_SUCCESS' })
    }
  } catch (err) {
    dispatch({ type: 'FAILURE', payload: err })
  }
}

export const updateProceduresByProvider = (id, data = {}) => async dispatch => {
  dispatch({ type: 'LOADING' })

  try {
    const res = await authedRequest.put(`/provider/${id}/procedures`, data)

    if (res.data.error) {
      throw new Error(res.data.error.message)
    } else {
      dispatch({ type: 'UPDATED_PROVIDER', payload: res.data.data })
      dispatch({ type: 'POST_SUCCESS' })
    }
  } catch (err) {
    dispatch({ type: 'FAILURE', payload: err })
  }
}

export const fetchConsultationsByUser = id => async dispatch => {
  dispatch({ type: 'LOADING' })

  try {
    const res = await authedRequest.get(`/consultation/user/${id}`, { _limit: 9999 })

    if (res.data.error) {
      throw new Error(res.data.error.message)
    } else {
      dispatch({ type: 'FETCHED_USER_CONSULTATIONS', payload: res.data.data })
      dispatch({ type: 'FETCH_SUCCESS' })
    }
  } catch (err) {
    dispatch({ type: 'FAILURE', payload: err })
  }
}

export const resetNotifications = () => ({ type: 'RESET' })

function getUrlBasedOnType(type) {
  switch (type) {
    case 'area':
      return '/procedure/area'
    case 'region':
      return '/procedure/region'
    case 'timeline':
      return 'procedure/timeline'
    case 'step':
      return 'procedure/timeline/step'
    case 'cities':
      return '/cities'
    case 'program':
      return 'referral/program'
    case 'flagged':
      return '/media/flagged'
    case 'prescans':
      return '/media/prescan-classified'
    case 'model':
      return '/media/ml/model'
    case 'plan':
      return '/plans/plan'
    case 'flag':
      return '/plans/flag'
    case 'ml_model':
      return '/ml/models'
    default:
      return `/${type}`
  }
}

export { fetchEntityAnalytics, fetchTopAnalytics } from './analytics'

export {
  fetchProcedureBeforeAfters,
  createProcedureBeforeAfter,
  updateProcedureBeforeAfter,
  deleteProcedureBeforeAfter,
  createProviderBeforeAfter,
  updateProviderBeforeAfter,
} from './before_after'

export {
  fetchMedia,
  batchUpdateMedia,
  fetchMediaByCategory,
  deleteTrainingStatus,
  addTrainingStatus,
  updateModel,
  createModel,
} from './media'

export {
  fetchFlagsByPlanDefinition,
  fetchPlanDefinitionFlag,
  createPlanDefinitionFlag,
  updatePlanDefinitionFlag,
  fetchAccountPlan,
  createAccountPlan,
  updateAccountPlan,
  fetchFlagsByAccountPlan,
  fetchAccountPlanFlag,
  createAccountPlanFlag,
  updateAccountPlanFlag,
} from './plan_flag.js'

export {
  updateProviderPractices,
  updateProviderPrimaryPractice,
  fetchProviderAvailabilityPeriods,
  registerProviderCalendar,
  deregisterProviderCalendar,
} from './provider.js'

export { createNewConsultation } from './user_consultation.js'

export { removeStepFromTimeline, addStepToTimeline } from './timeline.js'
