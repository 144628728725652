import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createModel } from 'actions'
import ModelForm from './_form'

class ModelNew extends Component {
  handleFormSubmit = values => {
    this.props.createModel(values)
  }

  componentDidUpdate(prevProps) {
    const { currentModel, history } = this.props

    if (currentModel.id && currentModel.id !== prevProps.currentModel.id) {
      history.push('/media/models')
    }
  }

  render() {
    const { loading, modelTypes } = this.props
    return (
      <div className="primary-content">
        <div className="controls">
          <h4>Add a New Model</h4>
        </div>
        <ModelForm onFormSubmit={this.handleFormSubmit} loading={loading} modelTypes={modelTypes} />
      </div>
    )
  }
}

const mapStateToProps = ({ ui, models, config }) => ({
  loading: ui.loading,
  currentModel: models.current,
  modelTypes: Object.values(config.media.MODEL_TYPES || []),
})

export default connect(
  mapStateToProps,
  { createModel }
)(ModelNew)
