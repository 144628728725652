import React, { useEffect, useRef, useState } from 'react'
import './flame.scss'

export const PARAMS = {
  trans: 'Translation',
  rot: 'Rotation',
  pose: 'Pose',
  shape: 'Shape',
  exp: 'Expression',
}

const DEFAULT_CONFIG = {
  pose: {
    0: { index: 0, min: -2, max: +2, step: 0.01 /*, name: '...'*/ },
    1: { index: 1, min: -2, max: +2, step: 0.01 },
    2: { index: 2, min: -2, max: +2, step: 0.01 },
    3: { index: 3, min: -2, max: +2, step: 0.01 },
    4: { index: 4, min: -2, max: +2, step: 0.01 },
    5: { index: 5, min: -2, max: +2, step: 0.01 },
    6: { index: 6, min: -2, max: +2, step: 0.01 },
    7: { index: 7, min: -2, max: +2, step: 0.01 },
    8: { index: 8, min: -2, max: +2, step: 0.01 },
    9: { index: 9, min: -2, max: +2, step: 0.01 },
    10: { index: 10, min: -2, max: +2, step: 0.01 },
    11: { index: 11, min: -2, max: +2, step: 0.01 },
  },
  shape: {
    0: { index: 0, min: -2, max: +2, step: 0.01 /*, name: '...'*/ },
    1: { index: 1, min: -2, max: +2, step: 0.01 },
    2: { index: 2, min: -2, max: +2, step: 0.01 },
    3: { index: 3, min: -2, max: +2, step: 0.01 },
    4: { index: 4, min: -2, max: +2, step: 0.01 },
    5: { index: 5, min: -2, max: +2, step: 0.01 },
    6: { index: 6, min: -2, max: +2, step: 0.01 },
    7: { index: 7, min: -2, max: +2, step: 0.01 },
    8: { index: 8, min: -2, max: +2, step: 0.01 },
    9: { index: 9, min: -2, max: +2, step: 0.01 },
  },
  exp: {
    0: { index: 0, min: -2, max: +2, step: 0.01 /*, name: '...'*/ },
    1: { index: 1, min: -2, max: +2, step: 0.01 },
    2: { index: 2, min: -2, max: +2, step: 0.01 },
    3: { index: 3, min: -2, max: +2, step: 0.01 },
    4: { index: 4, min: -2, max: +2, step: 0.01 },
    5: { index: 5, min: -2, max: +2, step: 0.01 },
    6: { index: 6, min: -2, max: +2, step: 0.01 },
    7: { index: 7, min: -2, max: +2, step: 0.01 },
    8: { index: 8, min: -2, max: +2, step: 0.01 },
    9: { index: 9, min: -2, max: +2, step: 0.01 },
  },
}

const FLAMEFilters = props => {
  const { value, onChange } = props
  const [customSliderRanges, setCustomSliderRanges] = useState(false)

  return (
    <div className="flame-filters">
      <div className="flame-filter-controls">
        <label className="form-switch">
          <input
            type="checkbox"
            checked={customSliderRanges}
            onChange={e => setCustomSliderRanges(e.target.checked)}
          />{' '}
          <i className="form-icon" /> Override sliders?
        </label>
      </div>

      <div className="flame-filters-container">
        <FLAMEFilter
          type="pose"
          value={value}
          onChange={onChange}
          customSliderRanges={customSliderRanges}
        />

        <FLAMEFilter
          type="shape"
          value={value}
          onChange={onChange}
          customSliderRanges={customSliderRanges}
        />

        <FLAMEFilter
          type="exp"
          value={value}
          onChange={onChange}
          customSliderRanges={customSliderRanges}
        />
      </div>
    </div>
  )
}

const FLAMEFilter = props => {
  const {
    type, // rot, pose, shape, exp
    value, // flame params
    onChange: _onChange,
    name = PARAMS[type],
    config = getDefaultConfig(type, value), // which sliders to render
    customSliderRanges = false,
  } = props

  const [originalValue, setOriginalValue] = useState()

  useEffect(() => {
    if (value && !originalValue) {
      setOriginalValue(value)
    }
  }, [value, originalValue])

  const reset = e => {
    e.preventDefault()

    _onChange({
      ...value,
      [type]: originalValue[type],
    })
  }

  const setAllToZeros = e => {
    e.preventDefault()

    _onChange({
      ...value,
      [type]: [new Array(value[type][0].length).fill(0)],
    })
  }

  const onChange = (index, newParamValue) => {
    const before = value[type][0].slice(0, index)
    const after = value[type][0].slice(index + 1)

    _onChange({
      ...value,
      [type]: [[...before, newParamValue, ...after]],
    })
  }

  return (
    <div className="flame-filter">
      <h3>{name}</h3>

      <div className="flame-filter-controls">
        <button
          onClick={reset}
          className="flame-filter-btn BEURO__btn --destructive">
          Reset
        </button>
        <button
          onClick={setAllToZeros}
          className="flame-filter-btn BEURO__btn --gold">
          Set to 0
        </button>
      </div>

      {config.map(cfg => {
        const index = cfg.index
        const paramValue = value?.[type]?.[0]?.[index] ?? 0

        return (
          <FLAMEParamSlider
            key={`flame_${type}_0_${index}`}
            {...cfg}
            type={type}
            name={cfg.name ?? `${name} ${index}`}
            value={paramValue}
            onChange={newParamValue => onChange(index, newParamValue)}
            customSliderRange={customSliderRanges}
          />
        )
      })}
    </div>
  )
}

const FLAMEParamSlider = props => {
  const {
    type,
    index,
    value: _value,
    onChange: _onChange,
    name = `${PARAMS[type]} ${index}`,
    min: _min = -5,
    max: _max = +5,
    step = 0.01,
    customSliderRange = false,
  } = props
  const key = `flame_${type}_0_${index}`

  const [value, setValue] = useState(_value)
  const [min, setMin] = useState(_min)
  const [max, setMax] = useState(_max)
  const ref = useRef()

  useEffect(() => {
    setValue(_value)
    ref.current.value = _value
  }, [_value])

  useEffect(() => {
    setMin(_min)
  }, [_min])

  useEffect(() => {
    setMax(_max)
  }, [_max])

  const onChange = e => {
    const newValue = parseFloat(e.target.value)
    setValue(value)
    _onChange(newValue)
  }

  if (typeof value === 'undefined' || value === null) {
    return null
  }

  return (
    <div className="flame-param-slider">
      <div>
        <label htmlFor={key}>{name}</label>
        <span className="slider">
          <input
            ref={ref}
            type="range"
            name={key}
            min={min}
            max={max}
            step={step}
            defaultValue={_value}
            onInput={onChange}
          />
          <output id={`${key}-output`}>{value.toFixed(2)}</output>
        </span>
      </div>

      {Boolean(customSliderRange) && (
        <div className="flame-param-custom-range">
          <div>
            <label htmlFor={`${key}_min`}>Min </label>
            <input
              type="number"
              name={`${key}_min`}
              value={min}
              onChange={e => setMin(e.target.value)}
              className="form-input"
            />
          </div>
          <div>
            <label htmlFor={`${key}_max`}>Max </label>
            <input
              type="number"
              name={`${key}_max`}
              value={max}
              onChange={e => setMax(e.target.value)}
              className="form-input"
            />
          </div>
        </div>
      )}
    </div>
  )
}

const getDefaultConfig = (type, value) => {
  const root = value?.[type]?.[0]
  if (!root) {
    return []
  }
  return root.map((_, index) => {
    let cfg = DEFAULT_CONFIG?.[type]?.[index]
    return {
      index,
      ...cfg,
    }
  })
}

export default FLAMEFilters
