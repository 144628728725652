import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { FormGroup, SelectField, FormFooter, TextField } from 'components/form'
import { validateRequiredFields } from 'config/util'

const ProviderAuthorizeSchedulerForm = props => {
  const { handleSubmit, invalid, loading, onSubmit, submitFailed, transports } = props

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="page-form form-horizontal">
      <FormGroup label="Transport" editable>
        <Field name="transport_id" component={SelectField} hideDisabledOption>
          <option key="" value="">
            Select One
          </option>
          {Object.values(transports).map(({ id }) => (
            <option key={id} value={id}>
              {id}
            </option>
          ))}
        </Field>
      </FormGroup>

      <FormGroup label="Client ID" editable>
        <Field name="client_id" component={TextField} />
      </FormGroup>

      <FormGroup label="Client Secret" editable>
        <Field name="client_secret" component={TextField} />
      </FormGroup>

      <FormFooter loading={loading} showFailure={invalid && submitFailed} btnText="Authorize" />
    </form>
  )
}

const validate = values => {
  const requiredFields = [['transport_id'], ['client_id'], ['client_secret']]

  return validateRequiredFields(values, requiredFields)
}

export default reduxForm({
  validate: validate,
  form: 'providerAuthorizeSchedulerForm',
  enableReinitialize: true,
})(ProviderAuthorizeSchedulerForm)
