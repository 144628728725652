import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchItem, updateItem, fetchItems, fetchAreasByRegion, createItem } from '../../actions'
import { Tabs, Tab, TabPanels, TabList } from '../../components/tabs'
import AnalyticsDisplay from '../../components/analytics_display'
import InfoForm from './_info_form'
import { convertObjectsToIds } from '../../config/util'
import store from '../../reducers'
import { Link } from 'react-router-dom'
import ProcedureBeforeAfters from './_procedure_before_afters'
import ProcedureVideos from './_procedure_videos'

class ProceduresShow extends Component {
  onFormSubmit = props => {
    const { has_consultation_fee, consultation_fee, ...submitData } = props
    // submitData properties we can't send to backend

    const toBeDeleted = [
      'procedure_areas',
      'procedure_region',
      'multimedia',
      'concerns',
      'children',
      'related',
      'objectID',
      'primary_image_relative_url',
      'primary_image_url',
      'primary_image_thumbnail_relative_url',
      'primary_image_thumbnail_url',
      'is_parent',
    ]

    toBeDeleted.forEach(item => {
      delete submitData[item]
    })

    const converted = convertObjectsToIds(submitData, [
      'area_ids',
      'anesthesia_types',
      'concern_ids',
      'child_ids',
      'related_ids',
    ])

    this.props.updateItem('procedure', {
      ...submitData,
      ...converted,
      has_consultation_fee,
      consultation_fee: consultation_fee ? consultation_fee : null,
    })
  }

  componentWillUnmount() {
    // clear out the currentProcedure object so that its properties don't potentially override
    // another procedure's
    store.dispatch({
      type: 'UPDATED_PROCEDURE',
      payload: {},
    })
  }

  componentWillReceiveProps(nextProps, nextState) {
    const currentId = this.props.match.params.id
    const nextId = nextProps.match.params.id
    if (currentId && currentId !== nextId) {
      // if on a procedure, then change to the next procedure, this is necessary
      this.props.fetchItem('procedure', nextId)
    }
  }

  componentDidMount() {
    const id = this.props.match.params.id
    this.props.fetchItem('procedure', id)

    // the following are for dropdowns/typeaheads - need all options, not just default 25
    const toBeFetched = ['region', 'concern', 'procedure', 'timeline']

    toBeFetched.forEach(f => {
      this.props.fetchItems(f, { _limit: 9999 })
    })
  }

  render() {
    const {
      procedure,
      loading,
      regions,
      regionAreas,
      fetchAreasByRegion,
      concerns,
      procedures,
      procedureTypeOptions,
      timelines,
    } = this.props

    const procedureId = +this.props.match.params.id

    const initialValues = {
      ...procedure,
      area_ids: procedure.procedure_areas,
      concern_ids: procedure.concerns,
      child_ids: procedure.children,
      related_ids: procedure.related,
      application_type: procedure?.application_type?.id,
    }

    return (
      <div className="primary-content">
        <div className="controls">
          <Link to="/procedures/new" className="btn btn-primary pull-right">
            Add New Procedure
          </Link>
          <h4>{procedure.name}</h4>
        </div>
        <Tabs>
          <TabList>
            <Tab title="General Info" />
            <Tab title="Before & Afters" />
            <Tab title="Video" />
            <Tab title="Analytics" />
          </TabList>
          <TabPanels>
            <InfoForm
              initialValues={initialValues}
              loading={loading}
              onFormSubmit={this.onFormSubmit}
              regions={regions}
              enableReinitialize={true}
              regionAreas={regionAreas}
              fetchAreasByRegion={fetchAreasByRegion}
              timelines={timelines}
              concerns={concerns}
              procedures={procedures.filter(proc => proc.id !== procedure.id)}
              procedureTypeOptions={procedureTypeOptions}
            />
            <ProcedureBeforeAfters procedureId={procedureId} />
            <ProcedureVideos procedureId={procedureId} />
            <AnalyticsDisplay entityType="procedure" entityId={procedureId} />
          </TabPanels>
        </Tabs>
      </div>
    )
  }
}

const mapStateToProps = ({ procedures, ui, regions, areas, concerns, config, timelines }) => {
  return {
    procedure: procedures.currentProcedure,
    loading: ui.loading,
    regions: regions.all,
    regionAreas: areas.regionAreas,
    concerns: concerns.all,
    procedures: procedures.all,
    procedureTypeOptions: config.procedure,
    timelines: timelines.all,
  }
}

export default connect(mapStateToProps, {
  fetchItem,
  updateItem,
  fetchItems,
  createItem,
  fetchAreasByRegion,
})(ProceduresShow)
