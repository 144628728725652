import React, { useState } from 'react'
import Modal from 'simple-react-modal'
import createVideoForm from './_video_form'
import { connect } from 'react-redux'

const VideoForm = createVideoForm('addProcedureVideoForm')

const AddVideoForm = ({ procedureId, onSubmitSucceeded, loading }) => {
  const [showModal, setShowModal] = useState(false)
  const toggleModal = () => setShowModal(!showModal)

  const onSubmit = () => {
    toggleModal()
    if (onSubmitSucceeded) {
      onSubmitSucceeded()
    }
  }

  return (
    <>
      <Modal
        show={showModal}
        onClose={toggleModal}
        containerStyle={{ borderRadius: '3px', width: '600px' }}>
        <VideoForm
          procedureId={procedureId}
          onSubmitSucceeded={onSubmit}
          className="page-form well form-horizontal"
        />
      </Modal>

      <div className="text-right mb-2">
        <button
          onClick={toggleModal}
          disabled={loading}
          className={`btn btn-primary ${loading ? 'loading' : ''}`}>
          Upload New Video
        </button>
      </div>
    </>
  )
}

const mapStateToProps = ({ ui }) => {
  return {
    loading: ui.loading,
  }
}

export default connect(mapStateToProps, {})(AddVideoForm)

