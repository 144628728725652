import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import {
  FormGroup,
  TextField,
  gPlacesAutocomplete,
  FormFooter,
  SelectAsync,
  SelectField,
  Base64FileInput,
  RadioField,
  TextArea,
} from '../../components/form'
import { validateRequiredFields, digitsOnly, setMaxCharLimit } from '../../config/util'
import _ from 'lodash'
import { RADIO_OPTIONS, PRACTICE_APPROVED_IMAGES } from '../../config/constants'
import CopyPaste from '../../components/copy_paste'

class PracticeForm extends Component {
  constructor() {
    super()
    this.fetchProviders = _.debounce(this.fetchProviders, 400)
  }

  deletePhoto = field => {
    this.props.change(field, null)
  }

  fetchProviders = query => {
    this.props.fetchItems('provider', { searching: true, term: query, _limit: 7 })
  }

  render() {
    const {
      loading,
      handleSubmit,
      onFormSubmit,
      invalid,
      submitFailed,
      providers,
      initialValues,
      change,
      practiceTypeOptions,
      logoImage,
      heroImage,
    } = this.props
    const practiceRootName =
      process.env.REACT_APP_PROTOCOL +
      process.env.REACT_APP_HOSTNAME +
      '/practice/' +
      initialValues.slug

    return (
      <form
        onSubmit={handleSubmit(onFormSubmit)}
        className="flex-fill page-form well form-horizontal">
        <FormGroup label="Name" editable>
          <Field name="name" component={TextField} />
        </FormGroup>

        <FormGroup label="Type" editable>
          <Field name="practice_type" component={SelectField}>
            {Object.values(practiceTypeOptions).map(prac => (
              <option value={prac.id} key={prac.id}>
                {prac.display_name}
              </option>
            ))}
          </Field>
        </FormGroup>

        <FormGroup label="Logo" editable>
          {logoImage && initialValues.date_created && initialValues.logo_image && (
            <span className="delete-image" onClick={() => this.deletePhoto('logo_image')}>
              <img src={initialValues.logo_image_url} className="pic-preview" alt="primary pic" />
              <p className="delete">X</p>
            </span>
          )}
          <Field component={Base64FileInput} type="file" name="logo_image" />
        </FormGroup>

        <FormGroup
          editable
          label={
            <div>
              Hero Image <br />
              <a href={PRACTICE_APPROVED_IMAGES} target="_blank" rel="noopener noreferrer">
                View Approved Images
              </a>
            </div>
          }>
          {heroImage && initialValues.date_created && initialValues.hero_image && (
            <span className="delete-image" onClick={() => this.deletePhoto('hero_image')}>
              <img src={initialValues.hero_image_url} className="pic-preview" alt="primary pic" />
              <p className="delete">X</p>
            </span>
          )}
          <Field component={Base64FileInput} type="file" name="hero_image" />
        </FormGroup>

        <FormGroup label="Phone" editable>
          <Field name="phone" component={TextField} parse={digitsOnly} type="tel" />
        </FormGroup>

        <FormGroup label="Address" editable>
          <Field
            component={gPlacesAutocomplete}
            type="text"
            name="location.long_name"
            change={change}
          />
        </FormGroup>

        <FormGroup label="Suite Number" editable>
          <Field name="location.suite_number" component={TextField} />
        </FormGroup>

        <FormGroup label="Google Place ID" editable>
          <Field name="location.place_id" component={TextField} disabled />
        </FormGroup>

        <FormGroup label="Phone Country" editable>
          <Field name="phone_country" component={TextField} />
        </FormGroup>

        <FormGroup label="Fax" editable>
          <Field name="fax" component={TextField} type="tel" parse={digitsOnly} />
        </FormGroup>

        <FormGroup label="Email" editable>
          <Field name="email" component={TextField} type="email" />
        </FormGroup>

        <FormGroup label="Primary Contact Name" editable>
          <Field name="contact_name" component={TextField} type="tel" />
        </FormGroup>

        <FormGroup label="Practice Quote" editable>
          <Field name="practice_quote" component={TextField} type="text" />
        </FormGroup>

        <FormGroup label="Practice Statement" editable>
          <Field name="practice_statement" component={TextArea} type="text" />
        </FormGroup>

        <FormGroup label="Providers" editable>
          <Field
            name="provider_ids"
            component={SelectAsync}
            options={providers.map(provider => ({
              ...provider,
              full_name: provider.first_name + ' ' + provider.last_name,
            }))}
            loading={loading}
            getItems={this.fetchProviders}
            labelKey="full_name"
            multi
          />
        </FormGroup>

        <FormGroup label="Publicly Viewable" editable>
          <Field component={RadioField} name="publicly_viewable" items={RADIO_OPTIONS} />
        </FormGroup>

        <FormGroup label="Is Active?" editable>
          <Field component={RadioField} name="is_active" items={RADIO_OPTIONS} />
        </FormGroup>

        {initialValues.date_created && (
          <Fragment>
            <FormGroup label="Slug">
              <CopyPaste text={initialValues.slug} />
            </FormGroup>

            <FormGroup label="Practice Page Link">
              <a href={practiceRootName} target="_blank" rel="noopener noreferrer">
                {initialValues.name}
              </a>
            </FormGroup>

            <FormGroup label="Date Created">
              <span>{initialValues.date_created}</span>
            </FormGroup>
            <FormGroup label="Last Updated">
              <span>{initialValues.date_updated}</span>
            </FormGroup>
          </Fragment>
        )}

        <FormFooter loading={loading} showFailure={invalid && submitFailed} />
      </form>
    )
  }
}

const validate = values => {
  const errors = {}
  const requiredFields = [['name'], ['phone'], ['practice_type'], ['is_active'], ['email']]

  const optionalFields = [
    ['practice_statement', 2000],
    ['practice_quote', 250],
    ['location.suite_number', 4],
  ]

  if (!values.location || (values.location && !values.location.long_name)) {
    errors.location = {
      long_name: 'Address is required',
    }
  }

  if (!values.place_id) {
    errors.place_id = 'You must select an address in the field above to get a Place ID'
  }

  if (values.fax && values.fax.length !== 10) {
    errors.fax = 'Make sure the fax number is 10 digits (or empty)'
  }

  return {
    ...validateRequiredFields(values, requiredFields),
    ...setMaxCharLimit(values, optionalFields),
    ...errors,
  }
}

PracticeForm = reduxForm({
  validate: validate,
  form: 'practiceForm',
  enableReinitialize: true,
})(PracticeForm)

const selector = formValueSelector('practiceForm')

PracticeForm = connect(state => {
  const logoImage = selector(state, 'logo_image')
  const heroImage = selector(state, 'hero_image')
  return {
    logoImage,
    heroImage,
  }
})(PracticeForm)

export default PracticeForm
