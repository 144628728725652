import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from './reducers'
import './styles/base.scss'
import Routes from './config/routes'
import ReduxToastr from 'react-redux-toastr'

ReactDOM.render(
  <Provider store={store}>
    <>
      <Routes />
      <ReduxToastr transitionIn="fadeIn" position="bottom-left" progressBar preventDuplicates />
    </>
  </Provider>,
  document.getElementById('root')
)
