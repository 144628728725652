import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useOauth } from 'config/hooks/oauth'
import {
  isInstantBookingAllowed,
  isInstantBookingEnabled,
  getProviderCalendar,
  createRandomString,
  buildUrl,
} from 'config/util'
import {
  fetchItem,
  fetchProviderAvailabilityPeriods,
  registerProviderCalendar,
  deregisterProviderCalendar,
} from 'actions'
import { AeditField } from '@aedit/styleguide/dist/form.js'
import './index.scss'

const ProviderCalendarForm = ({
  providerId,
  provider,
  loading,
  fetchItem,
  fetchProviderAvailabilityPeriods,
  registerProviderCalendar,
  deregisterProviderCalendar,
}) => {
  const [periods, _setPeriods] = useState([])

  const allowed = isInstantBookingAllowed(provider)
  const enabled = isInstantBookingEnabled(provider)
  const calendar = getProviderCalendar(provider)
  const authorize = useOauth()

  useEffect(() => {
    fetchItem('provider', providerId)
  }, [fetchItem, providerId])

  useEffect(() => {
    if (allowed && enabled) {
      const initAvailabilityPeriods = async () => {
        const periods = await fetchProviderAvailabilityPeriods(providerId)
        await _setPeriods(periods || [])
      }
      initAvailabilityPeriods()
    }
  }, [fetchProviderAvailabilityPeriods, providerId, allowed, enabled])

  const linkCalendar = async () => {
    if (!loading) {
      const authorize_uri = getAuthUri()
      const redirect_uri = getRedirectUri()
      const { code } = await authorize(authorize_uri, redirect_uri, 'width=486,height=690')
      await registerProviderCalendar(providerId, { code, redirect_uri })
      await fetchItem('provider', providerId)
    }
  }

  const unlinkCalendar = async () => {
    await deregisterProviderCalendar(providerId, true)
    await fetchItem('provider', providerId)
  }

  if (loading) {
    return <div className="flex-fill well" />
  }

  if (!allowed) {
    return (
      <div className="flex-fill well flex-centered">
        <p>
          Provider is not on a plan that allows instant booking or instant booking is not active
        </p>
      </div>
    )
  }

  if (!enabled) {
    return (
      <div className="flex-fill well flex-centered" style={{ flexDirection: 'column' }}>
        <p className="mb-2">Provider hasn't linked a calendar</p>
        <button className="btn btn-dark" onClick={linkCalendar}>
          Link Calendar
        </button>
      </div>
    )
  }

  return (
    <div className="d-flex flex-fill well p-1" style={{ flexDirection: 'row' }}>
      <div style={{ flex: 'auto', maxWidth: '680px' }}>
        <AeditField.SchedulingCalendar.Base
          intervals={periods}
          showTimePicker={true}
          maxDaysBehind={0}
          maxDaysAhead={200}
        />
      </div>

      <div className="d-flex p-2" style={{ flex: 1, flexDirection: 'column', alignItems: 'start' }}>
        <span className="mb-1">Linked to Google calendar:</span>
        <span className="mb-2">{calendar.profile_name}</span>

        <button className="btn btn-transparent" onClick={unlinkCalendar}>
          Unlink Calendar
        </button>
      </div>
    </div>
  )
}

const getAuthUri = params => {
  const { client_id, redirect_uri, state } = params || {}
  return buildUrl('https://app.cronofy.com/oauth/authorize', {
    avoid_linking: true,
    response_type: 'code',
    client_id: client_id || process.env.REACT_APP_CRONOFY_CLIENT_ID,
    redirect_uri,
    scope: [
      'change_participation_status',
      'read_free_busy',
      'delete_event',
      'create_event',
      'read_events',
      'create_calendar',
    ].join(' '),
    state,
    provider_name: 'google',
  })
}

const getRedirectUri = () => window.location.origin + '/providers/calendar-callback'

const mapStateToProps = ({ providers, ui }) => {
  return {
    provider: providers.current,
    loading: ui.loading,
  }
}

export default connect(mapStateToProps, {
  fetchItem,
  fetchProviderAvailabilityPeriods,
  registerProviderCalendar,
  deregisterProviderCalendar,
})(ProviderCalendarForm)
