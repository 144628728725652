import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchItems } from '../../actions'
import { Table } from '../../components/table'
import { Link } from 'react-router-dom'
import SearchBar from '../../components/searchbar'
import ReactPaginate from 'react-paginate'
import { Checkmark } from '../../components/checkmark'
import PropTypes from 'prop-types'
import moment from 'moment'

class ProceduresIndex extends Component {
  componentDidMount() {
    this.props.fetchItems('procedure', { order_by: 'name' })
  }

  onPageChange = ({ selected }) => {
    this.props.fetchItems('procedure', { _page: selected + 1, order_by: 'name' })
  }

  render() {
    const { procedures, loading, fetchItems } = this.props
    const tableHeaders = ['Name', 'ID', 'Region', 'Parent', 'Active', 'Created']

    return (
      <div className="primary-content">
        <div className="controls">
          <Link className="pull-right btn btn-primary" to="/procedures/new">
            Add New Procedure
          </Link>
          <SearchBar fetchItems={fetchItems} type="procedure" />
        </div>
        <Table items={procedures.all} loading={loading} headers={tableHeaders}>
          {procedures.all.map(proc => (
            <tr key={proc.id}>
              <td>
                <Link to={`/procedures/${proc.id}`}>{proc.name}</Link>
              </td>
              <td>{proc.id}</td>
              <td>{!!proc.procedure_areas ? proc.procedure_areas[0].procedure_region.name : ''}</td>
              <td>
                <Checkmark bool={!!proc.is_parent} />
              </td>
              <td>
                <Checkmark bool={!!proc.is_active} />
              </td>
              <td>{moment(proc.date_created).format('MM/DD/YY - h:mm A')}</td>
            </tr>
          ))}
        </Table>
        <ReactPaginate
          pageCount={procedures.totalPages}
          marginPagesDisplayed={1}
          pageRangeDisplayed={5}
          onPageChange={this.onPageChange}
          containerClassName="pagination"
          activeClassName="active"
          pageClassName="page-item"
          previousClassName="page-item"
          nextClassName="page-item"
        />
      </div>
    )
  }

  static propTypes = {
    loading: PropTypes.bool.isRequired,
    procedures: PropTypes.object.isRequired,
  }
}

const mapStateToProps = ({ procedures, ui }) => {
  return {
    loading: ui.loading,
    procedures: procedures,
  }
}

export default connect(
  mapStateToProps,
  { fetchItems }
)(ProceduresIndex)
