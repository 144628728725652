const defaultState = {
  provider: {
    BOARD_CERTIFICATIONS: {},
    DEGREE_TYPES: {},
    FELLOWSHIP_TRAINED_IN: {},
    GENDER: {},
    PROVIDER_LICENSE: {},
    PROVIDER_TYPES: {},
    SALUTATION: {},
    SPECIALTIES: {},
    LANGUAGES: {},
    NAME_SUFFIX: {},
  },
  practice: {
    PRACTICE_TYPES: {},
  },
  procedure: {
    ANESTHESIA_TYPES: {},
    APPLICATION_TYPES: {},
    FREQUENCY: {},
    TIMESPAN: {},
  },
  media: {
    CATEGORIES: {},
    TYPES: {},
    FLAG_REASONS: {},
    orderByDirections: {},
    orderByFields: {},
  },
  consultation: {
    PREFERRED_CONTACT_METHODS: {},
  },
  location: {
    US_STATES: {},
    US_STATE_ABBREVIATIONS: {},
  },
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case 'FETCHED_CONFIG_ALL':
      return {
        ...state,
        ...action.payload.data,
        provider: action.payload.data.provider,
        practice: action.payload.data.practice,
        procedure: action.payload.data.procedure,
        media: action.payload.data.media,
        consultation: action.payload.data.consultation,
        location: action.payload.data.location,
      }
    default:
      return state
  }
}
