import React, { Component } from 'react'
import { Table } from 'components/table'
import { Link } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import { connect } from 'react-redux'
import { fetchItems } from 'actions'
import moment from 'moment'
import { startCase } from 'lodash'

class ModelsIndex extends Component {
  componentDidMount() {
    this.props.fetchItems('model', { order_by_direction: 'desc', order_by: 'date_deployed' })
  }

  onPageChange = ({ selected }) => {
    this.props.fetchItems('model', {
      order_by_direction: 'desc',
      order_by: 'date_deployed',
      _page: selected + 1,
    })
  }

  render() {
    const { models, loading } = this.props
    const tableHeaders = ['Model File Name', 'Model Type', 'Minimum iOS Version', 'Deploy Date']

    return (
      <div className="primary-content">
        <div className="controls">
          <Link className="pull-right btn btn-primary" to="/media/models/new">
            Add New Model
          </Link>
          <h4>Models</h4>
        </div>
        <Table items={models.all} loading={loading} headers={tableHeaders}>
          {models.all.map(model => (
            <tr key={model.id}>
              <td>
                <Link to={`/media/models/${model.id}`}>{model.model_file}</Link>
              </td>
              <td>{startCase(model.meta.model_type)}</td>
              <td>{model.minimum_ios_version}</td>
              <td>{moment(model.date_deployed).format('MM/DD/YYYY')}</td>
            </tr>
          ))}
        </Table>
        <ReactPaginate
          pageCount={models.totalPages}
          marginPagesDisplayed={1}
          pageRangeDisplayed={5}
          onPageChange={this.onPageChange}
          containerClassName="pagination"
          activeClassName="active"
          pageClassName="page-item"
          previousClassName="page-item"
          nextClassName="page-item"
        />
      </div>
    )
  }
}

const mapStateToProps = ({ ui, models }) => {
  return {
    models: models,
    loading: ui.loading,
  }
}

export default connect(
  mapStateToProps,
  { fetchItems }
)(ModelsIndex)
