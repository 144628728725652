import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createItem} from '../../actions'
import TimelineForm from './_timeline_form'

class TimelinesNew extends Component {

  onFormSubmit = values => {
    this.props.createItem('timeline', values)
  }

  componentWillReceiveProps(nextProps) {
    const currentId = this.props.timeline.id
    const nextId = nextProps.timeline.id

    if (nextId && nextId !== currentId) {
      this.props.history.push('/procedures/timelines')
    }
  }

  render() {
    return (
      <div className="primary-content">
        <div className="controls">
          <h4>Add a New Timeline</h4>
        </div>
        <TimelineForm
          onFormSubmit={this.onFormSubmit}
          initialValues={{}}
          loading={this.props.loading}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ timelines, ui }) => {
  return {
    loading: ui.loading,
    timeline: timelines.current
  }
}

export default connect(mapStateToProps, {createItem})(TimelinesNew)
