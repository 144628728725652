import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import _ from 'lodash'
import { FormGroup, SelectAsync, FormFooter } from 'components/form'
import { updateProviderPrimaryPractice, fetchItems } from 'actions'

class ProviderPrimaryPracticeForm extends Component {
  constructor() {
    super()
    this.fetchPractices = _.debounce(this.fetchPractices, 400)
  }

  fetchPractices = query => {
    if (query.length) {
      this.props.fetchItems('practice', { searching: true, term: query, _limit: 7 })
    }
  }

  onSubmit = primaryPractice => {
    if (primaryPractice.primary_practice) {
      primaryPractice = {
        practice_id: primaryPractice.primary_practice.id,
      }
    }
    this.props.updateProviderPrimaryPractice(this.props.providerId, { ...primaryPractice })
  }

  render() {
    const { loading, handleSubmit, invalid, submitFailed, practices } = this.props
    const activePractices = practices.filter(practice => practice.is_active)

    return (
      <form
        onSubmit={handleSubmit(this.onSubmit)}
        className="flex-fill page-form well form-horizontal">
        <FormGroup label="Primary Practice" editable>
          <Field
            name="primary_practice"
            component={SelectAsync}
            options={activePractices}
            loading={loading}
            getItems={this.fetchPractices}
            labelKey="name"
          />
        </FormGroup>

        <FormFooter loading={loading} showFailure={invalid && submitFailed} />
      </form>
    )
  }
}

ProviderPrimaryPracticeForm = reduxForm({
  form: 'providerPrimaryPracticeForm',
  enableReinitialize: true,
})(ProviderPrimaryPracticeForm)

function mapStateToProps({ ui }) {
  return {
    loading: ui.loading,
  }
}

export default connect(mapStateToProps, { updateProviderPrimaryPractice, fetchItems })(
  ProviderPrimaryPracticeForm
)
