import React, { Component } from 'react'
import { Tabs, Tab, TabPanels, TabList } from '../../components/tabs'
import { connect } from 'react-redux'
import PasswordForm from './_password_form'
import ProfileForm from './_profile_form'
import Aedits from './_aedits'
import Uploads from './_uploads'
import { fetchItem, updateItem } from '../../actions'
import UserNotifications from './notifications'
import UserConsultations from './_consultations'
import UserPayments from './payments'
import Subscriptions from './_subscriptions'

class UsersShow extends Component {
  onFormSubmit = ({
    profile_picture_relative_url,
    profile_picture_url,
    referral_code,
    sso,
    location,
    occupation,
    ...rest
  }) => {
    const id = this.props.match.params.id

    const userData = { ...rest, id, address: location?.long_name, occupation: occupation?.id || '' }

    if (userData.phone === '') {
      userData.phone = null
    }
    this.props.updateItem('user', userData)
  }

  componentDidMount() {
    const id = this.props.match.params.id
    this.props.fetchItem('user', id)
  }

  componentWillReceiveProps(nextProps, nextState) {
    const currentId = this.props.match.params.id
    const nextId = nextProps.match.params.id
    if (currentId && currentId !== nextId) {
      // if on a user, then changing to the current admin, this is necessary
      this.props.fetchItem('user', nextId)
    }
  }

  render() {
    const { id } = this.props.match.params
    const { user, loading, config } = this.props

    return (
      <Tabs>
        <TabList>
          <Tab title="General" />
          <Tab title="Password" />
          <Tab title="Aedits" />
          <Tab title="Uploads" />
          <Tab title="Consultations" />
          <Tab title="Notifications" />
          <Tab title="Payments" />
          <Tab title={`Subscriptions (${user?.subscriptions?.length ?? 0})`} />
        </TabList>
        <TabPanels>
          <ProfileForm
            initialValues={user}
            handleFormSubmit={this.onFormSubmit}
            config={config}
            loading={loading}
          />
          <PasswordForm onFormSubmit={this.onFormSubmit} loading={loading} />
          <Aedits userId={id} />
          <Uploads userId={id} />
          <UserConsultations userId={id} />
          <UserNotifications userId={id} />
          <UserPayments user={user} setUser={() => this.props.fetchItem('user', id)} />
          <Subscriptions user={user} config={config} />
        </TabPanels>
      </Tabs>
    )
  }
}

const mapStateToProps = ({ users, ui, config }) => {
  return {
    user: users.current,
    loading: ui.loading,
    config: config,
  }
}

export default connect(
  mapStateToProps,
  { fetchItem, updateItem }
)(UsersShow)
