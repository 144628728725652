import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { validateRequiredFields, setMaxCharLimit, setMaxFileSizeLimit } from 'config/util'
import { TextArea, FormGroup, FormFooter, MediaField } from 'components/form'
import { MB } from 'config/constants'

const ProviderMyMediaForm = ({
  change,
  handleSubmit,
  initialValues,
  invalid,
  loading,
  onSubmit,
  submitFailed,
}) => {
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="page-form form-horizontal">
      <FormGroup label="Media" editable>
        <MediaField
          name="media"
          initialValue={initialValues.url}
          type={initialValues.type}
          onChange={() => change('url', null)}
          accept="image/*,video/*"
          style={{ maxHeight: '200px', maxWidth: '500px' }}
        />
      </FormGroup>

      <FormGroup label="Description" editable>
        <Field name="meta.description" component={TextArea} />
      </FormGroup>

      <FormFooter loading={loading} showFailure={invalid && submitFailed} />
    </form>
  )
}

const validate = values => {
  const requiredFields = []

  if (!values.url && !values.media) {
    requiredFields.push(['media'])
  }

  const optionalFields = [
    ['meta.description', 400],
  ]

  const fileFields = []

  if (values.media) {
    fileFields.push(['media', values.media.type.startsWith('video') ? 100 * MB : 2 * MB])
  }

  return {
    ...validateRequiredFields(values, requiredFields),
    ...setMaxCharLimit(values, optionalFields),
    ...setMaxFileSizeLimit(values, fileFields),
  }
}

export default reduxForm({
  validate,
  form: 'addProviderMyMediaForm',
  enableReinitialize: true,
})(ProviderMyMediaForm)
