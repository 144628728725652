import React, { useState, useEffect } from 'react'
import { EmptyState } from 'components/empty_state'
import { Table } from 'components/table'
import Modal from 'simple-react-modal'
import TestimonialForm from './_form'
import { authedRequest } from 'config/config'
import { toastr } from 'react-redux-toastr'

const ProviderTestimonials = ({ provider }) => {
  const [showModal, setShowModal] = useState(false)
  const [testimonials, setTestimonials] = useState([])
  const [editing, setEditing] = useState(null)

  const create = async formData => {
    const newItem = await authedRequest
      .post(`/provider/${provider.id}/testimonials`, formData)
      .then(res => res.data.data)

    onSuccess([...testimonials, newItem])
  }

  const update = async formData => {
    const updated = await authedRequest
      .put(`/provider/testimonials/${editing.id}`, formData)
      .then(res => res.data.data)

    const updatedTestimonials = testimonials.map(t => {
      if (t.id === editing.id) {
        return updated
      } else {
        return t
      }
    })

    onSuccess(updatedTestimonials)
  }

  const onSuccess = items => {
    setTestimonials(items)
    setShowModal(false)
    setEditing(null)
    toastr.success('Whoa, nice work!')
  }

  const remove = async id => {
    await authedRequest.delete(`/provider/testimonials/${id}`)

    onSuccess(testimonials.filter(item => item.id != id))
  }

  useEffect(() => {
    if (provider.id) {
      authedRequest
        .get(`/provider/testimonials`, {
          params: {
            provider_id: provider.id,
          },
        })
        .then(res => {
          setTestimonials(res.data.data)
        })
    }
  }, [provider.id])

  return (
    <>
      {testimonials.length ? (
        <>
          <Table
            items={testimonials}
            loading={false}
            headers={['Name', 'Procedure Name', 'Year', '']}>
            {testimonials.map((t, i) => (
              <tr key={i}>
                <td>{t.name}</td>
                <td>{t.procedure_name}</td>
                <td>{t.year}</td>
                <td className="text-right">
                  <span
                    onClick={() => {
                      setEditing(t)
                      setShowModal(true)
                    }}
                    className="pointer mr-2"
                    data-tooltip="Edit Testimonial">
                    <i className="fas fa-pencil-alt" />
                  </span>
                  <span
                    onClick={() => remove(t.id)}
                    data-tooltip="Remove Testimonial"
                    className="pointer mr-2">
                    <i className="far fa-trash-alt" />
                  </span>
                </td>
              </tr>
            ))}
          </Table>
          <div class="text-right mt-2 mb-2">
            <button
              class="btn btn-primary"
              onClick={() => {
                setEditing(false)
                setShowModal(true)
              }}>
              Add a New Testimonial
            </button>
          </div>
        </>
      ) : (
        <EmptyState
          icon="nonexistent"
          message={
            <div class="text-center">
              <h4>No testimonials just yet</h4>
              <button className="btn" onClick={() => setShowModal(true)}>
                Add a Testimonial
              </button>
            </div>
          }
        />
      )}

      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        containerStyle={{ padding: '6px 0 0 0', width: 700 }}>
        <h4 style={{ borderBottom: '1px solid #ddd', padding: 12, margin: 0 }}>
          <strong>{editing ? 'Edit' : 'Adding a'} Testimonial</strong>
        </h4>
        <TestimonialForm onSubmit={editing ? update : create} initialValues={editing || {}} />
      </Modal>
    </>
  )
}

export default ProviderTestimonials
