import React, {Component} from 'react'
import {connect} from 'react-redux'
import {fetchItems} from '../../actions'
import {Table} from '../../components/table'
import {Link} from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import {Checkmark} from '../../components/checkmark'
import moment from 'moment'

class TimelinesIndex extends Component {
  state = {
    orderBy: 'title',
    orderByDirection: 'asc'
  }

  componentDidMount() {
    this.props.fetchItems('timeline', {
      order_by: this.state.orderBy,
      order_by_direction: this.state.orderByDirection
    })
  }

  onPageChange = ({selected}) => {
    this.props.fetchItems('timeline', {
      _page: selected + 1,
      order_by: this.state.orderBy,
      order_by_direction: this.state.orderByDirection
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { orderBy } = this.state
    let direction = 'asc'
    if (prevState.orderBy !== orderBy) {
      if (orderBy === 'date_created') {
        direction = 'desc'
      }

      this.setState({ orderByDirection: direction })
      this.props.fetchItems('timeline', { order_by: orderBy, order_by_direction: direction })
    }
  }

  onDropdownChange = (e) => {
    this.setState({ orderBy: e.target.value })
  }

  render() {
    const { timelines, loading, totalPages } = this.props
    const tableHeaders = ['Name', 'ID', 'Active', 'Date Created']

    return (
      <div className="primary-content">
        <div className="controls">
          <Link className="pull-right btn btn-primary" to="/procedures/timelines/new">Add New Timeline</Link>
          <span className="pull-right mr-2">
            <label>Sort by </label>
            <select
              className="form-select timeline-dropdown"
              onChange={this.onDropdownChange}
              value={this.state.orderBy}
              >
                <option value="title">Title</option>
                <option value="date_created">Date Created</option>
              </select>
            </span>
          <h4>Timelines</h4>
        </div>
        <Table items={timelines} loading={loading} headers={tableHeaders}>
          {timelines.map(({title, date_created, is_active, id}) => (
            <tr key={id}>
              <td><Link to={`/procedures/timelines/${id}`}>{title}</Link></td>
              <td>{id}</td>
              <td><Checkmark bool={!!is_active} /></td>
              <td>{moment(date_created).format('MM/DD/YY - h:mm A')}</td>
            </tr>
          ))}
        </Table>
        <ReactPaginate
          pageCount={totalPages}
          marginPagesDisplayed={1}
          pageRangeDisplayed={5}
          onPageChange={this.onPageChange}
          containerClassName="pagination"
          activeClassName="active"
          pageClassName="page-item"
          previousClassName="page-item"
          nextClassName="page-item"
        />
      </div>
    )
  }
}


const mapStateToProps = ({ timelines, ui }) => {
  return {
    timelines: timelines.all,
    loading: ui.loading,
    totalPages: timelines.totalPages
  }
}

export default connect(mapStateToProps, { fetchItems })(TimelinesIndex)
