import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { authedRequest } from 'config/config'
import ReviewForm from '../_form'
import './index.scss'

const ProviderReviewsNew = props => {
  const { provider_id } = useParams()
  const [reviews, _setReviews] = useState(null)

  const fetchReviews = async () => {
    const data = {
      params: {
        provider_id,
      },
    }

    const res = await authedRequest.get(`/provider/reviews`, data)

    _setReviews(res?.data?.data)
  }

  // Fetch Reviews
  useEffect(() => {
    fetchReviews()
  }, [])

  return (
    <div className="t__products-new">
      <h3>Create a new Review</h3>
      {reviews ? <ReviewForm providerId={provider_id} reviews={reviews} /> : <></>}
    </div>
  )
}

export default ProviderReviewsNew
