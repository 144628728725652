import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { callApi } from 'config/config'
import ProviderSEOForm from './_form'

const ProviderSEO = props => {
  const { providerId } = props
  const loading = useSelector(state => state.ui.loading)
  const [seo, setSeo] = useState({});

  useEffect(() => {
    getSEO(providerId).then(setSeo)
  }, [providerId])

  const onFormSubmit = data => {
    updateSEO(providerId, data).then(setSeo)
  }

  return (
    <ProviderSEOForm
      initialValues={seo}
      loading={loading}
      onFormSubmit={onFormSubmit}
    />
  )
}

const getSEO = async providerId => {
  try {
    return await callApi('get', `/provider/${providerId}/seo`)
  } catch (err) {
    toastr.error(err.message)
    throw err
  }
}

const updateSEO = async (providerId, data) => {
  try {
    const seo = await callApi('put', `/provider/${providerId}/seo`, null, data)
    toastr.success('Successfully updated Provider SEO')
    return seo
  } catch (err) {
    toastr.error(err.message)
    throw err
  }
}

export default ProviderSEO
