import React, { useState } from 'react'
import { Table } from 'components/table'
import { Link } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import { Checkmark } from 'components/checkmark'
import moment from 'moment'
import { useAlgoliaSearchTerm } from 'config/hooks'

const providerIndex = process.env.REACT_APP_ALGOLIA_INDEX_PROVIDERS_ALL

const ProvidersSearch = () => {
  const [loading, setLoading] = useState(true)
  const [activeOnly, _setActiveOnly] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [providers, _setSearchTerm] = useAlgoliaSearchTerm(
    providerIndex,
    {
      setLoading,
      searchOptions: {
        filters: activeOnly ? 'is_active = 1' : '',
        page: currentPage,
      },
    },
    [activeOnly]
  )

  const setActiveOnly = value => {
    _setActiveOnly(value)
    setCurrentPage(0)
  }

  const setSearchTerm = value => {
    _setSearchTerm(value)
    setCurrentPage(0)
  }

  const onPageChange = ({ selected }) => setCurrentPage(selected)

  const onActiveToggle = () => setActiveOnly(!activeOnly)

  const tableHeaders = [
    'ID',
    'Email',
    'Name',
    'Publicly Viewable',
    'Active',
    'Verified',
    'Video Consults',
    'Date Created',
  ]

  return (
    <div className="primary-content">
      <div className="controls">
        <Link to="/providers/new" className="btn btn-primary pull-right">
          Add New Provider
        </Link>
        <div className="form-group pull-right mr-2">
          <span className="form-switch mr-2">Total providers: {providers.nbHits}</span>
          <label className="form-switch">
            <input type="checkbox" checked={activeOnly} onChange={onActiveToggle} />
            <i className="form-icon" /> Active Only
          </label>
        </div>
        <div className="input-group input-inline">
          <div className="has-icon-left">
            <input
              className="form-input"
              type="text"
              placeholder="Search"
              onChange={e => setSearchTerm(e.target.value)}
            />
            <i className="form-icon icon icon-search" />
          </div>
        </div>
      </div>
      <Table items={providers.hits} headers={tableHeaders} loading={loading}>
        {providers.hits.map(provider => (
          <tr key={provider.id}>
            <td>{provider.id}</td>
            <td>
              <Link to={`/providers/${provider.id}`}>{provider.email}</Link>
            </td>
            <td className="text-capitalize">{provider.first_name + ' ' + provider.last_name}</td>
            <td>
              <Checkmark bool={!!provider.publicly_viewable} />
            </td>
            <td>
              <Checkmark bool={!!provider.is_active} />
            </td>
            <td>
              <Checkmark bool={!!provider.is_verified} />
            </td>
            <td>
              <Checkmark bool={!!provider.accept_video_consultations} />
            </td>
            <td>{moment(provider.date_created * 1000).format('MM-DD-YYYY - h:mm:ss A')}</td>
          </tr>
        ))}
      </Table>
      <ReactPaginate
        pageCount={providers.nbPages}
        marginPagesDisplayed={1}
        pageRangeDisplayed={5}
        onPageChange={onPageChange}
        containerClassName="pagination"
        activeClassName="active"
        pageClassName="page-item"
        previousClassName="page-item"
        nextClassName="page-item"
      />
    </div>
  )
}

export default ProvidersSearch
export { default as ProvidersShow } from './show'
export { default as ProvidersNew } from './new'
export { default as ProvidersCalendarCallback } from './calendar'
export { default as ProvidersSchedulerCallback } from './scheduler'
