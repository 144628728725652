import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { fetchItem, updateItem, fetchItems } from 'actions'
import moment from 'moment'
import AnalyticsDisplay from 'components/analytics_display'
import { Tabs, Tab, TabPanels, TabList } from 'components/tabs'
import { convertObjectsToIds } from 'config/util'
import ProviderForm from '../_form'
import ProviderProductsIndex from '../products'
import ProviderReviewsIndex from '../reviews'
import {
  ProcedureAlbums,
  ProviderNews,
  ProviderPublications,
  ProviderHeros,
  ProviderMyMedia,
  ProviderFaqs,
  ProviderPlans,
  ProviderProcedureForm,
  ProviderPasswordForm,
  ProviderPracticeForm,
  ProviderPrimaryPracticeForm,
  ProviderCalendarForm,
  ProviderTestimonials,
  ProviderScheduler,
  ProviderSEO,
} from './tabs'

const ProvidersShow = props => {
  const { provider_id } = useParams()
  // redux
  const provider = useSelector(state => state.providers.current)
  const loading = useSelector(state => state.ui.loading)
  const practices = useSelector(state => state.practices.all)
  const areas = useSelector(state => state.areas.all)
  const campuses = useSelector(state => state.campuses.all)
  const config = useSelector(state => state.config.provider)
  const states = useSelector(state => state.config.location.US_STATE_ABBREVIATIONS)
  const procedures = useSelector(state =>
    state.procedures.all.sort((a, b) => a.name.localeCompare(b.name))
  )
  const providerProcedures = useSelector(state => state.providers?.current?.procedures || []).sort(
    (a, b) => a?.name?.localeCompare(b?.name)
  )
  const dispatch = useDispatch()

  const practiceList = provider.practices || []
  const agreementList = provider.agreements || []
  const primaryPractice = provider.primary_practice || null
  const licensesList = provider.licenses || []

  const initialValues = {
    ...provider,
    area_ids: provider.procedure_areas,
  }

  const onFormSubmit = data => {
    let {
      location,
      date_verified_by_aedit,
      education_graduation_institution,
      education_undergrad_institution,
      profile_picture_url,
      profile_picture_relative_url,
      has_consultation_fee,
      consultation_fee,
      licenses,
      ...submitData
    } = data
    const convertables = [
      'degrees',
      'types',
      'specialties',
      'area_ids',
      'fellowship_trained_in',
      'board_certifications',
      'gender',
      'licenses',
    ]

    licenses = licenses ? licenses.filter(license => license.state && license.state !== '') : ''
    licenses = licenses
      ? licenses.map(
          license =>
            (license = {
              state: license.state.abbreviation ? license.state.abbreviation : license.state,
              license_start: license.license_start,
              license_end: license.license_end,
            })
        )
      : ''
    licenses = licenses.length > 0 ? licenses : ''

    const converted = convertObjectsToIds(submitData, convertables)

    if (!date_verified_by_aedit && submitData.is_verified) {
      date_verified_by_aedit = moment()
    }

    const education_undergrad_institution_id = education_undergrad_institution
      ? education_undergrad_institution.id
      : null
    const education_graduation_institution_id = education_graduation_institution
      ? education_graduation_institution.id
      : null

    const consultationFee = has_consultation_fee > 0 ? consultation_fee : 0

    if (!location.long_name) {
      location.place_id = null
    }

    dispatch(
      updateItem('provider', {
        ...submitData,
        ...converted,
        licenses,
        education_undergrad_institution_id,
        education_graduation_institution_id,
        date_verified_by_aedit,
        place_id: location.place_id,
        has_consultation_fee,
        consultation_fee: consultationFee,
      })
    )
  }

  const pwFormSubmit = values => {
    dispatch(updateItem('provider', { ...values, id: provider_id }))
  }

  useEffect(() => {
    dispatch(fetchItem('provider', provider_id))
    dispatch(fetchItems('area', { _limit: 9999 }))
    dispatch(fetchItems('procedure', { _limit: 9999 }))
  }, [])

  return (
    <div className="t__providers-show primary-content">
      <div className="controls">
        <h4>{provider.first_name + ' ' + provider.last_name}</h4>
      </div>
      <Tabs>
        <TabList>
          <Tab title="General" />
          <Tab title="Products" />
          <Tab title="Procedures" />
          <Tab title="Primary Practice" />
          <Tab title="Practices" />
          <Tab title="Before & Afters" />
          <Tab title="Hero Images" />
          <Tab title="Password" />
          <Tab title="Analytics" />
          <Tab title="Plans" />
          <Tab title="Calendar" />
          <Tab title="Scheduler" />
          <Tab title="News" />
          <Tab title="Publications" />
          <Tab title="About Media" />
          <Tab title="FAQ" />
          <Tab title="Testimonials" />
          <Tab title="Reviews" />
          <Tab title="SEO" />
        </TabList>
        <TabPanels>
          <ProviderForm
            onFormSubmit={onFormSubmit}
            provider={provider}
            initialValues={initialValues}
            loading={loading}
            fetchItems={fetchItems}
            practices={practices}
            areas={areas}
            campuses={campuses}
            configOptions={config}
            states={states}
            primaryPractice={primaryPractice}
            practiceList={practiceList}
            licensesList={licensesList}
            agreementList={agreementList}
          />
          <ProviderProductsIndex />
          <ProviderProcedureForm
            initialValues={{
              procedure_ids: providerProcedures,
            }}
            providerId={provider.id}
            procedures={procedures}
          />
          <ProviderPrimaryPracticeForm
            initialValues={{ primary_practice: primaryPractice }}
            providerId={provider.id}
            practices={practices}
          />
          <ProviderPracticeForm
            initialValues={{ practice_ids: practiceList }}
            providerId={provider.id}
            practices={practices}
          />

          <ProcedureAlbums providerId={provider_id} />
          <ProviderHeros providerId={provider_id} />
          <ProviderPasswordForm onFormSubmit={pwFormSubmit} loading={loading} />
          <AnalyticsDisplay entityType="provider" entityId={provider_id} />
          <ProviderPlans providerId={provider_id} />
          <ProviderCalendarForm providerId={provider_id} />
          <ProviderScheduler providerId={provider_id} />
          <ProviderNews providerId={provider_id} />
          <ProviderPublications providerId={provider_id} />
          <ProviderMyMedia providerId={provider_id} />
          <ProviderFaqs providerId={provider_id} />
          <ProviderTestimonials provider={provider} />
          <ProviderReviewsIndex />
          <ProviderSEO providerId={provider_id} />
        </TabPanels>
      </Tabs>
    </div>
  )
}

export default ProvidersShow
