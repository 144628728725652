import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { TextField, TextArea, SelectField, FormGroup } from 'components/form'
import { validateRequiredFields, rangeOfYears } from 'config/util'

const TestimonialForm = ({ handleSubmit, loading, valid, pristine, onSubmit, submitting }) => (
  <form onSubmit={handleSubmit(onSubmit)} className="page-form form-horizontal">
    <FormGroup label="Name" editable>
      <Field component={TextField} type="text" name="name" />
    </FormGroup>
    <FormGroup label="Procedure Name" editable>
      <Field component={TextField} type="name" name="procedure_name" />
    </FormGroup>
    <FormGroup label="Year" editable>
      <Field type="number" name="year" component={SelectField} dropdownText="Year">
        {rangeOfYears(1950).map((year, i) => (
          <option value={year} key={i}>
            {year}
          </option>
        ))}
      </Field>
    </FormGroup>
    <FormGroup label="Blurb" editable>
      <Field component={TextArea} name="blurb" placeholder="Blurb" />
    </FormGroup>

    <footer className="form-footer">
      <button
        className={`btn btn-primary ${loading ? 'loading' : ''}`}
        type="submit"
        disabled={pristine || !valid || submitting}>
        Save Changes
      </button>
    </footer>
  </form>
)

const validate = values => {
  const fields = [['name', 25], ['procedure_name', 50], ['year', 4], ['blurb', 300]]

  return { ...validateRequiredFields(values, fields) }
}

export default reduxForm({
  validate: validate,
  form: 'testimonialForm',
})(TestimonialForm)
