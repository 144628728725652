const defaultState = {
  all: [],
  current: {},
  count: 0,
  totalPages: 0
}

export default function(state = defaultState, action) {
  switch(action.type) {
    case 'FETCHED_BEAUTY_BOARD_ALL':
      // more than 0 results
      if (action.payload.meta.paging) {
        const { total_results, total_pages } = action.payload.meta.paging
        return {
          ...state,
          all: action.payload.data,
          count: total_results,
          totalPages: total_pages
        }
      } else { // no results
        return { ...state, ...defaultState }
      }
    case 'FETCHED_BEAUTY_BOARD':
      return {
        ...state,
        current: action.payload
      }
    case 'CREATED_BEAUTY_BOARD':
      return {
        ...state,
        current: action.payload
      }
    case 'UPDATED_BEAUTY_BOARD':
      return {
        ...state,
        current: action.payload
      }
    case 'DELETED_BEAUTY_BOARD':
      return {
        ...state,
        current: {},
        all: state.all.filter(beauty_board => beauty_board.id !== action.payload)
      }
    default:
      return state
  }
}
