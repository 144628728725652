import React from 'react'
import moment from 'moment'
import { FormGroup } from 'components/form'
import { noop } from 'config/util'
import { getApplicationName } from 'config/util/applications'

const AppointmentLeadsForm = props => {
  const { lead, user, provider, procedure } = props

  return (
    <form onSubmit={noop} className="flex-fill page-form well form-horizontal">
      <FormGroup label="ID">
        <span>{lead.id}</span>
      </FormGroup>

      <FormGroup label="Name">
        <span>{lead.name}</span>
      </FormGroup>

      <FormGroup label="Email">
        <span>{lead.email}</span>
      </FormGroup>

      <FormGroup label="Phone Number">
        <span>{lead.phone_number}</span>
      </FormGroup>

      <FormGroup label="Notes">
        <span>{lead.notes}</span>
      </FormGroup>

      <FormGroup label="Provider ID">
        <span>{lead.provider_id}</span>
      </FormGroup>

      {Boolean(provider) && (
        <FormGroup label="Provider">
          <span>
            <a href={`/providers/${provider.id}`} target="_blank" rel="noopener noreferrer">
              {provider.first_name} {provider.last_name}
            </a>
          </span>
        </FormGroup>
      )}

      <FormGroup label="Procedure ID">
        <span>{lead.procedure_id}</span>
      </FormGroup>

      {Boolean(procedure) && (
        <FormGroup label="Procedure">
          <span>
            <a href={`/procedures/${procedure.id}`} target="_blank" rel="noopener noreferrer">
              {procedure.name}
            </a>
          </span>
        </FormGroup>
      )}

      <FormGroup label="Date Created">
        <td>{moment(lead.date_created).format('MM-DD-YYYY - h:mm:ss A')}</td>
      </FormGroup>

      <FormGroup label="Application">
        <span>{getApplicationName(lead.application_id) ?? lead.application_id}</span>
      </FormGroup>

      <FormGroup label="Source">
        <span>{lead.source_id}</span>
      </FormGroup>

      <FormGroup label="Page URL">
        <span>{lead.page_url}</span>
      </FormGroup>

      <FormGroup label="User ID">
        <span>{lead.user_id}</span>
      </FormGroup>

      {Boolean(user) && (
        <FormGroup label="User">
          <span>
            <a href={`/users/${user.id}`} target="_blank" rel="noopener noreferrer">
              {user.first_name} {user.last_name}
            </a>
          </span>
        </FormGroup>
      )}

      <FormGroup label="Location ID">
        <span>{lead.location_id}</span>
      </FormGroup>

      {Boolean(lead.location) && (
        <>
          <FormGroup label="Address">
            <span>{lead.location.address}</span>
          </FormGroup>

          <FormGroup label="City">
            <span>{lead.location.city}</span>
          </FormGroup>

          <FormGroup label="Postal Code">
            <span>{lead.location.postal_code}</span>
          </FormGroup>

          <FormGroup label="State">
            <span>{lead.location.state}</span>
          </FormGroup>

          <FormGroup label="Country">
            <span>{lead.location.country}</span>
          </FormGroup>

          <FormGroup label="Latitude">
            <span>{lead.location.latitude}</span>
          </FormGroup>

          <FormGroup label="Longitude">
            <span>{lead.location.longitude}</span>
          </FormGroup>
        </>
      )}
    </form>
  )
}

export default AppointmentLeadsForm
