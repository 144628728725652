import React, {Component} from 'react'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import {connect} from 'react-redux'
import {toastr} from 'react-redux-toastr'
import {resetNotifications, fetchItems} from '../actions'
import {authenticate} from '../actions/auth'
import Layout from '../components/layout'
import {authedRoutes} from './route_definitions'
import Login from '../containers/login'

class Routes extends Component {
  componentDidMount() {
    this.props.authenticate()
    this.props.fetchItems('config')
  }

  componentWillReceiveProps({ notification }) {
    if (notification && notification.type) {
      this.props.resetNotifications()
    }
  }

  componentDidUpdate() {
    const {notification} = this.props
    if ( notification && notification.type ) {
      toastr[notification.type](notification.type, notification.message)
    }
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route component={Login} path="/" exact />
          <Layout routes={authedRoutes} />
        </Switch>
      </BrowserRouter>
    )
  }
}

function mapStateToProps({ ui: {loading, notification } }) {
  return { loading, notification }
}

export default connect(mapStateToProps, { resetNotifications, authenticate, fetchItems })(Routes)
