import React, { useState, useEffect, useCallback } from 'react'
import Modal from 'simple-react-modal'
import { toastr } from 'react-redux-toastr'
import { authedRequest } from 'config/config'
import HeroList from './_list'
import ProviderHeroForm from './_form'

const ProviderHeros = ({ providerId }) => {
  const [items, setItems] = useState([])
  const [modalShown, setModalShown] = useState(false)
  const [loading, setLoading] = useState(false)

  const useApiCallback = (func, deps) =>
    useCallback(
      (...params) => {
        try {
          setLoading(true)
          func(...params)
        } catch (e) {
          toastr.error(e.message)
        } finally {
          setLoading(false)
        }
      },
      [func]
    )

  const fetchItems = useApiCallback(async () => {
    const res = await authedRequest.get(`provider/${providerId}/heros`)
    if (res.data.error) {
      throw new Error(res.data.error.message)
    }
    await setItems(res.data.data || [])
  }, [providerId])

  const insertItem = useApiCallback(
    async item => {
      const form = convertHeroToFormData(item)
      const res = await authedRequest.post(`provider/${providerId}/heros`, form)
      if (res.data.error) {
        throw new Error(res.data.error.message)
      }
      await setModalShown(false)
      await fetchItems()
    },
    [providerId]
  )

  const updateOrderNumbers = useApiCallback(
    async items => {
      await Promise.all(
        items.map(async ({ id, meta }) => {
          const form = convertHeroToFormData({ meta })
          const res = await authedRequest.put(`provider/heros/${id}`, form)
          if (res.data.error) {
            throw new Error(res.data.error.message)
          }
        })
      )
      await fetchItems()
    },
    [providerId]
  )

  const removeItem = useApiCallback(
    async ({ id }) => {
      if (window.confirm('Are you sure?')) {
        const res = await authedRequest.delete(`provider/heros/${id}`)
        if (res.data.error) {
          throw new Error(res.data.error.message)
        }
        await fetchItems()
      }
    },
    [providerId]
  )

  useEffect(() => {
    fetchItems()
  }, [providerId])

  const disableUploading = loading || items.length >= 4

  return (
    <div className="flex-fill page-form well form-horizontal">
      {loading || items.length ? (
        <HeroList
          items={items}
          loading={loading}
          remove={removeItem}
          onSortEnd={updateOrderNumbers}
        />
      ) : (
        <div className="empty" style={{ background: 'white' }}>
          <p className="empty-title h5">You have no hero images for this provider</p>
          <p className="empty-subtitle">Click below to get started.</p>
          <div className="empty-action">
            <button className="btn btn-primary" onClick={() => setModalShown(true)}>
              Upload Hero Image
            </button>
          </div>
        </div>
      )}

      <div className="text-right mb-2">
        <button
          className={`btn btn-primary pull-right mb-2 ${
            disableUploading ? 'tooltip tooltip-left' : ''
          }`}
          onClick={() => setModalShown(true)}
          disabled={disableUploading}
          data-tooltip={disableUploading ? 'Limit is 4' : ''}>
          Upload Hero Image
        </button>
      </div>

      {modalShown && (
        <Modal
          show={modalShown}
          onClose={() => setModalShown(false)}
          containerClassName="modal-content">
          <ProviderHeroForm onSubmit={insertItem} loading={loading} />
        </Modal>
      )}
    </div>
  )
}

const convertHeroToFormData = item => {
  const form = new FormData()
  const fields = ['image', 'meta']
  for (const [key, value] of Object.entries(item)) {
    if (fields.includes(key)) {
      switch (key) {
        case 'meta':
          form.append(key, JSON.stringify(value))
          break
        default:
          form.append(key, value)
          break
      }
    }
  }
  return form
}

export default ProviderHeros
