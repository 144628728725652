import React from 'react'
import { Dialogue } from '@aedit/styleguide/dist/components'
import { toastr } from 'react-redux-toastr'

const RemoveCardDialogue = props => {
  const { cardToRemove, setCardToRemove, removeCard, loading } = props

  const handleRemoveCard = async card => {
    try {
      await removeCard(card)
    } catch (e) {
      toastr.error(e.message)
    } finally {
      setCardToRemove(null)
    }
  }

  return (
    <Dialogue.Delete
      dialogueToggle={Boolean(cardToRemove)}
      setDialogueToggle={() => setCardToRemove(null)}
      header="Are you sure?"
      caption="Removing this payment method cannot be undone."
      firstButton={{
        text: 'Cancel',
        type: 'button',
        onClick: () => setCardToRemove(null),
        isPending: loading,
      }}
      secondButton={{
        text: 'Remove',
        type: 'submit',
        onClick: () => handleRemoveCard(cardToRemove),
        isPending: loading,
      }}
    />
  )
}

export default RemoveCardDialogue
