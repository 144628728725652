import React, { useState } from 'react'
import dayjs from 'dayjs'
import { toastr } from 'react-redux-toastr'
import { callApi } from 'config/config'
import { Loading } from 'components/loading'
import { Table } from 'components/table'
import { Checkmark } from '../../components/checkmark'
import { SUBSCRIPTION_EXTERNAL_SOURCES } from 'config/constants'
import { hasProSubscription } from 'config/util/user'

const tableHeaders = [
  'Date Created',
  'Group Display Name',
  'Product Display Name',
  'Source',
  'Status',
  'Expiration Date',
]

const Subscriptions = props => {
  const { user, config } = props

  return (
    <>
      <div className="controls">
        <ManageSubscriptionButton user={user} config={config} />
      </div>
      <SubscriptionsTable user={user} />
    </>
  )
}

const SubscriptionsTable = ({ user: { subscriptions } }) => {
  const subs = subscriptions || []

  const status = sub => {
    if (sub?.meta?.cancel_at_period_end && dayjs().isBefore(dayjs(sub.expiration_date))) {
      return 'Cancellation Pending'
    } else if (sub.is_active) {
      return <Checkmark bool={true} />
    } else {
      return <Checkmark bool={false} />
    }
  }

  return (
    <Table items={subs} loading={false} headers={tableHeaders}>
      {subs.map(sub => (
        <tr key={sub.id}>
          <td>{dayjs(sub.date_created).format('MM/DD/YYYY h:mm A')}</td>
          <td>{sub.group?.display_name}</td>
          <td>{sub.product?.display_name}</td>
          <td>{sub.external_source}</td>
          <td>{status(sub)}</td>
          <td>{dayjs(sub.expiration_date).format('MM/DD/YY, h:mm A')}</td>
        </tr>
      ))}
    </Table>
  )
}

const ManageSubscriptionButton = props => {
  const { user, config } = props
  const [loading, setLoading] = useState(false)
  const isStripePro = hasProSubscription(user, config, {
    inactive: true,
    source: SUBSCRIPTION_EXTERNAL_SOURCES.STRIPE,
  })

  const onClick = async () => {
    if (loading) {
      return
    }

    setLoading(true)

    try {
      const data = {
        return_url: window.location.href,
      }

      const res = await callApi('POST', `/user/${user.id}/payment/customer-portal`, null, data)
      const url = res.url

      let handle = window.open(url, '_blank')
      if (!handle) {
        window.location.assign(url)
      }
    } catch (err) {
      toastr.error(err.message)
    }
    {
      setLoading(false)
    }
  }

  return loading ? (
    <Loading color="#333333" />
  ) : (
    <button
      className="btn btn-primary pull-right"
      onClick={onClick}
      disabled={loading || !isStripePro}>
      Manage Stripe Subscriptions
    </button>
  )
}

export default Subscriptions
