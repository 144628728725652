import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import Route from 'route-parser'
import { isFunction, union } from 'lodash'

const getPathTokens = pathname => {
  const paths = ['/']

  if (pathname === '/') return paths

  pathname.split('/').reduce((prev, curr) => {
    const currPath = `${prev}/${curr}`
    paths.push(currPath)
    return currPath
  })

  return paths
}

function getRouteMatch(routes, path) {
  let arr = []
  routes.forEach(route => {
    if (route.routes) {
      route.routes.forEach(item => {
        arr.push(item)
      })
    }
  })

  // flatten nested routes
  return union(routes, arr)
    .map(route => {
      const params = new Route(route.path).match(path)

      return {
        didMatch: params !== false,
        params: params,
        name: route.breadcrumb,
      }
    })
    .find(item => item.didMatch)
}

function getBreadcrumbs({ routes, match, location }) {
  const pathTokens = getPathTokens(location.pathname)
  return pathTokens.map((path, i) => {
    const routeMatch = getRouteMatch(routes, path)
    const routeValue = routeMatch?.name
    const name = isFunction(routeValue) ? routeValue(routeMatch.params) : routeValue
    return { name, path }
  })
}

function Breadcrumbs({ routes, match, location }) {
  const breadcrumbs = getBreadcrumbs({ routes, match, location })

  return (
    <ul className="breadcrumb">
      {breadcrumbs.map((breadcrumb, i) => (
        <li key={breadcrumb.path} className="breadcrumb-item">
          <Link to={breadcrumb.path}>{breadcrumb.name}</Link>
        </li>
      ))}
    </ul>
  )
}

export default withRouter(Breadcrumbs)
