import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createItem, fetchItems} from '../../actions'
import ReferralCodeForm from './_code_form'

class ReferralCodesNew extends Component {

  handleFormSubmit = (values) => {
    this.props.createItem('referral', {
      ...values,
      created_by_user_id: this.props.currentUserId
    })
  }

  componentWillReceiveProps(nextProps) {
    const currentId = this.props.referral.id
    const nextId = nextProps.referral.id

    if (nextId && nextId !== currentId) {
      this.props.history.push('/referrals')
    }
  }

  componentDidMount() {
    this.props.fetchItems('program')
  }

  render() {
    const {loading, programs} = this.props

    return (
      <div className="primary-content">
        <div className="controls">
          <h4>Add a New Code</h4>
        </div>
        <ReferralCodeForm
          onFormSubmit={this.handleFormSubmit}
          initialValues={{}}
          programs={programs}
          loading={loading}
        />
      </div>
    )
  }
}

const mapStateToProps = ({referrals, ui, auth}) => {
  return {
    referral: referrals.currentCode,
    programs: referrals.programs,
    loading: ui.loading,
    currentUserId: auth.currentUser.id
  }
}

export default connect(mapStateToProps, {createItem, fetchItems})(ReferralCodesNew)
