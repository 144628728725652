import React, { useState, useEffect, useCallback } from 'react'
import moment from 'moment'
import ReactPaginate from 'react-paginate'
import { toastr } from 'react-redux-toastr'
import { Link } from 'react-router-dom'
import { Table } from 'components/table'
import { authedRequest } from 'config/config'

const AppointmentLeadsIndex = () => {
  const [leads, setLeads] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchData().then(({ totalPages }) => setTotalPages(totalPages))
  }, [])

  const fetchData = useCallback(async (page = 1) => {
    try {
      setLoading(true)

      const params = { _page: page, order_by: 'date_created', order_by_direction: 'desc' }
      const res = await authedRequest.get(`appointment_leads`, { params })

      if (res.data.error) {
        throw new Error(res.data.error.message)
      }

      const leads = res.data?.data
      const totalPages = res.data?.meta?.paging?.total_pages ?? 1

      setLeads(leads)
      return { leads, totalPages }
    } catch (e) {
      toastr.error(e.message)
    } finally {
      setLoading(false)
    }
  }, [])

  return (
    <div className="primary-content">
      <div className="controls">
        <h4>Appointment Leads</h4>
      </div>
      <Table
        items={leads}
        loading={loading}
        headers={['Name', 'Email', 'Provider ID', 'Date Created']}>
        {leads.map(lead => (
          <tr key={lead.id}>
            <td>
              <Link to={`/consultations/appointment_leads/${lead.id}`}>{lead.name}</Link>
            </td>
            <td>{lead.email}</td>
            <td>{lead.provider_id}</td>
            <td>{moment(lead.date_created).format('MM-DD-YYYY - h:mm:ss A')}</td>
          </tr>
        ))}
      </Table>
      <ReactPaginate
        pageCount={totalPages}
        marginPagesDisplayed={1}
        pageRangeDisplayed={5}
        onPageChange={({ selected }) => fetchData(selected + 1)}
        containerClassName="pagination"
        activeClassName="active"
        pageClassName="page-item"
        previousClassName="page-item"
        nextClassName="page-item"
      />
    </div>
  )
}

export default AppointmentLeadsIndex
export { default as AppointmentLeadsShow } from './_show'
