import React, {Component} from 'react'
import {connect} from 'react-redux'
import {fetchSynonyms, createSynonym, updateSynonym, deleteSynonym, deleteSynonyms} from '../../actions/synonyms'
import {Table} from '../../components/table'
import ReactPaginate from 'react-paginate'
import _ from 'lodash'

const isDuplicate = (all, search) => {
  return all.find(obj => {
    return _.isEmpty(_.xor(obj.synonyms, search))
  })
}

class SynonymsIndex extends Component {
  state = {
    newField: false,
    deleteAllIsOpen: false
  }

  componentDidMount() {
    this.props.fetchSynonyms()
  }

  onPageChange = ({ selected }) => {
    this.props.fetchSynonyms({page: selected + 1})
  }

  toggleModal = () => {
    this.setState(prevState => ({ deleteAllIsOpen: !prevState.deleteAllIsOpen }))
  }

  onDeleteAll = () => {
    this.toggleModal()
    this.props.deleteSynonyms()
  }

  onSynonymDelete = (id) => {
    if (window.confirm('Are you sure you want to delete?')) {
      this.props.deleteSynonym(id)
    }
  }

  createSynonym = (synonyms) => {
    if (!isDuplicate(this.props.synonyms.all, synonyms)) {
      this.props.createSynonym(synonyms)
      this.setState({ newField: false })
    } else {
      alert('dupe!')
    }
  }

  updateSynonym = (id, synonyms) => {
    if (!isDuplicate(this.props.synonyms.all, synonyms)) {
      this.props.updateSynonym(id, synonyms)
    } else {
      alert('dupe!')
    }
  }

  toggleNewSynonymField = () => {
    this.setState(prevState => ({ newField: !prevState.newField }))
  }

  render() {
    const { synonyms, loading } = this.props
    const { newField, deleteAllIsOpen } = this.state
    const tableHeaders = ['Type', 'Expansion']

    return (
      <div className="primary-content synonyms-container">
        <div className="controls">
          <div className="pull-right">

            <span onClick={this.toggleNewSynonymField} className={`btn ${newField ? 'hidden' : ''}`}>
              <i className="fas fa-plus-circle"></i>
            </span>

            <span onClick={this.toggleNewSynonymField} className={`btn ${newField ? '' : 'hidden'}`}>
              <i className="fas fa-minus-circle"></i>
            </span>

            {
              synonyms.count > 0 &&
              <span onClick={this.toggleModal} className="btn ml-2">
                Delete All
              </span>
            }
          </div>
          <h4>Procedure Synonyms</h4>
        </div>

        {
          deleteAllIsOpen &&
          <div className="delete-all-container">
            <div className="text-center">
              <span>Warning! Delete all synonyms?</span>
              <button className="btn btn-primary" onClick={this.onDeleteAll}>Yes</button>
              <button className="btn btn-primary ml-2" onClick={this.toggleModal}>No</button>
            </div>
          </div>
        }

        <Table items={newField || synonyms.count !== 0 ? [''] : []} headers={tableHeaders} loading={loading}>
          {newField &&
            <EditableField
              synonym={{ type: 'synonym', synonyms: ''}}
              createSynonym={this.createSynonym}
              creating={true}
            />
          }

          {synonyms.all.map((s, index) => (
            <EditableField
              synonym={{...s, synonyms: s.synonyms.join(', ')}}
              key={s.objectID}
              updateSynonym={this.updateSynonym}
              delete={this.onSynonymDelete}
              creating={false}
            />
          ))}
        </Table>

        <ReactPaginate
          pageCount={Math.floor(synonyms.count/20)}
          marginPagesDisplayed={1}
          pageRangeDisplayed={5}
          onPageChange={this.onPageChange}
          containerClassName="pagination"
          activeClassName="active"
          pageClassName="page-item"
          previousClassName="page-item"
          nextClassName="page-item"
        />
      </div>
    )
  }
}


class EditableField extends React.Component {

  state = {
    synonyms: this.props.synonym.synonyms
  }

  onChange = (e) => {
    this.setState({ synonyms: e.target.value })
  }

  save = (e) => {
    e.preventDefault()

    const synonymArray = this.state.synonyms.split(',').map(item => item.trim())

    if (this.props.creating) {
      this.props.createSynonym(synonymArray)
    } else {
      const {synonym: { objectID }} = this.props
      this.props.updateSynonym(objectID, synonymArray)
    }
  }

  render() {
    const {synonym, creating} = this.props
    const showSaveButton = synonym.synonyms !== this.state.synonyms

    return (
      <tr>
        <td>
          {synonym.type}
        </td>
        <td>
          <form onSubmit={(e) => this.save(e)}>
            <input
              type="text"
              className="text-field"
              value={this.state.synonyms}
              placeholder="Comma separated synonyms"
              onChange={this.onChange}
            />
          </form>
        </td>
        <td>
          <span onClick={(e) => this.save(e)} className={showSaveButton ? '' : 'invisible'}>
            <i className="small-icons fas fa-save"></i>
          </span>

          {
            !creating &&
            <span onClick={() => this.props.delete(synonym.objectID)} className="ml-2">
              <i className="small-icons fas fa-trash-alt"></i>
            </span>
          }
        </td>
      </tr>
    )
  }
}

const mapStateToProps = ({ synonyms, ui }) => {
  return {
    synonyms: synonyms,
    loading: ui.loading
  }
}

export default connect(mapStateToProps, { fetchSynonyms, createSynonym, updateSynonym, deleteSynonym, deleteSynonyms })(SynonymsIndex)
