import React, { useState, useRef, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { fetchItems } from 'actions'
import axios from 'axios'
import _ from 'lodash'

const protocol = process.env.REACT_APP_PROTOCOL
const host =
  protocol +
  process.env.REACT_APP_API_ROOT +
  ':' +
  process.env.REACT_APP_API_PORT +
  '/' +
  process.env.REACT_APP_API_PATH
const URL = host

const Morphing = ({ models, fetchItems }) => {
  const [morphingActive, setMorphingActive] = useState(false)
  const [model, setModel] = useState('')
  const [uploading, setUploading] = useState(false)
  const [classifications, setClassifications] = useState([])
  const [imagePreview, setImage] = useState(null)
  const [pointMap, setPoints] = useState([])
  const fileRef = useRef(null)

  const uploadFile = useCallback(async () => {
    const file = _.get(fileRef, 'current.files[0]')

    if (!file) return

    setUploading(true)
    setPoints([])

    const formData = new FormData()

    formData.append('image', file)

    try {
      const res = await axios.post(URL + '/ml/glasses-hair-eye-eyebag-classifier', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      const plots = await axios.post(URL + '/ml/face-landmark-detection', formData, {
        params: {
          'shape-predictor': model,
          'points-only': true,
          points: model.match(/^shape_predictor_(\d+)_/)[1],
        },
      })
      const points = _.get(plots, 'data.data[0].points')

      if (!points) {
        throw new Error('No points found for this image')
      }

      setImage(window.URL.createObjectURL(file))
      setClassifications(Object.entries(res.data.data))
      setPoints(points)
    } catch (e) {
      alert('Error: ' + e.message)
    } finally {
      setUploading(false)
    }
  }, [fileRef, model])

  useEffect(() => {
    fetchItems('ml_model')
  }, [fetchItems])

  // reupload file when changing model
  useEffect(() => {
    const file = _.get(fileRef, 'current.files[0]')
    if (model && file) {
      uploadFile()
    }
  }, [model, uploadFile])

  // set last model in the list as the default model
  useEffect(() => {
    if (!model && models.length) {
      setModel(models[models.length - 1])
    }
  }, [model, models])

  const handleUploadFile = async e => {
    e.preventDefault()
    await uploadFile()
  }

  return (
    <div className="primary-content">
      <div className="controls">
        <form className="empty-action" onSubmit={handleUploadFile}>
          <label className="form-switch">
            <input
              type="checkbox"
              checked={morphingActive}
              onChange={() => setMorphingActive(!morphingActive)}
            />
            <i className="form-icon" /> Morphing App
          </label>

          <label>
            Model&nbsp;
            <select
              className="form-select"
              style={{ width: 480 }}
              value={model}
              onChange={e => setModel(e.target.value)}>
              {models.map(id => (
                <option key={id} value={id}>
                  {id}
                </option>
              ))}
            </select>
          </label>

          <label
            className="btn btn-primary pull-right"
            style={{ opacity: uploading ? 0.5 : 1, pointerEvents: uploading ? 'none' : 'all' }}>
            <input
              type="file"
              name="image"
              ref={fileRef}
              accept="image/*"
              className="hidden"
              id="Base64FileInput"
              onChange={uploadFile}
            />
            Select File
          </label>
        </form>
      </div>
      <div className="table-container well mb-2" style={{ display: 'flex', paddingTop: 0 }}>
        {/* Will be cleaned up after merge */}

        <div className={morphingActive ? '' : 'hidden'}>
          {/* <MorphApp image={imagePreview} points={pointMap} /> */}
        </div>

        {imagePreview && pointMap.length && Boolean(classifications.length) ? (
          <>
            <div className={morphingActive ? 'hidden' : ''}>
              <ImagePointMap points={pointMap} image={imagePreview} />
              <Classifications data={classifications} />
            </div>
          </>
        ) : (
          <div style={{ padding: 30, textAlign: 'center', flexGrow: 1 }}>
            <i className="icon icon-2x icon-upload mb-2" />
            <h5>Upload an image to get started</h5>
          </div>
        )}
      </div>
    </div>
  )
}

const ImagePointMap = ({ points, image }) => {
  return (
    <div className="text-center">
      <div className="morph-wrapper">
        {points.map((point, index) => (
          <span
            key={index}
            className="image-recognition-point"
            style={{ left: point.x - 1, top: point.y - 1 }}
          />
        ))}
        <img src={image} alt="" />
      </div>
    </div>
  )
}

const Classifications = ({ data }) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th>Property</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        {data.map(([property, value], i) => (
          <tr className="active" key={i}>
            <td>{_.startCase(property)}</td>
            <td>{parseFloat(value).toFixed(2)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

const mapStateToProps = ({ ml }) => {
  return {
    models: ml.face_landmark_detection,
  }
}

export default connect(
  mapStateToProps,
  { fetchItems }
)(Morphing)
