// users
import UsersNew from '../containers/users/users_new'
import UsersIndex from '../containers/users/users_index'
import UsersShow from '../containers/users/users_show'
import UsersNewsletter from '../containers/users/users_newsletter'

// consultations
import ConsultationsIndex from '../containers/consultations/consultations_index'
import ConsultationsShow from '../containers/consultations/consultations_show'
import ConsultationsNew from '../containers/consultations/consultations_new'

// providers
import ProvidersIndex, {
  ProvidersNew,
  ProvidersShow,
  ProvidersCalendarCallback,
  ProvidersSchedulerCallback,
} from '../containers/providers/index.js'
// providers/flags
import ProvidersFlagIndex, {
  ProvidersFlagNew,
  ProvidersFlagShow,
} from '../containers/providers/flags/index.js'
// providers/products
import ProviderProductsIndex, {
  ProviderProductsNew,
  ProviderProductsShow,
} from '../containers/providers/products/index.js'
//  providers/reviews
import ProviderReviewsIndex, {
  ProviderReviewsNew,
  ProviderReviewsShow,
} from '../containers/providers/reviews/index.js'
// campus
import CampusIndex from '../containers/campus/campus_index'
import CampusShow from '../containers/campus/campus_show'
import CampusNew from '../containers/campus/campus_new'

// practices
import PracticesIndex from '../containers/practices/practices_index'
import PracticesShow from '../containers/practices/practices_show'
import PracticesNew from '../containers/practices/practices_new'

// procedures
import ProceduresIndex from '../containers/procedures/procedures_index'
import ProceduresShow from '../containers/procedures/procedures_show'
import ProceduresNew from '../containers/procedures/procedures_new'

// procedure videos
import ProceduresVideos from '../containers/procedures/procedures_videos'
import ProceduresVideoShow from '../containers/procedures/procedures_video_show'

// procedure areas
import AreasIndex from '../containers/areas/areas_index'
import AreasNew from '../containers/areas/areas_new'
import AreasShow from '../containers/areas/areas_show'

// procedure regions
import RegionsNew from '../containers/regions/regions_new'
import RegionsIndex from '../containers/regions/regions_index'
import RegionsShow from '../containers/regions/regions_show'

// concerns
import ConcernsNew from '../containers/concerns/concerns_new'
import ConcernsIndex from '../containers/concerns/concerns_index'
import ConcernsShow from '../containers/concerns/concerns_show'

// Timelines
import TimelinesIndex from '../containers/timelines/timelines_index'
import TimelinesShow from '../containers/timelines/timelines_show'
import TimelinesNew from '../containers/timelines/timelines_new'

// Steps
import StepsNew from '../containers/timelines/steps_new'
import StepsShow from '../containers/timelines/steps_show'
import StepsIndex from '../containers/timelines/steps_index'

// facial recognition
import AeditsIndex from '../containers/media/aedits_index'
import AeditsFlaggedIndex from '../containers/media/aedits_flagged_index'
import Morphing from '../containers/media/morphing'
import AeditsFailedIndex from '../containers/media/aedits_failed_index'
import Media3d from '../containers/media/3d'
import PrescanIndex from '../containers/media/prescan_index'
import MyAedits3d from '../containers/media/my_aedits_3d'

// Referral Codes
import ReferralCodesIndex from '../containers/referrals/codes_index'
import ReferralCodesNew from '../containers/referrals/codes_new'
import ReferralCodesShow from '../containers/referrals/codes_show'

// Referral Programs
import ReferralProgramsIndex from '../containers/referrals/programs_index'
import ReferralProgramsNew from '../containers/referrals/programs_new'
import ReferralProgramsShow from '../containers/referrals/programs_show'

// Board Board Members
import BeautyBoardMembersIndex from '../containers/beauty_board_members/beauty_board_members_index'
import BeautyBoardMembersNew from '../containers/beauty_board_members/beauty_board_members_new'
import BeautyBoardMembersShow from '../containers/beauty_board_members/beauty_board_members_show'

// synonyms
import SynonymsIndex from '../containers/synonyms/synonyms_index'

// models
import ModelsIndex from 'containers/media/models'
import ModelsNew from 'containers/media/models/new'
import ModelsShow from 'containers/media/models/show'
// statistics
import StatisticsIndex from 'containers/statistics/statistics_index'

// Plans
import PlansIndex from '../containers/plans/plans_index'
import PlansNew from '../containers/plans/plans_new'
import PlansShow from '../containers/plans/plans_show'
import PlansFlagList from '../containers/plans/plans_flag_list'
import PlansFlagNew from '../containers/plans/plans_flag_new'
import PlansFlagShow from '../containers/plans/plans_flag_show'

// Appointment leads
import AppointmentLeadsIndex, {
  AppointmentLeadsShow,
} from 'containers/consultations/appointment_leads'

// Order is important
export const authedRoutes = [
  {
    path: '/',
    component: UsersIndex,
    breadcrumb: 'Home',
    exact: true,
  },
  {
    path: '/users',
    component: UsersIndex,
    breadcrumb: 'Users',
    exact: true,
  },
  {
    path: '/users/newsletter',
    component: UsersNewsletter,
    breadcrumb: 'Newsletter Only',
  },
  {
    path: '/users/new',
    component: UsersNew,
    breadcrumb: 'New',
  },
  {
    path: '/users/:id',
    component: UsersShow,
    breadcrumb: params => params.id,
  },
  {
    path: '/consultations',
    component: ConsultationsIndex,
    breadcrumb: 'Consultations',
    exact: true,
  },
  {
    path: '/consultations/new',
    component: ConsultationsNew,
    breadcrumb: 'New',
  },
  {
    path: '/consultations/appointment_leads',
    component: AppointmentLeadsIndex,
    breadcrumb: 'Appointment Leads',
    exact: true,
  },
  {
    path: '/consultations/appointment_leads/:id',
    component: AppointmentLeadsShow,
    breadcrumb: params => params.id,
  },
  {
    path: '/consultations/:id',
    component: ConsultationsShow,
    breadcrumb: params => params.id,
  },
  {
    path: '/procedures/regions/new',
    component: RegionsNew,
    breadcrumb: 'New',
  },
  {
    path: '/procedures/regions/:id',
    component: RegionsShow,
    breadcrumb: param => param.id,
  },
  {
    path: '/procedures/regions',
    component: RegionsIndex,
    breadcrumb: 'Regions',
    exact: true,
  },
  {
    path: '/procedures/areas/new',
    component: AreasNew,
    breadcrumb: 'New',
  },
  {
    path: '/procedures/areas/:id',
    component: AreasShow,
    breadcrumb: param => param.id,
  },
  {
    path: '/procedures/timelines',
    exact: true,
    component: TimelinesIndex,
    breadcrumb: 'Timelines',
  },
  {
    path: '/procedures/timelines/new',
    exact: true,
    component: TimelinesNew,
    breadcrumb: 'New',
  },
  {
    path: '/procedures/timelines/:id',
    component: TimelinesShow,
    breadcrumb: params => params.id,
    exact: true,
  },
  {
    path: '/procedures/steps/new',
    breadcrumb: 'New',
    exact: true,
    component: StepsNew,
  },
  {
    path: '/procedures/steps/:id',
    breadcrumb: params => params.id,
    exact: true,
    component: StepsShow,
  },
  {
    path: '/procedures/steps',
    breadcrumb: 'Steps',
    exact: true,
    component: StepsIndex,
  },
  {
    path: '/procedures/areas',
    component: AreasIndex,
    breadcrumb: 'Areas',
    exact: true,
  },
  {
    path: '/procedures/new',
    component: ProceduresNew,
    breadcrumb: 'New',
    exact: true,
  },
  {
    path: '/procedures/:id',
    component: ProceduresShow,
    breadcrumb: params => params.id,
    exact: true,
  },
  {
    path: '/procedures',
    exact: true,
    component: ProceduresIndex,
    breadcrumb: 'Procedures',
  },
  {
    path: '/procedures/:id/videos',
    exact: true,
    component: ProceduresVideos,
    breadcrumb: `Videos`,
  },
  {
    path: '/procedures/:id/videos/:videoId',
    exact: true,
    component: ProceduresVideoShow,
    breadcrumb: params => `Video ${params.videoId}`,
  },
  {
    path: '/providers/new',
    exact: true,
    component: ProvidersNew,
    breadcrumb: 'New',
  },
  {
    path: '/providers/calendar-callback',
    exact: true,
    component: ProvidersCalendarCallback,
    breadcrumb: 'Calendar Callback',
  },
  {
    path: '/providers/scheduler-callback',
    exact: true,
    component: ProvidersSchedulerCallback,
    breadcrumb: 'Scheduler Callback',
  },
  {
    path: '/providers/:id/flags',
    exact: true,
    component: ProvidersFlagIndex,
    breadcrumb: 'Flags',
  },
  {
    path: '/providers/:id/flags/new',
    exact: true,
    component: ProvidersFlagNew,
    breadcrumb: 'New Flag',
  },
  {
    path: '/providers/:id/flags/:flag_id',
    exact: true,
    component: ProvidersFlagShow,
    breadcrumb: params => `Flag ${params.flag_id}`,
  },
  {
    path: '/providers/:provider_id/products',
    exact: true,
    component: ProviderProductsIndex,
    breadcrumb: 'Products',
  },
  {
    path: '/providers/:provider_id/products/new',
    exact: true,
    component: ProviderProductsNew,
    breadcrumb: 'New Product',
  },
  {
    path: '/providers/:provider_id/products/:product_id',
    exact: true,
    component: ProviderProductsShow,
    breadcrumb: params => `Product ${params.product_id}`,
  },
  {
    path: '/providers/:provider_id/reviews',
    exact: true,
    component: ProviderReviewsIndex,
    breadcrumb: 'Reviews',
  },
  {
    path: '/providers/:provider_id/reviews/new',
    exact: true,
    component: ProviderReviewsNew,
    breadcrumb: 'New Review',
  },
  {
    path: '/providers/:provider_id/reviews/:review_id',
    exact: true,
    component: ProviderReviewsShow,
    breadcrumb: params => `Review ${params.review_id}`,
  },
  {
    path: '/providers/:provider_id',
    component: ProvidersShow,
    breadcrumb: params => params.provider_id,
  },
  {
    path: '/providers',
    exact: true,
    component: ProvidersIndex,
    breadcrumb: 'Providers',
  },
  {
    path: '/campus/new',
    exact: true,
    component: CampusNew,
    breadcrumb: 'New',
  },
  {
    path: '/campus/:id',
    component: CampusShow,
    breadcrumb: params => params.id,
  },
  {
    path: '/campus',
    exact: true,
    component: CampusIndex,
    breadcrumb: 'Universities',
  },
  {
    path: '/practices/new',
    exact: true,
    component: PracticesNew,
    breadcrumb: 'New',
  },
  {
    path: '/practices/:id',
    component: PracticesShow,
    breadcrumb: params => params.id,
  },
  {
    path: '/practices',
    exact: true,
    component: PracticesIndex,
    breadcrumb: 'Practices',
  },
  {
    path: '/concerns',
    component: ConcernsIndex,
    breadcrumb: 'Concerns',
    exact: true,
  },
  {
    path: '/concerns/new',
    component: ConcernsNew,
    breadcrumb: 'New',
  },
  {
    path: '/concerns/:id',
    component: ConcernsShow,
    breadcrumb: param => param.id,
  },
  {
    path: '/media',
    component: AeditsIndex,
    exact: true,
    breadcrumb: 'User Aedits',
  },
  {
    path: '/media/failed',
    component: AeditsFailedIndex,
    exact: true,
    breadcrumb: 'Failed Aedits',
  },
  {
    path: '/media/flagged',
    component: AeditsFlaggedIndex,
    exact: true,
    breadcrumb: 'Flagged Aedits',
  },
  {
    path: '/media/prescans',
    component: PrescanIndex,
    exact: true,
    breadcrumb: 'Prescans',
  },
  {
    path: '/media/morphing',
    component: Morphing,
    exact: true,
    breadcrumb: 'Mighty Morphing Camera Feature',
  },
  {
    path: '/media/3d',
    component: Media3d,
    exact: true,
    breadcrumb: '3D Facial Recognition',
  },
  {
    path: '/media/category/my_aedits_3d',
    component: MyAedits3d,
    exact: true,
    breadcrumb: 'Lookbook (My Aedits)',
  },
  {
    path: '/media/models',
    component: ModelsIndex,
    exact: true,
    breadcrumb: 'Models',
  },
  {
    path: '/media/models/new',
    component: ModelsNew,
    exact: true,
    breadcrumb: 'New',
  },
  {
    path: '/media/models/:id',
    component: ModelsShow,
    exact: true,
    breadcrumb: params => params.id,
  },
  {
    path: '/synonyms',
    exact: true,
    component: SynonymsIndex,
    breadcrumb: 'Procedures',
  },
  {
    path: '/referrals',
    exact: true,
    component: ReferralCodesIndex,
    breadcrumb: 'Referrals',
  },
  {
    path: '/referrals/new',
    exact: true,
    component: ReferralCodesNew,
    breadcrumb: 'New',
  },
  {
    path: '/referrals/programs',
    exact: true,
    component: ReferralProgramsIndex,
    breadcrumb: 'Programs',
  },
  {
    path: '/referrals/programs/new',
    exact: true,
    component: ReferralProgramsNew,
    breadcrumb: 'New',
  },
  {
    path: '/referrals/:id',
    exact: true,
    component: ReferralCodesShow,
    breadcrumb: params => params.id,
  },
  {
    path: '/referrals/programs/:id',
    component: ReferralProgramsShow,
    breadcrumb: params => params.id,
  },
  {
    path: '/beauty-board',
    exact: true,
    component: BeautyBoardMembersIndex,
    breadcrumb: 'Beauty Board Members',
  },
  {
    path: '/beauty-board/new',
    exact: true,
    component: BeautyBoardMembersNew,
    breadcrumb: 'New',
  },
  {
    path: '/beauty-board/:id',
    component: BeautyBoardMembersShow,
    breadcrumb: params => params.id,
  },
  {
    path: '/statistics',
    exact: true,
    component: StatisticsIndex,
    breadcrumb: 'Statistics',
  },
  {
    path: '/plans',
    exact: true,
    component: PlansIndex,
    breadcrumb: 'Plans',
  },
  {
    path: '/plans/new',
    exact: true,
    component: PlansNew,
    breadcrumb: 'New',
  },
  {
    path: '/plans/:id',
    exact: true,
    component: PlansShow,
    breadcrumb: params => params.id,
  },
  {
    path: '/plans/:id/flags',
    exact: true,
    component: PlansFlagList,
    breadcrumb: 'Flags',
  },
  {
    path: '/plans/:id/flags/new',
    exact: true,
    component: PlansFlagNew,
    breadcrumb: 'New Flag',
  },
  {
    path: '/plans/:id/flags/:flag_id',
    exact: true,
    component: PlansFlagShow,
    breadcrumb: params => `Flag ${params.flag_id}`,
  },
]
