import React, { useState } from 'react'
import { connect } from 'react-redux'
import { AeditForm } from '@aedit/styleguide/dist/form.js'
import { BEURO__Btn } from '@aedit/styleguide/dist/components.js'
import BeforeAfterForm from './form.js'
import './index.scss'

const defaultBeforeAndAfters = {
  id: null,
  order: 1,
  after_images: [
    {
      meta: {
        after_count: '',
        after_length_of_time: '',
        caption: '',
        alt_caption: '',
      },
    },
  ],
  after_images_meta: [],
  before_image: [],
  candidate_age: null,
  candidate_gender: '',
  candidate_name: '',
  caption: '',
  date_created: '',
  date_deleted: null,
  date_updated: '',
  meta: {
    after_count: null,
    after_length_of_time: '',
  },
  procedure_id: null,
  provider_id: null,
  _type: '',
}

const ProceduresAddBeforeAfterForm = props => {
  let { onFormSubmit, onSubmitSucceeded, onClose } = props // functions
  let { initialValues } = props
  const [pending, setPending] = useState(false)

  const handleSubmit = async (values, actions) => {
    setPending(true)

    await onFormSubmit(values)

    actions.setSubmitting(false)
    setPending(false)
    onSubmitSucceeded()
  }

  return (
    <AeditForm
      initialValues={{ ...defaultBeforeAndAfters, ...initialValues }}
      id="before-after-procedures-form"
      onSubmit={handleSubmit}>
      <BeforeAfterForm />

      <div className="button-wrapper">
        <button className="btn btn-secondary mr-2" onClick={onClose}>
          Cancel
        </button>
        <button className="btn btn-primary" disabled={pending}>
          {pending ? 'Saving...' : 'Save'}
        </button>
      </div>
    </AeditForm>
  )
}

const mapStateToProps = ({ ui }) => {
  return {
    loading: ui.loading,
  }
}

export default connect(
  mapStateToProps,
  null
)(ProceduresAddBeforeAfterForm)
