import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { fetchConsultationsByUser } from '../../actions'
import { Table } from '../../components/table'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { getStripeDashboardUrl } from '../../config/util'

const UserConsultations = props => {
  const { userId, loading, consultations, fetchConsultationsByUser } = props

  useEffect(() => {
    fetchConsultationsByUser(userId)
  }, [])

  const tableHeaders = [
    'Consultation ID',
    'Consultation Date',
    'Provider Id',
    'Payment',
    'Date Created',
  ]

  return (
    <Table items={consultations} loading={loading} headers={tableHeaders}>
      {consultations.map(consult => (
        <tr key={consult.id}>
          <td>
            <Link to={`/consultations/${consult.id}`}>{consult.id}</Link>
          </td>
          <td>{consult.consultation_dates}</td>
          <td>
            <Link to={`/providers/${consult.provider_id}`}>{consult.provider_id}</Link>
          </td>
          <td>
            <a
              href={getStripeDashboardUrl(`payments/${consult.payment_confirmation}`)}
              target="_blank"
              rel="noopener noreferrer">
              Payment
            </a>
          </td>
          <td>{moment(consult.date_created).format('MM-DD-YYYY, h:mm:ss A')}</td>
        </tr>
      ))}
    </Table>
  )
}

const mapStateToProps = ({ users, ui, config }) => {
  return {
    user: users.current,
    loading: ui.loading,
    consultations: users.consultations,
    config: config,
  }
}

export default connect(mapStateToProps, {
  fetchConsultationsByUser,
})(UserConsultations)
