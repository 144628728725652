import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import ReactPaginate from 'react-paginate'
import { fetchItems as fetchItemsAction } from 'actions'
import PrescanList from '../../components/multimedia/prescan_list'

const PAGE_LIMIT = 50

const PrescanIndex = ({ all, fetchItems, totalPages, count }) => {
  // iterates over each image and returns the image's prescan_classification images
  // so an array of arrays - each representing a given media's prescan_classification images as a group
  const prescanImageGroups = all
    .reduce((acc, img) => {
      const hasDownloadableZip = img.url && img.url.slice(-4) === '.zip';
      // build the prop shape expected by the MetaData component
      // for displaying contextual information about the parent image
      const parent = {
        meta: {
          name: img.meta.name,
          device_model: img.meta?.device_model,
          version_number: img.meta?.version_number,
          model_name: img.meta?.model_name,
        },
        date_created: img.date_created,
        stored_name: img.stored_name,
        displayId: img.displayId,
        id: img.id,
        download_zip: Boolean(hasDownloadableZip),
        ...(Boolean(hasDownloadableZip) ? { related_files: img.url } : {}),
        user: {
          email: img.user?.email
        }
      }

      // add the parent image info to each prescan
      const prescans = img.meta.prescan_classification.images.map((ps) => ({
        ...ps,
        parent
      }))
      return [...acc, [...prescans]]
    }, [])

  useEffect(() => {
    fetchItems('prescans', {
      _limit: PAGE_LIMIT,
      order_by: 'date_created',
      order_by_direction: 'desc',
    })
  }, [fetchItems])

  const onPageChange = ({ selected }) => {
    fetchItems('prescans', {
      _page: selected + 1,
      _limit: PAGE_LIMIT,
      order_by: 'date_created',
      order_by_direction: 'desc',
    })
  }

  return (
    <div className="primary-content t__prescan-classifiers">
      <PrescanList groups={prescanImageGroups} />

      <ReactPaginate
        pageCount={totalPages}
        marginPagesDisplayed={1}
        pageRangeDisplayed={5}
        onPageChange={onPageChange}
        containerClassName="pagination"
        activeClassName="active"
        pageClassName="page-item"
        previousClassName="page-item"
        nextClassName="page-item"
      />
    </div>
  )
}

const mapStateToProps = ({ media }) => ({
  totalPages: media.totalPages,
  all: media.all,
  count: media.count,
})

export default connect(mapStateToProps, { fetchItems: fetchItemsAction })(PrescanIndex)
