import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { authedRequest } from 'config/config'
import AddBeforeAfterForm from './_add_before_after_form'
import { Table } from 'components/table'
import { sortableContainer, sortableElement, arrayMove } from 'react-sortable-hoc'
import { createProviderBeforeAfter, updateProviderBeforeAfter } from 'actions'

const ProviderProcedureAlbum = props => {
  const { providerId, createProviderBeforeAfter, updateProviderBeforeAfter } = props
  const [provider, setProvider] = useState(null)
  const [procedures, setProcedures] = useState([])
  const [currentAlbum, setCurrentAlbum] = useState(null)
  const [modalShown, setModalShown] = useState(false)
  const [initialValues, setInitialValues] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const initialize = async () => {
      if (providerId) {
        const res1 = await authedRequest.get(`provider/${providerId}`)
        setProvider(res1.data.data)

        const res2 = await authedRequest.get(`provider/${providerId}/procedures`, {
          params: {
            _limit: 9999,
            order_by: 'name',
            order_by_direction: 'desc',
          },
        })

        if (Array.isArray(res2.data.data)) {
          setProcedures(res2.data.data)
        }

        setLoading(false)
      }
    }

    initialize()
  }, [providerId])

  const closeModal = () => {
    setModalShown(false)
    setInitialValues()
  }

  const fetchAlbum = async procedure => {
    const res = await authedRequest.get(`provider/${providerId}/before_and_after`, {
      params: { procedure_id: procedure.id },
    })

    setCurrentAlbum({
      procedure,
      photos: res.data.data,
    })
  }

  const saveImageSet = async data => {
    const creating = !data.id

    const currentProcedure = data.procedure
    data.procedure_id = currentProcedure.id
    delete data.procedure

    const form = await convertBeforeAfterSetToFormData(data)

    const err = creating
      ? await createProviderBeforeAfter(providerId, form)
      : await updateProviderBeforeAfter(data.id, form)

    if (!err) {
      fetchAlbum(currentProcedure)
      closeModal()
    }
  }

  const deleteSet = async id => {
    if (window.confirm('Are you sure?')) {
      await authedRequest.delete(`provider/before_and_after/${id}`)

      setCurrentAlbum({
        ...currentAlbum,
        photos: currentAlbum.photos.filter(item => item.id !== id),
      })
    }
  }

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    setLoading(true)
    const newArray = arrayMove(currentAlbum.photos, oldIndex, newIndex)

    const promises = newArray.map((item, index) => {
      const newOrder = index + 1
      if (Number(item.order) !== newOrder) {
        return authedRequest.put(`provider/before_and_after/${item.id}`, {
          order: newOrder,
        })
      }

      return null
    })

    const results = await Promise.all(promises.filter(Boolean))
    const reordered = results.map(r => r.data.data)
    const reorderedSet = new Set(reordered.map(({ id }) => id))
    const oldPhotos = currentAlbum.photos.filter(item => !reorderedSet.has(item.id))

    setCurrentAlbum({
      ...currentAlbum,
      photos: [...oldPhotos, ...reordered],
    })
    setLoading(false)
  }

  const sortedPhotos = currentAlbum ? currentAlbum.photos.sort((a, b) => a.order - b.order) : []

  return (
    <>
      <div className="flex-fill page-form well form-horizontal">
        {currentAlbum ? (
          <>
            <span
              className="link"
              style={{ padding: '10px' }}
              onClick={() => setCurrentAlbum(null)}>
              &lsaquo; Back
            </span>
            {currentAlbum.photos && currentAlbum.photos.length > 0 ? (
              <SortableTable
                pressDelay={100}
                onSortEnd={onSortEnd}
                items={sortedPhotos}
                headers={['Order', 'Before / After', 'Name or Gender', 'Age', 'Edit', 'Remove']}
                loading={loading}>
                {sortedPhotos.map((p, i) => (
                  <SortableRow
                    index={i}
                    set={p}
                    key={p.id}
                    setModalShown={setModalShown}
                    setInitialValues={setInitialValues}
                    currentAlbum={currentAlbum}
                    deleteSet={deleteSet}
                  />
                ))}
              </SortableTable>
            ) : (
              <div className="empty" style={{ background: 'white' }}>
                <p className="empty-title h5">You have no albums for this procedure</p>
                <p className="empty-subtitle">Click below to get started.</p>
                <div className="empty-action">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      const procedure = currentAlbum.procedure
                      setInitialValues({
                        procedure,
                        meta: { orientation: 'portrait', explicit: procedure?.explicit },
                      })
                      setModalShown(true)
                    }}>
                    Create Album
                  </button>
                </div>
              </div>
            )}
          </>
        ) : (
          <Table items={procedures} headers={['Procedure']} loading={loading}>
            {procedures
              .sort((a, b) => a.name.localeCompare(b.name))
              .map(proc => (
                <tr key={proc.id} onClick={() => fetchAlbum(proc)} style={{ cursor: 'pointer' }}>
                  <td>{proc.name}</td>
                </tr>
              ))}
          </Table>
        )}
      </div>

      <div className="text-right mb-2">
        <button
          className="btn btn-primary pull-right mb-2"
          onClick={() => {
            if (currentAlbum !== null) {
              const procedure = currentAlbum.procedure
              setInitialValues({
                procedure,
                meta: { orientation: 'portrait', explicit: procedure.explicit },
                isEditing: false,
              })
            }
            setModalShown(true)
          }}>
          Upload New Image Set
        </button>
      </div>

      {modalShown && (
        <AddBeforeAfterForm
          initialValues={initialValues}
          modalShown={modalShown}
          setModalShown={setModalShown}
          procedures={procedures}
          closeModal={closeModal}
          saveImageSet={saveImageSet}
          updateImageSet={saveImageSet}
          provider={provider}
        />
      )}
    </>
  )
}

// This needs to be extracted out as a util function
const fetchImageAndConvertToFile = async url => {
  const original_filename = url.split('before_and_afters/')[1]
  return await fetch(url)
    .then(res => res.blob())
    .then(res => new File([res], original_filename))
    .catch(err => console.error(err))
}

const convertBeforeAfterSetToFormData = async data => {
  let { before_image, after_images, procedure_id, meta } = data
  let { candidate_name, candidate_age, candidate_gender, caption, recovery } = data // Candidate info

  const form = new FormData()

  if (!data.candidate_name) {
    data.candidate_name = ''
  }

  if (!data.caption) {
    data.caption = ''
  }

  appendImages(form, 'before_image', await loadImage(before_image))
  appendImages(form, 'after_images', await loadImage(after_images))

  form.append('candidate_name', candidate_name)
  form.append('candidate_age', candidate_age)
  form.append(
    'candidate_gender',
    candidate_gender.value ? candidate_gender.value : candidate_gender
  )
  form.append('caption', caption)
  form.append('recovery', recovery)
  form.append('procedure_id', procedure_id)
  form.append('meta', JSON.stringify(meta || {}))

  return form
}

const appendImages = (form, key, images) => {
  images = Array.isArray(images) ? images : [images]
  for (let image of images) {
    form.append(key, image)
  }
}

const loadImage = async value => {
  if (value instanceof File) {
    return value // file uploaded through file input component
  } else if (typeof value === 'string') {
    return await fetchImageAndConvertToFile(value) // value is url
  } else if (typeof value.url === 'string') {
    return await fetchImageAndConvertToFile(value.url) // before&after api object
  } else if (Array.isArray(value)) {
    return await Promise.all(value.map(loadImage)) // array of before&after objects
  } else {
    return value
  }
}

const SortableTable = sortableContainer(Table)
const SortableRow = sortableElement(
  ({ set, setModalShown, setInitialValues, deleteSet, currentAlbum }) => {
    return (
      <tr>
        <td>{set.order || '?'}</td>
        <td>
          <img
            style={{ width: '100px' }}
            src={set.before_image.url}
            alt=""
            crossOrigin="anonymous"
          />
          <img
            style={{ width: '100px' }}
            src={set.after_images[0].url}
            alt=""
            crossOrigin="anonymous"
          />
        </td>
        <td>{set.candidate_name ? set.candidate_name : set.candidate_gender}</td>
        <td>{set.candidate_age}</td>
        <td
          onClick={() => {
            setModalShown(true)
            setInitialValues({
              id: set.id,
              before_image: set.before_image.url,
              after_images: set.after_images,
              candidate_age: set.candidate_age,
              candidate_name: set.candidate_name,
              candidate_gender: set.candidate_gender,
              caption: set.caption,
              recovery: set.recovery,
              meta: { explicit: currentAlbum.procedure.explicit, ...set.meta },
              procedure: currentAlbum.procedure,
              isEditing: true,
            })
          }}>
          <button
            className="btn btn-link btn-action btn-lg tooltip tooltip-left"
            data-tooltip="Edit Album">
            <i className="icon icon-edit" />
          </button>
        </td>
        <td>
          <button
            className="btn btn-link btn-action btn-lg tooltip tooltip-left"
            data-tooltip="Remove Album"
            onClick={() => deleteSet(set.id)}>
            <i className="icon icon-cross" />
          </button>
        </td>
      </tr>
    )
  }
)

export default connect(null, {
  createProviderBeforeAfter,
  updateProviderBeforeAfter,
})(ProviderProcedureAlbum)
