import React, {Component} from 'react'
import {connect} from 'react-redux'
import {fetchItem, fetchItems, updateItem} from '../../actions'
import {Link} from 'react-router-dom'
import AreaForm from './_area_form'
import {convertObjectsToIds} from '../../config/util'

class AreasShow extends Component {

  onFormSubmit = ({procedure_region, concerns, primary_image_url, primary_image_relative_url, ...rest}) => {
    const converted = convertObjectsToIds(rest, ['concern_ids'])
    this.props.updateItem('area', {...rest, ...converted})
  }

  componentDidMount() {
    const id = this.props.match.params.id
    this.props.fetchItem('area', id)
    this.props.fetchItems('region', { _limit: 9999 })
    this.props.fetchItems('concern', { _limit: 9999 })
  }

  render() {
    const { area, loading, regions, procedures, procedureCount, concerns } = this.props
    const initialValues = {...area, concern_ids: area.concerns}

    return (
      <div className="primary-content">
        <div className="controls">
          <Link to="/procedures/areas/new" className="btn btn-primary pull-right">Add New Area</Link>
          <h4>{area.name} Area</h4>
        </div>
        <AreaForm
          initialValues={initialValues}
          area={area}
          loading={loading}
          regions={regions}
          procedures={procedures}
          procedureCount={procedureCount}
          onFormSubmit={this.onFormSubmit}
          concerns={concerns}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ areas, ui, regions, concerns }) => {
  return {
    area: areas.current,
    loading: ui.loading,
    regions: regions.all,
    procedureCount: areas.procedures.count,
    procedures: areas.procedures.all,
    concerns: concerns.all
  }
}

export default connect(mapStateToProps, { fetchItem, fetchItems, updateItem })(AreasShow)
