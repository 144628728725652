import React from 'react'
import { reduxForm, Field } from 'redux-form'
import { validateRequiredFields } from 'config/util'
import { FormGroup, FormFooter, FileInput } from 'components/form'

const ProviderHeroForm = ({
  handleSubmit,
  invalid,
  loading,
  onSubmit,
  submitFailed,
}) => {
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="page-form form-horizontal">
      <FormGroup label="Image" editable>
        <Field name="image" type="file" component={FileInput} />
      </FormGroup>

      <FormFooter loading={loading} showFailure={invalid && submitFailed} />
    </form>
  )
}

const validate = values => {
  const requiredFields = [
    ['image'],
  ]

  return validateRequiredFields(values, requiredFields)
}

export default reduxForm({
  validate,
  form: 'addProviderHeroForm',
  enableReinitialize: true,
})(ProviderHeroForm)
