import React, {Component} from 'react'
import {connect} from 'react-redux'
import CampusForm from './_campus_form'
import {fetchItem, updateItem} from '../../actions'
import {Link} from 'react-router-dom'

class CampusShow extends Component {
  onFormSubmit = (values) => {
    this.props.updateItem('campus', values)
  }

  componentDidMount() {
    const id = this.props.match.params.id
    this.props.fetchItem('campus', id)
  }

  render() {
    const { campus, loading } = this.props

    return (
      <div className="primary-content">
        <div className="controls">
          <Link to="/campus/new" className="btn btn-primary pull-right">Add New University</Link>
          <h4>{campus.name}</h4>
        </div>
        <CampusForm
          onFormSubmit={this.onFormSubmit}
          loading={loading}
          initialValues={campus}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ campuses, ui }) => {
  return {
    campus: campuses.current,
    loading: ui.loading
  }
}

export default connect(mapStateToProps, { fetchItem, updateItem })(CampusShow)
