import React, { useEffect, useContext } from 'react'
import './index.scss'

const Consumer = props => {
  const { className = '', Morph } = props
  const { canvasRef, canvasLoaded } = useContext(Morph.Context)
  const { _setCanvasLoaded } = useContext(Morph.ParentContext)

  useEffect(() => {
    _setCanvasLoaded(!canvasLoaded)
  }, [canvasLoaded])

  return (
    <div className={`${className} morph-canvas`}>
      <canvas id="morph-canvas" ref={canvasRef} width="520" height="640" />
      {canvasLoaded ? (
        <div className="loader">
          <div className="loader loader--first">
            <span className="line" />
            <span className="line" />
            <span className="line" />
            <span className="line" />
            <span className="line" />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export const MorphApp = props => {
  const { media, Morph } = props

  return (
    <Morph.App user={media}>
      <Consumer {...props} />
    </Morph.App>
  )
}
