import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import NewConsultationForm from './_new_consultation_form'
import { toastr } from 'react-redux-toastr'

const ConsultationsNew = () => {
  const config = useSelector(state => state.config.consultation)
  const history = useHistory()

  const onFormSubmit = async created => {
    try {
      if (created?.id) {
        toastr.success('Created successfully')
        history.push(`/consultations/${created.id}`)
      } else {
        throw new Error('Creating consultation failed')
      }
    } catch (e) {
      toastr.error(e.message)
    }
  }

  return (
    <div className="primary-content">
      <div className="controls">
        <h4>Add a New Consultations</h4>
      </div>
      <NewConsultationForm
        config={config}
        onFormSubmit={onFormSubmit}
      />
    </div>
  )
}
export default ConsultationsNew
