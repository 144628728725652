import React, { useState, useEffect } from 'react'
import Modal from 'simple-react-modal'
import { authedRequest } from 'config/config'
import ProviderNewsForm from './_form'
import Table from './_table'
import ReactPaginate from 'react-paginate'
import { toastr } from 'react-redux-toastr'

const ProviderNews = ({ providerId }) => {
  const [items, setItems] = useState([])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [loading, setLoading] = useState(false)
  const [modalValues, setModalValues] = useState()

  useEffect(() => {
    fetchItems()
  }, [page, providerId])

  const fetchItems = async () => {
    try {
      await setLoading(true)

      const params = { _page: page }
      const {
        data: {
          data,
          meta: { paging: { total_pages = 1 } = {} },
        },
      } = await authedRequest.get(`provider/${providerId}/news`, { params })

      await setItems(data || [])
      await setTotalPages(total_pages)
    } catch (e) {
      toastr.error(e.message)
    } finally {
      await setLoading(false)
    }
  }

  const upsertItem = async item => {
    if (item.id) {
      await updateItem(item)
    } else {
      await insertItem(item)
    }
  }

  const insertItem = async item => {
    try {
      await setLoading(true)
      let form = convertNewsToFormData(item)
      await authedRequest.post(`provider/${providerId}/news`, form)
    } catch (e) {
      toastr.error(e.message)
    } finally {
      await setModalValues()
      await setLoading(false)
      await fetchItems()
    }
  }

  const updateItem = async ({ id, ...item }) => {
    try {
      await setLoading(true)
      let form = convertNewsToFormData(item)
      await authedRequest.put(`provider/news/${id}`, form)
    } catch (e) {
      toastr.error(e.message)
    } finally {
      await setModalValues()
      await setLoading(false)
      await fetchItems()
    }
  }

  const removeItem = async ({ id }) => {
    if (window.confirm('Are you sure?')) {
      try {
        await setLoading(true)
        await authedRequest.delete(`provider/news/${id}`)
      } catch (e) {
        toastr.error(e.message)
      } finally {
        await setModalValues()
        await setLoading(false)
        await fetchItems()
      }
    }
  }

  return (
    <>
      <div className="flex-fill page-form well form-horizontal">
        {loading || items.length ? (
          <Table items={items} loading={loading} edit={setModalValues} remove={removeItem} />
        ) : (
          <div className="empty" style={{ background: 'white' }}>
            <p className="empty-title h5">You have no news for this provider</p>
            <p className="empty-subtitle">Click below to get started.</p>
            <div className="empty-action">
              <button className="btn btn-primary" onClick={() => setModalValues({})}>
                Upload News and Media
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="text-right mb-2">
        <button className="btn btn-primary pull-right mb-2" onClick={() => setModalValues({})}>
          Upload News and Media
        </button>
      </div>

      {!!modalValues && (
        <Modal
          show={!!modalValues}
          onClose={() => setModalValues()}
          containerClassName="modal-content">
          <ProviderNewsForm initialValues={modalValues} onSubmit={upsertItem} loading={loading} />
        </Modal>
      )}

      <ReactPaginate
        pageCount={totalPages}
        marginPagesDisplayed={1}
        pageRangeDisplayed={5}
        onPageChange={({ selected }) => setPage(selected + 1)}
        containerClassName="pagination"
        activeClassName="active"
        pageClassName="page-item"
        previousClassName="page-item"
        nextClassName="page-item"
      />
    </>
  )
}

const convertNewsToFormData = item => {
  const form = new FormData()
  const fields = ['name', 'url', 'image']
  for (const [key, value] of Object.entries(item)) {
    if (fields.includes(key)) {
      form.append(key, value)
    }
  }
  return form
}

export default ProviderNews
