import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createItem, fetchItems, fetchAreasByRegion } from '../../actions'
import InfoForm from './_info_form'
import { DEFAULT_PROCEDURE_VALUES } from '../../config/constants'

class ProceduresNew extends Component {
  componentDidMount() {
    const toBeFetched = ['region', 'concern', 'procedure', 'timeline']

    toBeFetched.forEach(f => {
      this.props.fetchItems(f, { _limit: 9999 })
    })
  }

  componentWillReceiveProps(nextProps) {
    const currentId = this.props.currentProcedure.id
    const nextId = nextProps.currentProcedure.id

    if (nextId && nextId !== currentId) {
      this.props.history.push(`/procedures`)
    }
  }

  onFormSubmit = ({
    area_ids = [],
    anesthesia_types = [],
    concern_ids = [],
    child_ids = [],
    related_ids = [],
    has_consultation_fee,
    consultation_fee,
    ...submitData
  }) => {
    // will throw if sent down to back-end
    delete submitData.procedure_areas
    delete submitData.isParent

    anesthesia_types = anesthesia_types.map(obj => obj.id)
    area_ids = area_ids.map(obj => obj.id)
    concern_ids = concern_ids.map(obj => obj.id)
    child_ids = child_ids.map(obj => obj.id)
    related_ids = related_ids.map(obj => obj.id)

    this.props.createItem('procedure', {
      ...submitData,
      area_ids,
      anesthesia_types,
      concern_ids,
      child_ids,
      related_ids,
      is_parent: Boolean(Number(submitData.is_solution)),
    })
  }

  render() {
    const {
      regions,
      loading,
      regionAreas,
      concerns,
      fetchAreasByRegion,
      procedures,
      procedureTypeOptions,
      timelines,
    } = this.props

    return (
      <div className="primary-content">
        <div className="controls">
          <h4>Add a New Procedure</h4>
        </div>
        <InfoForm
          onFormSubmit={this.onFormSubmit}
          regions={regions}
          loading={loading}
          initialValues={DEFAULT_PROCEDURE_VALUES}
          regionAreas={regionAreas}
          fetchAreasByRegion={fetchAreasByRegion}
          concerns={concerns}
          procedures={procedures}
          procedureTypeOptions={procedureTypeOptions}
          timelines={timelines}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ regions, ui, areas, procedures, concerns, config, timelines }) => {
  return {
    loading: ui.loading,
    regions: regions.all,
    procedures: procedures.all,
    currentProcedure: procedures.currentProcedure,
    regionAreas: areas.regionAreas,
    concerns: concerns.all,
    procedureTypeOptions: config.procedure,
    timelines: timelines.all,
  }
}

export default connect(
  mapStateToProps,
  { createItem, fetchItems, fetchAreasByRegion }
)(ProceduresNew)
