import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { FormGroup, TextField, FormFooter } from 'components/form'

const Form = props => {
  const { handleSubmit, loading, onFormSubmit, invalid, submitFailed } = props

  return (
    <form
      onSubmit={handleSubmit(onFormSubmit)}
      className="flex-fill page-form well form-horizontal">
      <FormGroup label="Description" editable>
        <Field component={TextField} type="text" name="description" />
      </FormGroup>

      <FormGroup label="Title" editable>
        <Field component={TextField} type="text" name="title" />
      </FormGroup>

      <FormFooter loading={loading} showFailure={invalid && submitFailed} />
    </form>
  )
}

const ProviderSEOForm = reduxForm({
  form: 'providerSEOForm',
  enableReinitialize: true,
})(Form)

export default ProviderSEOForm
