import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { FormGroup } from './form'
import { fetchEntityAnalytics } from '../actions'

const AnalyticsDisplay = ({
  analytics: { current },
  entityType,
  entityId,
  fetchEntityAnalytics,
}) => {
  useEffect(() => {
    fetchEntityAnalytics({
      entity_type: entityType,
      entity_id: entityId,
    })
  }, [entityType, entityId, fetchEntityAnalytics])

  return (
    <div className="flex-fill page-form well form-horizontal">
      <FormGroup label="Total Views">
        <span>{current.totalViews !== null ? current.totalViews : ' - '}</span>
      </FormGroup>
    </div>
  )
}

const mapStateToProps = ({ analytics, ui }) => ({
  analytics: analytics,
  loading: ui.loading,
})

export default connect(
  mapStateToProps,
  { fetchEntityAnalytics }
)(AnalyticsDisplay)
