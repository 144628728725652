import React, {Component} from 'react'
import {Table} from '../../components/table'
import {Link} from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import {connect} from 'react-redux'
import {Checkmark} from '../../components/checkmark'
import {fetchItems} from '../../actions'
import moment from 'moment'

class BeautyBoardMembersIndex extends Component {

  componentDidMount() {
    this.props.fetchItems('beauty_board')
  }

  onPageChange = ({selected}) => {
    this.props.fetchItems('beauty_board', { _page: selected + 1 })
  }

  render() {
    const { beautyBoardMembers, loading } = this.props
    const tableHeaders = ['ID', 'Name', 'Active', 'Date Created', 'Order']

    return (
      <div className="primary-content">
        <div className="controls">
          <h4 className="pull-left">Beauty Board Members</h4>
          <Link className="pull-right btn btn-primary" to="/beauty-board/new">Add New Member</Link>
        </div>
        <Table items={beautyBoardMembers.all} loading={loading} headers={tableHeaders}>
          { beautyBoardMembers.all.map(member => {
              const m = {
                ...member,
                ...member.provider,
                id: member.id,
                is_active: member.is_active,
                date_created: member.date_created
              }
              return (
                <tr key={m.id}>
                  <td>{m.id}</td>
                  <td className="text-capitalize">
                    <Link to={`/beauty-board/${m.id}`}>
                      {m.salutation.toLowerCase() === 'none' ? null : m.salutation} {`${m.first_name} ${m.last_name}`}
                    </Link>
                  </td>
                  <td><Checkmark bool={!!m.is_active} /></td>
                  <td>{moment(m.date_created).format('MM-DD-YYYY - h:mm:ss A')}</td>
                  <td>{m.order}</td>
                </tr>
              )
            }
          )}
        </Table>
        <ReactPaginate
          pageCount={beautyBoardMembers.totalPages}
          marginPagesDisplayed={1}
          pageRangeDisplayed={5}
          onPageChange={this.onPageChange}
          containerClassName="pagination"
          activeClassName="active"
          pageClassName="page-item"
          previousClassName="page-item"
          nextClassName="page-item"
        />
      </div>
    )
  }
}


const mapStateToProps = ({beautyBoardMembers, ui}) => {
  return {
    beautyBoardMembers: beautyBoardMembers,
    loading: ui.loading
  }
}

export default connect(mapStateToProps, { fetchItems })(BeautyBoardMembersIndex)
