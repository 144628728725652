import React from 'react'
import { useParams } from 'react-router-dom'
import ProductForm from '../_form'
import './index.scss'

const ProviderProductsNew = props => {
  const { provider_id } = useParams()

  return (
    <div className="t__products-new">
      <h3>Create a new Product</h3>

      <ProductForm providerId={provider_id} />
    </div>
  )
}

export default ProviderProductsNew
