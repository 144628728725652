import { validateRequiredFields } from 'config/util'

export const validateFaq = values => {
  const requiredFields = [
    ['question', 100],
    ['answer', 1000],
  ]

  return validateRequiredFields(values, requiredFields)
}
