import React, {Component} from 'react'
import {Field, reduxForm} from 'redux-form'
import {
  FormGroup,
  TextField,
  RadioField,
  FormFooter,
  SelectField,
  TextArea
} from '../../components/form'
import {validateRequiredFields} from '../../config/util'
import {RADIO_OPTIONS} from '../../config/constants'
import moment from 'moment'

class ReferralCodeForm extends Component {

  render() {
    const {loading, handleSubmit, onFormSubmit, invalid, submitFailed, referral, programs} = this.props
    const editingRecord = referral && referral.date_created
    let programName = editingRecord ? programs.find(program => (program.id === referral.referral_program_id)) : ''

    return (
      <form onSubmit={handleSubmit(onFormSubmit)} className="flex-fill page-form well form-horizontal">
        <FormGroup label="Code Name" editable>
          <Field component={TextField} type="text" name="name" placeholder="Name" />
        </FormGroup>

        <FormGroup label="Code" editable={!editingRecord}>
          { !editingRecord ?
            <Field component={TextField} type="text" name="code" placeholder="Code" />
          :
            <span>{referral.name}</span>
          }
        </FormGroup>

        <FormGroup label="Blurb" editable>
          <Field
            component={TextArea}
            name="blurb"
            placeholder="Blurb"
          />
        </FormGroup>

        <FormGroup label="Program" editable={!editingRecord}>
          { !editingRecord ?
            <Field name="referral_program_id" component={SelectField}>
              {
                programs.map(p => (
                  <option value={p.id} key={p.id}>{p.name}</option>
                ))
              }
            </Field>
          :
            <span>
              { programName = programName ? programName.name : '' }
            </span>
          }
        </FormGroup>

        <FormGroup label="Is Active?" editable>
          <Field component={RadioField} name="is_active" items={RADIO_OPTIONS} />
        </FormGroup>

        {
          referral && referral.date_created &&
          <React.Fragment>
            <FormGroup label="Created By ID">
              <span>{referral.created_by_user_id}</span>
            </FormGroup>
            <FormGroup label="Date Created">
              <span>{moment(referral.date_created).format('MMMM DD, YYYY')}</span>
            </FormGroup>
          </React.Fragment>
        }

        <FormFooter loading={loading} showFailure={invalid && submitFailed} />
      </form>
    )
  }
}

const validate = values => {
  let errors = {}

  const fields = [
    ['name'],
    ['code'],
    ['is_active'],
    ['referral_program_id']
  ]

  if (values.code && values.code.length < 6) {
    errors.code = 'Must be at least 6 characters'
  }

  return { ...validateRequiredFields(values, fields), ...errors }
}

export default reduxForm({
  validate: validate,
  form: 'codeForm',
  enableReinitialize: true
})(ReferralCodeForm)
