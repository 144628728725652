import React, { useEffect } from 'react'
import { BEURO__Carousel } from '@aedit/styleguide/dist/state.js'

export const MorphCarousel = props => {
  const { files } = props

  const slide = data => {
    if (!data) {
      return null
    }
    return <img crossOrigin="Anonymous" src={data.url} alt="aedit-3d" />
  }

  const ImageIndicatorList = ({ activeSlideIndex, handleIndicatorClick }) => {
    return files.map((slide, idx) => (
      <img
        crossOrigin="Anonymous"
        key={slide.url}
        className={`indicator ${idx === activeSlideIndex ? '--active' : ''}`}
        onClick={() => handleIndicatorClick(idx)}
        src={slide.url}
      />
    ))
  }

  useEffect(() => {
    const handleKeyboard = e => {
      switch (e.key) {
        case 'ArrowLeft':
          document.getElementsByClassName('carousel-prev')[0].click()
          break
        case 'ArrowRight':
          document.getElementsByClassName('carousel-next')[0].click()
          break
      }
    }

    document.addEventListener('keydown', handleKeyboard)

    return () => {
      document.removeEventListener('keydown', handleKeyboard)
    }
  }, [])

  return (
    <BEURO__Carousel slides={files}>
      <BEURO__Carousel.Slider className="carousel-3d" renderSlide={slide} />
      <BEURO__Carousel.Indicators
        className="carousel-3d--indicators"
        renderIndicators={ImageIndicatorList}>
        <BEURO__Carousel.Prev className="carousel-3d--prev" />
        <BEURO__Carousel.Next className="carousel-3d--next" />
      </BEURO__Carousel.Indicators>
    </BEURO__Carousel>
  )
}
