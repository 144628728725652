import React from 'react'
import FileUpload from 'components/form/index.js'
import { AeditField } from '@aedit/styleguide/dist/form.js'
import { FieldArray } from 'formik'

const BeforeAfterForm = () => {
  return (
    <div className="o__ba-modal">
      <div className="mt-2 mb-1">
        <strong className="mt-3">Before Image</strong>
      </div>
      <FieldArray
        name="before_image"
        render={fieldArrayHelpers => (
          <FileUpload
            name="before_image"
            accept=".jpg"
            uploadText="Upload Before Image"
            maxFileSizeInMb={2}
            allowPreview
            fieldArrayHelpers={fieldArrayHelpers}>
            {({ index }) => (
              <div className="editable-content">
                <section>
                  <AeditField.Input type="text" name="candidate_name" required label="Name" />
                  <AeditField.Input type="number" name="candidate_age" required label="Age" />
                  <AeditField.Select
                    name="candidate_gender"
                    required
                    className="margin-override"
                    label="Gender"
                    options={[
                      { label: 'Choose gender', value: '' },
                      { label: 'Male', value: 'male' },
                      { label: 'Female', value: 'female' },
                      { label: 'Non-binary', value: 'non_binary' },
                    ]}
                  />
                </section>
                <section>
                  <AeditField.Input
                    name="caption"
                    type="textarea"
                    className="full-width"
                    rows={6}
                    label="Caption"
                    required
                  />
                </section>
              </div>
            )}
          </FileUpload>
        )}
      />

      <div className="mt-3 mb-1">
        <strong>After Images</strong> <br />
        <span style={{ fontSize: 14, marginTop: 3, color: '#666' }}>
          max of 10 - sorted by how long after the procedure it occurred
        </span>
      </div>
      <FieldArray
        name="after_images"
        render={fieldArrayHelpers => (
          <FileUpload
            name="after_images"
            accept=".jpg"
            uploadText="Upload After Images"
            maxFileSizeInMb={2}
            maxNumOfFiles={10}
            allowPreview
            fieldArrayHelpers={fieldArrayHelpers}>
            {({ index }) => (
              <div className="editable-content">
                <section>
                  <AeditField.Input
                    name={`after_images[${index}].meta.after_count`}
                    type="number"
                    label="After Count"
                    required
                  />

                  <AeditField.Select
                    name={`after_images[${index}].meta.after_length_of_time`}
                    label="After length of time"
                    className="margin-override"
                    options={[
                      { label: 'Choose unit of time', value: '' },
                      { label: 'Days', value: 'days' },
                      { label: 'Weeks', value: 'weeks' },
                      { label: 'Months', value: 'months' },
                      { label: 'Years', value: 'years' },
                    ]}
                    required
                  />

                  <AeditField.Input
                    name={`after_images[${index}].meta.order`}
                    type="number"
                    className="ml-1"
                    label="Display order (in admin)"
                    required
                  />
                </section>

                <section>
                  <AeditField.Input
                    name={`after_images[${index}].meta.caption`}
                    type="textarea"
                    rows={6}
                    label="Image Caption"
                    className="full-width"
                    required
                  />

                  <AeditField.Input
                    name={`after_images[${index}].meta.alt_caption`}
                    type="textarea"
                    rows={6}
                    className="full-width"
                    label="Alt Timeline Caption"
                  />
                </section>
              </div>
            )}
          </FileUpload>
        )}
      />
    </div>
  )
}

export default BeforeAfterForm
