import { authedRequest } from '../config/config'
import dayjs from 'dayjs'

export const updateProviderPractices = (id, data = {}) => async dispatch => {
  dispatch({ type: 'LOADING' })
  try {
    const res = await authedRequest.put(`/provider/${id}/practice`, data)

    if (res.data.error) {
      throw new Error(res.data.error.message)
    } else {
      dispatch({ type: 'UPDATED_PROVIDER', payload: res.data.data })
      dispatch({ type: 'POST_SUCCESS' })
    }
  } catch (err) {
    dispatch({ type: 'FAILURE', payload: err })
  }
}

export const updateProviderPrimaryPractice = (id, data = {}) => async dispatch => {
  dispatch({ type: 'LOADING' })
  try {
    const res = await authedRequest.post(`/provider/${id}/practice/primary`, data)

    if (res.data.error) {
      throw new Error(res.data.error.message)
    } else {
      dispatch({ type: 'UPDATED_PROVIDER', payload: res.data.data })
      dispatch({ type: 'POST_SUCCESS' })
    }
  } catch (err) {
    dispatch({ type: 'FAILURE', payload: err })
  }
}

export const fetchProviderAvailabilityPeriods = provider_id => async dispatch => {
  dispatch({ type: 'LOADING' })
  try {
    const fetch = async (from, to) => {
      const queryString = query => new URLSearchParams(Object.entries(query)).toString()

      const query = {
        provider_id,
        from_date: from.toISOString(),
        to_date: to.toISOString(),
        appointment_length_minutes: 30,
        response_format: 'slots',
      }

      const res = await authedRequest.get(`/provider/calendar/availability?${queryString(query)}`)
      if (res.data.error) {
        throw res.data.error.message
      } else {
        return res.data.data
      }
    }

    // cronofy supports only 200 days ahead
    const from = dayjs()
    const to = from.add(200, 'day').subtract(1, 'second')

    // split into multiple max-30-days-long chunks
    let prev = from
    const tasks = []
    while (true) {
      if (prev.isAfter(to) || prev.isSame(to)) {
        break
      }

      let next = prev.add(30, 'day')
      next = dayjs(Math.min(next, to))

      const t = fetch(prev, next)
      tasks.push(t)

      prev = next
    }

    const results = await Promise.all(tasks)
    const periods = results.flat().map(({ start, end }) => ({
      start: dayjs(start),
      end: dayjs(end),
    }))

    dispatch({ type: `FETCHED_PROVIDER_AVAILABILITY_PERIODS_ALL`, payload: periods })
    dispatch({ type: 'FETCH_SUCCESS' })

    return periods
  } catch (err) {
    dispatch({ type: 'FAILURE', payload: err })
  }
}

export const registerProviderCalendar = (provider_id, data) => async dispatch => {
  dispatch({ type: 'LOADING' })
  try {
    const config = {
      params: {
        provider_id,
      },
    }
    const res = await authedRequest.post(`/provider/calendar/register`, data, config)
    const { data: result, error } = res.data
    if (error) {
      throw new Error(error.message)
    }

    dispatch({ type: `CREATED_PROVIDER_CALENDAR`, payload: result })
    dispatch({ type: 'POST_SUCCESS' })
  } catch (err) {
    dispatch({ type: 'FAILURE', payload: err })
  }
}

export const deregisterProviderCalendar = (provider_id, revoke) => async dispatch => {
  dispatch({ type: 'LOADING' })
  try {
    const config = {
      params: {
        provider_id,
        revoke: +revoke,
      },
    }
    const res = await authedRequest.delete(`/provider/calendar`, config)
    const { data: result, error } = res.data
    if (error) {
      throw new Error(error.message)
    }

    dispatch({ type: `DELETED_PROVIDER_CALENDAR`, payload: result })
    dispatch({ type: 'DELETE_SUCCESS' })
  } catch (err) {
    dispatch({ type: 'FAILURE', payload: err })
  }
}
