import React from 'react'
import { formatCardName } from 'config/util/payment'
import './card.scss'

const Card = props => {
  const { card, setDefaultCard, setCardToRemove, loading, editable } = props
  const { is_default } = card

  const actions = [
    { label: 'Default', color: '#ab9889', onClick: setDefaultCard, disabled: is_default },
    { label: 'Remove', color: 'lightcoral', onClick: setCardToRemove },
  ].filter(Boolean)

  return (
    <>
      <span>{formatCardName(card)}</span>

      {editable && actions.map(({ label, color, onClick, disabled }, i) => (
          <span
            key={`card-action-${i}`}
            className={`ml-2 credit-card__action ${disabled ? 'credit-card__action--disabled' : ''}`}
            style={{ color: disabled ? 'lightgray' : color }}
            onClick={e => {
              e.preventDefault()
              if (!loading && !disabled && editable) {
                onClick(card)
              }
            }}>
          {label}
        </span>
      ))}
    </>
  )
}

export default Card
