const defaultState = {
  all: [],
  current: {},
  count: 0,
  totalPages: 0,
  procedures: {
    all: [],
    count: 0
  }
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case 'FETCHED_CONCERN_ALL':
      if (action.payload.meta.paging) {
        const { total_results, total_pages } = action.payload.meta.paging
        return {
          ...state,
          all: action.payload.data,
          count: total_results,
          totalPages: total_pages
        }
      } else { // no results
        return { ...state, ...defaultState }
      }
    case 'FETCHED_CONCERN':
      return {
        ...state,
        current: action.payload
      }
    case 'UPDATED_CONCERN':
      return {
        ...state,
        current: action.payload
      }
    case 'CREATED_CONCERN':
      return {
        ...state,
        current: action.payload
      }
    case 'FETCHED_CONCERN_PROCEDURES':
      return {
        ...state,
        procedures: {
          all: action.payload.data || [],
          count: action.payload.meta.items || 0
        }
      }
    default:
      return state
  }
}
