import moment from 'moment'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchItems } from '../../actions'
import { Checkmark } from '../../components/checkmark'
import { Table } from '../../components/table'

class PlansIndex extends Component {

  componentDidMount() {
    this.props.fetchItems('plan')
  }

  render() {
    const { loading, plans } = this.props
    const tableHeaders = [
      'ID',
      'Name',
      'Active Providers',
      'Annual Cost',
      'Monthly Cost',
      'Active',
      'Date Created',
      'Date Updated',
    ]

    return (
      <div className="primary-content">
        <div className="controls">
          <Link to="/plans/new" className="pull-right btn btn-primary">Add New Plan</Link>
        </div>
        <Table items={plans.all} headers={tableHeaders} loading={loading}>
          {plans.all.map(plan => (
            <tr key={plan.id}>
              <td>{plan.id}</td>
              <td><Link to={`/plans/${plan.id}`}>{plan.name}</Link></td>
              <td>{plan.total_providers}</td>
              <td>{plan.annual_cost}</td>
              <td>{plan.monthly_cost}</td>
              <td><Checkmark bool={!!plan.is_active} /></td>
              <td>{moment(plan.date_created).format('MM-DD-YYYY - h:mm:ss A')}</td>
              <td>{moment(plan.date_updated).format('MM-DD-YYYY - h:mm:ss A')}</td>
            </tr>
          ))}
        </Table>
      </div>
    )
  }
}

const mapStateToProps = ({ ui, plans }) => (
  {
    loading: ui.loading,
    plans,
  }
)

export default connect(mapStateToProps, { fetchItems })(PlansIndex)
