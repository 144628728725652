import React, {Component} from 'react'
import {connect} from 'react-redux'
import CampusForm from './_campus_form'
import {createItem} from '../../actions'

class CampusNew extends Component {

  onFormSubmit = (values) => {
    this.props.createItem('campus', values)
  }

  componentWillReceiveProps(nextProps) {
    const currentId = this.props.campus.id
    const nextId = nextProps.campus.id

    if (nextId && nextId !== currentId) {
      this.props.history.push(`/campus`)
    }
  }

  render() {
    return (
      <div className="primary-content">
        <div className="controls">
          <h4>Add a New University</h4>
        </div>
        <CampusForm
          onFormSubmit={this.onFormSubmit}
          loading={this.props.loading}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ ui, campuses }) => {
  return {
    loading: ui.loading,
    campus: campuses.current
  }
}

export default connect(mapStateToProps, { createItem })(CampusNew)
