const initialState = {
  codes: [],
  currentCode: {},
  programs: [],
  currentProgram: {},
  totalPages: 0,
  count: 0
}

export default function(state = initialState, action) {

  const {type, payload} = action

  switch(type) {
    case 'FETCHED_REFERRAL_ALL':
      if (payload.meta.paging) {
        const { total_results, total_pages } = payload.meta.paging
        return {
          ...state,
          codes: payload.data,
          count: total_results,
          totalPages: total_pages
        }
      } else {
        return { ...state, ...initialState }
      }
    case 'FETCHED_REFERRAL':
      return {
        ...state,
        currentCode: payload
      }
    case 'CREATED_REFERRAL':
      return {
        ...state,
        currentCode: payload
      }
    case 'UPDATED_REFERRAL':
      return {
        ...state,
        currentCode: payload
      }
    case 'FETCHED_PROGRAM_ALL':
      if (payload.meta.paging) {
        const { total_results, total_pages } = payload.meta.paging
        return {
          ...state,
          programs: payload.data,
          count: total_results,
          totalPages: total_pages
        }
      } else {
        return { ...state, ...initialState }
      }
    case 'FETCHED_PROGRAM':
      return {
        ...state,
        currentProgram: payload
      }
    case 'CREATED_PROGRAM':
      return {
        ...state,
        currentProgram: payload
      }
    case 'UPDATED_PROGRAM':
      return {
        ...state,
        currentCode: payload
      }
    default:
      return state
  }
}
