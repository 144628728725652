import {authedRequest} from '../config/config'
import axios from 'axios'

export const fetchCurrentUser = () =>
  async dispatch => {
    try {
      const res = await authedRequest.get('/user/me')

      if (res.data.error) {
        throw new Error(res.data.error.message)
      } else {
        dispatch({ type: 'FETCHED_CURRENT_USER', payload: res.data.data })
        dispatch(authenticate(localStorage.getItem('access_token')))
      }
    } catch(err) {
      dispatch(authenticate(null))
    }
  }

export const login = (token) =>
  async dispatch => {
    try {
      const res = await axios.post('/authenticate/user/google', { token })
      const { error, data } = res.data

      if (data.user && data.user.role_name !== 'admin') {
        throw new Error('Must be an Aedit admin.')
      }

      if (error) {
        throw new Error(error.message)
      } else {
        const accessToken = data.access_token.access_token
        localStorage.setItem('access_token', accessToken)
        // immediately authenticate as well, causing a re-render after action dispatch
        dispatch(authenticate(accessToken))
      }
    } catch(err) {
      dispatch({ type: 'FAILURE', payload: err })
    }
  }

export const logout = () =>
  dispatch => {
    localStorage.removeItem('access_token')
    dispatch(authenticate(null))
  }

export const authenticate = (accessToken = null) =>
  dispatch => {
    if (accessToken) {
      dispatch({ type: 'AUTH_CHANGE', authed: true })
    } else {
      dispatch({ type: 'AUTH_CHANGE', authed: false })
    }
  }
