import React from 'react'
import { Table as TableContainer } from 'components/table'
import { Checkmark } from '../../../components/checkmark'

const Table = props => {
  const { items, loading, edit } = props
  return (
    <TableContainer
      items={items}
      loading={loading}
      headers={['Type', 'Channel', 'Active?']}>
      {items.map((item, i) => (
        <Row
          key={i}
          item={item}
          edit={edit}
        />
      ))}
    </TableContainer>
  )
}

const Row = ({ item, edit }) => {
  return (
    <tr>
      <td>{item.type}</td>
      <td>{item.channel}</td>
      <td
        onClick={() => edit({ ...item, value: !item.value })}
        style={{ cursor: 'pointer' }}
        data-tooltip={`Turn notification ${item.value ? 'off' : 'on'}`}
        className="tooltip tooltip-left">
        <Checkmark key={item.value} bool={!!item.value} />
      </td>
    </tr>
  )
}

export default Table
