const defaultState = {
  all: [],
  current: {},
  count: 0,
  totalPages: 0
}

export default function(state = defaultState, action) {
  switch(action.type) {
    case 'FETCHED_PAGE_ALL':
      // more than 0 results
      if (action.payload.meta.paging) {
        const { total_results, total_pages } = action.payload.meta.paging
        return {
          ...state,
          all: action.payload.data,
          count: total_results,
          totalPages: total_pages
        }
      } else { // no results
        return { ...state, ...defaultState }
      }
    case 'FETCHED_PAGE':
      return {
        ...state,
        current: action.payload
      }
    case 'UPDATED_PAGE':
      return {
        ...state,
        current: action.payload
      }
    case 'CREATED_PAGE':
      return {
        ...state,
        current: action.payload
      }
    default:
      return state
  }
}
