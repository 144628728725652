import moment from 'moment'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { FormFooter, FormGroup, RadioField, TextArea, TextField } from '../../components/form'
import { RADIO_OPTIONS } from '../../config/constants'
import { digitsOnly, setOnBeforeUnload, validateRequiredFields } from '../../config/util'

class PlanForm extends Component {

  componentDidUpdate() {
    setOnBeforeUnload(this.props.dirty)
  }

  componentWillUnmount() {
    setOnBeforeUnload(false)
  }

  render() {
    const { initialValues, loading, handleSubmit, onFormSubmit, submitFailed, invalid } = this.props

    return (
      <form onSubmit={handleSubmit(onFormSubmit)} className="flex-fill page-form well form-horizontal">

        <FormGroup label="Name" editable>
          <Field name="name" component={TextField} />
        </FormGroup>

        <FormGroup label="Description" editable>
          <Field name="description" component={TextArea} />
        </FormGroup>

        <FormGroup label="Annual Cost" editable>
          <Field name="annual_cost" component={TextField} parse={digitsOnly} type="number" />
        </FormGroup>

        <FormGroup label="Monthly Cost" editable>
          <Field name="monthly_cost" component={TextField} parse={digitsOnly} type="number" />
        </FormGroup>

        <FormGroup label="Active?" editable>
          <Field name="is_active" items={RADIO_OPTIONS} component={RadioField} />
        </FormGroup>

        {initialValues && initialValues.date_created && (
          <>
            <FormGroup label="# of Providers">
              <span>{initialValues.total_providers}</span>
            </FormGroup>
            <FormGroup label="Created At">
              <span>{moment(initialValues.date_created).format('MM-DD-YYYY - h:mm:ss A')}</span>
            </FormGroup>
            <FormGroup label="Updated At">
              <span>{moment(initialValues.date_updated).format('MM-DD-YYYY - h:mm:ss A')}</span>
            </FormGroup>
          </>
        )}

        <FormFooter loading={loading} showFailure={invalid && submitFailed} />
      </form>
    )
  }

}

const validate = (values) => {
  const errors = {}
  const requiredFields = [
    ['name'],
    ['description'],
    ['annual_cost', 6],
    ['monthly_cost', 6],
    ['is_active'],
  ]

  return {
    ...validateRequiredFields(values, requiredFields),
    ...errors,
  }
}

PlanForm = reduxForm({
  validate,
  form: 'planForm',
  enableReinitialize: true,
})(PlanForm)

export default connect(null)(PlanForm)
