import React, { Component } from 'react'
import { reduxForm } from 'redux-form'
import {
    FormGroup,
} from '../../components/form'
import { validateRequiredFields } from '../../config/util'
import dayjs from 'dayjs'
import { authedRequest } from '../../config/config'
import { toDollars } from '../../config/util'

class ConsultationVoucher extends Component {
    constructor(props) {
        super(props)

        this.state = {
            initalized: false,
            voucher: '',
        }
    }

    componentDidMount = async () => {
        const res = await authedRequest.get(`voucher/search?consultation_id=${this.props.consultation.id}`)
        const voucher = res.data.data[0]
        this.setState({
            initalized: true,
            voucher
        })
    }

    render() {
        const { voucher } = this.state

        const validVoucher = voucher ? voucher : null

        // expiration dates of voucherify vouchers were stored in meta.voucher.expiration_date
        const expirationDate = voucher.meta?.voucher?.expiration_date ?? voucher.expiration_date

        return (
            <form
                className="flex-fill page-form well form-horizontal">
                <>
                    <FormGroup label="Voucher ID">
                        {validVoucher && <span name="voucher_id">{voucher.id}</span>}
                    </FormGroup>

                    <FormGroup label="External Source">
                        {validVoucher && <span name="voucher_external_source">{voucher.external_source}</span>}
                    </FormGroup>

                    <FormGroup label="Amount">
                        {validVoucher && <span name="voucher_amount">{toDollars(voucher.amount)}</span>}
                    </FormGroup>

                    <FormGroup label="Voucher Barcode">
                        {(validVoucher && Boolean(voucher.meta?.voucher?.assets?.barcode)) ?
                            <img src={voucher.meta?.voucher?.assets?.barcode?.url} />
                            : <span name="voucher_barcode">'N/A'</span>
                        }
                    </FormGroup>

                    <FormGroup label="Voucher Code">
                        {validVoucher && <span name="voucher_code">{Boolean(voucher.meta?.voucher?.assets?.barcode) ? voucher.meta?.voucher?.code : 'N/A'}</span>}
                    </FormGroup>

                    <FormGroup label="Voucher Category">
                        {validVoucher && <span name="voucher_category">{voucher.meta?.voucher?.category}</span>}
                    </FormGroup>

                    <FormGroup label="Voucher Expiration">
                        {validVoucher && Boolean(expirationDate) && (
                          <span name="voucher_expiration">{dayjs(expirationDate).format('YYYY-MM-DD h:mm:ss A')}</span>
                        )}
                    </FormGroup>

                    <FormGroup label="Active?">
                        {validVoucher && <span name="is_active">{Boolean(voucher.is_active) ? 'Yes' : 'No'}</span>}
                    </FormGroup>

                    <FormGroup label="Redeemed?">
                        {validVoucher && <span name="is_redeemed">{Boolean(voucher.is_redeemed) ? 'Yes' : 'No'}</span>}
                    </FormGroup>

                    <FormGroup label="Date Redeemed">
                        {validVoucher && <span name="date_redeemed">{Boolean(voucher.date_redeemed) ? dayjs(voucher.date_redeemed).format('YYYY-MM-DD') : 'N/A'}</span>}
                    </FormGroup>
                </>
            </form>
        )
    }
}

const validate = values => {
    const requiredFields = [
        ['voucher_id'],
        ['voucher_external_source'],
        ['voucher_amount'],
        ['voucher_category'],
        ['voucher_expiration'],
        ['is_active'],
        ['is_redeemed']
    ]

    return { ...validateRequiredFields(values, requiredFields) }
}

export default reduxForm({
    validate: validate,
    form: 'consultationVoucher',
    enableReinitialize: true,
})(ConsultationVoucher)
