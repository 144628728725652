import { useState, useMemo, useRef, useCallback, useEffect } from 'react'
import algoliasearch, { SearchIndex } from 'algoliasearch'
import { authedRequest } from 'config/config'
import { toastr } from 'react-redux-toastr'
import _ from 'lodash'
import { noop } from 'config/util/abstract'
import { rawFetchItems } from 'actions'

export const useApi = (initialValue, baseClient = authedRequest) => {
  const [loading, setLoading] = useState(initialValue)

  const api = useMemo(() => {
    const handlerFactory = method => async (url, ...params) => {
      try {
        await setLoading(true)
        const { data } = await baseClient[method](url, ...params)
        if (data.error) {
          throw new Error(data.error.message)
        }
        return data.data
      } catch (e) {
        toastr.error(e.message)
      } finally {
        await setLoading(false)
      }
    }

    const client = {}
    for (const method of ['get', 'post', 'put', 'delete']) {
      client[method] = handlerFactory(method)
    }
    return client
  }, [baseClient])

  return [api, loading, setLoading]
}

export const useDebouncedCallback = (callback, params = {}) => {
  const { delay, opts, deps } = params
  const callbackRef = useRef()
  callbackRef.current = callback;
  const debounced = _.debounce(
    (...args) => callbackRef.current(...args),
    delay,
    opts
  )
  return useCallback(debounced, deps ?? []);
}

export const useSearchTerm = (type, params = {}) => {
  const { initialItems = [], initialTerm = '', setLoading = noop } = params

  const [items, setItems] = useState(initialItems)
  const [term, setTerm] = useState(initialTerm)

  const fetchItems = useDebouncedCallback(rawFetchItems, { delay: 100 })

  useEffect(() => {
    const handleUpdatedTerm = async () => {
      try {
        setLoading(true)

        const newItems = term
          ? await fetchItems(type, { is_active: 1, searching: true, term })
          : await rawFetchItems(type, { is_active: 1 })

        if (newItems) {
          setItems(newItems)
        }

        setLoading(false)
      } catch (e) {
        toastr.error(e.message)
      }
    }

    handleUpdatedTerm()
  }, [term])

  return [items, setTerm]
}

/**
 * @param {string|SearchIndex} indexOrIndexName
 *  If this is a string, then we create a new algolia client for you and connect it to an index with
 *  the specified name. (The implication is that the request/response cache in the client is bound
 *  to the lifetime of the client, i.e. when you navigate away from the current page and then back,
 *  then the client cache would be empty.)
 *
 *  If this is a SearchIndex then you're in control of the client cache, i.e. it is not cleared
 *  automatically.
 */
export const useAlgoliaIndex = indexOrIndexName => {
  const index = useRef()

  useEffect(() => {
    if (!index.current) {
      if (typeof indexOrIndexName === 'string') {
        const algolia = algoliasearch(
          process.env.REACT_APP_ALGOLIA_APP_ID,
          process.env.REACT_APP_ALGOLIA_API_KEY
        )
        index.current = algolia.initIndex(indexOrIndexName)
      } else {
        index.current = indexOrIndexName
      }
    }
  }, [indexOrIndexName])

  return index
}

export const useAlgoliaSearchTerm = (indexOrIndexName, params = {}, deps = []) => {
  const {
    initialItems = [],
    initialTerm = '',
    setLoading = noop,
    debounce = true,
    searchOptions = {},
    // When false, then we are using the Search Algolia endpoint. It is faster. It has full
    // support for filtering. It supports retrieving only the first 1000 items.
    // When true, then we are using the Browse Algolia endpoint. It is slower. If you have
    // active filter then the paging metadata are not returned. It supports retrieving the first
    // 10000 items.
    browse = false,
    sortBy,
  } = params

  const index = useAlgoliaIndex(indexOrIndexName)
  const [term, setTerm] = useState(initialTerm)
  const [results, setResults] = useState({ hits: initialItems })

  const search = async args => {
    try {
      setLoading(true)

      const searchFunc = browse ? _browseIndex : _searchIndex
      const results = await searchFunc(index.current, args)

      const newHits = [...results.hits]
      if (sortBy) {
        newHits.sort((a, b) => a[sortBy] - b[sortBy])
      }

      // algolia supports browsing only the first 1000 when searching
      // and the first 10000 items when browsing
      const maxItems = browse ? 10000 : 1000
      const maxPages = maxItems / args.hitsPerPage
      const nbPages = Math.min(results.nbPages || 1, maxPages)

      const newResults = { ...results, hits: newHits, nbPages }
      setResults(newResults)

      setLoading(false)
    } catch (e) {
      toastr.error(e.message)
    }
  }

  const debouncedSearchItems = useDebouncedCallback(search, { delay: 100, deps: [sortBy] })

  useEffect(() => {
    const searchItems = debounce ? debouncedSearchItems : search
    searchItems({
      query: term || '',
      page: 0,
      hitsPerPage: 100,
      ...searchOptions,
    })
  }, [term, searchOptions?.page, ...deps])

  return [results, setTerm]
}

const _browseIndex = async (index, searchOptions) => {
  // this method fetches only one page at a time
  // https://github.com/algolia/algoliasearch-client-javascript/issues/991

  let results

  await index.browseObjects({
    ...searchOptions,
    shouldStop: res => {
      results = res
      return true
    },
  })

  return results
}

const _searchIndex = async (index, searchOptions) => {
  const term = searchOptions.query || ''
  return await index.search(term, searchOptions)
}
