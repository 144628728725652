import React, { useState } from 'react'
import ReactPaginate from 'react-paginate'
import UsersTable from './_users_table'
import { Link } from 'react-router-dom'
import { useAlgoliaSearchTerm } from 'config/hooks'

const usersIndex = process.env.REACT_APP_ALGOLIA_INDEX_USERS

const UsersSearch = () => {
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(0)
  const [users, _setSearchTerm] = useAlgoliaSearchTerm(usersIndex, {
    setLoading,
    searchOptions: {
      page: currentPage,
    },
  })

  const setSearchTerm = value => {
    _setSearchTerm(value)
    setCurrentPage(0)
  }

  const onPageChange = ({ selected }) => setCurrentPage(selected)

  return (
    <div className="primary-content">
      <div className="controls">
        <Link className="pull-right btn btn-primary" to="/users/new">
          Add New User
        </Link>
        <div className="form-group pull-right mr-2">
          <span className="form-switch mr-2">
            Total users: {users.nbHits}
          </span>
        </div>
        <div className="input-group input-inline">
          <div className="has-icon-left">
            <input
              className="form-input"
              type="text"
              placeholder="Search"
              onChange={e => setSearchTerm(e.target.value)}
            />
            <i className="form-icon icon icon-search" />
          </div>
        </div>
      </div>
      <UsersTable users={users.hits} loading={loading} />
      <ReactPaginate
        pageCount={users.nbPages}
        marginPagesDisplayed={1}
        pageRangeDisplayed={5}
        onPageChange={onPageChange}
        containerClassName="pagination"
        activeClassName="active"
        pageClassName="page-item"
        previousClassName="page-item"
        nextClassName="page-item"
      />
    </div>
  )
}

export default UsersSearch
