import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { createItem } from '../../actions'
import NewProfileForm from './_new_profile_form'

const UsersNew = () => {
  const loading = useSelector(state => state.ui.loading)

  const dispatch = useDispatch()
  const history = useHistory()

  const onFormSubmit = userData => {
    if (userData.phone === '') {
      userData.phone = null
    }
    dispatch(createItem('user', userData)).then(res => {
      res?.user?.id && history.push(`/users/${res?.user?.id}`)
    })
  }

  return (
    <div className="primary-content">
      <div className="controls">
        <h4>Add a New User</h4>
      </div>
      <NewProfileForm
        handleFormSubmit={onFormSubmit}
        loading={loading}
        initialValues={{
          phone_country: 'US',
        }}
      />
    </div>
  )
}

export default UsersNew
