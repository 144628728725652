const defaultState = {
  all: [],
  count: 0
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case 'FETCHED_SYNONYM_ALL':
      // more than 0 results
      if (action.payload) {
        return {
          ...state,
          all: action.payload.hits,
          count: action.payload.nbHits
        }
      } else { // no results
        return { ...state, ...defaultState }
      }
    case 'UPDATED_SYNONYM':
      let originalSynonym = state.all.find(obj => obj.objectID === action.payload.objectID)
      originalSynonym.synonyms = action.payload.synonyms
      return {
        ...state,
        all: [...state.all]
      }
    case 'CREATED_SYNONYM':
      return {
        ...state,
        all: [...state.all, action.payload],
        count: state.all.length + 1
      }
    case 'DELETED_SYNONYM':
      const deleteSynonym = state.all.filter((obj) => {
        return obj.objectID !== action.payload
      })
      return {
        ...state,
        all: deleteSynonym,
        count: state.all.length - 1
      }
    case 'DELETED_SYNONYM_ALL':
      return { ...defaultState }
    default:
      return state
  }
}
