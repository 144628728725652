import React, { useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import createVideoForm from './_video_form'
import { fetchItem, fetchMedia } from '../../actions'

const VideoForm = createVideoForm('editProcedureVideoForm')

const ProceduresVideoShow = props => {
  let {
    procedure,
    video,
    fetchItem,
    fetchMedia,
    match: {
      params: { id: procedureId },
    },
  } = props

  const { meta: { candidate_name, candidate_age } = {} } = video || {}

  const fetchVideos = useCallback(async () => {
    await fetchMedia({
      entity_type: 'procedure',
      entity_id: procedureId,
      category: 'procedure_before_after',
    })
  }, [fetchMedia, procedureId])

  useEffect(() => {
    fetchItem('procedure', procedureId)
    fetchVideos()
  }, [fetchItem, procedureId, fetchVideos])

  return (
    <div className="primary-content">
      <div className="controls">
        <h4>{[procedure.name, candidate_name, candidate_age].filter(Boolean).join(', ')}</h4>
      </div>
      <VideoForm
        procedure={procedure}
        procedureId={procedureId}
        initialValues={video}
        onSubmitSucceeded={fetchVideos}
        className="flex-fill page-form well form-horizontal"
      />
    </div>
  )
}

const mapStateToProps = (
  { media, procedures },
  {
    match: {
      params: { videoId },
    },
  }
) => {
  return {
    procedure: procedures.currentProcedure,
    video: media.all.filter(m => +m.id === +videoId)[0],
  }
}

export default connect(mapStateToProps, {
  fetchItem,
  fetchMedia,
})(ProceduresVideoShow)
