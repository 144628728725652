import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import ReactPaginate from 'react-paginate'
import {
  fetchItems,
  fetchMediaByCategory,
  updateItem,
  addTrainingStatus,
  deleteTrainingStatus,
} from 'actions'
import ImageList from 'components/image_list'

const AeditFailedIndex = ({
  all,
  count,
  totalPages,
  config,
  fetchItems,
  fetchMediaByCategory,
  updateItem,
  addTrainingStatus,
  deleteTrainingStatus,
}) => {
  useEffect(() => {
    fetchMediaByCategory('face_detection_failure', {
      _limit: 50,
      order_by: 'date_created',
      order_by_direction: 'desc',
    })
    fetchItems('config')
  }, [fetchItems, fetchMediaByCategory])

  const onPageChange = ({ selected }) => {
    fetchMediaByCategory('face_detection_failure', {
      _page: selected + 1,
      _limit: 50,
      order_by: 'date_created',
      order_by_direction: 'desc',
    })
  }

  const onSubmit = values => {
    updateItem('media', values)
  }

  return (
    <div className="primary-content">
      <div className="controls">
        <h4>Failed Facial Detection Images ({count})</h4>
      </div>

      <ImageList
        items={all}
        count={count}
        flagged={all}
        flaggedReasons={config}
        onSubmit={onSubmit}
        onTrainingStatusSubmit={addTrainingStatus}
        onTrainingStatusDelete={deleteTrainingStatus}
      />

      <ReactPaginate
        pageCount={totalPages}
        marginPagesDisplayed={1}
        pageRangeDisplayed={5}
        onPageChange={onPageChange}
        containerClassName="pagination"
        activeClassName="active"
        pageClassName="page-item"
        previousClassName="page-item"
        nextClassName="page-item"
      />
    </div>
  )
}

const mapStateToProps = ({ media, config }) => {
  return {
    all: media.all,
    count: media.count,
    totalPages: media.totalPages,
    config: Object.values(config.media.FLAG_REASONS).sort((a, b) => a.localeCompare(b)),
  }
}

export default connect(
  mapStateToProps,
  {
    fetchItems,
    fetchMediaByCategory,
    updateItem,
    addTrainingStatus,
    deleteTrainingStatus,
  }
)(AeditFailedIndex)
