const defaultState = {
  all: [],
  current: {},
  consultations: [],
  count: 0,
  totalPages: 0,
  currentPage: 0,
}

export default function (state = defaultState, action) {
  switch (action.type) {
    case 'FETCHED_USER_ALL':
      let newState

      if (action.payload.meta.paging) {
        const { total_results, page, total_pages } = action.payload.meta.paging
        newState = {
          ...state,
          all: action.payload.data,
          count: total_results,
          totalPages: total_pages,
          currentPage: page - 1,
        }
      } else {
        newState = { ...state, ...defaultState }
      }

      return newState
    case 'FETCHED_USER':
      return {
        ...state,
        current: action.payload,
      }
    case 'UPDATED_USER':
      return {
        ...state,
        current: action.payload,
      }
    case 'CREATED_USER':
      return {
        ...state,
        current: action.payload,
      }
    case 'FETCHED_USER_CONSULTATIONS':
      return {
        ...state,
        consultations: action.payload,
      }
    default:
      return state
  }
}
