const defaultState = {
  all: [],
  current: {},
  currentPlan: {},
  currentFlags: [],
  currentFlag: {},
  count: 0,
  totalPages: 0,
  currentProcedures: [],
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case 'FETCHED_PROVIDER_ALL':
      // more than 0 results
      if (action.payload.meta.paging) {
        const { total_results, total_pages } = action.payload.meta.paging
        return {
          ...state,
          all: action.payload.data,
          count: total_results,
          totalPages: total_pages
        }
      } else { // no results
        return { ...state, ...defaultState }
      }
    case 'FETCHED_PROVIDER':
      return {
        ...state,
        current: action.payload
      }
    case 'UPDATED_PROVIDER':
      return {
        ...state,
        current: action.payload
      }
    case 'CREATED_PROVIDER':
      return {
        ...state,
        current: action.payload.provider
      }

    case 'FETCHED_PROVIDER_PROCEDURES':
      if (!Array.isArray(action.payload)) {
        return state
      }
      return {
        ...state,
        currentProcedures: action.payload,
      }

    // Plan
    case 'FETCHED_PROVIDER_PLAN':
      return {
        ...state,
        currentPlan: action.payload.is_active ? action.payload : {},
      }
    case 'UPDATED_PROVIDER_PLAN':
      return {
        ...state,
        currentPlan: action.payload.is_active ? action.payload : {},
      }
    case 'CREATED_PROVIDER_PLAN':
      return {
        ...state,
        currentPlan: action.payload.is_active ? action.payload : {},
      }

    // Flags attached to currentPlan
    case 'FETCHED_PROVIDER_FLAG_ALL':
      return {
        ...state,
        currentFlags: action.payload,
      }
    case 'FETCHED_PROVIDER_FLAG':
      return {
        ...state,
        currentFlag: action.payload,
      }
    case 'UPDATED_PROVIDER_FLAG':
      return {
        ...state,
        currentFlag: action.payload,
      }
    case 'CREATED_PROVIDER_FLAG':
      return {
        ...state,
        currentFlag: action.payload,
      }

    default:
      return state
  }
}
