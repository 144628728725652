import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  fetchMediaByCategory,
  updateItem,
  fetchItems,
  deleteTrainingStatus,
  addTrainingStatus,
} from 'actions'
import ReactPaginate from 'react-paginate'
import ImageList from 'components/image_list'

class AeditsIndex extends Component {
  componentDidMount() {
    this.props.fetchMediaByCategory('face_recognition', {
      _limit: 50,
      order_by: 'date_created',
      order_by_direction: 'desc',
    })
    this.props.fetchItems('config')
  }

  onPageChange = ({ selected }) => {
    this.props.fetchMediaByCategory('face_recognition', {
      _page: selected + 1,
      _limit: 50,
      order_by: 'date_created',
      order_by_direction: 'desc',
    })
  }

  onSubmit = values => {
    this.props.updateItem('media', values)
  }

  onTrainingStatusDelete = id => {
    this.props.deleteTrainingStatus(id)
  }

  onTrainingStatusSubmit = id => {
    this.props.addTrainingStatus(id)
  }

  render() {
    const { all, totalPages, fetchItems, count, fetchMediaByCategory, config } = this.props

    return (
      <div className="primary-content">
        <ImageList
          items={all}
          onSubmit={this.onSubmit}
          onTrainingStatusDelete={this.onTrainingStatusDelete}
          onTrainingStatusSubmit={this.onTrainingStatusSubmit}
          fetchItems={fetchItems}
          count={count}
          fetchMediaByCategory={fetchMediaByCategory}
          flagged={all}
          flaggedReasons={config}
        />

        <ReactPaginate
          pageCount={totalPages}
          marginPagesDisplayed={1}
          pageRangeDisplayed={5}
          onPageChange={this.onPageChange}
          containerClassName="pagination"
          activeClassName="active"
          pageClassName="page-item"
          previousClassName="page-item"
          nextClassName="page-item"
        />
      </div>
    )
  }
}

const mapStateToProps = ({ media, config }) => {
  return {
    totalPages: media.totalPages,
    all: media.all,
    count: media.count,
    config: Object.values(config.media.FLAG_REASONS).sort((a, b) => a.localeCompare(b)),
  }
}

export default connect(
  mapStateToProps,
  { fetchMediaByCategory, updateItem, fetchItems, deleteTrainingStatus, addTrainingStatus }
)(AeditsIndex)
