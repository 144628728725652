import React from 'react'
import { FormGroup } from '../../../components/form'
import { getStripeCustomerId, getStripeDashboardUrl } from '../../../config/util'
import CardList from 'components/payments/card_list'

const UserPayments = ({ user, setUser }) => {
  const customerId = getStripeCustomerId(user)
  return (
    <div className="flex-fill page-form well form-horizontal">
      <FormGroup label="Customer">
        <span>
          {customerId ? (
            <a
              href={getStripeDashboardUrl(`customers/${customerId}`)}
              target="_blank"
              rel="noopener noreferrer">
              Customer
            </a>
          ) : (
            <span>No payment methods</span>
          )}
        </span>
      </FormGroup>
      <FormGroup label="Payment methods">
        <CardList name="payment_methods" user={user} setUser={setUser} editable />
      </FormGroup>
    </div>
  )
}

export default UserPayments
