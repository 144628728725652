import React from 'react'
import {Route, Redirect} from 'react-router-dom'

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => {
    return (
      rest.authed
      ? (
        <Component {...props} />
      ) : (
        <Redirect to={{
          pathname: '/',
          state: { from: props.location }
        }}/>
      )
    )
  }}/>
)
