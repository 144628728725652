import React from 'react'
import {Table} from '../../components/table'
import {Link} from 'react-router-dom'
import {Checkmark} from '../../components/checkmark'
import moment from 'moment'

const tableHeaders = ['Internal Title', 'ID', 'Active', 'Date Created']

export default ({steps, loading, onDelete}) => (
  <Table items={steps} loading={loading} headers={tableHeaders}>
    {
      steps
        .sort((a,b) => a.order - b.order)
        .map(({internal_title, date_created, is_active, id, order}) =>
          <tr key={id}>
            <td><Link to={`/procedures/steps/${id}`}>{internal_title}</Link></td>
            <td>{id}</td>
            <td><Checkmark bool={!!is_active} /></td>
            <td>{moment(date_created).format('MM/DD/YY')}</td>
            {
              order ? <td>{`Order: ${order}`}</td> : null
            }
            <td>
              <span onClick={() => onDelete(id)}>
                <i className="fa fa-trash"></i>
              </span>
            </td>
          </tr>
        )
    }
  </Table>
)
