import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createItem} from '../../actions'
import {Field, reduxForm} from 'redux-form'
import {FormGroup, TextField, RadioField, FormFooter} from '../../components/form'
import {validateRequiredFields, setOnBeforeUnload} from '../../config/util'

class RegionsNew extends Component {

  onFormSubmit = values => {
    this.props.createItem('region', values)
  }

  componentWillReceiveProps(nextProps) {
    const currentId = this.props.region.id
    const nextId = nextProps.region.id

    if (nextId && nextId !== currentId) {
      this.props.reset() // clear form since the default behavior is overridden
      this.props.history.push('/procedures/regions')
    }
  }

  componentDidUpdate = () => {
    setOnBeforeUnload(this.props.dirty)
  }

  componentWillUnmount() {
    setOnBeforeUnload(false)
  }

  render() {
    const { loading, invalid, handleSubmit, submitFailed } = this.props

    return (
      <div className="primary-content">
        <div className="controls">
          <h4>Add a New Region</h4>
        </div>

        <form onSubmit={handleSubmit(this.onFormSubmit)} className="flex-fill page-form well form-horizontal">
          <FormGroup label="Name" editable>
            <Field component={TextField} type="text" name="name" placeholder="Name" />
          </FormGroup>
          <FormGroup label="Is Active?" editable>
            <Field component={RadioField} name="is_active" items={[
                { labelText: 'No', value: 0 },
                { labelText: 'Yes', value: 1 }
              ]}
            />
          </FormGroup>

          <FormFooter loading={loading} showFailure={invalid && submitFailed} />
        </form>
      </div>
    )
  }
}

const validate = values => {
  const errors = {}
  const fields = [
    ['name', 100]
  ]

  return {...validateRequiredFields(values, fields), ...errors}
}

const mapStateToProps = ({ regions, ui }) => {
  return {
    loading: ui.loading,
    region: regions.current
  }
}

RegionsNew = reduxForm({
  validate: validate,
  form: 'newAreaForm',
  destroyOnUnmount: false,
  initialValues: {
    is_active: '0'
  }
})(RegionsNew)

export default connect(mapStateToProps, {createItem})(RegionsNew)
