import React from 'react'
import { getStripeCustomerId, getStripeDashboardUrl } from '../../config/util'
import { FormGroup } from '../../components/form'

const ConsultationPayment = props => {
  const { consultation, user } = props
  const customerId = getStripeCustomerId(user)
  const paymentId = consultation?.payment_confirmation

  return (
    <div className="flex-fill page-form well form-horizontal">
      <FormGroup label="Customer">
        <span>
          <a
            href={getStripeDashboardUrl(`customers/${customerId}`)}
            target="_blank"
            rel="noopener noreferrer">
            Customer
          </a>
        </span>
      </FormGroup>
      <FormGroup label="Payment">
        <span>
          <a
            href={getStripeDashboardUrl(`payments/${paymentId}`)}
            target="_blank"
            rel="noopener noreferrer">
            Payment
          </a>
        </span>
      </FormGroup>
    </div>
  )
}

export default ConsultationPayment
