import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import AnalyticsDisplay from 'components/analytics_display'
import { fetchItem, updateItem, fetchProceduresByConcern } from '../../actions'
import { Tabs, Tab, TabPanels, TabList } from '../../components/tabs'
import ConcernForm from './_concern_form'
import { EntityByConcern } from './_entity_by_concern'
import _ from 'lodash'

class ConcernsShow extends Component {
  onFormSubmit = ({
    concern,
    primary_image_url,
    primary_image_relative_url,
    primary_image_thumbnail_url,
    primary_image_thumbnail_relative_url,
    contentful,
    ...submitData
  }) => {
    this.props.updateItem('concern', submitData)
  }

  componentDidMount() {
    const id = this.props.match.params.id
    this.props.fetchItem('concern', id)
    this.props.fetchProceduresByConcern(id)
  }

  render() {
    const { concern, loading, concernProcedures, concernProceduresCount } = this.props

    const concernAreas = concern.procedure_areas
    const concernAreasCount = concernAreas ? concernAreas.length : 0

    return (
      <div className="primary-content">
        <div className="controls">
          <Link to="/concerns/new" className="btn btn-primary pull-right">
            Add New Concern
          </Link>
          <h4>{concern.name} Concern</h4>
        </div>
        <Tabs>
          <TabList>
            <Tab title="General" />
            <Tab title={`Procedures (${concernProceduresCount})`} />
            <Tab title={`Areas (${concernAreasCount})`} />
            <Tab title="Analytics" />
          </TabList>
          <TabPanels>
            <ConcernForm
              onFormSubmit={this.onFormSubmit}
              initialValues={concern}
              loading={loading}
              concern={concern}
            />
            <EntityByConcern
              label="Procedures"
              entity={concernProcedures}
              count={concernProceduresCount}
            />
            <EntityByConcern
              label="Areas"
              entity={concernAreas}
              count={concernAreasCount}
              url="/areas"
            />
            <AnalyticsDisplay entityType="concern" entityId={this.props.match.params.id} />
          </TabPanels>
        </Tabs>
      </div>
    )
  }
}

const mapStateToProps = ({ ui, concerns }) => {
  return {
    concern: concerns.current,
    loading: ui.loading,
    concernProcedures: concerns.procedures.all,
    concernProceduresCount: concerns.procedures.count,
  }
}

export default connect(
  mapStateToProps,
  { fetchItem, updateItem, fetchProceduresByConcern }
)(ConcernsShow)
