import React from 'react'
import { FLAG_TYPES, VALID_PHONE, VALID_URL, MB } from 'config/constants'
import _ from 'lodash'
import dayjs from 'dayjs'

const VALID_EMAIL = /.+@.+/
const MIN_PASSWORD_LENGTH = 6
const VALID_PASSWORD = /(?=.{6,})/ // 6 chars or longer

export const digitsOnly = val => val.replace(/\D/g, '')

export const digitsAndPeriodOnly = val => val.replace(/[^0-9.]/g, '')

export const lettersAndSymbolsOnly = val => val.replace(/[0-9]/g, '')

export function validateRequiredFields(values, fields = []) {
  const errors = {}

  fields.forEach(field => {
    const name = field[0]
    const maxChars = field[1]

    const value = _.get(values, name)

    if (maxChars && (!value || value.length > maxChars)) {
      _.set(errors, name, `Must be between 1 and ${maxChars} characters`)
    } else if (typeof value !== 'number' && !value) {
      // ^ any number including 0 is valid e.g. in the case of radio buttons
      _.set(errors, name, 'Field required')
    } else if (Array.isArray(value) && !value.length) {
      // ^ if it's an array we want to make sure it's not empty
      _.set(errors, name, 'Field required')
    }

    if (name === 'email' && !VALID_EMAIL.test(values.email)) {
      errors.email = 'Email not formatted properly'
    }

    if (name === 'phone' && !VALID_PHONE.test(values.phone)) {
      errors.phone = 'Phone number not formatted properly.'
    }

    if (name.includes('password') && !VALID_PASSWORD.test(value)) {
      _.set(errors, name, `Password must be at least ${MIN_PASSWORD_LENGTH} characters`)
    }
  })

  return errors
}

export function validateUrls(values, urls = []) {
  const errors = {}

  urls.forEach(url => {
    if (values[url] && !VALID_URL.test(values[url])) {
      errors[url] = 'Url must start with http(s)'
    }
  })

  return errors
}

export function setOnBeforeUnload(formIsDirty) {
  if (formIsDirty) {
    window.onbeforeunload = () => true
  } else {
    window.onbeforeunload = undefined
  }
}

export function convertObjectsToIds(masterObject, arrayOfObjects) {
  let newObj = {}

  arrayOfObjects.forEach(function (name) {
    if (masterObject[name] === null || masterObject[name] === undefined) {
      newObj[name] = []
    } else {
      newObj[name] = masterObject[name].filter(o => o && o.id).map(o => o.id)
    }
  })

  return newObj
}

export function setMaxCharLimit(values, fields = []) {
  const errors = {}

  fields.forEach(field => {
    const name = field[0]
    const maxChars = field[1]

    const value = _.get(values, name)

    if (maxChars && value && value.length > maxChars) {
      _.set(errors, name, `Maximum character limit is ${maxChars}`)
    }
  })
  return errors
}

// returns a list of the last n number of years starting with current
export const lastNYears = numberOfYears => {
  const currentYear = new Date().getFullYear()
  const years = []

  for (var i = currentYear; years.length <= numberOfYears; i--) {
    years.push(i)
  }

  return years.map((year, i) => (
    <option value={year} key={i}>
      {year}
    </option>
  ))
}

export function roundTo(n, digits) {
  var negative = false
  if (digits === undefined) {
    digits = 0
  }
  if (n < 0) {
    negative = true
    n = n * -1
  }
  var multiplicator = Math.pow(10, digits)
  n = parseFloat((n * multiplicator).toFixed(11))
  n = (Math.round(n) / multiplicator).toFixed(digits)
  if (negative) {
    n = (n * -1).toFixed(digits)
  }
  return n
}

// returns a list of a range of years starting from the current year and looping through until reaching a specific year in the past
export const rangeOfYears = startYear => {
  const currentYear = new Date().getFullYear()
  const yearCount = currentYear - startYear
  const years = []

  for (var i = currentYear; years.length <= yearCount; i--) {
    years.push(i)
  }

  return years
}

// Compare arrays and objects recursively by value instead of by reference
export function deepEquals(a, b) {
  if (a === null && b === null) return true
  if (a instanceof Array && b instanceof Array) return arraysEqual(a, b)
  if (
    Object.getPrototypeOf(a) === Object.prototype &&
    Object.getPrototypeOf(b) === Object.prototype
  )
    return objectsEqual(a, b)
  if (a instanceof Set && b instanceof Set)
    throw Error('Error: set equality by hashing not implemented.')
  return a === b
}

export function arraysEqual(a, b) {
  if (a.length !== b.length) return false
  for (var i = 0; i < a.length; i++) if (!deepEquals(a[i], b[i])) return false
  return true
}

export function objectsEqual(a, b) {
  const aKeys = Object.getOwnPropertyNames(a)
  const bKeys = Object.getOwnPropertyNames(b)
  if (aKeys.length !== bKeys.length) return false
  aKeys.sort()
  bKeys.sort()
  for (var i = 0; i < aKeys.length; i++)
    if (aKeys[i] !== bKeys[i])
      // keys must be strings
      return false
  return deepEquals(
    aKeys.map(k => a[k]),
    aKeys.map(k => b[k])
  )
}

// https://gist.github.com/ghinda/8442a57f22099bdb2e34
export const objectToFormData = (obj, form, namespace) => {
  var fd = form || new FormData()
  var formKey

  for (var property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        formKey = namespace + '[' + property + ']'
      } else {
        formKey = property
      }

      // if the property is an object, but not a File,
      // use recursivity.
      if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
        objectToFormData(obj[property], fd, property)
      } else {
        // if it's a string or a File object
        fd.append(formKey, obj[property])
      }
    }
  }

  return fd
}

// reshaping top analytics data for display
export const shapeTopViewsData = topViews => {
  const empty = {
    analytics: {
      entity_id: '-',
    },
  }
  // fill to 15 elements
  return [...Array(15).keys()].map(idx => [
    (topViews.provider[idx] || empty).analytics,
    (topViews.procedure[idx] || empty).analytics,
    (topViews.concern[idx] || empty).analytics,
  ])
}

export const getBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export const fetchAsBase64 = async url => {
  const image = await fetch(url)
  const blob = await image.blob()
  return await getBase64(blob)
}

/**
 * @param account either user or provider
 * @param slug unique slug of the flag
 * @returns {undefined | boolean | {min: number, max: number}} effective flag value
 */
export const getProfileFlag = (account, slug) => {
  const flags = account.flags || []
  const flag = flags.find(f => f.is_active && f.slug === slug)
  return flag && flag.value
}

export const getProfileMaxFlag = (user, slug, defaultValue = null) => {
  const flag = getProfileFlag(user, slug)
  return _.get(flag, 'max', defaultValue)
}

export const getProviderCalendar = provider => {
  return _.get(provider, 'calendars.cronofy.calendar')
}

/**
 * @return {boolean} whether the provider can access instant booking related features
 */
export const isInstantBookingAllowed = provider => {
  return provider && +provider.allow_instant_booking
}

/**
 * @return {boolean} whether the provider can access scheduler related features
 */
export const isSchedulerAllowed = provider => {
  return provider && +provider.allow_schedule
}

/**
 * @return {boolean} whether users can instantly book with this provider
 */
export const isInstantBookingEnabled = provider => {
  return (
    isInstantBookingAllowed(provider) &&
    provider &&
    +provider.instant_booking_enabled &&
    !!getProviderCalendar(provider)
  )
}

/**
 * @return {boolean} whether users can book through scheduler with this provider
 */
export const isSchedulerEnabled = provider => {
  return isSchedulerAllowed(provider) && provider && +provider.schedule_enabled
}

export const createRandomString = length => {
  // https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const buildUrl = (url, query) => {
  const res = new URL(url)
  const originalQuery = Object.fromEntries(res.searchParams.entries())
  const newQuery = { ...originalQuery, ...query }
  res.search = new URLSearchParams(Object.entries(newQuery)).toString()
  return res.toString()
}

export function setMaxFileSizeLimit(values, fields = []) {
  const errors = {}

  fields.forEach(([name, maxSize]) => {
    if (maxSize && values[name] && values[name].size > maxSize) {
      const maxSizeInMb = Math.floor(maxSize / MB)
      errors[name] = `File must be smaller than ${maxSizeInMb}MB!`
    }
  })

  return errors
}

export const getDisplayNameForFlagType = flag_type => {
  const type = Object.values(FLAG_TYPES).find(x => x.id === flag_type)
  return type ? type.display_name : null
}

/**
 * Evaluate flags in order from the most specific flag to least specific flag.
 *
 * @param accountFlag Flag attached to specific account
 * @param sameAccountTypeFlag Flag attached to plan definition and a non-null account type
 *        (shared by all users/providers who have this plan activated)
 * @param allAccountTypesFlag Flag attached to a plan definition and no account type
 *        (shared by all accounts who have this plan activated)
 * @param definition Flag definition (shared by all plan definitions)
 * @return Null if there's no matching flag, otherwise the flag value is returned
 */
export const getFlagEffectiveValue = ({
  accountFlag,
  sameAccountTypeFlag,
  allAccountTypesFlag,
  definition,
}) => {
  // is_active can be either an integer (0, 1) if it originates from API, or
  // a string with a single digit ("0", "1") if it originates from react-form field.

  for (const current of [accountFlag, sameAccountTypeFlag, allAccountTypesFlag, definition]) {
    if (current && +current.is_active) {
      return current.value
    }
  }
  return null
}

export const formatDate = date => {
  const parsed = date && dayjs(date)
  const formatted = parsed && parsed.isValid() && parsed.format('MM-DD-YYYY - h:mm:ss A')
  return formatted || '?'
}

export const formatDateShort = date => {
  const parsed = date && dayjs(date)
  const formatted = parsed && parsed.isValid() && parsed.format('YYYY-MM-DD')
  return formatted || null
}

export const toDollars = number => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  }).format(number)
}

export const getStripeDashboardUrl = relativePath => {
  return [process.env.REACT_APP_STRIPE_DASHBOARD_URL, relativePath]
    .map(str => _.trim(str, '/'))
    .join('/')
}

export const getStripeCustomerId = user => {
  const cards = (user?.payment_methods?.credit || []).filter(card => !card.date_deleted)

  return cards[0]?.customer_reference
}

export const noop = () => undefined

const getLengthOfTimeInDays = item => {
  const unit = item?.meta?.after_length_of_time?.value
  const count = Number(item?.meta?.after_count)

  if (unit === 'days') {
    return count
  } else if (unit === 'weeks') {
    return count * 7
  } else if (unit === 'months') {
    return count * 30
  } else if (unit === 'years') {
    return count * 365
  }
}

export const maxArrayLength = (values, fields = []) => {
  const errors = {}

  fields.forEach(field => {
    const name = field[0]
    const maxLength = field[1]

    if (values[name]?.length > maxLength) {
      errors[name] = `Please limit selection to ${maxLength}`
    }
  })

  return errors
}
