import React, { useState, useEffect } from 'react'
import { authedRequest } from 'config/config'
import ProviderPublicationForm from './_form'
import Table from './_table'
import ReactPaginate from 'react-paginate'
import { toastr } from 'react-redux-toastr'

const ProviderPublications = ({ providerId }) => {
  const [items, setItems] = useState([])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchItems()
  }, [page, providerId])

  const fetchItems = async () => {
    try {
      await setLoading(true)

      const params = { _page: page }
      const {
        data: {
          data,
          meta: { paging: { total_pages = 1 } = {} },
        },
      } = await authedRequest.get(`provider/${providerId}/publications?_start=0&_limit=5`, {
        params,
      })

      await setItems(data || [])
      await setTotalPages(total_pages)
    } catch (e) {
      toastr.error(e.message)
    } finally {
      await setLoading(false)
    }
  }

  const insertItem = async (item) => {
    try {
      await setLoading(true)
      let form = convertPublicationToFormData(item)
      await authedRequest.post(`provider/${providerId}/publications`, form)
    } catch (e) {
      toastr.error(e.message)
    } finally {
      await setLoading(false)
      await fetchItems()
    }
  }

  const updateItem = async ({ id, ...item }) => {
    try {
      await setLoading(true)
      let form = convertPublicationToFormData(item)
      await authedRequest.put(`provider/publications/${id}`, form)
    } catch (e) {
      toastr.error(e.message)
    } finally {
      await setLoading(false)
      await fetchItems()
    }
  }

  const removeItem = async ({ id }) => {
    if (window.confirm('Are you sure?')) {
      try {
        await setLoading(true)
        await authedRequest.delete(`provider/publications/${id}`)
      } catch (e) {
        toastr.error(e.message)
      } finally {
        await setLoading(false)
        await fetchItems()
      }
    }
  }

  return (
    <div className="primary-content">
      <Table items={items} loading={loading} edit={updateItem} remove={removeItem} />

      <ReactPaginate
        pageCount={totalPages}
        marginPagesDisplayed={1}
        pageRangeDisplayed={5}
        onPageChange={({ selected }) => setPage(selected + 1)}
        containerClassName="pagination"
        activeClassName="active"
        pageClassName="page-item"
        previousClassName="page-item"
        nextClassName="page-item"
      />

      <ProviderPublicationForm onSubmit={insertItem} loading={loading} />
    </div>
  )
}

const convertPublicationToFormData = (item) => {
  const form = new FormData()
  const fields = ['title', 'source', 'url', 'publication_year']
  for (const [key, value] of Object.entries(item)) {
    if (fields.includes(key)) {
      form.append(key, value)
    }
  }
  return form
}

export default ProviderPublications
