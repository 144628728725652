const defaultState = {
  authenticated: false,
  currentUser: null
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case 'FETCHED_CURRENT_USER':
      return {...state, currentUser: action.payload }
    case 'AUTH_CHANGE':
      if (action.authed) {
        return {...state, authenticated: true }
      } else {
        return {...state, authenticated: false, currentUser: null }
      }
    default:
      return state
  }
}
