import { combineReducers, createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { reducer as formReducer } from 'redux-form'
import { reducer as toastrReducer } from 'react-redux-toastr'
import reduxThunk from 'redux-thunk'

import authReducer from './auth_reducer'
import analyticsReducer from './analytics_reducer'
import pagesReducer from './pages_reducer'
import usersReducer from './users_reducer'
import regionsReducer from './regions_reducer'
import practicesReducer from './practices_reducer'
import areasReducer from './areas_reducer'
import uiReducer from './ui_reducer'
import proceduresReducer from './procedures_reducer'
import providersReducer from './providers_reducer'
import concernsReducer from './concerns_reducer'
import mediaReducer from './media_reducer'
import citiesReducer from './cities_reducer'
import campusesReducer from './campuses_reducer'
import configReducer from './config_reducer'
import synonymsReducer from './synonyms_reducer'
import timelinesReducer from './timelines_reducer'
import stepsReducer from './steps_reducer'
import referralReducer from './referral_reducer'
import consultationsReducer from './consultations_reducer'
import beautyBoardMembersReducer from './beauty_board_members_reducer'
import statisticsReducer from './statistics_reducer'
import modelsReducer from './models_reducer'
import plansReducer from './plans_reducer'
import flagsReducer from './flags_reducer'
import helpfulReducer from './helpful_reducer'
import mlReducer from './ml_reducer'

const reducers = combineReducers({
  auth: authReducer,
  analytics: analyticsReducer,
  users: usersReducer,
  pages: pagesReducer,
  procedures: proceduresReducer,
  areas: areasReducer,
  regions: regionsReducer,
  practices: practicesReducer,
  form: formReducer,
  toastr: toastrReducer,
  ui: uiReducer,
  providers: providersReducer,
  concerns: concernsReducer,
  media: mediaReducer,
  cities: citiesReducer,
  campuses: campusesReducer,
  config: configReducer,
  synonyms: synonymsReducer,
  timelines: timelinesReducer,
  steps: stepsReducer,
  referrals: referralReducer,
  consultations: consultationsReducer,
  beautyBoardMembers: beautyBoardMembersReducer,
  statistics: statisticsReducer,
  models: modelsReducer,
  plans: plansReducer,
  flags: flagsReducer,
  helpful: helpfulReducer,
  ml: mlReducer,
})

export default createStore(reducers, {}, composeWithDevTools(applyMiddleware(reduxThunk)))
