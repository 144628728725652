import _ from 'lodash'
import React from 'react'
import { Checkmark } from '../../components/checkmark'
import { FLAG_TYPES } from '../../config/constants'

const FlagValue = ({ value, type }) => {

  if (value === null) {
    return null
  }

  switch (type) {
    case FLAG_TYPES.BOOL.id:
      // providing key allows for rerendering the checkmark when the value changes
      return (
        <Checkmark key={!!value} bool={!!value} />
      )

    case FLAG_TYPES.INT_RANGE.id:
      const { min, max } = value
      const parts = []
      if (_.isNumber(min)) {
        parts.push(`≥ ${min}`)
      }
      if (_.isNumber(max)) {
        parts.push(`≤ ${max}`)
      }
      if (parts.length > 0) {
        return parts.join(', ')
      } else {
        return 'Unlimited'
      }

    default:
      return null
  }

}

export default FlagValue