const defaultState = {
  all: [],
  current: {},
  regionAreas: [],
  procedures: {
    count: 0,
    all: []
  },
  concernAreas: [],
  count: 0,
  totalPages: 0
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case 'FETCHED_AREA_ALL':
      if (action.payload.meta.paging) {
        const { total_results, total_pages } = action.payload.meta.paging
        return {
          ...state,
          all: action.payload.data,
          count: total_results,
          totalPages: total_pages
        }
      } else { // no results
        return { ...state, ...defaultState }
      }
    case 'FETCHED_AREA':
      return {
        ...state,
        current: action.payload
      }
    case 'CREATED_AREA':
      return {
        ...state,
        current: action.payload
      }
    case 'UPDATED_AREA':
      return {
        ...state,
        current: action.payload
      }
    case 'FETCHED_REGION_AREAS':
      return {
        ...state,
        regionAreas: action.payload
      }
    case 'FETCHED_AREA_PROCEDURES':
      return {
        ...state,
        procedures: {
          count: action.payload.meta.items,
          all: action.payload.data
        }
      }
    case 'FETCHED_AREA_CONCERNS':
      return {
        ...state,
        concernAreas: action.payload,
      }
    default:
      return state
  }
}
