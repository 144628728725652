import React, {Component} from 'react'
import {connect} from 'react-redux'
import {fetchItems, createItem} from '../../actions'
import AreaForm from './_area_form'

class AreasNew extends Component {

  handleFormSubmit = ({concern_ids = [], ...values}) => {
    concern_ids = concern_ids.map(obj => obj.id)
    this.props.createItem('area', {...values, concern_ids})
  }

  componentWillReceiveProps(nextProps) {
    const { area } = nextProps
    if (area && area.id && (this.props.area.id !== area.id)) {
      this.props.history.push('/procedures/areas')
    }
  }

  componentDidMount() {
    this.props.fetchItems('region', { _limit: 9999 })
    this.props.fetchItems('concern', { _limit: 9999 })
  }

  render() {
    const { regions, concerns, loading } = this.props
    const initialValues = {
      is_active: '0'
    }

    return (
      <div className="primary-content">
        <div className="controls">
          <h4>Add a New Area</h4>
        </div>
        <AreaForm
          onFormSubmit={this.handleFormSubmit}
          regions={regions}
          concerns={concerns}
          initialValues={initialValues}
          loading={loading}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ regions, areas, ui, concerns }) => {
  return {
    regions: regions.all,
    area: areas.current,
    loading: ui.loading,
    concerns: concerns.all
  }
}

export default connect(mapStateToProps, { fetchItems, createItem })(AreasNew)
