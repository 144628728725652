import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import ReactPaginate from 'react-paginate'
import { fetchMediaByCategory } from 'actions'
import ImageList from '../../components/image_list'
import { getPreviewImage } from 'config/util/media'

const PAGE_LIMIT = 50

const Media3d = ({ all, fetchMediaByCategory, totalPages }) => {
  const images = all.filter(image => Boolean(image?.meta?.related_files))

  useEffect(() => {
    fetchMediaByCategory('facial_recognition_3d', {
      _limit: PAGE_LIMIT,
      order_by: 'date_created',
      order_by_direction: 'desc',
    })
  }, [fetchMediaByCategory])

  const onPageChange = ({ selected }) => {
    fetchMediaByCategory('facial_recognition_3d', {
      _page: selected + 1,
      _limit: PAGE_LIMIT,
      order_by: 'date_created',
      order_by_direction: 'desc',
    })
  }

  const imageData = images.map(image => {
    const preview = getPreviewImage(image)

    return {
      id: image.id,
      date_created: image.date_created,
      entity_type: image.entity_type,
      entity_id: image.entity_id,
      related_files: image.url,
      download_zip: true,
      meta: image.meta,
      stored_name: preview?.original_filename,
      url: preview?.url,
      user: image.user,
    }
  })

  return (
    <div className="primary-content t__3D-facial-recognition">
      <ImageList isMorph items={imageData} />

      <ReactPaginate
        pageCount={totalPages}
        marginPagesDisplayed={1}
        pageRangeDisplayed={5}
        onPageChange={onPageChange}
        containerClassName="pagination"
        activeClassName="active"
        pageClassName="page-item"
        previousClassName="page-item"
        nextClassName="page-item"
      />
    </div>
  )
}

const mapStateToProps = ({ media }) => ({
  totalPages: media.totalPages,
  all: media.all,
})

export default connect(
  mapStateToProps,
  { fetchMediaByCategory }
)(Media3d)
