import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

const ProvidersFlagIndex = ({ match }) => {
  return (
    <Redirect to={`/providers/${match.params.id}?tab=8`} />
  )
}

export default connect(null)(ProvidersFlagIndex)
export { default as ProvidersFlagNew } from './new'
export { default as ProvidersFlagShow } from './show'