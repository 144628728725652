import React, { useState, useEffect } from 'react'
import { Form, Input, Checkbox, Reset } from '@aedit/styleguide/dist/form.js'

const Playback = props => {
  const { Morph, media: _media, defaultValues } = props
  const { Constants } = Morph
  const [isPlaying, _setIsPlaying] = useState(false)
  const [media, _setMedia] = useState(_media)
  const [hasInteractions, _setHasInteractions] = useState(false)

  const [currentEffect, _setCurrentEffect] = useState("Original")
  const [currentValue, _setCurrentValue] = useState("")
  const [currentDateTime, _setCurrentDateTime] = useState("")
  const [zoomLevel, _setZoomLevel] = useState(0)

  const checkboxOptions = [
    {
      label: isPlaying ? 'Stop  ' : 'Play  ',
      value: "",
    },
  ]

  const [numberOfInteractions, _setNumberOfInteractions] = useState(1)

  const init = media => {
    console.log(media?.meta)
    checkInteractions(media)
  }

  const setMorph = val => {
    const index = parseInt(val[0]) - 1

    // reset first
    Constants.WASM._reset_params_cpp(0)

    if (index < 0) {
      _setCurrentEffect("Original")
      _setCurrentValue(``)
			Constants.THETA = 0
			Constants.PHI = 0
			Constants.ZOOM = 1.4
		return
    }

    const interactions = media?.meta?.interaction_log?.interactions

    for (let i=0; i<=index; i++) {
      const effects = interactions[i]?.effects
      if (effects.length > 0) {
        for (const effect of effects) {
          const areaId = effect.area.id
          const effectId = effect.effect.id
          const value = effect.value
          // apply effects to the morph
          applyEffect(areaId, effectId, value)
        }
      } else if (interactions[i]?.ui_info?.type === "view_transformation") {
				const vp = interactions[i]?.ui_info?.view_parameters
				const rotation_x = vp["rotation_x"]?.toFixed(2)
				const rotation_y = vp["rotation_y"]?.toFixed(2)
				const scale = vp["scale"]?.toFixed(2)
				Constants.THETA = rotation_y / 20.0 * 90.0	// HACK: we divide by 20 because it's multiplied by 20 in Cylinder.set_cylindrical_face_rotation()
				Constants.PHI = rotation_x / 20.0 * 90.0
				Constants.ZOOM = 1.4 + scale * 0.1
			}
    }

    let onoff = ""
    let interaction = interactions[index]
    if (interaction?.ui_info?.type === "try_on") {
      let isOn = null != interaction.effects.filter(effect => effect.value > 0.0)[0]
      onoff = isOn ? " (On)" : " (Off)"
    }
    _setCurrentEffect(interaction?.ui_info?.type + ': ' + interaction?.ui_info?.name + onoff)

    if (interaction?.ui_info?.type === "slider") {
      const val = interaction?.ui_info?.value.toFixed(2)
      _setCurrentValue(`: ${val}`)
		} else if (interaction?.ui_info?.type === "view_transformation") {
			const vp = interaction?.ui_info?.view_parameters
			const rotation_x = vp["rotation_x"]?.toFixed(2)
			const rotation_y = vp["rotation_y"]?.toFixed(2)
			const scale = vp["scale"]?.toFixed(2)
			_setCurrentEffect(interaction?.ui_info?.name)
			_setCurrentValue(`: (rot_x, rot_y, scale) = (${rotation_x}, ${rotation_y}, ${scale})`)
    } else {
      _setCurrentValue(``)
    }

    _setCurrentDateTime(interaction?.time)
  }

  const startPlayback = e => {
    _setIsPlaying(e.target.checked)
    // console.log(e.target.checked ? 'playing' : 'stopped')

    // start playback timer

  }

  const checkInteractions = media => {
    if (media?.id) {
      const interactions = media?.meta?.interaction_log?.interactions
      const hasInteractions = Boolean(Array.isArray(interactions) && interactions?.length)
      _setHasInteractions(hasInteractions)

      _setNumberOfInteractions(interactions?.length)
    }
  }

  const printError = () => {
    console.log("Error: No matching effect found.")
  }

  const applyEffect = (area, effect, value) => {
    // console.log("area: ", area, "effect: ", effect, "value: ", value)
    Constants.WASM._apply_effect_cpp(value, effect, area)
  }

  useEffect(() => {
    if (Morph) {
      init(media)
    }
  }, [Morph, media?.id])

  if (!hasInteractions) {
    return null
  }

  return (
    <Form defaultValues={defaultValues}>
      <h3>Playback</h3>
        {/* <Checkbox
          className="original-button" 
          name="playing"
          options={checkboxOptions}
          onClick={startPlayback}
        />
      <pre> </pre> */}
      <Input name=" " type="range" min={0} max={numberOfInteractions} onUpdate={setMorph} />
      <div>{currentEffect}{currentValue}</div>
      {/* <>{currentDateTime}</> */}
    </Form>
  )
}

export default Playback