import axios from 'axios'

const protocol = process.env.REACT_APP_PROTOCOL
const host =
  protocol +
  process.env.REACT_APP_API_ROOT +
  ':' +
  process.env.REACT_APP_API_PORT +
  '/' +
  process.env.REACT_APP_API_PATH
const http = axios.create()

axios.defaults.baseURL = host
axios.defaults.headers.common['X-API-KEY'] = process.env.REACT_APP_API_KEY

http.defaults.baseURL = host
http.defaults.headers.common['X-API-KEY'] = process.env.REACT_APP_API_KEY

http.interceptors.request.use(
  config => {
    const token = localStorage.getItem('access_token')

    if (token) {
      config.headers.common['X-ACCESS-TOKEN'] = token
    }

    return config
  },
  err => {
    return Promise.reject(err)
  }
)

export const authedRequest = http

export const callApi = async (method, url, params, data, timeout) => {
  try {
    method = method.toLowerCase()
    params = params || {}
    const res = await http.request({
      method,
      url,
      params,
      data,
      timeout,
    })
    if (res.data.error) {
      throw new Error(res.data.error.message)
    }
    return res.data.data
  } catch (e) {
    if (e.code === 'ECONNABORTED') {
      throw new Error('We are sorry, please try again later')
    }
    throw e
  }
}
