import moment from 'moment'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import { FormFooter, FormGroup, RadioField, SelectField, TextArea, TextField } from '../../components/form'
import { FLAG_TYPES, RADIO_OPTIONS } from '../../config/constants'
import { digitsOnly, setMaxCharLimit, setOnBeforeUnload, validateRequiredFields } from '../../config/util'
import FlagValue from './_flag_value'
import { getDisplayNameForFlagType, getFlagEffectiveValue } from 'config/util'

const FlagForm = ({ is_active, type, value_value, value_min, value_max, initialValues, loading, handleSubmit, onFormSubmit, submitFailed, invalid, dirty }) => {

  useEffect(() => {
    setOnBeforeUnload(dirty)
    return () => setOnBeforeUnload(false)
  }, [dirty])

  const editing = initialValues && initialValues.date_created

  let effectiveValue
  if (editing) {
    effectiveValue = getFlagEffectiveValue({
      definition: initialValues.definition,
      allAccountTypesFlag: initialValues.allAccountTypesFlag,
      sameAccountTypeFlag: {
        is_active: is_active,
        value: createEntityValueFromProps({ type, value_value, value_min, value_max }),
      },
    })
  }

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} className="flex-fill page-form well form-horizontal">

      <FormGroup label="Slug" editable={!editing}>
        {editing ? (
          <span>{initialValues.slug}</span>
        ) : (
          <Field name="slug" component={TextField} />
        )}
      </FormGroup>

      <FormGroup label="Description" editable>
        <Field name="description" component={TextArea} />
      </FormGroup>

      <FormGroup label="Active?" editable>
        <Field name="is_active" items={RADIO_OPTIONS} component={RadioField} />
      </FormGroup>

      <FormGroup label="Type" editable={!editing}>
        {editing ? (
          <span>{getDisplayNameForFlagType(type)}</span>
        ) : (
          <Field name="type" component={SelectField} hideDisabledOption>
            <option value={null}>Select Flag Type ...</option>
            {Object.values(FLAG_TYPES).map(type => (
              <option key={type.id} value={type.id}>{type.display_name}</option>
            ))}
          </Field>
        )}
      </FormGroup>

      {type === FLAG_TYPES.BOOL.id && (
        <FormGroup label="Value" editable>
          <Field name="value_value" items={RADIO_OPTIONS} component={RadioField} />
        </FormGroup>
      )}

      {type === FLAG_TYPES.INT_RANGE.id && (
        <>
          <FormGroup label="Minimum Value" editable>
            <Field name="value_min" component={TextField} parse={digitsOnly} type="number" />
          </FormGroup>
          <FormGroup label="Maximum Value" editable>
            <Field name="value_max" component={TextField} parse={digitsOnly} type="number" />
          </FormGroup>
        </>
      )}

      {editing && (
        <FormGroup label="Effective Value">
          <FlagValue value={effectiveValue} type={type} />
        </FormGroup>
      )}

      {editing && (
        <>
          <FormGroup label="Created At">
            <span>{moment(initialValues.date_created).format('MM-DD-YYYY - h:mm:ss A')}</span>
          </FormGroup>
          <FormGroup label="Updated At">
            <span>{moment(initialValues.date_updated).format('MM-DD-YYYY - h:mm:ss A')}</span>
          </FormGroup>
        </>
      )}

      <FormFooter loading={loading} showFailure={invalid && submitFailed} />

    </form>
  )

}

const validate = (values) => {

  let errors = {}

  const requiredFields = [
    ['slug', 255],
    ['type', 255],
    ['description'],
    ['is_active'],
  ]

  const optionalFields = [
    ['value_min', 3],
    ['value_max', 3],
  ]

  if (values.type === FLAG_TYPES.BOOL.id) {
    errors = {
      ...validateRequiredFields(values, ['value_value']),
      ...errors,
    }
  }

  return {
    ...validateRequiredFields(values, requiredFields),
    ...setMaxCharLimit(values, optionalFields),
    ...errors,
  }
}

const FlagReduxForm = reduxForm({
  validate,
  form: 'flagForm',
  enableReinitialize: true,
})(FlagForm)

const selector = formValueSelector('flagForm')

const mapStateToProps = (state) => {
  return {
    is_active: selector(state, 'is_active'),
    type: selector(state, 'type'),
    value_value: selector(state, 'value_value'),
    value_min: selector(state, 'value_min'),
    value_max: selector(state, 'value_max'),
  }
}

export default connect(mapStateToProps)(FlagReduxForm)

/**
 * Converts API entities into FlagForm props.
 *
 * @param definition Flag definition (shared by all plan definitions)
 * @param allAccountTypesFlag Flag attached to a plan definition and no account type
 *        (shared by all accounts who have this plan activated)
 * @param flag Flag attached to plan definition and a non-null account type
 *        (shared by all users/providers who have this plan activated)
 */
export const createPropsFromEntities = ({ definition, allAccountTypesFlag, flag }) => {

  const { slug, description, type } = definition
  const { is_active, value, date_created, date_updated } = flag

  const { value_value, value_min, value_max } = createValuePropsFromEntities({ type, value })

  return ({
    slug,
    description,
    is_active,
    type,
    value,
    value_value,
    value_min,
    value_max,
    date_created,
    date_updated,
    definition,
    allAccountTypesFlag,
  })

}

/**
 * Converts FlagForm props into API entities.
 */
export const createEntitiesFromProps = ({
  slug,
  description,
  is_active,
  type,
  value_value,
  value_min,
  value_max,
}) => {

  const value = createEntityValueFromProps({ type, value_value, value_min, value_max })

  return ({
    definition: {
      slug,
      type,
      description,
      is_active: false,
      value,
    },
    flag: {
      value,
      is_active,
    },
  })

}

/**
 * Converts API field "value" into FlagForm props.
 */
const createValuePropsFromEntities = ({ type, value }) => {
  if (type === FLAG_TYPES.BOOL.id) {

    // value is a bool
    return { value_value: `${+value}` }

  } else if (type === FLAG_TYPES.INT_RANGE.id) {

    // min, max are numbers
    const { min, max } = value || {}
    return {
      value_min: `${isset(min) ? min : ''}`,
      value_max: `${isset(max) ? max : ''}`,
    }

  } else {
    return {}
  }
}

/**
 * Converts FlagForm props into API field "value".
 */
const createEntityValueFromProps = ({ type, value_value, value_min, value_max }) => {
  if (type === FLAG_TYPES.BOOL.id) {

    // value is a string with a single digit ("0", "1")
    return isset(value_value) ? !!parseInt(value_value) : null

  } else if (type === FLAG_TYPES.INT_RANGE.id) {

    // min, max are strings with numbers (e.g. "123")
    return {
      min: isset(value_min) && value_min.length > 0 ? parseInt(value_min) : null,
      max: isset(value_max) && value_max.length > 0 ? parseInt(value_max) : null,
    }

  }
}

function isset(val) {
  return typeof val !== 'undefined' && val !== null
}
