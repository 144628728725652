import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createItem, fetchItems} from '../../actions'
import PracticeForm from './_practice_form'

class PracticesNew extends Component {
  onFormSubmit = ({location, provider_ids = [], ...submitData}) => {
    provider_ids = provider_ids.map(obj => obj.id)
    this.props.createItem('practice', {...submitData, place_id: location.place_id, provider_ids})
  }

  componentWillReceiveProps(nextProps) {
    const currentId = this.props.practice.id
    const nextId = nextProps.practice.id

    if (nextId && nextId !== currentId) {
      this.props.history.push('/practices')
    }
  }

  render() {
    const { fetchItems, loading, providers, practiceTypeOptions } = this.props
    const initialValues = {
      phone_country: 'US',
      publicly_viewable: 1
    }

    return (
      <div className="primary-content">
        <div className="controls">
          <h4>Add a New Practice</h4>
        </div>
        <PracticeForm
          onFormSubmit={this.onFormSubmit}
          loading={loading}
          fetchItems={fetchItems}
          providers={providers}
          initialValues={initialValues}
          practiceTypeOptions={practiceTypeOptions}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ ui, practices, providers, config }) => {
  return {
    loading: ui.loading,
    practice: practices.current,
    providers: providers.all,
    practiceTypeOptions: config.practice.PRACTICE_TYPES
  }
}

export default connect(mapStateToProps, { createItem, fetchItems })(PracticesNew)
