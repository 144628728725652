import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { validateRequiredFields, validateUrls, rangeOfYears } from 'config/util'
import { TextField, FormGroup, FormFooter, SelectField } from 'components/form'

const ProviderPublicationForm = ({
  handleSubmit,
  invalid,
  loading,
  onSubmit,
  reset,
  submitFailed,
}) => {
  const onFormSubmit = async data => {
    await onSubmit(data)
    reset()
  }

  return (
    <form
      onSubmit={handleSubmit(onFormSubmit)}
      style={{ overflow: 'visible' }}
      className="mt-2 page-form well form-horizontal">
      <FormGroup label="Add a Publication Link" editable>

        <div className="form-vertical link-form-left">
          <Field
            name="title"
            className="link-field"
            component={TextField}
            type="text"
            placeholder="Title"
            showLength={false}
          />

          <Field
            name="url"
            className="link-field"
            component={TextField}
            type="text"
            placeholder="Url"
            showLength={false}
          />
        </div>

        <div className="form-vertical link-form-right">
          <Field
            className="link-field"
            name="source"
            component={TextField}
            type="text"
            placeholder="Source"
            showLength={false}
          />

          <div className="link-year-dropdown">
            <Field
              type="number"
              name="publication_year"
              component={SelectField}
              dropdownText="Year">
              {rangeOfYears(1950).map((year, i) =>
                <option value={year} key={i}>{year}</option>,
              )}
            </Field>
          </div>
        </div>

        <FormFooter loading={loading} showFailure={invalid && submitFailed} />
      </FormGroup>
    </form>
  )
}

const validate = values => {
  const requiredFields = [
    ['title'],
    ['source'],
    ['url'],
    ['publication_year'],
  ]

  const urls = [
    'url',
  ]

  return {
    ...validateRequiredFields(values, requiredFields),
    ...validateUrls(values, urls),
  }
}

export default reduxForm({
  validate: validate,
  form: 'addProviderPublicationForm',
  enableReinitialize: true,
})(ProviderPublicationForm)
