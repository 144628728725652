import React, {Component} from 'react'
import {Field, reduxForm} from 'redux-form'
import {FormGroup, FormFooter, TextField, RadioField} from '../../components/form'
import {validateRequiredFields, digitsOnly} from '../../config/util'
import {RADIO_OPTIONS} from '../../config/constants'

class CampusForm extends Component {
  render() {
    const { loading, submitFailed, invalid, handleSubmit, onFormSubmit } = this.props
    return (
      <form onSubmit={handleSubmit(onFormSubmit)} className="flex-fill page-form well form-horizontal">
        <FormGroup label="Name" editable>
          <Field component={TextField} type="text" name="name" placeholder="Name" />
        </FormGroup>

        <FormGroup label="Address" editable>
          <Field component={TextField} type="text" name="street_address" placeholder="Address" />
        </FormGroup>

        <FormGroup label="City" editable>
          <Field component={TextField} type="text" name="city" placeholder="City" />
        </FormGroup>

        <FormGroup label="State" editable>
          <Field component={TextField} type="text" name="state" placeholder="State" />
        </FormGroup>

        <FormGroup label="Zip Code" editable>
          <Field component={TextField} type="number" name="zip" parse={digitsOnly} placeholder="Zip Code" />
        </FormGroup>

        <FormGroup label="Ipeds Unit ID" editable>
          <Field component={TextField} type="number" name="ipeds_unit_id" parse={digitsOnly} placeholder="Ipeds Unit ID" />
        </FormGroup>

        <FormGroup label="Active" editable>
          <Field component={RadioField} name="is_active" items={RADIO_OPTIONS} />
        </FormGroup>

        <FormFooter loading={loading} showFailure={invalid && submitFailed} />
      </form>
    )
  }
}

const validate = (values) => {
  const requiredFields = [
    ['name', 250],
    ['street_address'],
    ['city'],
    ['state'],
    ['zip'],
    ['ipeds_unit_id'],
    ['is_active']
  ]

  return {...validateRequiredFields(values, requiredFields)}
}


export default reduxForm({
  validate: validate,
  form: 'campusForm',
  enableReinitialize: true
})(CampusForm)
