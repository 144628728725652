import React, {Component} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {arraysEqual} from '../config/util'
import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc'
import {fetchMedia, batchUpdateMedia} from '../actions'


const SortableList = SortableContainer(({items, component: Component, onItemClick}) => {
  const WrappedComponent = SortableElement(Component)
  onItemClick = onItemClick || _.noop
  return (
    <ul className="sortable-list">
      {items.map((item, index) =>
        <WrappedComponent
          image={item}
          index={index}
          key={item.id}
          sortIndex={index}
          onItemClick={(action) => onItemClick({ item, index, action })}
        />
      )}
    </ul>
  )
})

class SortableImages extends Component {

  static propTypes = {
    entityId: PropTypes.number,
    entityType: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    component: PropTypes.func.isRequired, // this is the component you'll end up iterating over
    type: PropTypes.oneOf(['image', 'video']),
    onItemClick: PropTypes.func,
  }

  state = {
    items: this.props.images,
    fetched: false,
    reordering: false,
  }

  componentDidMount() {
    if (this.props.entityId) {
      this.fetchImages()
      this.setState({fetched: true})
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { entityId } = this.props
    const { items, fetched, reordering } = this.state

    if (reordering) {
      return
    }

    // initializing fetch for page reload
    if (entityId && !fetched) {
      this.fetchImages()
      this.setState({fetched: true})
    }

    if (
      this.state.items &&
      this.props.images &&
      !arraysEqual(
        _.sortBy(this.state.items, 'id'),
        _.sortBy(this.props.images, 'id')
      )
    ) {
      this.setState({items: this.props.images})
    }

    // ordering was changed, make api requests
    if (prevState.items.length) {
      const previousImageOrderById = prevState.items.map(item => item.id)
      const currentImageOrderById = items.map(item => item.id)

      this.updateImageOrdering(previousImageOrderById, currentImageOrderById)
    }
  }

  updateImageOrdering = async (previous, current) => {
    if (!_.isEqual(previous, current)) {
      const reorderedImages = this.state.items.map(({ id }, index) => {
        return { id, meta: { order_number: index + 1 } }
      })

      await this.setState({reordering: true})
      await this.props.batchUpdateMedia(reorderedImages)
      await this.setState({reordering: false})
    }
  }

  fetchImages = () => {
    this.props.fetchMedia({
      entity_type: this.props.entityType,
      entity_id: this.props.entityId,
      category: this.props.category
    })
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState({
      items: arrayMove([...this.state.items], oldIndex, newIndex)
    })
  }

  render() {
    return (
      <div className="well flex-fill page-form form-horizontal">
        <SortableList
          items={this.state.items}
          onSortEnd={this.onSortEnd}
          axis="xy"
          component={this.props.component}
          distance={1} // otherwise click handlers get swallowed https://github.com/clauderic/react-sortable-hoc#click-events-being-swallowed
          helperClass="sortable-item"
          onItemClick={this.props.onItemClick}
        />
      </div>
    )
  }
}

const mapStateToProps = ({media}, {type}) => {
  return {
    images: media
      .all
      .filter(item => type
        ? item.meta.type === type
        : item.type.includes('image')
      )
      .sort((a, b) => a.meta.order_number - b.meta.order_number),
  }
}


export default connect(
  mapStateToProps,
  { fetchMedia, batchUpdateMedia },
  null,
  {
    areStatesEqual(next, prev) { // only run mapStateToProps when necesssary
      return prev.media.all === next.media.all
    }
  }
)(SortableImages)
