import React from 'react'
import PropTypes from 'prop-types'
import { Table } from '../../components/table'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'

// This in combination with columns prop lets us reuse this table in various circumstances
// with different header combinations, allowing us to properly order the column data without
// requiring the columns to be specified in a certain order
const UserDataColumn = ({ column, user, renderOptions = {} }) => {
  const map = {
    ID: ({ user }) => user.id,
    Email: ({ user }) => <Link to={`/users/${user.id}`}>{user.email}</Link>,
    Token: ({ user }) => user.hash,
    'Is Active': ({ user }) => !!user.is_active + '',
    'Is Verified': ({ user }) => !!user.is_verified + '',
    'Date Created': ({ user }) => dayjs.unix(user.date_created).format('MM-DD-YYYY - h:mm:ss A'),
    // allows you to piecewise overwrite render functions
    ...renderOptions,
  }
  return <td>{map[column]({ user })}</td>
}

export default function UsersTable({ users, loading, columns, renderOptions = {} }) {
  return (
    <Table items={users} loading={loading} headers={columns}>
      {users.map(user => (
        <tr key={user.id}>
          {columns.map(column => (
            <UserDataColumn
              key={column}
              column={column}
              user={user}
              renderOptions={renderOptions}
            />
          ))}
        </tr>
      ))}
    </Table>
  )
}

UsersTable.defaultProps = {
  columns: ['ID', 'Email', 'Token', 'Is Active', 'Is Verified', 'Date Created'],
}

UsersTable.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  loading: PropTypes.bool.isRequired,
  columns: PropTypes.arrayOf(PropTypes.string),
}
