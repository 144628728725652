import React, { useEffect, useState } from 'react'
import { Tabs, Tab, TabPanels, TabList } from '../../components/tabs'
import { connect, useSelector } from 'react-redux'
import ConsultationDetailForm from './_consultation_detail_form'
import ConsultationVoucher from './_consultation_voucher'
import ImageList from '../../components/image_list'
import { EmptyState } from '../../components/empty_state'
import _ from 'lodash'
import dayjs from 'dayjs'
import ConsultationPayment from './_consultation_payment'
import ConsultationTriggers from './consultation_triggers'
import { callApi } from 'config/config'
import { toastr } from 'react-redux-toastr'

const ConsultationsShow = props => {
  const { match } = props
  const id = match.params.id
  const config = useSelector(state => state.config.consultation)
  const [loading, setLoading] = useState(true)
  const [consultation, setConsultation] = useState()
  const [provider, setProvider] = useState()
  const [user, setUser] = useState()
  const [procedure, setProcedure] = useState()

  useEffect(() => {
    fetchData()
  }, [id])

  const fetchData = async () => {
    try {
      setLoading(true)

      const consultation = await callApi('get', `/consultation/${id}`)
      const provider = await callApi('get', `/provider/${consultation.provider_id}`)
      const user = await callApi('get', `/user/${consultation.user_id}`)
      const procedure = await callApi('get', `/procedure/${consultation.procedure_id}`)

      setConsultation(consultation)
      setProvider(provider)
      setUser(user)
      setProcedure(procedure)

      setLoading(false)
    } catch (e) {
      toastr.error(e.message)
    }
  }

  const onDetailFormSubmit = async ({
    consultation_booked,
    preferred_contact_methods,
    ...values
  }) => {
    try {
      setLoading(true)

      consultation_booked = parseInt(consultation_booked, 10)

      if (!consultation_booked) {
        delete values.consultation_date_booked
      }

      if (!_.every(preferred_contact_methods, _.isString)) {
        preferred_contact_methods = preferred_contact_methods.map(p => p.id)
      }

      await callApi('put', `/consultation/${id}`, null, {
        ...values,
        preferred_contact_methods,
        consultation_booked: consultation_booked,
      })
      await fetchData()
    } catch (e) {
      toastr.error(e.message)
      setLoading(false)
    }
  }

  const initialValues = {
    ...consultation,
    consultation_dates: consultation?.consultation_dates
      ? consultation.consultation_dates.toString()
      : null,
    consultation_date_booked: consultation?.consultation_date_booked
      ? dayjs(consultation.consultation_date_booked).toISOString()
      : null,
  }

  const attachments = [...(consultation?.my_aedits ?? []), ...(consultation?.my_uploads ?? [])]

  return (
    <div className="primary-content">
      <Tabs>
        <TabList>
          <Tab title="General" />
          <Tab title="Voucher" />
          <Tab title="Attachments" />
          <Tab title="Payment" />
          <Tab title="Triggers" />
        </TabList>
        <TabPanels loading={loading}>
          <ConsultationDetailForm
            onFormSubmit={onDetailFormSubmit}
            loading={loading}
            consultation={consultation}
            initialValues={initialValues}
            user={user}
            provider={provider}
            procedure={procedure}
            configOptions={config}
          />

          <ConsultationVoucher loading={loading} consultation={consultation} user={user} />

          {Boolean(attachments.length) ? (
            <ImageList items={attachments} downloadButton />
          ) : (
            <EmptyState message="No results, sorry" />
          )}

          <ConsultationPayment consultation={consultation} user={user} />
          <ConsultationTriggers consultationId={props.match.params.id} />
        </TabPanels>
      </Tabs>
    </div>
  )
}

export default ConsultationsShow
