import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createItem, fetchItems} from '../../actions'
import BeautyBoardMemberForm from './_beauty_board_member_form'

class BeautyBoardMembersNew extends Component {
  onFormSubmit = ({salutation, first_name, last_name, short_blurb, profile_picture, profile_picture_url, location, title, profile_picture_relative_url, external_url, is_provider, provider = {}, ...values}) => {
    let val
    if (is_provider > 0) {
      val = {
        entity_id: provider.id,
        entity_type: 'provider',
        provider
      }
    } else {
      val = {
        first_name,
        last_name,
        salutation,
        profile_picture,
        short_blurb,
        external_url,
        location,
        title
      }
    }
    this.props.createItem('beauty_board', {...values, ...val})
  }

  componentWillReceiveProps(nextProps) {
    const currentId = this.props.beautyBoardMember.id
    const nextId = nextProps.beautyBoardMember.id

    if (nextId && nextId !== currentId) {
      this.props.history.push('/beauty-board')
    }
  }

  render() {
    const { loading, providers, config, fetchItems } = this.props
    return (
      <div className="primary-content">
        <div className="controls">
          <h4>Add a New Beauty Board Member</h4>
        </div>
        <BeautyBoardMemberForm
          loading={loading}
          onFormSubmit={this.onFormSubmit}
          providers={providers}
          fetchItems={fetchItems}
          configOptions={config}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ beautyBoardMembers, providers, config, ui }) => {
  return {
    providers: providers.all.filter(item => item.is_beauty_board_member),
    beautyBoardMember: beautyBoardMembers.current,
    config: config.provider,
    loading: ui.loading
  }
}

export default connect(mapStateToProps, {createItem, fetchItems})(BeautyBoardMembersNew)
