import JSZip from 'jszip'

export const getPreviewImage = (image, isFLAME = false) => {
  if (isZip(image)) {
    const related = image?.meta?.related_files
    let filename = '0_0.png'
    if (isFLAME) {
      filename = '0_0_FLAME.png'
    }
    const preview = related?.find(img => img.original_filename === filename)
    return preview ?? related?.[0]
  } else {
    return image
  }
}

export const getPreviewImageUrl = (image, isFLAME = false) => {
  const preview = getPreviewImage(image, isFLAME)
  return preview.url
}

const isZip = image => {
  return /\.zip$/.test(image.original_filename) || /\.zip$/.test(image.related_files) 
}

export const downloadZippedMorphStills = async (media, filename) => {
  const stills = getMorphStills(media)

  const files = await Promise.all(
    stills.map(async ({ url, original_filename }) => {
      const response = await fetch(url)
      const data = await response.blob()
      return { path: original_filename, data }
    })
  )

  const zip = await createZip(files)
  downloadFile(zip, filename)
}

/**
 * @param media
 * @returns {{ url: string, original_filename: string, relative_url: string }[]} Still images
 */
export const getMorphStills = media => {
  const providerViews = media?.meta?.provider_views
  if (providerViews && Object.keys(providerViews).length) {
    return ['-90', '-45', '0', '45', '90', 'tilted_up']
      .map(key => providerViews[key])
      .filter(Boolean)
  } else {
    return media?.meta?.related_files?.filter(file => /\.jpg$/.test(file?.url))
  }
}

/**
 * @param {{ path: string, data: * }[]} files Files to include in the zip file
 * @return {Promise<Blob>} Generated zip file
 */
export const createZip = async files => {
  const zip = new JSZip()

  for (const { path, data } of files) {
    zip.file(path, data)
  }

  return await zip.generateAsync({ type: 'blob', compression: 'STORE' })
}

export const downloadFile = (object, filename) => {
  const url = URL.createObjectURL(object)
  try {
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = url
    a.download = filename
    a.click()
    a.remove()
  } finally {
    URL.revokeObjectURL(url)
  }
}
