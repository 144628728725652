import React, { useState, useEffect, useCallback } from 'react'
import Modal from 'simple-react-modal'
import ReactPaginate from 'react-paginate'
import { toastr } from 'react-redux-toastr'
import { authedRequest } from 'config/config'
import { getProfileMaxFlag } from 'config/util'
import ProviderMyMediaForm from './_form'
import ProviderMyMediaList from './_list'
import _ from 'lodash'

const ProviderMyMedia = ({ providerId }) => {
  const [items, setItems] = useState([])
  const [maxItems, setMaxItems] = useState()
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [disabled, setDisabled] = useState(true)
  const [modalValues, setModalValues] = useState()
  const [loading, setLoading] = useState(false)

  const useApiCallback = (func, deps) =>
    useCallback(async (...params) => {
      try {
        await setLoading(true)
        await func(...params)
      } catch (e) {
        toastr.error(e.message)
      } finally {
        await setLoading(false)
      }
    }, deps)

  const fetchProviderMaxItems = useApiCallback(async () => {
    const {
      data: { data, error },
    } = await authedRequest.get(`provider/${providerId}`)
    if (error) {
      throw new Error(error.message)
    }
    const max = getProfileMaxFlag(data, 'provideraboutpictures') // is null if unlimited
    setMaxItems(max)
  }, [providerId])

  const fetchItems = useApiCallback(async () => {
    const params = { _page: page }
    const {
      data: { data, error, meta: { paging: { total_pages = 1 } = {} } = {} },
    } = await authedRequest.get(`provider/${providerId}/my-media`, { params })
    if (error) {
      throw new Error(error.message)
    }
    await setItems(data || [])
    await setTotalPages(total_pages)
  }, [page, providerId])

  const insertItem = useApiCallback(
    async item => {
      const form = convertMyMediaToFormData(item)
      const res = await authedRequest.post(`provider/${providerId}/my-media`, form)
      if (res.data.error) {
        throw new Error(res.data.error.message)
      }
      await setModalValues()
      await fetchItems()
    },
    [providerId]
  )

  const updateItem = useApiCallback(
    async ({ id, ...item }) => {
      const form = convertMyMediaToFormData(item)
      const res = await authedRequest.put(`provider/my-media/${id}`, form)
      if (res.data.error) {
        throw new Error(res.data.error.message)
      }
      await setModalValues()
      await fetchItems()
    },
    [providerId]
  )

  const upsertItem = useApiCallback(
    async item => {
      if (item.id) {
        await updateItem(item)
      } else {
        await insertItem(item)
      }
    },
    [updateItem, insertItem]
  )

  const removeItem = useApiCallback(
    async ({ id }) => {
      if (window.confirm('Are you sure?')) {
        const res = await authedRequest.delete(`provider/my-media/${id}`)
        if (res.data.error) {
          throw new Error(res.data.error.message)
        }
        await setModalValues()
        await fetchItems()
      }
    },
    [providerId]
  )

  const updateOrderNumbers = useApiCallback(
    async items => {
      await Promise.all(
        items.map(async ({ id, meta }) => {
          const form = convertMyMediaToFormData({ meta })
          const res = await authedRequest.put(`provider/my-media/${id}`, form)
          if (res.data.error) {
            throw new Error(res.data.error.message)
          }
        })
      )
      await setModalValues()
      await fetchItems()
    },
    [providerId]
  )

  useEffect(() => {
    fetchProviderMaxItems()
  }, [fetchProviderMaxItems])

  useEffect(() => {
    fetchItems()
  }, [fetchItems])

  useEffect(() => {
    setDisabled(maxItems !== null && items.length >= maxItems)
  }, [maxItems, items.length])

  return (
    <>
      <div className="flex-fill page-form well form-horizontal">
        {loading || items.length ? (
          <ProviderMyMediaList
            items={items}
            loading={loading}
            edit={setModalValues}
            remove={removeItem}
            onSortEnd={updateOrderNumbers}
          />
        ) : (
          <div className="empty" style={{ background: 'white' }}>
            <p className="empty-title h5">You have no about media for this provider</p>
            <p className="empty-subtitle">Click below to get started.</p>
            <div className="empty-action">
              <UploadButton
                setModalValues={setModalValues}
                disabled={disabled}
                maxItems={maxItems}
              />
            </div>
          </div>
        )}
      </div>

      <div className="text-right mb-2">
        <UploadButton
          setModalValues={setModalValues}
          disabled={disabled}
          maxItems={maxItems}
          className="pull-right mb-2"
        />
      </div>

      {!!modalValues && (
        <Modal
          show={!!modalValues}
          onClose={() => setModalValues()}
          containerClassName="modal-content">
          <ProviderMyMediaForm
            initialValues={modalValues}
            onSubmit={upsertItem}
            loading={loading}
          />
        </Modal>
      )}

      <ReactPaginate
        pageCount={totalPages}
        marginPagesDisplayed={1}
        pageRangeDisplayed={5}
        onPageChange={({ selected }) => setPage(selected + 1)}
        containerClassName="pagination"
        activeClassName="active"
        pageClassName="page-item"
        previousClassName="page-item"
        nextClassName="page-item"
      />
    </>
  )
}

const UploadButton = ({ setModalValues, disabled, maxItems, className = '' }) => {
  return (
    <button
      onClick={() => setModalValues({})}
      disabled={disabled}
      className={`btn btn-primary ${className} ${disabled ? 'tooltip tooltip-left' : ''}`}
      data-tooltip={disabled ? `Limit is ${maxItems}` : ''}>
      Upload About Media
    </button>
  )
}

const convertMyMediaToFormData = item => {
  const defaults = {
    meta: {
      upload_to_vimeo: true,
    },
  }
  item = _.merge(item, defaults)

  const form = new FormData()
  const fields = ['media', 'meta']
  for (const [key, value] of Object.entries(item)) {
    if (fields.includes(key)) {
      switch (key) {
        case 'meta':
          form.append(key, JSON.stringify(value))
          break
        default:
          form.append(key, value)
          break
      }
    }
  }
  return form
}

export default ProviderMyMedia
