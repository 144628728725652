import React, {Component} from 'react'
import {Field, reduxForm} from 'redux-form'
import {FormGroup, TextField, RadioField, TextArea, FormFooter} from '../../components/form'
import {validateRequiredFields} from '../../config/util'
import {RADIO_OPTIONS} from '../../config/constants'

class StepForm extends Component {

  render() {
    const {loading, handleSubmit, onFormSubmit, step, invalid, submitFailed} = this.props

    return (
      <form onSubmit={handleSubmit(onFormSubmit)} className="flex-fill page-form well form-horizontal">

        <FormGroup label="Public Name" editable>
          <Field component={TextField} type="text" name="title" placeholder="Public Name" />
        </FormGroup>

        <FormGroup label="Internal Name" editable>
          <Field component={TextField} type="text" name="internal_title" placeholder="Internal Name" />
        </FormGroup>

        <FormGroup label="Summary" helper="Markdown supported in this field!" editable>
          <Field component={TextArea} name="summary" />
        </FormGroup>

        <FormGroup label="Is Active?" editable>
          <Field component={RadioField} name="is_active" items={RADIO_OPTIONS} />
        </FormGroup>

        {
          step &&
          <FormGroup label="Date Created">
            <span>{step.date_created}</span>
          </FormGroup>
        }

        <FormFooter loading={loading} showFailure={invalid && submitFailed} />
      </form>
    )
  }
}

const validate = values => {
  const fields = [
    ['title'],
    ['internal_title'],
    ['summary'],
    ['is_active']
  ]

  return {...validateRequiredFields(values, fields)}
}

export default reduxForm({
  validate: validate,
  form: 'stepForm',
  enableReinitialize: true
})(StepForm)
