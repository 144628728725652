import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { toastr } from 'react-redux-toastr'
import { Form, Input, Submit } from '@aedit/styleguide/dist/form.js'
import { authedRequest } from 'config/config'
import './index.scss'

const ProductForm = props => {
  const { product = {}, providerId } = props
  const [loading, setLoading] = useState(false)
  const [defaultValues, setDefaultValues] = useState(product)
  const history = useHistory()

  const productsRedirect = {
    pathname: `/providers/${providerId}`,
    search: '?tab=1',
  }

  const handleSubmit = async data => {
    const formData = new FormData()

    setLoading(true)
    setDefaultValues(data)

    // Update to use arrays instead of FileList
    for (const [key, value] of Object.entries(data)) {
      if (/product_image/.test(key) && value[0]) {
        formData.append(key, value[0].file)
      } else {
        formData.append(key, value)
      }
    }

    if (product.id) {
      await authedRequest
        .put(`/provider/products/${product.id}`, formData)
        .then(() => toastr.success('Successfully edited Product'))
        .then(() => history.push(productsRedirect))
        .catch(err => toastr.error(err))
        .finally(() => setLoading(false))
    } else {
      await authedRequest
        .post(`/provider/${providerId}/products`, formData)
        .then(() => toastr.success('Successfully created Product'))
        .then(() => history.push(productsRedirect))
        .catch(err => toastr.error(err))
        .finally(() => setLoading(false))
    }
  }

  useEffect(() => {
    const defaultValues = {
      ...product,
      product_image: [
        {
          url: product?.product_image_url,
        },
      ],
    }

    setDefaultValues(defaultValues)

    return () => {}
  }, [product.id])

  return (
    <div className="provider-product-form">
      <Form defaultValues={{ ...defaultValues }} onSubmit={handleSubmit}>
        <Input name="name" isRequired />
        <Input name="url" type="url" isRequired />
        <Input name="description" maxLength={10} />
        <Input name="sku" maxLength={10} isRequired />
        <Input name="product_image" type="file" />
        <Submit isPending={loading} />
      </Form>
    </div>
  )
}

export default ProductForm
