import React, { useState } from 'react'
import { Table } from '../../components/table'
import { Link } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import dayjs from 'dayjs'
import { useAlgoliaSearchTerm } from 'config/hooks'
import { getStripeDashboardUrl } from '../../config/util'
import { Checkmark } from 'components/checkmark'

const consultationsIndex = process.env.REACT_APP_ALGOLIA_ALL_CONSULTATIONS_INDEX

const ConsultationsIndex = () => {
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(0)
  const [consultations] = useAlgoliaSearchTerm(consultationsIndex, {
    setLoading,
    searchOptions: {
      page: currentPage,
    },
  })

  const onPageChange = ({ selected }) => setCurrentPage(selected)

  const tableHeaders = [
    'ID',
    'User',
    'Provider',
    'Video',
    'Confirmed',
    'Instant',
    'Payment',
    'Requested Date',
    'Active',
    'Source Application ID',
    'Date Created (UTC)',
    'Date Confirmed (UTC)',
  ]

  return (
    <div className="primary-content">
      <div className="controls">
        <Link className="pull-right btn btn-primary" to="/consultations/new">
          Add New Consultation
        </Link>
        <h4>Consultations</h4>
      </div>
      <Table items={consultations.hits} loading={loading} headers={tableHeaders}>
        {consultations.hits.map(consult => (
          <tr key={consult.id}>
            <td>
              <Link to={`/consultations/${consult.id}`}>{consult.id}</Link>
            </td>
            <td>
              {consult.user.first_name} {consult.user.last_name}
            </td>
            <td>
              {consult.provider?.first_name} {consult.provider?.last_name}
            </td>
            <td>{consult.is_video_consultation ? `Yes` : `No`}</td>
            <td>
              <Checkmark bool={Boolean(consult.consultation_booked)} />
            </td>
            <td>{consult.is_instant_booking ? `Yes` : `No`}</td>
            <td>
              {Boolean(consult.payment_confirmation) && (
                <a
                  href={getStripeDashboardUrl(`payments/${consult.payment_confirmation}`)}
                  target="_blank"
                  rel="noopener noreferrer">
                  Payment
                </a>
              )}
            </td>
            <td>{consult.consultation_dates ? consult.consultation_dates : 'Instant'}</td>
            <td>
              <Checkmark bool={Boolean(consult.is_active)} />
            </td>
            <td>{consult.application_id}</td>
            <td>
              {dayjs
                .unix(consult.date_created)
                .utc()
                .format('YYYY-MM-DD HH:mm')}
            </td>
            <td>
              {consult.is_instant_booking
                ? dayjs
                    .unix(consult.instant_booking_start_date)
                    .utc()
                    .format('YYYY-MM-DD HH:mm')
                : '-'}
            </td>
          </tr>
        ))}
      </Table>
      <ReactPaginate
        pageCount={consultations.nbPages}
        marginPagesDisplayed={1}
        pageRangeDisplayed={5}
        onPageChange={onPageChange}
        containerClassName="pagination"
        activeClassName="active"
        pageClassName="page-item"
        previousClassName="page-item"
        nextClassName="page-item"
      />
    </div>
  )
}

export default ConsultationsIndex
