const defaultState = {
  loading: false,
  notification: null
}

export default function uiReducer(state = defaultState, action) {
  switch (action.type) {
    case 'LOADING':
      return { ...state, loading: true }
    case 'FAILURE':
      return {
        ...state,
        notification: { type: 'error', message: action.payload.message },
        loading: false
      }
    case 'RESET':
      return {
        ...state,
        notification: null,
        loading: false
      }
    case 'UPDATE_SUCCESS':
      return {
        ...state,
        notification: { type: 'success', message: 'Updated successfully' },
        loading: false
      }
    case 'POST_SUCCESS':
     return {
       ...state,
       notification: { type: 'success', message: 'Created successfully' },
       loading: false
     }
    case 'FETCH_SUCCESS':
      return { ...state, loading: false }
    case 'DELETE_SUCCESS':
      return { ...state, loading: false }
    default:
     return state
  }
}
