import { authedRequest } from '../config/config'

export const fetchProcedureBeforeAfters = procedure_id => async dispatch => {
  dispatch({ type: 'LOADING' })
  try {
    const res = await authedRequest.get(`/procedure/${procedure_id}/before_and_after`)
    const { data: result, error } = res.data
    if (error) {
      throw new Error(error.message)
    }

    dispatch({ type: `FETCHED_PROCEDURE_BEFORE_AFTER_ALL`, payload: result })
    dispatch({ type: 'FETCH_SUCCESS' })
  } catch (err) {
    dispatch({ type: 'FAILURE', payload: err })
  }
}

export const createProcedureBeforeAfter = (procedure_id, data) => async dispatch => {
  dispatch({ type: 'LOADING' })
  try {
    const res = await authedRequest.post(`/procedure/${procedure_id}/before_and_after`, data)
    const { data: result, error } = res.data
    if (error) {
      throw new Error(error.message)
    }

    dispatch({ type: `CREATED_PROCEDURE_BEFORE_AFTER`, payload: result })
    dispatch({ type: 'POST_SUCCESS' })
  } catch (err) {
    dispatch({ type: 'FAILURE', payload: err })
  }
}

export const updateProcedureBeforeAfter = (media_id, data) => async dispatch => {
  dispatch({ type: 'LOADING' })
  try {
    const res = await authedRequest.put(`/procedure/before_and_after/${media_id}`, data)
    const { data: result, error } = res.data
    if (error) {
      throw new Error(error.message)
    }

    dispatch({ type: `UPDATED_PROCEDURE_BEFORE_AFTER`, payload: result })
    dispatch({ type: 'UPDATE_SUCCESS' })
  } catch (err) {
    dispatch({ type: 'FAILURE', payload: err })
  }
}

export const deleteProcedureBeforeAfter = media_id => async dispatch => {
  dispatch({ type: 'LOADING' })
  try {
    const res = await authedRequest.delete(`/procedure/before_and_after/${media_id}`)
    const { error } = res.data
    if (error) {
      throw new Error(error.message)
    }

    dispatch({ type: `DELETED_PROCEDURE_BEFORE_AFTER` })
    dispatch({ type: 'DELETE_SUCCESS' })
  } catch (err) {
    dispatch({ type: 'FAILURE', payload: err })
  }
}

export const createProviderBeforeAfter = (provider_id, data) => async dispatch => {
  dispatch({ type: 'LOADING' })
  try {
    const res = await authedRequest.post(`/provider/${provider_id}/before_and_after`, data)
    const { data: result, error } = res.data
    if (error) {
      throw new Error(error.message)
    }

    dispatch({ type: `CREATED_PROVIDER_BEFORE_AFTER`, payload: result })
    dispatch({ type: 'POST_SUCCESS' })
  } catch (err) {
    dispatch({ type: 'FAILURE', payload: err })
  }
}

export const updateProviderBeforeAfter = (media_id, data) => async dispatch => {
  dispatch({ type: 'LOADING' })
  try {
    const res = await authedRequest.put(`/provider/before_and_after/${media_id}`, data)
    const { data: result, error } = res.data
    if (error) {
      throw new Error(error.message)
    }

    dispatch({ type: `UPDATED_PROVIDER_BEFORE_AFTER`, payload: result })
    dispatch({ type: 'UPDATE_SUCCESS' })
  } catch (err) {
    dispatch({ type: 'FAILURE', payload: err })
    return err
  }
}
