import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

export default function CollapsibleNavItem({ text, items }) {
  const [isOpen, setOpen] = useState(false)
  return (
    <div className={`accordion ${isOpen ? 'open' : ''}`}>
      <label className="accordion-header" onClick={() => setOpen(!isOpen)}>
        <i className="icon icon-arrow-right" /> {text}
      </label>
      <div className="accordion-body">
        <ul className="menu menu-nav">
          {items.map(({ text, to, exact = false }) => (
            <li className="menu-item" key={text}>
              <NavLink exact={exact} to={to}>
                {text}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

CollapsibleNavItem.propTypes = {
  text: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      exact: PropTypes.bool,
    })
  ).isRequired,
}
