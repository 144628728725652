export const filterValid = (getDataFn = a => a) => filterFn => input =>
  getDataFn(input).filter(filterFn)

export const id = a => a

export const noop = () => undefined

export const not = a => !a

export const zip = (a, b, fillValue = undefined) => {
  const [longer, shorter, left] = a.length >= b.length ? [a, b, true] : [b, a, false]
  return longer.map((item, idx) =>
    left ? [item, shorter[idx] || fillValue] : [shorter[idx] || fillValue, item]
  )
}

export const round = (num, decimalPlaces) => {
  if (typeof num !== 'string' && typeof num !== 'number') {
    return undefined
  }

  const parsed = parseFloat(num)
  if (isNaN(parsed)) {
    return undefined
  }

  const q = Math.pow(10, decimalPlaces)
  const r = Math.round((parsed + Number.EPSILON) * q) / q
  return Number(r).toFixed(decimalPlaces)
}
