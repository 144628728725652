const defaultState = {
  face_landmark_detection: [],
  glasses_hair_eye_eyebag_classifier: [],
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case 'FETCHED_ML_MODEL_ALL':
      return {
        ...state,
        face_landmark_detection: action.payload.data.face_landmark_detection,
        glasses_hair_eye_eyebag_classifier: action.payload.data.glasses_hair_eye_eyebag_classifier,
      }
    default:
      return state
  }
}