import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchItem } from 'actions'
import ProviderPlanForm from './_plan_form'
import FlagList from './_flag_list'

const ProviderPlans = ({ providerId, provider, loading, fetchItem }) => {
  const plan = provider.plan || {}
  const flags = provider.flags || []

  useEffect(() => {
    fetchItem('provider', providerId)
  }, [providerId, fetchItem])

  const onFormSubmit = () => {
    fetchItem('provider', providerId)
  }

  return (
    <div className="primary-content">
      <ProviderPlanForm
        provider={provider}
        initialValues={plan}
        onFormSubmit={onFormSubmit}
      />
      <div className="flex-fill mt-2">
        <div className="controls">
          <Link
            to={`/providers/${provider.id}/flags/new`}
            className="btn btn-primary pull-right"
            disabled={!plan.is_active}>
            Add New Flag
          </Link>
          <h4>Flags</h4>
        </div>
        <FlagList plan={plan} flags={flags} loading={loading} />
      </div>
    </div>
  )
}

const mapStateToProps = ({ providers, ui }) => {
  return {
    provider: providers.current,
    loading: ui.loading,
  }
}

export default connect(mapStateToProps, {
  fetchItem,
})(ProviderPlans)
