import React, { Component } from 'react'
import { connect } from 'react-redux'
import ModelForm from './_form'
import { fetchItem, updateModel } from 'actions'

class ModelShow extends Component {
  onFormSubmit = ({ meta, date_deployed, minimum_ios_version, model_file }) => {
    const modelId = this.props.match.params.id

    this.props.updateModel(modelId, {
      meta,
      date_deployed,
      minimum_ios_version,
      model_file,
    })
  }

  componentDidMount() {
    const id = this.props.match.params.id
    this.props.fetchItem('model', id)
  }

  render() {
    const { model, loading, modelTypes } = this.props
    return (
      <div className="primary-content">
        <div className="controls">
          <h4>Model Details</h4>
        </div>
        <ModelForm
          onFormSubmit={this.onFormSubmit}
          initialValues={model}
          loading={loading}
          modelTypes={modelTypes}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ models, ui, config }) => ({
  model: models.current,
  loading: ui.loading,
  modelTypes: Object.values(config.media.MODEL_TYPES || []),
})

export default connect(
  mapStateToProps,
  { fetchItem, updateModel }
)(ModelShow)
