import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

export default class SearchBar extends Component {
  constructor() {
    super()
    this.submitQuery = _.debounce(this.submitQuery, 400)
    this.state = {
      query: '',
    }
  }

  static propTypes = {
    fetchItems: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
  }

  submitQuery = () => {
    const { query } = this.state
    this.props.fetchItems(this.props.type, {
      name: query ? query : null,
      term: query ? query : null,
      searching: query ? true : false,
    })
  }

  handleInput = e => {
    this.setState(
      {
        query: e.target.value,
      },
      this.submitQuery
    )
  }

  render() {
    return (
      <div className="input-group input-inline">
        <div className="has-icon-left">
          <input
            className="form-input"
            type="text"
            placeholder="Search"
            onChange={this.handleInput}
          />
          <i className="form-icon icon icon-search"></i>
        </div>
      </div>
    )
  }
}
