import React, {Component} from 'react'
import {Field, reduxForm} from 'redux-form'
import {
  FormGroup,
  TextField,
  RadioField,
  FormFooter,
  TextArea
} from '../../components/form'
import {validateRequiredFields} from '../../config/util'
import {RADIO_OPTIONS} from '../../config/constants'
import moment from 'moment'

class ReferralProgramForm extends Component {

  render() {
    const {loading, handleSubmit, onFormSubmit, invalid, submitFailed, program} = this.props

    return (
      <form onSubmit={handleSubmit(onFormSubmit)} className="flex-fill page-form well form-horizontal">
        <FormGroup label="Name" editable>
          <Field component={TextField} type="text" name="name" placeholder="Name" />
        </FormGroup>

        <FormGroup label="Blurb" editable>
          <Field
            component={TextArea}
            name="blurb"
            placeholder="Blurb"
          />
        </FormGroup>

        <FormGroup label="Is Active?" editable>
          <Field component={RadioField} name="is_active" items={RADIO_OPTIONS} />
        </FormGroup>

        {
          program && program.date_created &&
          <FormGroup label="Date Created">
            <span>{moment(program.date_created).format('MMMM DD, YYYY')}</span>
          </FormGroup>
        }

        <FormFooter loading={loading} showFailure={invalid && submitFailed} />
      </form>
    )
  }
}

const validate = values => {
  const fields = [
    ['name'],
    ['is_active']
  ]

  return { ...validateRequiredFields(values, fields) }
}

export default reduxForm({
  validate: validate,
  form: 'programForm',
  enableReinitialize: true
})(ReferralProgramForm)
