import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import _ from 'lodash'
import { updateProceduresByProvider, fetchItems } from 'actions'
import { FormGroup, SelectAsync, FormFooter } from 'components/form'

class ProviderProcedureForm extends Component {
  constructor() {
    super()
    this.fetchProcedures = _.debounce(this.fetchProcedures, 300)
  }

  fetchProcedures = query => {
    this.props.fetchItems('procedure', { searching: true, term: query, _limit: 7 })
  }

  onSubmit = ({ procedure_ids }) => {
    const procedureIdArr = procedure_ids.map(obj => obj.id)
    this.props.updateProceduresByProvider(this.props.providerId, { procedure_ids: procedureIdArr })
  }

  render() {
    const {
      loading,
      handleSubmit,
      invalid,
      submitFailed,
      procedures = [],
      initialValues: { procedure_ids: selectedProcedures },
    } = this.props
    const procedureCount = (selectedProcedures || []).length

    return (
      <form
        onSubmit={handleSubmit(this.onSubmit)}
        className="flex-fill page-form well form-horizontal">
        <FormGroup label={`${procedureCount} Procedure${procedureCount === 1 ? '' : 's'}`} editable>
          <Field
            name="procedure_ids"
            component={SelectAsync}
            options={procedures}
            loading={loading}
            clearable={false}
            getItems={this.fetchProcedures}
            labelKey="name"
            valueKey="id"
            multi
          />
        </FormGroup>

        <FormFooter loading={loading} showFailure={invalid && submitFailed} />
      </form>
    )
  }
}

ProviderProcedureForm = reduxForm({
  form: 'providerProcedureForm',
  enableReinitialize: true,
})(ProviderProcedureForm)

function mapStateToProps({ ui }) {
  return {
    loading: ui.loading,
  }
}

export default connect(mapStateToProps, { updateProceduresByProvider, fetchItems })(
  ProviderProcedureForm
)
