import React from 'react'
import { EmptyState } from './empty_state'
import { Loading } from './loading'
import PropTypes from 'prop-types'

const TableHeader = ({ header }) => (
  <th>
    {header}
    <div>{header}</div>
  </th>
)

export const Table = ({ items, loading, headers, children }) => (
  <div className="table-container well">
    <div className="table-wrapper">
      {items.length < 1 && !loading ? (
        <EmptyState message="No results, sorry" />
      ) : (items.length < 1 && loading) || loading ? (
        <Loading color="#333333" />
      ) : (
        <table className="table table-hover">
          <thead>
            <tr>
              {headers.map(header => (
                <TableHeader key={header} header={header} />
              ))}
            </tr>
          </thead>
          <tbody>{children}</tbody>
        </table>
      )}
    </div>
  </div>
)

Table.propTypes = {
  items: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  headers: PropTypes.array.isRequired,
  children: PropTypes.array.isRequired,
}
