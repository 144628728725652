import React, { useState, Fragment } from 'react'
import Modal from 'simple-react-modal'
import { authedRequest } from '../../../config/config'
import { toastr } from 'react-redux-toastr'

const ConfirmationModal = props => {
  const { showModal, closeModal, onConfirm, children } = props

  return (
    <Modal show={showModal} onClose={closeModal}>
      <div className="p2 my-2 text-center">{children}</div>
      <div className="flex-space-around px2 my-2">
        <button
          className="btn"
          name="Cancel"
          aria-label="Cancel"
          type="button"
          onClick={closeModal}>
          Cancel
        </button>
        <button
          className="btn btn-primary"
          name="Confirm"
          aria-label="Confirm"
          type="button"
          onClick={onConfirm}>
          Confirm
        </button>
      </div>
    </Modal>
  )
}

const SendEmailButton = props => {
  const { name, url, children, disabled } = props
  const [showModal, setShowModal] = useState(false)
  const [state, setState] = useState({
    loading: false,
    isSent: false,
  })
  const triggerEmailSend = async () => {
    setState(s => ({ ...s, error: null, loading: true }))
    authedRequest
      .post(url)
      .then(() => setState({ isSent: true, loading: false }))
      .catch(e => {
        setState({ isSent: false, loading: false })
        toastr.error(e.message)
      })
  }
  const onClick = e => {
    e.preventDefault()
    setShowModal(true)
  }
  const onConfirm = () => {
    setShowModal(false)
    triggerEmailSend()
  }
  const { loading, isSent } = state
  return isSent ? (
    'Sent!'
  ) : (
    <Fragment>
      <ConfirmationModal
        onConfirm={onConfirm}
        showModal={showModal}
        closeModal={() => setShowModal(false)}>
        You are about to send an email to a customer. Are you sure?
      </ConfirmationModal>
      <button
        className="btn btn-primary"
        onClick={onClick}
        name={name}
        disabled={disabled || loading}
        aria-label={name.replace(/-/g, ' ')}
        type="button">
        {loading ? 'Sending...' : children}
      </button>
    </Fragment>
  )
}

export default SendEmailButton
