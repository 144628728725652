import React from 'react'

export const DropDown = props => {
  const { flaggedReasons, onDropDownChange, image } = props

  return (
    <select
      className="flagged-reason flagged-reason-dropdown"
      name="flagged_reason"
      onChange={e => onDropDownChange(image, e.target.value)}
      value={image.flagged_reason}>
      <option disabled value="">
        Flagged Reason
      </option>
      {flaggedReasons.map((name, index) => (
        <option value={name} key={index}>
          {name.replace(/_/g, ' ')}
        </option>
      ))}
    </select>
  )
}
