import { authedRequest } from '../config/config'

// this utility function takes an array and returns a Promise but does not dispatch anything.
// this is how you should use it:
//
//  type AnalyticsItem = {
//    entity_type: 'provider' | 'procedure' | 'concern';
//    entity_id: Number;
//    _limit: Number;
//  }
//
//  export const fetchAnalytics = (x = [analyticsItem, ...]) => async dispatch => {
//    dispatch({ type: 'LOADING' })
//    const imWaitingForThis = fetchAnalytics(x)
//    Promise.all(imWaitingForThis).then((res) => {
//      dispatch({ type: 'xxx_RESOLVED', payload: getDataFromRes(res) })
//      })
//
// *** don't export this function! ***

const fetchAnalytics = fetchThis => {
  const fetchThese = Array.isArray(fetchThis) ? fetchThis : [fetchThis]

  return Promise.all(
    fetchThese.map(
      ({ entity_type, entity_id, _limit = 15 }) =>
        entity_type === null
          ? Promise.reject('entity_type must not be null')
          : authedRequest.get('/analytics/entity/views', {
              params: { entity_id, entity_type, _limit },
            })
    )
  )
}

export const fetchEntityAnalytics = ({
  entity_type = null,
  entity_id = null,
}) => async dispatch => {
  dispatch({ type: 'LOADING' })

  try {
    const [res] = await fetchAnalytics({ entity_type, entity_id })
    if (res.data.error) {
      throw new Error(res.data.error.message)
    } else {
      dispatch({ type: 'FETCHED_ENTITY_ANALYTICS', payload: res.data.data })
      dispatch({ type: 'FETCH_SUCCESS' })
    }
  } catch (err) {
    dispatch({ type: 'FAILURE', payload: err })
  }
}

export const fetchTopAnalytics = () => async dispatch => {
  dispatch({ type: 'LOADING' })

  try {
    const results = await fetchAnalytics(
      ['provider', 'procedure', 'concern'].map(p => ({ entity_type: p }))
    )
    results.forEach(res => {
      if (res.data.error) {
        throw new Error(res.data.error.message)
      }
    })
    const payload = {
      provider: results[0].data.data,
      procedure: results[1].data.data,
      concern: results[2].data.data,
    }
    dispatch({ type: 'FETCHED_TOP_ANALYTICS', payload })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'FAILURE', payload: err })
  }
}
