import React from 'react'
import { Table as TableContainer } from 'components/table'

const Table = ({ items, loading, edit, remove }) => {
  return (
    <TableContainer
      items={items}
      loading={loading}
      headers={['Name', 'URL', 'Image', 'Edit', 'Remove']}>
      {items.map((item, i) => (
        <Row
          key={i}
          item={item}
          edit={edit}
          remove={remove}
        />
      ))}
    </TableContainer>
  )
}

const Row = ({ item, edit, remove }) => {
  return (
    <tr>
      <td>{item.name}</td>
      <td>{item.url}</td>
      <td>
        <img
          style={{ width: '100px' }}
          src={item.image_url}
          alt=""
          crossOrigin="anonymous"
        />
      </td>
      <td>
        <button
          className="btn btn-link btn-action btn-lg tooltip tooltip-left"
          data-tooltip="Edit News Item"
          onClick={() => edit(item)}>
          <i className="icon icon-edit" />
        </button>
      </td>
      <td>
        <button
          className="btn btn-link btn-action btn-lg tooltip tooltip-left"
          data-tooltip="Remove News Item"
          onClick={() => remove(item)}>
          <i className="icon icon-cross" />
        </button>
      </td>
    </tr>
  )
}

export default Table
