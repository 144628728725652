import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { authedRequest } from 'config/config'
import ProductForm from '../_form'

const ProviderProductsShow = () => {
  const { provider_id, product_id } = useParams()
  const [product, _setProduct] = useState()

  const fetchProduct = async () => {
    const data = {
      params: {
        provider_id,
      },
    }

    const res = await authedRequest.get(`/provider/products/${product_id}`, data)

    _setProduct(res?.data?.data)
  }

  // Fetch Product
  useEffect(() => {
    fetchProduct(provider_id)
  }, [])

  return (
    <div className="t__products-new">
      <h3>Edit Product {product_id}</h3>
      <ProductForm product={product} providerId={provider_id} />
    </div>
  )
}

export default ProviderProductsShow
