import { useState, useEffect, useCallback } from 'react'
import { createRandomString, buildUrl } from 'config/util'

export const useOauth = () => {
  const [handler, _setHandler] = useState(null)

  useEffect(() => {
    return () => window.removeEventListener('message', handler)
  }, [handler])

  const setHandler = useCallback(newHandler => {
    _setHandler(oldHandler => {
      if (Object.is(oldHandler, newHandler)) {
        return oldHandler
      }

      window.removeEventListener('message', oldHandler)
      window.addEventListener('message', newHandler)
      return newHandler
    })
  }, [])

  const authorize = useCallback(async (authorize_uri, redirect_uri, windowFeatures = null) => {
    return await new Promise(resolve => {
      // We open authorize_uri in a popup and let the user authorize the AEDIT access to the 3rd
      // party service. 3rd party then redirects the user to redirect_uri. The component, which
      // renders the redirect_uri page, then post the query params (authorization code, ...)
      // through message event to the current window.

      const state = createRandomString(10)
      const uri = buildUrl(authorize_uri, { state, redirect_uri })

      const receiveMessage = message => {
        message = message || {}
        const origin = message.origin
        const data = message.data || {}
        const params = data.params || {}

        if (origin !== window.location.origin || data.sender !== redirect_uri) {
          return false // not the message we're interested in
        }

        if (params.state !== state) {
          return false // callback from another popup
        }

        resolve(params)
        return true
      }

      setHandler(receiveMessage)
      window.open(uri, null, windowFeatures)
    })
  }, [])

  return authorize
}

export const useOauthCallback = () => {
  if (window.opener) {
    // if this is a popup, then notify the opener window about the result of oauth call

    const url = new URL(window.location)

    const sender = url.origin + url.pathname
    const params = Object.fromEntries(url.searchParams.entries())

    const message = {
      sender,
      params,
    }

    window.opener.postMessage(message, window.opener.origin)
    window.close()
  }

  window.location = '/'
}
