import React, { useState } from 'react'
import { Table as TableContainer } from 'components/table'
import { rangeOfYears } from 'config/util'

const Table = ({ items, loading, edit, remove }) => {
  return (
    <TableContainer
      items={items}
      loading={loading}
      headers={['Title', 'URL', 'Source', 'Publication Year']}>
      {items.map((item, i) => (
        <Row
          key={i}
          item={item}
          edit={edit}
          remove={remove}
        />
      ))}
    </TableContainer>
  )
}

const Row = ({ item, edit, remove }) => {
  const [state, setState] = useState(item)

  const onChange = ({ target: { name, value } }) => {
    setState({
      ...state,
      [name]: value,
    })
  }

  return (
    <tr>
      <td>
        <input
          className="author-form-fields"
          name="title"
          type="text"
          value={state.title}
          onChange={onChange}
        />
      </td>

      <td>
        <input
          className="author-form-fields"
          type="text"
          name="url"
          value={state.url}
          onChange={onChange}
        />
      </td>

      <td>
        <input
          className="author-form-fields"
          type="text"
          name="source"
          value={state.source}
          onChange={onChange}
        />
      </td>

      <td>
        <select
          value={state.publication_year}
          name="publication_year"
          onChange={onChange}
          className="form-select author-form-fields publication-year">
          {rangeOfYears(1950).map((year, i) => (
            <option value={year} key={i}>
              {year}
            </option>
          ))}
        </select>
      </td>

      <td>
          <span onClick={() => edit(state)} className="save-link" data-tooltip="Edit Publication">
            <i className="fa fa-save" />
          </span>
      </td>

      <td>
          <span onClick={() => remove(state)} data-tooltip="Remove Publication">
            <i className="fas fa-trash" />
          </span>
      </td>
    </tr>
  )
}

export default Table
