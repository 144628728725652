import React, { Fragment, useState, useRef, useEffect } from 'react'
import dayjs from 'dayjs'
import _ from 'lodash'
import { compose } from 'redux'
import { Field, reduxForm } from 'redux-form'
import { FormGroup, RadioField, DateFieldWithConfigurableTimezone, FormFooter, TypeAhead, TextArea } from 'components/form'
import CardList from 'components/payments/card_list'
import { callApi } from 'config/config'
import { RADIO_OPTIONS } from 'config/constants'
import { useAlgoliaSearchTerm } from 'config/hooks'
import { validateRequiredFields } from 'config/util'
import { getConsultationFee, usePayment, withStripe, getVoucherValue } from 'config/util/payment'
import { toastr } from 'react-redux-toastr'

const userIndex = process.env.REACT_APP_ALGOLIA_INDEX_USERS
const providerIndex = process.env.REACT_APP_ALGOLIA_INDEX_PROVIDERS_ALL


const NewConsultationForm = props => {
  const {
    handleSubmit,
    onFormSubmit,
    invalid,
    submitFailed,
    config,
    change,
    untouch,
  } = props
  const [loading, setLoading] = useState(true)
  const [users, searchUsers] = useAlgoliaSearchTerm(userIndex, { setLoading })
  const [providers, searchProviders] = useAlgoliaSearchTerm(providerIndex, {
    setLoading,
    searchOptions: { filters: 'is_active = 1' },
  })

  const [validUser, setValidUser] = useState(null)
  const [validProvider, setValidProvider] = useState(null)
  const [validProcedure, setValidProcedure] = useState(null)
  const [validContactMethod, setValidContactMethod] = useState(null)

  const [makePayment, setMakePayment] = useState(false)
  const { setPaymentMethod, submitPayment } = usePayment(validUser)

  useEffect(() => {
    // clear fields related to payment
    setMakePayment(false)
    const fieldsToClear = ['makePayment', 'paymentMethod']
    for (const field of fieldsToClear) {
      change(field, undefined)
      untouch(field)
    }
  }, [validUser?.id])

  const createConsultation = useRef()
  createConsultation.current = async values => {
    try {
      setLoading(true)

      const { currentUser, provider, procedure, makePayment } = values
      const consultation = serializeConsultation(values)

      if (currentUser && provider && procedure && +makePayment) {
        const paymentConfirmation = await submitPayment(provider.id, procedure.id, consultation)
        consultation.payment_confirmation = paymentConfirmation
      }

      const userId = currentUser.id
      const created = await callApi('post', `/user/${userId}/consultation`, null, consultation)

      await onFormSubmit(created)
    } finally {
      setLoading(false)
    }
  }

  const fetchUser = async id => {
    try {
      setLoading(true)
      const user = await callApi('get', `/user/${id}`)
      await setValidUser(user)
    } catch (e) {
      toastr.error(e.message)
    } finally {
      setLoading(false)
    }
  }

  const providerTz = validProvider?.primary_practice?.location?.timezone

  return (
    <form
      onSubmit={handleSubmit(values => createConsultation.current(values))}
      className="flex-fill page-form well form-horizontal">
      <FormGroup label="User (Search by email)" editable>
        <Field
          name="currentUser"
          component={TypeAhead}
          options={users.hits}
          labelKey="email"
          valueKey="id"
          onChange={algoliaUser => fetchUser(algoliaUser.id)}
          onInputChange={searchUsers}
        />
      </FormGroup>

      <FormGroup label="Provider (Search by last name)" editable>
        <Field
          name="provider"
          component={TypeAhead}
          options={providers.hits}
          optionRenderer={option => option?.first_name + ' ' + option?.last_name}
          valueRenderer={option => option?.first_name + ' ' + option?.last_name}
          valueKey="last_name"
          onChange={setValidProvider}
          onInputChange={searchProviders}
        />
      </FormGroup>

      <FormGroup label="Procedure" editable>
        <Field
          name="procedure"
          component={TypeAhead}
          options={validProvider?.procedures}
          labelKey="name"
          valueKey="id"
          onChange={setValidProcedure}
          disabled={!Boolean(validProvider)}
        />
      </FormGroup>

      {validUser && (
        <Fragment>
          <FormGroup label="Candidate ID">
            <span>{validUser.id}</span>
          </FormGroup>
          <FormGroup label="Candidate Email">
            <span>
              <a href={`/users/${validUser.id}`} target="_blank" rel="noopener noreferrer">
                {validUser.email}
              </a>
            </span>
          </FormGroup>
          <FormGroup label="Candidate First Name">
            <span>{validUser.first_name}</span>
          </FormGroup>
          <FormGroup label="Candidate Last Name">
            <span>{validUser.last_name}</span>
          </FormGroup>
          <FormGroup label="Candidate Age">
            <span>{dayjs().subtract(validUser.dob_year, 'year').year()}</span>
          </FormGroup>
          <FormGroup label="Candidate Gender">
            <span>{validUser.gender}</span>
          </FormGroup>
          <FormGroup label="Candidate Phone">
            <span>{validUser.phone ? validUser.phone : 'N/A'}</span>
          </FormGroup>
        </Fragment>
      )}
      {validProvider && (
        <Fragment>
          <FormGroup label="Provider Salutation">
            <span>{validProvider.salutation}</span>
          </FormGroup>

          <FormGroup label="Provider First Name">
            <span>{validProvider.first_name}</span>
          </FormGroup>

          <FormGroup label="Provider Last Name">
            <span>{validProvider.last_name}</span>
          </FormGroup>
        </Fragment>
      )}

      {validProcedure && (
        <Fragment>
          <FormGroup label="Procedure ID">
            <span>{validProcedure.id}</span>
          </FormGroup>
          <FormGroup label="Procedure Name">
            <span>{validProcedure.name}</span>
          </FormGroup>
        </Fragment>
      )}

      {validUser && validProvider && validProcedure && (
        <Fragment>
          <FormGroup label="Is Video Consultation" editable>
            <Field name="is_video_consultation" component={RadioField} items={RADIO_OPTIONS} />
          </FormGroup>
          <FormGroup label="Practice Timezone">
            <span>{providerTz}</span>
          </FormGroup>
          <FormGroup label="Consultation Date Booked (Practice Timezone)" editable>
            <Field
              name="consultation_date_booked"
              component={DateFieldWithConfigurableTimezone}
              showTimeSelect={true}
              dateFormat="yyyy-MM-dd h:mm aa"
              targetTimezone={providerTz}
            />
          </FormGroup>
          <FormGroup label="Consultation Booked" editable>
            <Field component={RadioField} name="consultation_booked" items={RADIO_OPTIONS} />
          </FormGroup>
          <FormGroup label="Preferred Contact Method" editable>
            <Field
              name="preferred_contact_methods"
              component={TypeAhead}
              options={Object.values(config.PREFERRED_CONTACT_METHODS)}
              labelKey="display_name"
              valueKey="id"
              onChange={setValidContactMethod}
            />
          </FormGroup>
          {validContactMethod?.id === 'phone_call' && (
            <FormGroup label="Candidate Preferred Contact Times" editable>
              <Field
                name="preferred_contact_times"
                component={TypeAhead}
                options={Object.values(config.PREFERRED_CONTACT_TIMES)}
                labelKey="display_name"
                valueKey="id"
              />
            </FormGroup>
          )}
          <FormGroup label="Notes for the Provider">
            <Field name="notes" component={TextArea} />
          </FormGroup>

          <FormGroup label="Make a payment?" editable>
            <Field
              name="makePayment"
              component={RadioField}
              items={RADIO_OPTIONS}
              onChange={(e, value) => setMakePayment(Boolean(+value))}
            />
          </FormGroup>

          {makePayment && (
            <>
              <FormGroup label="Consultation fee">
                <span>${getConsultationFee(validProvider)}</span>
              </FormGroup>

              <FormGroup label="Voucher value">
                <span>${getVoucherValue(validProvider, validProcedure.id)}</span>
              </FormGroup>

              <FormGroup label="Payment method" editable>
                <CardList
                  name="paymentMethod"
                  user={validUser}
                  setUser={setValidUser}
                  setPaymentMethod={setPaymentMethod}
                  showUserLink
                  editable
                />
              </FormGroup>
            </>
          )}
        </Fragment>
      )}

      <FormFooter loading={loading} showFailure={invalid && submitFailed} />
    </form>
  )
}

const validate = values => {
  const requiredFields = [
    ['currentUser'],
    ['provider'],
    ['procedure'],
    ['is_video_consultation'],
    ['consultation_date_booked'],
    ['consultation_booked'],
    ['preferred_contact_methods'],
    ['makePayment'],
  ]

  const errors = {}

  const { makePayment, paymentMethod } = values
  if (+makePayment && !paymentMethod) {
    errors.paymentMethod = 'Field required'
  }

  return {
    ...validateRequiredFields(values, requiredFields),
    ...errors,
  }
}

const serializeConsultation = values => {
  values = { ...values }
  const { currentUser, provider, procedure } = values

  delete values.makePayment
  delete values.paymentMethod
  delete values.currentUser
  delete values.provider
  delete values.procedure

  values.user_id = currentUser.id
  values.provider_id = provider.id
  values.procedure_id = procedure.id
  values.concerning_entities = []
  values.concerning_entities[0] = {
    entity_type: 'user',
    entity_id: currentUser.id,
  }
  values.concerning_entities[1] = {
    entity_type: 'procedure',
    entity_id: procedure.id,
  }

  if (!_.isString(values.preferred_contact_methods)) {
    values.preferred_contact_methods = values.preferred_contact_methods.id
  }

  if (Boolean(values.preferred_contact_times)) {
    values.preferred_contact_times = values.preferred_contact_times.id
  }

  return values
}

export default compose(
  withStripe,
  reduxForm({
    validate: validate,
    form: 'newConsultationForm',
    enableReinitialize: true,
    initialValues: {
      consultation_booked: 1,
    },
  })
)(NewConsultationForm)
