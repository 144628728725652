import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import PlacesAutocomplete from 'react-places-autocomplete'
import { digitsOnly, lettersAndSymbolsOnly, getBase64, formatDateShort } from '../config/util'
import DatePicker from 'react-datepicker'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import _ from 'lodash'

import 'react-datepicker/dist/react-datepicker.css'
import { Field } from 'redux-form'

dayjs.extend(utc)
dayjs.extend(timezone)

export const Base64FileInput = ({ input, meta: { error }, allowPreview = false, previewUrl }) => {
  const [preview, setPreview] = useState()
  useEffect(() => setPreview(previewUrl), [previewUrl])

  const getBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  const onFileChange = async e => {
    const targetFile = e.target.files[0]

    if (targetFile) {
      if (targetFile.size > 1500000) {
        return alert(
          ' File must be smaller than 1.5MB! Go to compressor.io and run the image through there first if need be.'
        )
      }
      const val = await getBase64(targetFile)
      input.onChange(val)
      setPreview(val)
    } else {
      input.onChange(null)
      setPreview()
    }
  }

  return (
    <>
      <input type="file" onChange={onFileChange} />
      {preview && allowPreview && <img src={preview} width="100" alt="" />}
      {error && <span className="field-error">{error}</span>}
    </>
  )
}

const adaptFileEventToValue = delegate => e => delegate(e.target.files[0])

export const FileInput = ({
  input: { value: omitValue, onChange, onBlur, ...inputProps },
  meta: { touched, error, active },
  ...props
}) => (
  <>
    <input
      onChange={adaptFileEventToValue(onChange)}
      onBlur={adaptFileEventToValue(onBlur)}
      type="file"
      {...inputProps}
      {...props}
    />
    {error && <span className="field-error">{error}</span>}
  </>
)

export const TextField = ({
  input,
  label,
  placeholder,
  showLength,
  disabled,
  type,
  className,
  meta: { touched, error, active },
}) => {
  const showError = error && (touched || active)

  return (
    <>
      <label>{label}</label>
      <input
        {...input}
        disabled={disabled ? disabled : false}
        type={type}
        placeholder={placeholder}
        className={`form-input ${showError ? 'error' : ''} ${className ? className : ''}`}
      />
      {showError && <span className="field-error">{error}</span>}
      {active && showLength && <span className="char-data">{input.value.length}</span>}
    </>
  )
}

TextField.defaultProps = {
  type: 'text',
  showLength: true,
}

export const FormGroup = ({
  children,
  label,
  editable,
  helper,
  className = '',
  inputHolderClassName = '',
}) => (
  <div className={`form-group ${className}`}>
    <div className="col-3 label-holder">
      <label className={`form-label ${helper ? 'helper tooltip' : ''}`} data-tooltip={helper || ''}>
        {label}
      </label>
    </div>
    <div className={`col-8 input-holder ${editable ? 'white-bg' : ''} ${inputHolderClassName}`}>
      {children}
    </div>
  </div>
)

export const RadioField = ({ items, input, meta: { touched, error } }) => {
  const showError = touched && error

  const serializeValue = value => typeof value === 'number' ? Number(value).toFixed(0) : value

  return (
    <>
      {items.map(item => {
        const inputValue = serializeValue(input.value)
        const itemValue = serializeValue(item.value)
        const checked = input.value !== '' && inputValue === itemValue
        return (
          <label className="form-radio" key={item.value}>
            <input {...input} value={item.value} checked={checked} type="radio" />
            <i className="form-icon" /> {item.labelText}
          </label>
        )
      })}
      {showError && <span className="field-error">{error}</span>}
    </>
  )
}

RadioField.propTypes = {
  items: PropTypes.array.isRequired,
}

export const SelectField = ({
  children,
  input,
  dropdownText,
  hideDisabledOption,
  meta: { touched, error },
}) => {
  const showError = touched && error
  return (
    <>
      <select className={`form-select ${showError ? 'error' : ''}`} {...input}>
        {!hideDisabledOption && <option disabled value="">{`Select ${dropdownText}`}</option>}
        {children}
      </select>
      {showError && <span className="field-error">{error}</span>}
    </>
  )
}

SelectField.defaultProps = {
  dropdownText: 'One',
  hideDisabledOption: false,
}

export const TextArea = ({
  input,
  placeholder,
  style,
  className = '',
  meta: { touched, error, active },
}) => {
  const showError = error && (touched || active)
  return (
    <>
      <textarea
        {...input}
        placeholder={placeholder}
        style={style}
        className={`form-input ${showError ? 'error' : ''} ${className}`}
      />
      {active && <span className="char-data">{input.value.length}</span>}
      {showError && <span className="field-error">{error}</span>}
    </>
  )
}

export const TypeAhead = ({ input, meta, options, label, ...props }) => {
  const showError = meta.error && meta.touched
  return (
    <>
      <label>{label}</label>
      <Select
        {...props}
        value={input.value}
        onChange={value => input.onChange(value)}
        onBlur={() => input.onBlur(input.value)}
        options={options}
      />
      {showError && <span className="field-error">{meta.error}</span>}
    </>
  )
}

TypeAhead.propTypes = {
  options: PropTypes.array.isRequired,
}

// Google Places Autocomplete
export const gPlacesAutocomplete = ({ input, change, meta: { error, touched, active } }) => {
  const addressStyles = {
    root: 'g-places-container',
    input: 'form-input search-input',
    autocompleteContainer: 'autocomplete-container',
  }

  const handleSelect = (address, placeId) => {
    input.onChange(address)
    change('location.place_id', placeId)
  }

  return (
    <>
      <PlacesAutocomplete
        inputProps={{
          value: input.value,
          onChange: address => input.onChange(address),
        }}
        renderSuggestion={AutocompleteItem}
        classNames={addressStyles}
        onSelect={handleSelect}
      />
      {error && (touched || active) && <span className="field-error">{error}</span>}
    </>
  )
}

gPlacesAutocomplete.propTypes = {
  change: PropTypes.func.isRequired,
}

const AutocompleteItem = ({ formattedSuggestion }) => (
  <div className="suggestion-item">
    <i className="fas fa-map-marker-alt suggestion-icon" />
    <strong>{formattedSuggestion.mainText}</strong>{' '}
    <small className="text-muted">{formattedSuggestion.secondaryText}</small>
  </div>
)

export const FormFooter = ({ showFailure, loading, btnText }) => (
  <footer className="form-footer">
    {showFailure && (
      <span className="invalid-hint">Oops, looks like there is an invalid field!</span>
    )}
    <button
      className={`btn btn-primary ${loading ? 'loading' : ''}`}
      type="submit"
      disabled={loading}>
      {btnText}
    </button>
  </footer>
)

FormFooter.defaultProps = {
  btnText: 'Save',
}
FormFooter.propTypes = {
  loading: PropTypes.bool.isRequired,
}

export const SelectAsync = ({
  getItems,
  input,
  meta,
  labelKey,
  loading,
  multi,
  options,
  valueKey,
  clearable = true,
  ...rest
}) => {
  const showError = meta.error && meta.touched
  let fetchItems = _.debounce(getItems, 400)

  return (
    <>
      <Select
        {...rest}
        value={input.value}
        valueKey={valueKey}
        labelKey={labelKey}
        isLoading={loading}
        clearable={clearable}
        multi={multi || false}
        onInputChange={value => fetchItems(value)}
        onChange={value => input.onChange(value)}
        onBlur={() => input.onBlur(input.value)}
        filterOption={() => true}
        options={options}
      />
      {showError && <span className="field-error">{meta.error}</span>}
    </>
  )
}

SelectAsync.defaultProps = {
  valueKey: 'id',
}

SelectAsync.propTypes = {
  labelKey: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  getItems: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  valueKey: PropTypes.string,
}

export const CustomTag = ({ filterType, multi, meta, input, placeholder }) => {
  const showError = meta.error && meta.touched

  return (
    <>
      <Select.Creatable
        {...input}
        value={input.value}
        placeholder={placeholder}
        multi={multi}
        onChange={values => {
          // below shenanigans bc it had a className property on each object that was causing backend issues
          return input.onChange(values.map(({ value, label }) => ({ ...{}, value, label })))
        }}
        onInputChange={value =>
          filterType === 'digitsOnly' ? digitsOnly(value) : lettersAndSymbolsOnly(value)
        }
        onBlur={() => input.onBlur(input.value)}
      />
      {showError && <span className="field-error">{meta.error}</span>}
    </>
  )
}

CustomTag.propTypes = {
  filterType: PropTypes.string.isRequired,
}

export const DateField = props => {
  const {
    openDate,
    minDate,
    maxDate,
    yearDropdownItemNumber,
    meta,
    input,
    showTimeSelect = false,
    dateFormat = 'yyyy-MM-dd',
  } = props

  const showError = meta.error && meta.touched
  const dateField = document.getElementsByClassName('datepicker')

  //Prevent manual date entry
  for (let i = 0; i < dateField.length; i++) {
    dateField[i].onkeydown = () => {
      return false
    }
  }

  // DatePicker needs to be passed a Date object to process correctly.
  // parse date with dayjs and convert to a date object for the datepicker
  const inputValue = input.value ? dayjs(input.value).toDate() : undefined;

  const openToDate = inputValue ?? openDate ?? maxDate ?? dayjs().toDate()

  return (
    <>
      <DatePicker
        {...input}
        selected={inputValue ?? dayjs().toDate()} // default to current date if no input value
        onBlur={e => undefined /* to avoid a bug that crashes the page. let's get rid of redux-form soon... */}
        onChange={date => input.onChange(formatDateShort(date))}
        openToDate={openToDate}
        minDate={minDate}
        maxDate={maxDate}
        autoComplete="off"
        showMonthDropdown
        useShortMonthInDropdown
        showYearDropdown
        scrollableYearDropdown
        yearDropdownItemNumber={yearDropdownItemNumber}
        fixedHeight
        disabled={props.disabled}
        dateFormat={dateFormat}
        showTimeSelect={showTimeSelect}
        timeFormat="h:mm a"
        timeIntervals={15}
        timeCaption="Time"
        className="datepicker"
      />
      {showError && <span className="field-error">{meta.error}</span>}
    </>
  )
}

export const DateFieldWithConfigurableTimezone = props => {
  const {
    openDate,
    minDate,
    maxDate,
    yearDropdownItemNumber,
    meta,
    input,
    showTimeSelect = false,
    dateFormat = 'yyyy-MM-dd',
    targetTimezone: ttz,
  } = props

  const showError = meta.error && meta.touched
  const dateField = document.getElementsByClassName('datepicker-tz')

  //Prevent manual date entry
  for (let i = 0; i < dateField.length; i++) {
    dateField[i].onkeydown = () => {
      return false
    }
  }

  if (!Boolean(ttz)) {
    return <span>No timezone provided! This is a development issue and should be reported to the web team.</span>
  }

  // DatePicker needs to be passed a Date object to process correctly.
  // the input.value for this field should be a UTC ISO string.
  if (input.value) {
    // parse input.value as UTC, convert it to the target timezone, and get those time values.
    // then create a new Date in our local timezone which has the same time values.
    // the UTC time will be wrong, but the datepicker will show what we expect. 
    // from the user's perspective, it appears that they're selecting a time in the target timezone.
    input.value = new Date(
      dayjs(input.value)
        .tz(ttz)
        .format('YYYY-MM-DD HH:mm')
    )
  }

  const ttzShortName = input.value.toLocaleString('en-us', {timeZone: ttz, timeZoneName: 'short'}).split(' ').find((_, i, a) => i === a.length - 1)

  return (
    <>
      <DatePicker
        {...input}
        selected={input.value ? input.value : dayjs().toDate()}
        onBlur={e => undefined /* to avoid a bug that crashes the page. let's get rid of redux-form soon... */}
        onChange={date => {
          // coerce the date we got to be relative to the target timezone.
          // we can't do this by parsing the date directly in dayjs.
          // the UTC time underlying this date object is the time the user selected, in the browser
          // timezone, converted to UTC.
          // what we want is to parse the date's time values as though they were _already_ in the
          // target timezone, and convert _that_ to UTC.
          // for that, we get the individual time values from the date and set the equivalent values
          // on a new dayjs object which is already in the target timezone.
          // we ignore seconds and milliseconds.
          // after setting the values manually, we convert to UTC and generate an ISO string.
          const targetDateISOString = dayjs()
            .tz(ttz)
            .year(date.getFullYear())
            .month(date.getMonth())
            .date(date.getDate())
            .hour(date.getHours())
            .minute(date.getMinutes())
            .second(0)
            .millisecond(0)
            .utc()
            .toISOString()
          input.onChange(targetDateISOString)
        }}
        openToDate={
          input.value ? input.value : openDate ? openDate : maxDate ? maxDate : new Date()
        }
        minDate={minDate}
        maxDate={maxDate}
        autoComplete="off"
        showMonthDropdown
        useShortMonthInDropdown
        showYearDropdown
        scrollableYearDropdown
        yearDropdownItemNumber={yearDropdownItemNumber}
        fixedHeight
        disabled={props.disabled}
        dateFormat={dateFormat}
        showTimeSelect={showTimeSelect}
        timeFormat="h:mm a"
        timeIntervals={15}
        timeCaption="Time"
        className="datepicker datepicker-tz"
      />
      <span className="ml-1">{ttzShortName}</span>
      {showError && <span className="field-error">{meta.error}</span>}
    </>
  )
}

export const MediaField = props => {
  const { name, initialValue, type, onChange, editable = true, accept = '*', ...fieldProps } = props
  const [value, setValue] = useState()
  const [editing, setEditing] = useState(!initialValue)

  useEffect(() => {
    if (initialValue instanceof File) {
      ;(async () => setValue(await getBase64(initialValue)))()
    } else if (typeof initialValue === 'string') {
      setValue(initialValue)
    }
  }, [initialValue])

  const onClick = () => {
    if (editable) {
      setEditing(true)
      if (onChange) {
        onChange()
      }
    }
  }

  return (
    <>
      {editable && editing ? (
        <Field name={name} type="file" accept={accept} component={FileInput} {...fieldProps} />
      ) : (
        <div onClick={onClick}>
          {type && type.startsWith('video') ? (
            <video src={value} crossOrigin="anonymous" {...fieldProps} />
          ) : (
            <img src={value} alt="" crossOrigin="anonymous" {...fieldProps} />
          )}
          {editable && <span className="link">Remove</span>}
        </div>
      )}
    </>
  )
}
