import { authedRequest } from '../config/config'

const createFlagDefinition = async data => {
  const res = await authedRequest.post(`/plans/flag`, data)
  const { data: result, error } = res.data
  if (error) {
    throw new Error(error.message)
  }
  return result
}

const updateFlagDefinition = async (id, data) => {
  const res = await authedRequest.put(`/plans/flag/${id}`, data)
  const { data: result, error } = res.data
  if (error) {
    throw new Error(error.message)
  }
  return result
}

const createOrUpdateFlagDefinition = async (slug, data) => {
  const res = await authedRequest.get(`/plans/flag/slug/${slug}`)
  const { data: flag, error } = res.data
  if (error) {
    throw new Error(error.message)
  }
  if (flag.id) {
    const result = await updateFlagDefinition(flag.id, data)
    return { definition: result, created: false }
  } else {
    const result = await createFlagDefinition(data)
    return { definition: result, created: true }
  }
}

export const fetchFlagsByPlanDefinition = plan_def_id => async dispatch => {
  dispatch({ type: 'LOADING' })

  try {
    const res = await authedRequest.get(`/plans/plan/${plan_def_id}/flags`)

    if (res.data.error) {
      throw new Error(res.data.error.message)
    }

    const flags = res.data.data.filter(f => !f.account_id)

    dispatch({ type: 'FETCHED_PLAN_FLAG_ALL', payload: flags })
    dispatch({ type: 'FETCH_SUCCESS' })
  } catch (err) {
    dispatch({ type: 'FAILURE', payload: err })
  }
}

export const fetchPlanDefinitionFlag = (plan_def_id, flag_id) => async dispatch => {
  dispatch({ type: 'LOADING' })

  try {
    const res = await authedRequest.get(`/plans/plan/${plan_def_id}/flags/${flag_id}`)

    if (res.data.error) {
      throw new Error(res.data.error.message)
    } else {
      dispatch({ type: 'FETCHED_PLAN_FLAG', payload: res.data.data })
      dispatch({ type: 'FETCH_SUCCESS' })
    }
  } catch (err) {
    dispatch({ type: 'FAILURE', payload: err })
  }
}

export const createPlanDefinitionFlag = (plan_def_id, { definition, flag }) => async dispatch => {
  dispatch({ type: 'LOADING' })

  try {
    const { definition: createdDefinition, created } = await createOrUpdateFlagDefinition(
      definition.slug,
      definition
    )

    // Create flag

    flag = {
      ...flag,
      flag_definition_id: createdDefinition.id,
    }

    const createFlagResponse = await authedRequest.post(`/plans/plan/${plan_def_id}/flags`, flag)
    if (createFlagResponse.data.error) {
      throw new Error(createFlagResponse.data.error.message)
    }

    dispatch({ type: created ? 'CREATED_FLAG' : 'UPDATED_FLAG', payload: createdDefinition })
    dispatch({ type: 'CREATED_PLAN_FLAG', payload: createFlagResponse.data.data })
    dispatch({ type: 'FETCH_SUCCESS' })
  } catch (err) {
    dispatch({ type: 'FAILURE', payload: err })
  }
}

export const updatePlanDefinitionFlag = (
  plan_def_id,
  flag_def_id,
  flag_id,
  { definition, flag }
) => async dispatch => {
  dispatch({ type: 'LOADING' })

  try {
    // Update flag definition. (This is basically the same as doing `updateItem('flag', definition)`,
    // but we need to interrupt the execution in case of an error.)

    const updateDefinitionResponse = await authedRequest.put(
      `/plans/flag/${flag_def_id}`,
      definition
    )
    if (updateDefinitionResponse.data.error) {
      throw new Error(updateDefinitionResponse.data.error.message)
    }

    // Update flag.

    const updateFlagResponse = await authedRequest.put(
      `/plans/plan/${plan_def_id}/flags/${flag_id}`,
      flag
    )
    if (updateFlagResponse.data.error) {
      throw new Error(updateFlagResponse.data.error.message)
    }

    dispatch({ type: 'UPDATED_FLAG', payload: updateDefinitionResponse.data.data })
    dispatch({ type: 'UPDATED_PLAN_FLAG', payload: updateFlagResponse.data.data })
    dispatch({ type: 'FETCH_SUCCESS' })
  } catch (err) {
    dispatch({ type: 'FAILURE', payload: err })
  }
}

export const fetchAccountPlan = (account_type, account_id, plan_id) => async dispatch => {
  dispatch({ type: 'LOADING' })

  try {
    const url = `/${account_type}/${account_id}/plan/${plan_id}`
    const res = await authedRequest.get(url)

    if (res.data.error) {
      throw new Error(res.data.error.message)
    } else {
      dispatch({ type: `FETCHED_${account_type.toUpperCase()}_PLAN`, payload: res.data.data })
      dispatch({ type: 'FETCH_SUCCESS' })
    }
  } catch (err) {
    dispatch({ type: 'FAILURE', payload: err })
  }
}

export const createAccountPlan = (account_type, account_id, data = {}) => async dispatch => {
  dispatch({ type: 'LOADING' })

  try {
    // Create plan

    const url = `/${account_type}/${account_id}/plan`
    const createPlanResponse = await authedRequest.post(url, data)
    if (createPlanResponse.data.error) {
      throw new Error(createPlanResponse.data.error.message)
    }

    // Fetch updated profile

    const getAccountResponse = await authedRequest.get(`/${account_type}/${account_id}`)
    if (getAccountResponse.data.error) {
      throw new Error(getAccountResponse.data.error.message)
    }

    dispatch({
      type: `CREATED_${account_type.toUpperCase()}_PLAN`,
      payload: createPlanResponse.data.data,
    })
    dispatch({
      type: `UPDATED_${account_type.toUpperCase()}`,
      payload: getAccountResponse.data.data,
    })
    dispatch({ type: 'FETCH_SUCCESS' })
  } catch (err) {
    dispatch({ type: 'FAILURE', payload: err })
  }
}

export const updateAccountPlan = (
  account_type,
  account_id,
  plan_id,
  data = {}
) => async dispatch => {
  dispatch({ type: 'LOADING' })

  try {
    // Update plan

    const url = `/${account_type}/${account_id}/plan/${plan_id}`
    const res = await authedRequest.put(url, data)

    if (res.data.error) {
      throw new Error(res.data.error.message)
    }

    // Fetch updated profile

    const getAccountResponse = await authedRequest.get(`/${account_type}/${account_id}`)
    if (getAccountResponse.data.error) {
      throw new Error(getAccountResponse.data.error.message)
    }

    dispatch({ type: `UPDATED_${account_type.toUpperCase()}_PLAN`, payload: res.data.data })
    dispatch({
      type: `UPDATED_${account_type.toUpperCase()}`,
      payload: getAccountResponse.data.data,
    })
    dispatch({ type: 'FETCH_SUCCESS' })
  } catch (err) {
    dispatch({ type: 'FAILURE', payload: err })
  }
}

export const fetchFlagsByAccountPlan = (account_type, account_id, plan_id) => async dispatch => {
  dispatch({ type: 'LOADING' })

  try {
    const url = `/${account_type}/${account_id}/plan/${plan_id}/flags`
    const res = await authedRequest.get(url)

    if (res.data.error) {
      throw new Error(res.data.error.message)
    }

    dispatch({ type: `FETCHED_${account_type.toUpperCase()}_FLAG_ALL`, payload: res.data.data })
    dispatch({ type: 'FETCH_SUCCESS' })
  } catch (err) {
    dispatch({ type: 'FAILURE', payload: err })
  }
}

export const fetchAccountPlanFlag = (
  account_type,
  account_id,
  plan_id,
  flag_id
) => async dispatch => {
  dispatch({ type: 'LOADING' })

  try {
    const url = `/${account_type}/${account_id}/plan/${plan_id}/flags/${flag_id}`
    const res = await authedRequest.get(url)

    if (res.data.error) {
      throw new Error(res.data.error.message)
    }

    dispatch({ type: `FETCHED_${account_type.toUpperCase()}_FLAG`, payload: res.data.data })
    dispatch({ type: 'FETCH_SUCCESS' })
  } catch (err) {
    dispatch({ type: 'FAILURE', payload: err })
  }
}

export const createAccountPlanFlag = (
  account_type,
  account_id,
  plan_id,
  { definition, flag }
) => async dispatch => {
  dispatch({ type: 'LOADING' })

  try {
    const { definition: createdDefinition, created } = await createOrUpdateFlagDefinition(
      definition.slug,
      definition
    )

    // Create flag

    flag = {
      ...flag,
      flag_definition_id: createdDefinition.id,
    }

    const updateFlagResponse = await authedRequest.post(
      `/${account_type}/${account_id}/plan/${plan_id}/flags`,
      flag
    )
    if (updateFlagResponse.data.error) {
      throw new Error(updateFlagResponse.data.error.message)
    }

    dispatch({ type: created ? 'CREATED_FLAG' : 'UPDATED_FLAG', payload: createdDefinition })
    dispatch({
      type: `CREATED_${account_type.toUpperCase()}_FLAG`,
      payload: updateFlagResponse.data.data,
    })
    dispatch({ type: 'FETCH_SUCCESS' })
  } catch (err) {
    dispatch({ type: 'FAILURE', payload: err })
  }
}

export const updateAccountPlanFlag = (
  account_type,
  account_id,
  plan_id,
  flag_def_id,
  flag_id,
  { definition, flag }
) => async dispatch => {
  dispatch({ type: 'LOADING' })

  try {
    // Update the flag definition

    const updateDefinitionResponse = await authedRequest.put(
      `/plans/flag/${flag_def_id}`,
      definition
    )
    if (updateDefinitionResponse.data.error) {
      throw new Error(updateDefinitionResponse.data.error.message)
    }

    // Update the flag

    const updateFlagResponse = await authedRequest.put(
      `/${account_type}/${account_id}/plan/${plan_id}/flags/${flag_id}`,
      flag
    )
    if (updateFlagResponse.data.error) {
      throw new Error(updateFlagResponse.data.error.message)
    }

    dispatch({ type: 'UPDATED_FLAG', payload: updateDefinitionResponse.data.data })
    dispatch({
      type: `UPDATED_${account_type.toUpperCase()}_FLAG`,
      payload: updateFlagResponse.data.data,
    })
    dispatch({ type: 'FETCH_SUCCESS' })
  } catch (err) {
    dispatch({ type: 'FAILURE', payload: err })
  }
}
