import React from 'react'
import moment from 'moment'
import { toastr } from 'react-redux-toastr'

export const MetaData = props => {
  const { image } = props
  const { meta } = image
  const clipboardSupported = Boolean(navigator?.clipboard?.writeText)

  const copyToClipboard = content => {
    if (clipboardSupported) {
      navigator.clipboard.writeText(content)
      toastr.success(`${content} copied to clipboard`)
    }
  }

  return (
    <>
      {meta ? (
        <>
          <span className="caption-item">{meta.name}</span>
          <span className="caption-item">
            {meta.device_model} | Version: {meta.version_number}
          </span>
          {meta.model_name && <span className="caption-item">{meta.model_name}</span>}
        </>
      ) : (
        <span className="caption-item">-</span>
      )}
      <span className="caption-item">
        Date Created: {moment(image.date_created).format('MM/DD/YY')}
      </span>
      <span className="caption-item" onClick={() => copyToClipboard(image.stored_name)}>
        {image.stored_name} {clipboardSupported && <i className="far fa-clipboard pointer ml-1" />}
      </span>
      {image.displayId !== false && (
        <span className="caption-item" onClick={() => copyToClipboard(image.id)}>
          ID: {image.id} {clipboardSupported && <i className="far fa-clipboard pointer ml-1" />}
        </span>
      )}
      {image.download_zip && (
        <a
          href={image.related_files}
          target="_blank"
          rel="noopener noreferrer"
          className="caption-item"
          style={{ color: 'white' }}>
          Download Zip <i className="fas fa-download ml-1 pointer" />
        </a>
      )}
      <span className="caption-item pointer" onClick={() => copyToClipboard(image?.user?.email)}>
        {image?.user?.email}
      </span>
    </>
  )
}
