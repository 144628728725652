import React, { useState, useEffect, useCallback } from 'react'
import ReactPaginate from 'react-paginate'
import { toastr } from 'react-redux-toastr'
import { authedRequest } from 'config/config'
import ProviderFaqTable from './_table'
import ProviderFaqForm from './_form'

const ProviderFaqs = ({ providerId }) => {
  const [items, setItems] = useState([])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [loading, setLoading] = useState(false)

  const useApiCallback = (func, deps) =>
    useCallback(async (...params) => {
      try {
        await setLoading(true)
        await func(...params)
      } catch (e) {
        toastr.error(e.message)
      } finally {
        await setLoading(false)
      }
    }, deps)

  const fetchItems = useApiCallback(async () => {
    const params = { _page: page, _limit: 20 }
    const {
      data: { data, error, meta: { paging: { total_pages = 1 } = {} } = {} },
    } = await authedRequest.get(`provider/${providerId}/faq`, { params })
    if (error) {
      throw new Error(error.message)
    }
    await setItems(data || [])
    await setTotalPages(total_pages)
  }, [page, providerId])

  const insertItem = useApiCallback(
    async item => {
      const res = await authedRequest.post(`provider/${providerId}/faq`, item)
      if (res.data.error) {
        throw new Error(res.data.error.message)
      }
      await fetchItems()
    },
    [providerId]
  )

  const updateItem = useApiCallback(
    async ({ id, ...item }) => {
      const res = await authedRequest.put(`provider/faq/${id}`, item)
      if (res.data.error) {
        throw new Error(res.data.error.message)
      }
      await fetchItems()
    },
    [providerId]
  )

  const removeItem = useApiCallback(
    async ({ id }) => {
      if (window.confirm('Are you sure?')) {
        const res = await authedRequest.delete(`provider/faq/${id}`)
        if (res.data.error) {
          throw new Error(res.data.error.message)
        }
        await fetchItems()
      }
    },
    [providerId]
  )

  useEffect(() => {
    fetchItems()
  }, [fetchItems])

  return (
    <div className="primary-content">
      <ProviderFaqTable items={items} loading={loading} edit={updateItem} remove={removeItem} />

      <ReactPaginate
        pageCount={totalPages}
        marginPagesDisplayed={1}
        pageRangeDisplayed={5}
        onPageChange={({ selected }) => setPage(selected + 1)}
        containerClassName="pagination"
        activeClassName="active"
        pageClassName="page-item"
        previousClassName="page-item"
        nextClassName="page-item"
      />

      <ProviderFaqForm onSubmit={insertItem} loading={loading} />
    </div>
  )
}

export default ProviderFaqs
