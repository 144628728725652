import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import qs from 'query-string'

class TabComponent extends Component {
  state = {
    activeIndex: parseInt(qs.parse(this.props.location.search).tab, 10) || 0,
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props
    if (
      prevState.activeIndex === this.state.activeIndex &&
      prevProps.location.search !== location.search
    ) {
      const activeIndex = parseInt(qs.parse(location.search).tab, 10) || 0
      this.selectTabIndex(activeIndex)
    }
  }

  selectTabIndex = activeIndex => {
    this.setState({ activeIndex })
    this.props.history.push(`?tab=${activeIndex}`)
  }

  render() {
    const children = React.Children.map(this.props.children, child => {
      return React.cloneElement(child, {
        activeIndex: this.state.activeIndex,
        selectTab: this.selectTabIndex,
      })
    })

    return <div className="primary-content">{children}</div>
  }
}

export const Tabs = withRouter(TabComponent)

export class TabList extends Component {
  render() {
    const { activeIndex, selectTab } = this.props
    const children = React.Children.map(this.props.children, (child, index) => {
      if (child) {
        return React.cloneElement(child, {
          isActive: index === activeIndex,
          onSelect: () => selectTab(index),
        })
      }
    })

    return <ul className="tab">{children}</ul>
  }
}

export class Tab extends Component {
  render() {
    const { isActive, title, onSelect } = this.props
    return (
      <li className="tab-item" onClick={onSelect}>
        <span className={`${isActive ? 'active' : ''}`}>{title}</span>
      </li>
    )
  }
}

export class TabPanels extends Component {
  render() {
    return (
      <div className="panels">
        <TabPanel {...this.props} />
      </div>
    )
  }
}

const TabPanel = props => {
  const { activeIndex, children, loading = false } = props

  if (!children.length) {
    return children
  }

  if (loading) {
    return (
      <div className="loading-icon" alt="loading icon">
        <div className="loading loading-lg" />
      </div>
    )
  }

  return children[activeIndex]
}
