import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { createItem, updateItem } from '../../actions'
import { validateRequiredFields, digitsOnly } from '../../config/util'
import { RADIO_OPTIONS } from '../../config/constants'
import {
  FormGroup,
  TextField,
  FileInput,
  FormFooter,
  RadioField,
  TextArea,
  SelectField,
} from '../../components/form'
import TranscriptionEditor, { validateTranscription } from '../../components/transcription'

const VideoForm = props => {
  let {
    className,
    createItem,
    handleSubmit,
    initialValues,
    invalid,
    loading,
    onFormSubmit,
    onSubmitSucceeded,
    procedure,
    procedureId,
    reset,
    submitFailed,
    submitSucceeded,
    updateItem,
  } = props

  initialValues = initialValues || {}

  const {
    original_filename: originalFilename,
    transcription_received_date: transcriptionReceivedDate,
    date_created: dateCreated,
    meta = {},
  } = initialValues

  const editing = Boolean(dateCreated)
  const getTranscription = Number(meta.get_transcription)

  useEffect(() => {
    if (submitSucceeded) {
      reset()
      if (onSubmitSucceeded) {
        onSubmitSucceeded()
      }
    }
  }, [submitSucceeded, reset, onSubmitSucceeded])

  const onSubmit = async data => {
    if (editing) {
      await updateVideo(data)
    } else {
      await createVideo(data)
    }
    if (onFormSubmit) {
      onFormSubmit(data)
    }
  }

  const createVideo = async ({
    media,
    meta: {
      title,
      caption,
      summary,
      short_description,
      get_transcription,
      number_of_speakers,
      vocabulary,
      ...otherMetas
    },
  }) => {
    const data = new FormData()
    data.append('media', media)
    data.append('entity_id', procedureId)
    data.append('entity_type', 'procedure')
    data.append('category', 'procedure_before_after')
    data.append(
      'meta',
      JSON.stringify({
        type: 'video',
        title,
        caption,
        summary,
        upload_to_vimeo: true,
        short_description,
        get_transcription: +get_transcription,
        number_of_speakers: +number_of_speakers,
        vocabulary,
        ...otherMetas,
      })
    )

    await createItem('media', data)
  }

  const updateVideo = async ({ id, meta, aedit_transcription }) => {
    await updateItem('media', { id, meta, aedit_transcription })
  }

  return (
    <form className={className} style={{ overflow: 'scroll' }} onSubmit={handleSubmit(onSubmit)}>
      <FormGroup label="Title" editable>
        <Field component={TextField} type="text" name="meta.title" placeholder="Title" required />
      </FormGroup>

      <FormGroup label="Caption" editable>
        <Field
          component={TextField}
          type="text"
          name="meta.caption"
          placeholder="Caption"
          required
        />
      </FormGroup>

      <FormGroup label="Summary" editable>
        <Field
          component={TextArea}
          type="text"
          name="meta.summary"
          placeholder="Summary"
          required
        />
      </FormGroup>

      <FormGroup label="Video" editable={!editing}>
        {editing ? (
          <div className="justify-space-between">
            <a href={initialValues.url} target="_blank" rel="noopener noreferrer">
              {originalFilename}
            </a>
          </div>
        ) : (
          <Field component={FileInput} name="media" />
        )}
      </FormGroup>

      <FormGroup label="Candidate Name" editable>
        <Field
          component={TextField}
          type="text"
          name="meta.candidate_name"
          placeholder="Candidate Name"
        />
      </FormGroup>

      <FormGroup label="Candidate Age" editable>
        <Field
          component={TextField}
          type="text"
          parse={digitsOnly}
          name="meta.candidate_age"
          placeholder="Candidate Age"
        />
      </FormGroup>

      <FormGroup label="Provider Name" editable>
        <Field
          component={TextField}
          type="text"
          name="meta.provider_name"
          placeholder="Provider Name"
        />
      </FormGroup>

      <FormGroup label="Short Description" editable>
        <Field
          component={TextArea}
          type="text"
          name="meta.short_description"
          placeholder="Short Description"
        />
      </FormGroup>

      <FormGroup label="Transcribe?" editable>
        <Field component={RadioField} name="meta.get_transcription" items={RADIO_OPTIONS} />
      </FormGroup>

      <FormGroup label="Number of Speakers" editable>
        <Field
          component={TextField}
          parse={digitsOnly}
          type="number"
          name="meta.number_of_speakers"
          placeholder="Number of Speakers"
        />
      </FormGroup>

      <FormGroup label="Transcribe Vocabulary Name" editable>
        <Field component={SelectField} name="meta.vocabulary">
          <option value={''}>None</option>
          <option value={'MasterVocab'}>MasterVocab</option>
        </Field>
      </FormGroup>

      {editing && (
        <>
          <FormGroup label="Transcription Status" editable={false}>
            <span>
              {getTranscription ? (transcriptionReceivedDate ? 'Transcribed' : 'Pending') : null}
            </span>
          </FormGroup>

          <FormGroup label="Transcription Date" editable={false}>
            <span>
              {getTranscription && transcriptionReceivedDate
                ? new Date(transcriptionReceivedDate).toLocaleString()
                : null}
            </span>
          </FormGroup>
        </>
      )}

      {procedure?.id && (
        <FormGroup label="Transcription Page">
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="mt-2"
            href={`https://${process.env.REACT_APP_HOSTNAME}/procedure/${procedure.slug}/transcription/${initialValues.id}`}>
            Link to Transcription Page
          </a>
        </FormGroup>
      )}

      {editing && (
        <FormGroup label="Transcription" editable>
          <Field
            component={TranscriptionEditor}
            type="text"
            name="aedit_transcription"
            placeholder="Transcription"
          />
        </FormGroup>
      )}

      <FormFooter loading={loading} showFailure={invalid && submitFailed} />
    </form>
  )
}

const validate = values => {
  const fields = [
    ['media'],
    ['meta.title'],
    ['meta.caption'],
    ['meta.summary'],
    ['meta.provider_name'],
    ['meta.candidate_name'],
    ['meta.candidate_age'],
    ['meta.vocabulary'],
  ]

  return {
    ...validateRequiredFields(values, fields),
    ...validateTranscription('aedit_transcription', values.aedit_transcription),
  }
}

const mapStateToProps = ({ ui }) => {
  return {
    loading: ui.loading,
  }
}

const mapDispatchToProps = {
  createItem,
  updateItem,
}

const createVideoForm = form => {
  const VideoReduxForm = reduxForm({
    validate: validate,
    form,
    enableReinitialize: true,
  })(VideoForm)

  return connect(mapStateToProps, mapDispatchToProps)(VideoReduxForm)
}

export default createVideoForm
