import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Table} from '../../components/table'
import {Link} from 'react-router-dom'
import {fetchItems} from '../../actions'
import SearchBar from '../../components/searchbar'
import ReactPaginate from 'react-paginate'
import {Checkmark} from '../../components/checkmark'
import moment from 'moment'

class CampusIndex extends Component {
  componentDidMount() {
    this.props.fetchItems('campus')
  }

  onPageChange = ({selected}) => {
    this.props.fetchItems('campus', {_page: selected + 1})
  }

  render() {
    const { loading, fetchItems, campuses } = this.props
    const tableHeaders = ['ID', 'Name', 'Active', 'Date Created']

    return (
      <div className="primary-content">
        <div className="controls">
          <Link to="/campus/new" className="btn btn-primary pull-right">Add New University</Link>
          <SearchBar fetchItems={fetchItems} type="campus" />
        </div>
        <Table items={campuses.all} headers={tableHeaders} loading={loading}>
          { campuses.all.map(camp => (
            <tr key={camp.id}>
              <td>{camp.id}</td>
              <td><Link to={`/campus/${camp.id}`}>{camp.name}</Link></td>
              <td><Checkmark bool={!!camp.is_active} /></td>
              <td>{moment(camp.date_created).format('MM-DD-YYYY - h:mm:ss A')}</td>
            </tr>
          ))}
        </Table>
        <ReactPaginate
          pageCount={campuses.totalPages}
          marginPagesDisplayed={1}
          pageRangeDisplayed={5}
          onPageChange={this.onPageChange}
          containerClassName="pagination"
          activeClassName="active"
          pageClassName="page-item"
          previousClassName="page-item"
          nextClassName="page-item"
        />
      </div>
    )
  }
}

const mapStateToProps = ({ ui, campuses }) => {
  return {
    loading: ui.loading,
    campuses: campuses
  }
}

export default connect(mapStateToProps, { fetchItems })(CampusIndex)
