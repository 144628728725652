import React, {Component} from 'react'
import {connect} from 'react-redux'
import StepForm from './_step_form'
import {Link} from 'react-router-dom'
import {fetchItem, updateItem} from '../../actions'

class StepsShow extends Component {

  onFormSubmit = ({procedure_areas, ...submitData}) => {
    this.props.updateItem('step', submitData)
  }

  componentDidMount() {
    const id = this.props.match.params.id
    this.props.fetchItem('step', id)
  }

  render() {
    const { step, loading } = this.props
    return (
      <div className="primary-content">
        <div className="controls">
          <Link
            to={`/procedures/steps/new`}
            className="btn btn-primary pull-right">
            Add New Step
          </Link>
          <h4>{step.title}</h4>
        </div>
        <StepForm
          onFormSubmit={this.onFormSubmit}
          step={step}
          initialValues={step}
          loading={loading}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ steps, ui }) => {
  return {
    step: steps.current,
    loading: ui.loading
  }
}

export default connect(mapStateToProps, {fetchItem, updateItem})(StepsShow)
