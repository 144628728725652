import React, {Component} from 'react'
import {Field, reduxForm} from 'redux-form'
import {FormGroup, TextField, RadioField, FormFooter} from '../../components/form'
import {validateRequiredFields} from '../../config/util'
import {RADIO_OPTIONS} from '../../config/constants'

class RegionForm extends Component {

  render() {
    const { loading, handleSubmit, onFormSubmit, region, invalid, submitFailed } = this.props

    return (
      <form onSubmit={handleSubmit(onFormSubmit)} className="flex-fill page-form well form-horizontal">

        <FormGroup label="Name" editable>
          <Field component={TextField} type="text" name="name" placeholder="Name" />
        </FormGroup>

        <FormGroup label="Is Active?" editable>
          <Field component={RadioField} name="is_active" items={RADIO_OPTIONS} />
        </FormGroup>

        <FormGroup label="Date Created">
          <span>{region.date_created}</span>
        </FormGroup>

        <FormGroup label="Last Updated">
          <span>{region.date_updated}</span>
        </FormGroup>

        <FormFooter loading={loading} showFailure={invalid && submitFailed} />
      </form>
    )
  }
}

const validate = values => {
  const fields = [
    ['name', 100]
  ]

  return {...validateRequiredFields(values, fields)}
}

export default reduxForm({
  validate: validate,
  form: 'areaForm',
  enableReinitialize: true
})(RegionForm)
