import {Checkmark} from '../../components/checkmark'
import SearchBar from '../../components/searchbar'
import {Table} from '../../components/table'
import ReactPaginate from 'react-paginate'
import {fetchItems} from '../../actions'
import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import moment from 'moment'

class ReferralProgramsIndex extends Component {

  componentDidMount() {
    this.props.fetchItems('program')
  }

  onPageChange = ({selected}) => {
    this.props.fetchItems('program', { _page: selected + 1 })
  }

  render() {
    const {loading, programs, fetchItems, totalPages} = this.props
    const tableHeaders = ['ID','Program Name', 'Active', 'Date Created']

    return (
      <div className="primary-content">
        <div className="controls">
          <Link to="/referrals/programs/new" className="btn btn-primary pull-right">Add New Program</Link>
          <SearchBar fetchItems={fetchItems} type="program" />
        </div>
        <Table items={programs} headers={tableHeaders} loading={loading}>
          {
            programs.map(({id, is_active, date_created, name}) => (
              <tr key={id}>
                <td>{id}</td>
                <td><Link to={`/referrals/programs/${id}`}>{name}</Link></td>
                <td><Checkmark bool={!!is_active} /></td>
                <td>{moment(date_created).format('MMM DD, YYYY')}</td>
              </tr>
            ))
          }
        </Table>
        <ReactPaginate
          pageCount={totalPages}
          marginPagesDisplayed={1}
          pageRangeDisplayed={5}
          onPageChange={this.onPageChange}
          containerClassName="pagination"
          activeClassName="active"
          pageClassName="page-item"
          previousClassName="page-item"
          nextClassName="page-item"
        />
      </div>
    )
  }
}


const mapStateToProps = ({ui, referrals}) => {
  return {
    loading: ui.loading,
    programs: referrals.programs,
    totalPages: referrals.totalPages
  }
}

export default connect(mapStateToProps, {fetchItems})(ReferralProgramsIndex)
