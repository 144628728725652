import moment from 'moment'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchItems } from '../../actions'
import { Checkmark } from '../../components/checkmark'
import { Table } from '../../components/table'
import FlagValue from './_flag_value'
import { getDisplayNameForFlagType, getFlagEffectiveValue } from 'config/util'

const FlagList = ({ plan, flags, definitions, loading, fetchItems }) => {

  useEffect(() => {
    fetchItems('flag')
  }, [fetchItems])

  const headers = [
    'ID',
    'Slug',
    'Type',
    'Description',
    'Value',
    'Effective Value',
    'Active',
    'Date Created',
    'Date Updated',
  ]

  return (
    <div className="primary-content">
      <Table items={flags} headers={headers} loading={loading}>
        {flags.filter(flag => flag.account_type === 'provider').map(flag => {

          const definition = definitions.find(def => def.id === flag.flag_definition_id) || {}
          const effectiveValue = getFlagEffectiveValue({ definition, sameAccountTypeFlag: flag })

          return (
            <tr key={flag.id}>
              <td>{flag.id}</td>
              <td><Link to={`/plans/${plan.id}/flags/${flag.id}`}>{definition.slug}</Link></td>
              <td>{getDisplayNameForFlagType(definition.type)}</td>
              <td>{definition.description}</td>
              <td><FlagValue value={flag.value} type={definition.type} /></td>
              <td><FlagValue value={effectiveValue} type={definition.type} /></td>
              <td><Checkmark bool={!!flag.is_active} /></td>
              <td>{moment(flag.date_created).format('MM-DD-YYYY - h:mm:ss A')}</td>
              <td>{moment(flag.date_updated).format('MM-DD-YYYY - h:mm:ss A')}</td>
            </tr>
          )

        })}
      </Table>
    </div>
  )

}

const mapStateToProps = ({ flags, ui }) => {
  return {
    definitions: flags.all,
    loading: ui.loading,
  }
}

export default connect(mapStateToProps, {
  fetchItems,
})(FlagList)
