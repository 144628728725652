import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { Loading } from 'components/loading'
import { callApi } from 'config/config'
import { FormGroup } from 'components/form'
import { useOauth } from 'config/hooks/oauth'
import { isSchedulerAllowed } from 'config/util'
import ProviderAuthorizeSchedulerForm from './_authorize'
import ProviderSchedulerOfficeForm from './_office'

const ProviderScheduler = props => {
  const { providerId, transports } = props
  const [loading, setLoading] = useState(true)
  const [provider, setProvider] = useState(null)
  const [schedule, setSchedule] = useState(null)
  const [offices, setOffices] = useState(null)
  const authorize = useOauth()

  useEffect(() => {
    setProvider(null)
    setSchedule(null)
    setOffices(null)
    fetchProvider(providerId)
  }, [providerId])

  const fetchProvider = async provider_id => {
    setLoading(true)
    try {
      const provider = await callApi('GET', `/provider/${provider_id}`)
      setProvider(provider)
      return provider
    } catch (err) {
      toastr.error(err.message)
      throw err
    } finally {
      setLoading(false)
    }
  }

  const fetchOffices = async schedule_id => {
    setLoading(true)
    try {
      const offices = await callApi('GET', `/schedule/${schedule_id}/drchrono/offices`)
      setOffices(offices)
      return offices
    } catch (err) {
      toastr.error(err.message)
      throw err
    } finally {
      setLoading(false)
    }
  }

  const registerSchedule = async (transport_id, client_id, client_secret) => {
    setLoading(true)
    try {
      const { url } = await callApi('GET', `/schedule/authorize/url`, { transport_id, client_id })
      const redirect_uri = window.location.origin + '/providers/scheduler-callback'

      const { code } = await authorize(url, redirect_uri)
      const schedule = await callApi('POST', `/provider/${providerId}/schedule/register`, null, {
        code,
        transport_id,
        client_id,
        client_secret,
        redirect_uri,
      })
      setSchedule(schedule)
      return schedule
    } catch (err) {
      toastr.error(err.message)
      throw err
    } finally {
      setLoading(false)
    }
  }

  const updateSchedule = async (schedule_id, data) => {
    setLoading(true)
    try {
      const schedule = await callApi('PUT', `/schedule/${schedule_id}`, null, data)
      setSchedule(schedule)
      toastr.success('Scheduler successfully configured')
      return schedule
    } catch (err) {
      toastr.error(err.message)
      throw err
    } finally {
      setLoading(false)
    }
  }

  const onSubmitAuthorizeScheduler = async values => {
    const { transport_id, client_id, client_secret } = values
    const schedule = await registerSchedule(transport_id, client_id, client_secret)
    await fetchProvider(providerId)

    if (schedule.transport_id === transports?.drchrono?.id) {
      await fetchOffices(schedule.id)
    }
  }

  const onSubmitSelectOffice = async values => {
    await updateSchedule(schedule.id, {
      external_practice_id: parseInt(values.external_practice_id),
    })
  }

  if (!provider) {
    return (
      <div className="flex-fill well">
        <Loading />
      </div>
    )
  }

  if (!isSchedulerAllowed(provider)) {
    return (
      <div className="flex-fill well flex-centered">
        <p>Scheduler is not allowed for this provider</p>
      </div>
    )
  }

  return (
    <div className="flex-fill page-form well form-horizontal">
      <FormGroup label="Scheduler Enabled">
        <span>{provider.schedule_enabled ? 'Yes' : 'No'}</span>
      </FormGroup>

      <ProviderAuthorizeSchedulerForm
        transports={transports}
        loading={loading}
        onSubmit={onSubmitAuthorizeScheduler}
      />

      {Boolean(offices) && (
        <ProviderSchedulerOfficeForm
          offices={offices}
          loading={loading}
          onSubmit={onSubmitSelectOffice}
        />
      )}
    </div>
  )
}

const mapStateToProps = ({ config }) => {
  return {
    transports: config?.schedule?.SUPPORTED_TRANSPORTS || {},
  }
}

export default connect(mapStateToProps)(ProviderScheduler)
