import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { toastr } from 'react-redux-toastr'
import { authedRequest } from 'config/config'
import { Table } from 'components/table'
import './index.scss'

const ProviderReviewsIndex = () => {
  const { provider_id } = useParams()
  const [reviews, _setReviews] = useState([])
  const tableHeaders = ['ID', 'Source', 'Star Rating (1-5)', 'Review Count', 'URL']

  const fetchReviews = async () => {
    const data = {
      params: {
        provider_id,
      },
    }

    const res = await authedRequest.get(`/provider/reviews`, data)

    _setReviews(res?.data?.data)
  }

  const onDelete = async review => {
    const data = {
      params: {
        provider_id,
      },
    }

    if (window.confirm('Are you sure?')) {
      await authedRequest
        .delete(`/provider/reviews/${review.id}`, data)
        .then(() => _setReviews(reviews.filter(item => item.id !== review.id)))
        .then(() => toastr.success('Successfully Deleted Review'))
        .catch(err => toastr.error(err))
    }
  }

  // Fetch Products
  useEffect(() => {
    fetchReviews(provider_id)

    return () => {}
  }, [provider_id])

  return (
    <div className="o__reviews-index">
      <div className="add-product-button">
        <Link to={`/providers/${provider_id}/reviews/new`} className="btn btn-primary pull-right">
          Add New Review
        </Link>
      </div>

      <Table loading={false} items={reviews} headers={tableHeaders}>
        {reviews?.map(review => (
          <tr key={review.id}>
            <td>
              <Link to={`/providers/${provider_id}/reviews/${review.id}`}>{review.id}</Link>
            </td>
            <td>{review.source}</td>
            <td>{review.star_rating}</td>
            <td>{review.review_count}</td>
            <td>
              {Boolean(review.url) && (
                <a href={review.url} target="_blank" rel="noopener noreferrer">
                  {review.url}
                </a>
              )}
            </td>
            <td>
              <button
                className="btn btn-link btn-action btn-lg tooltip tooltip-left"
                data-tooltip="Remove"
                onClick={() => onDelete(review)}>
                <i className="icon icon-cross" />
              </button>
            </td>
          </tr>
        ))}
      </Table>
    </div>
  )
}

export default ProviderReviewsIndex
export { default as ProviderReviewsNew } from './new'
export { default as ProviderReviewsShow } from './show'
