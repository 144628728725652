import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

const PlansFlagList = ({ match }) => {
  return (
    <Redirect to={`/plans/${match.params.id}?tab=1`} />
  )
}

export default connect(null)(PlansFlagList)