import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  fetchItem,
  fetchAccountPlanFlag,
  updateAccountPlanFlag,
  fetchFlagsByPlanDefinition,
  fetchFlagsByAccountPlan,
} from 'actions'
import store from 'reducers'
import FlagForm from '../_form'
import { createEntitiesFromProps, createPropsFromEntities } from '../_util'

const PlansFlagShow = ({
  provider,
  accountFlag,
  sameAccountTypeFlag,
  definition,
  loading,
  match,
  fetchItem,
  fetchAccountPlanFlag,
  updateAccountPlanFlag,
  fetchFlagsByPlanDefinition,
  fetchFlagsByAccountPlan,
}) => {
  const { id: providerId, flag_id: flagId } = match.params
  const plan = provider.plan || {}
  const planId = plan.id
  const planDefinitionId = plan.plan_definition_id
  const flagDefinitionId = accountFlag.flag_definition_id

  useEffect(() => {
    fetchItem('provider', providerId)
  }, [fetchItem, providerId])

  useEffect(() => {
    if (planId) {
      fetchAccountPlanFlag('provider', providerId, planId, flagId)
    }
  }, [fetchAccountPlanFlag, providerId, planId, flagId])

  useEffect(() => {
    if (planDefinitionId) {
      fetchFlagsByPlanDefinition(planDefinitionId)
    }
  }, [fetchFlagsByPlanDefinition, planDefinitionId])

  useEffect(() => {
    if (flagDefinitionId) {
      fetchItem('flag', flagDefinitionId)
    }
  }, [fetchItem, flagDefinitionId])

  useEffect(() => {
    // clear out the currentFlag object so that its properties don't potentially override
    // another flag's
    return () => {
      store.dispatch({
        type: 'UPDATED_PROVIDER_FLAG',
        payload: {},
      })
      store.dispatch({
        type: 'UPDATED_FLAG',
        payload: {},
      })
    }
  }, [])

  const onFormSubmit = async data => {
    const entities = createEntitiesFromProps(data)
    await updateAccountPlanFlag('provider', providerId, planId, flagDefinitionId, flagId, entities)
    await fetchFlagsByAccountPlan('provider', providerId, planId)
  }

  return (
    <div className="primary-content">
      <div className="controls">
        <h4>{definition.slug}</h4>
      </div>
      <FlagForm
        onFormSubmit={onFormSubmit}
        loading={loading}
        initialValues={createPropsFromEntities({ definition, sameAccountTypeFlag, accountFlag })}
      />
    </div>
  )
}

const mapStateToProps = ({ providers, plans, flags, ui }) => {
  return {
    provider: providers.current,
    accountFlag: providers.currentFlag,
    sameAccountTypeFlag: plans.currentFlags.find(
      f =>
        f.flag_definition_id === providers.currentFlag.flag_definition_id &&
        f.account_type === 'provider'
    ),
    definition: flags.currentFlag,
    loading: ui.loading,
  }
}

export default connect(mapStateToProps, {
  fetchItem,
  fetchAccountPlanFlag,
  updateAccountPlanFlag,
  fetchFlagsByPlanDefinition,
  fetchFlagsByAccountPlan,
})(PlansFlagShow)
