import ImageList from '../../components/image_list'
import { fetchMedia } from '../../actions'
import { Table } from '../../components/table'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactPaginate from 'react-paginate'

class Aedits extends Component {
  componentDidMount() {
    this.props.fetchMedia({
      entity_id: this.props.userId,
      category: 'my_aedits',
    })
  }

  onPageChange = ({ selected }) => {
    this.props.fetchMedia({
      entity_id: this.props.userId,
      category: 'my_aedits',
      _page: selected + 1,
      _limit: 25,
    })
  }

  render() {
    const { aedits, loading, pageCount } = this.props
    const aeditData = aedits.map(aedit => ({
      displayId: false,
      id: aedit.id,
      date_created: aedit.date_created,
      url: aedit.url,
    }))

    if (aeditData?.length) {
      return (
        <>
          <ImageList items={aeditData} />
          <ReactPaginate
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={5}
            onPageChange={this.onPageChange}
            containerClassName="pagination"
            activeClassName="active"
            pageClassName="page-item"
            previousClassName="page-item"
            nextClassName="page-item"
          />
        </>
      )
    }

    return (
      <Table items={[]} headers={[]} loading={false}>
        {[]}
      </Table>
    )
  }
}

const mapStateToProps = ({ ui, media }) => {
  return {
    loading: ui.loading,
    aedits: media.all,
    pageCount: media.totalPages,
  }
}

export default connect(
  mapStateToProps,
  { fetchMedia }
)(Aedits)
