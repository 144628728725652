import React, { memo } from 'react'
import { useField, useFormikContext } from 'formik'
import './index.scss'

let ba_default = {
  meta: {
    after_count: '',
    after_length_of_time: { label: '', value: '' },
    alt_caption: '',
    caption: '',
  },
}
// THIS ONLY WORKS FOR BA
const FileUpload = memo(props => {
  const {
    className = '',
    accept = '*',
    maxNumOfFiles = 1,
    multiple = maxNumOfFiles === 1 ? false : true, // turn off multiple if maxNumOfFiles at 1
    maxFileSizeInMb = 2,
    allowPreview = false,
    uploadText = 'Upload File',
    name,
    fieldArrayHelpers,
    children,
  } = props

  const { setFieldTouched } = useFormikContext()
  let [{ value = {} }] = useField({ ...props })
  value = Array.isArray(value) ? value : [value]

  const fileErrorHandler = (e, text) => {
    alert(text)

    return (e.target.value = '')
  }

  const addFile = e => {
    const targetFiles = Array.from(e.target.files)
    const mbMax = maxFileSizeInMb * 750000

    targetFiles.forEach((file, index) => {
      if (!file) return false
      if (file.size > mbMax) return fileErrorHandler(e, 'Please lower file size to below 1.5mb')
      if (index + 1 > maxNumOfFiles) {
        return fileErrorHandler(e, `Exceeded max file limit of ${maxNumOfFiles}!`)
      }

      let preview = URL.createObjectURL(file)

      // Make sure to pass in previous data into the fieldArray via ...value[index]
      value[index] && (value[index].file || value[index].url)
        ? fieldArrayHelpers.unshift({ ...ba_default, file, preview })
        : fieldArrayHelpers.replace(index, { ...value[index], file, preview })
    })
  }

  const editFile = async (e, index) => {
    const file = e.target.files[0]
    const preview = URL.createObjectURL(file)

    // Make sure to pass in previous data into the fieldArray via ...value[index]
    fieldArrayHelpers.replace(index, { ...value[index], file, preview })
  }

  const removeFile = async index => fieldArrayHelpers.remove(index)

  return (
    <div className={className}>
      {value.map((file, index) => {
        if ((file.preview || file.url) && allowPreview) {
          return (
            <div className="file-container" key={`file-upload-${index}`}>
              <span className="image-wrapper">
                <img crossOrigin="Anonymous" src={file.preview || file.url} alt="" />
                <input
                  id={`file-${name}-${index}`}
                  name={`${name}_upload`}
                  type="file"
                  className="upload-container--input"
                  accept={accept}
                  multiple={multiple}
                  onChange={e => editFile(e, index)}
                />
                <label htmlFor={`file-${name}-${index}`}>
                  <i className="icon icon-edit" />
                </label>
              </span>

              {children({ index })}

              <i className="icon icon-cross" onClick={() => removeFile(index)} />
            </div>
          )
        }

        return null
      })}

      {value.length < maxNumOfFiles && (
        <div className="upload-container">
          <input
            id={`upload-${name}`}
            name={`${name}_upload`}
            type="file"
            className="upload-container--input"
            accept={accept}
            multiple={multiple}
            onClick={() => setFieldTouched(name, true, true)}
            onChange={e => addFile(e)}
          />

          <label className="upload-container--label" htmlFor={`upload-${name}`}>
            <i className="icon-link icon mr-2" />
            {uploadText}
          </label>
        </div>
      )}
    </div>
  )
})

export default FileUpload
