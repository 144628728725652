/*eslint no-unused-vars: ["error", { "varsIgnorePattern": "toggled" }]*/

const defaultState = {
  all: [],
  count: 0,
  totalPages: 0,
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case 'FETCHED_MEDIA':
    case 'FETCHED_FLAGGED_ALL':
    case 'FETCHED_PRESCANS_ALL':
      // more than 0 results
      if (action.payload.meta.paging) {
        const { total_results, total_pages } = action.payload.meta.paging
        return {
          ...state,
          all: action.payload.data,
          count: total_results,
          totalPages: total_pages,
        }
      } else {
        // no results
        return { ...state, ...defaultState }
      }

    // Flag image
    case 'UPDATED_MEDIA':
      return {
        ...state,
        all: state.all.map(x => (x.id === action.payload.id ? action.payload : x)),
      }
    case 'DELETED_MEDIA':
      return {
        ...state,
        all: state.all.filter(item => item.id !== action.payload),
      }
    case 'REORDERED_IMAGES':
      return {
        ...state,
        all: action.payload,
      }
    case 'CREATED_MEDIA':
      return {
        ...state,
        all: [...state.all, ...action.payload],
      }
    // Deleting image from training set (S3 Bucket)
    case 'DELETED_TRAINING_STATUS':
      if (!action.payload) {
        return state
      }
      return {
        ...state,
        all: state.all.map(image => {
          if (action.payload && image.id !== action.payload.id) {
            return image
          }
          return { ...image, ...action.payload }
        }),
      }
    // Adding image to training set (S3 Bucket)
    case 'UPDATED_TRAINING_STATUS':
      return {
        ...state,
        all: state.all.map(image => {
          if (action.payload && image.id !== action.payload.id) {
            return image
          }
          return { ...image, ...action.payload }
        }),
      }
    default:
      return state
  }
}
