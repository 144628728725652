import React, {Component} from 'react'
import {Field, reduxForm} from 'redux-form'
import {TextField, FormGroup} from '../../components/form'
import {validateRequiredFields} from '../../config/util'

class PasswordForm extends Component {

  render() {
    const { handleSubmit, loading, valid, pristine, onFormSubmit} = this.props
    return (
      <form onSubmit={handleSubmit(onFormSubmit)} className="flex-fill page-form well form-horizontal">
        <FormGroup label="New Password" editable>
          <Field component={TextField} type="password" name="password" />
        </FormGroup>
        <FormGroup label="Confirm Password" editable>
          <Field component={TextField} type="password" name="password_confirmation" />
        </FormGroup>
        <footer className="form-footer">
          <button
            className={`btn btn-primary ${loading ? 'loading' : ''}`}
            type="submit"
            disabled={pristine || !valid}>
            Save Changes
          </button>
        </footer>
      </form>
    )
  }
}

const validate = values => {
  const {password, password_confirmation} = values
  const errors = {}
  const fields = [
    ['password'],
    ['password_confirmation']
  ]

  if (password && password_confirmation && (password_confirmation !== password)) {
    errors.password_confirmation = 'Passwords do not match'
  }

  return {...validateRequiredFields(values, fields), ...errors}
}

export default reduxForm({
  validate: validate,
  form: 'userPasswordForm',
})(PasswordForm)
