import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { FormGroup, SelectField, FormFooter } from 'components/form'
import { validateRequiredFields } from 'config/util'

const ProviderSchedulerOfficeForm = props => {
  const { handleSubmit, invalid, loading, onSubmit, submitFailed, offices } = props

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="page-form form-horizontal">
      <FormGroup label="Office" editable>
        <Field name="external_practice_id" component={SelectField} hideDisabledOption>
          <option key="" value="">
            Select One
          </option>
          {Object.values(offices).map(({ id, name, address, city, state }) => {
            const formattedAddress = [address, city, state].filter(Boolean).join(', ')
            return (
              <option key={id} value={id}>
                {name} ({formattedAddress})
              </option>
            )
          })}
        </Field>
      </FormGroup>

      <FormFooter loading={loading} showFailure={invalid && submitFailed} btnText="Save" />
    </form>
  )
}

const validate = values => {
  const requiredFields = [['transport_id'], ['client_id'], ['client_secret']]

  return validateRequiredFields(values, requiredFields)
}

export default reduxForm({
  validate: validate,
  form: 'providerSchedulerOfficeForm',
  enableReinitialize: true,
})(ProviderSchedulerOfficeForm)
