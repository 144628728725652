import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { fetchItems } from '../../actions'
import TopViews from './top_views.js'
import HelpfulStatistics from './helpful'

const StatisticsIndex = ({ fetchItems, providerStats, userStats, newsletterStats }) => {
  useEffect(() => {
    fetchItems('statistics')
  }, [fetchItems])

  return (
    <>
      <div className="primary-content">
        <div className="controls">
          <a
            href="http://status.aedit.com/"
            className="text-warning"
            target="_blank"
            rel="noopener noreferrer">
            <i className="fas fa-signal" /> Infrastructure Status &rsaquo;
          </a>

          <a
            href="http://aedit-lighthouse-report.aedit.com/app/projects/mainsite"
            className="ml-2"
            target="_blank"
            rel="noopener noreferrer">
            <i className="fas fa-tachometer-alt" /> Lighthouse Report &rsaquo;
          </a>
        </div>
        <div className="table-container well mb-2" style={{ padding: 20 }}>
          <h4>User Sign-Ups</h4>
          <div className="stats">
            <section>
              <strong>Aeditor</strong>
              <span>{userStats.iOS}</span>
            </section>

            <section>
              <strong>Website</strong>
              <span>{userStats.Web}</span>
            </section>

            <section>
              <strong>Total</strong>
              <span>{userStats.total}</span>
            </section>
          </div>

          <h4>Provider Sign-Ups</h4>
          <div className="stats">
            <section>
              <strong>Active</strong>
              <span>{providerStats.total_active}</span>
            </section>
            <section>
              <strong>Publicly Viewable</strong>
              <span>{providerStats.total_publicly_viewable}</span>
            </section>
            <section>
              <strong>Total</strong>
              <span>{providerStats.total}</span>
            </section>
          </div>

          <h4>Newsletter Sign-Ups</h4>
          <div className="stats">
            <section>
              <strong>Total</strong>
              <span>{newsletterStats.total}</span>
            </section>
          </div>

          <hr />

          <TopViews />

          <hr />

          <HelpfulStatistics />
        </div>
      </div>
    </>
  )
}

const mapStateToProps = ({ statistics }) => ({
  providerStats: statistics.providers,
  userStats: statistics.users,
  newsletterStats: statistics.newsletter,
})

export default connect(
  mapStateToProps,
  { fetchItems }
)(StatisticsIndex)
