import React, {Component, Fragment} from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'

export default class CopyPaste extends Component {
  state = {
    copied: false
  }

  render() {
    const {text} = this.props
    return (
      <Fragment>
        <span>{text}</span>
        <CopyToClipboard text={text} onCopy={() => this.setState({copied: true})}>
          <button className="btn btn-link copy" onClick={(e) => e.preventDefault()}>
            <i className="fas fa-copy"></i>
          </button>
        </CopyToClipboard>
      </Fragment>
    )
  }
}
