import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchFlagsByPlanDefinition, fetchItem, updateItem } from '../../actions'
import { Tab, TabList, TabPanels, Tabs } from '../../components/tabs'
import store from '../../reducers'
import FlagList from './_flag_list'
import PlanForm from './_plan_form'

const PlansShow = ({
  currentPlan,
  currentFlags,
  loading,
  match,
  fetchFlagsByPlanDefinition,
  fetchItem,
  updateItem,
}) => {

  useEffect(() => {
    const id = match.params.id
    fetchItem('plan', id)
    fetchFlagsByPlanDefinition(id)

    // clear out the currentPlan object so that its properties don't potentially override
    // another plan's
    return () => {
      store.dispatch({
        type: 'UPDATED_PLAN',
        payload: {},
      })
    }
  }, [fetchFlagsByPlanDefinition, fetchItem, match.params.id])

  return (
    <div className="primary-content">
      <div className="controls">
        <Link to={`/plans/${currentPlan.id}/flags/new`} className="btn btn-primary pull-right">
          Add New Flag
        </Link>
        <h4>{currentPlan.name}</h4>
      </div>
      <Tabs>
        <TabList>
          <Tab title="General" />
          <Tab title="Flags" />
        </TabList>
        <TabPanels>
          <PlanForm
            onFormSubmit={data => updateItem('plan', data)}
            loading={loading}
            initialValues={currentPlan}
          />
          <FlagList
            plan={currentPlan}
            flags={currentFlags}
            loading={loading}
          />
        </TabPanels>
      </Tabs>
    </div>
  )

}

const mapStateToProps = ({ plans, ui }) => {
  return {
    currentPlan: plans.currentPlan,
    currentFlags: plans.currentFlags,
    loading: ui.loading,
  }
}

export default connect(mapStateToProps, {
  fetchFlagsByPlanDefinition,
  fetchItem,
  updateItem,
})(PlansShow)
