export const RADIO_OPTIONS = [
  { labelText: 'Yes', value: 1 },
  { labelText: 'No', value: 0 },
]

// URL starts with HTTP/HTTPS:
export const VALID_URL = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/
export const VALID_PHONE = /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/

export const CONTENTFUL_ENTRY_BASE_URL = `https://app.contentful.com/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE}/entries`

export const AREA_APPROVED_IMAGES =
  'https://s3.amazonaws.com/assets.aedit.com/website/pages/homepage/areas/areas_approved_images.zip'

export const AREA_THUMBNAIL_APPROVED_IMAGES =
  'https://s3.amazonaws.com/assets.aedit.com/website/pages/homepage/areas/areas_approved_images.zip'

export const AREA_ICON_APPROVED_IMAGES =
  'https://s3.amazonaws.com/assets.aedit.com/website/pages/providers/provider_profile/20200218_AREA_ICONS.zip'


export const CONCERN_APPROVED_IMAGES =
  'https://s3.amazonaws.com/assets.aedit.com/website/pages/concerns/main_images/concerns.zip'

export const CONCERN_THUMBNAIL_APPROVED_IMAGES =
  'https://s3.amazonaws.com/assets.aedit.com/website/pages/concerns/main_images/thumbnails/concerns_thumbnails.zip'

export const PROCEDURE_APPROVED_IMAGES =
  'https://s3.amazonaws.com/assets.aedit.com/website/pages/procedures/procedures.zip'

export const PROCEDURE_THUMBNAIL_APPROVED_IMAGES =
  'https://s3.amazonaws.com/assets.aedit.com/website/pages/procedures/thumbnails/approved_thumbnails.zip'

export const PROVIDER_APPROVED_IMAGES =
  'https://s3.amazonaws.com/assets.aedit.com/website/pages/providers/hero_images/approved_provider.zip'

export const PRACTICE_APPROVED_IMAGES =
  'https://s3.amazonaws.com/assets.aedit.com/website/pages/practices/hero_images/approved_practice_images.zip'

export const DEFAULT_PROCEDURE_VALUES = {
  procedure_areas: [{ region_id: '01' }],
  min_recovery_frequency: 'weeks',
  avg_recovery_frequency: 'weeks',
  max_recovery_frequency: 'weeks',
  min_frequency_period: 'lifetime',
  min_completion_period: 'lifetime',
  avg_frequency_period: 'lifetime',
  avg_completion_period: 'lifetime',
  max_frequency_period: 'lifetime',
  max_completion_period: 'lifetime',
  avg_upkeep_frequency_period: 'weekly',
  min_procedure_cost_type: 'overall',
  max_procedure_cost_type: 'overall',
  avg_procedure_cost_type: 'overall',
  is_active: 0,
  // Removed these values because otherwise the save on the parent procedures
  // outpatient: 0,
  // min_frequency_unit: 1,
  // avg_frequency_unit: 1,
  // max_frequency_unit: 1
}

export const FLAG_TYPES = {
  BOOL: {
    id: 'bool',
    display_name: 'Boolean',
  },
  INT_RANGE: {
    id: 'int_range',
    display_name: 'Range',
  },
}

export const MB = 1000 * 1000

export const MAX_USER_CARDS = 3

export const SUBSCRIPTION_EXTERNAL_SOURCES = {
  APPLE: 'apple',
  STRIPE: 'stripe',
}
