import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchItems } from '../../actions'
import { Table } from '../../components/table'
import { Link } from 'react-router-dom'
import SearchBar from '../../components/searchbar'
import ReactPaginate from 'react-paginate'
import moment from 'moment'

class PracticesIndex extends Component {
  componentDidMount() {
    this.props.fetchItems('practice')
  }

  onPageChange = ({ selected }) => {
    this.props.fetchItems('practice', { _page: selected + 1 })
  }

  render() {
    const { practices, loading, fetchItems } = this.props
    const tableHeaders = ['ID', 'Name', 'Contact Name', 'Active', 'Date Created']

    return (
      <div className="primary-content">
        <div className="controls">
          <Link className="pull-right btn btn-primary" to="/practices/new">
            Add New Practice
          </Link>
          <SearchBar fetchItems={fetchItems} type="practice" />
        </div>
        <Table items={practices.all} loading={loading} headers={tableHeaders}>
          {practices.all.map(practice => (
            <tr key={practice.id}>
              <td>{practice.id}</td>
              <td>
                <Link to={`/practices/${practice.id}`}>{practice.name}</Link>
              </td>
              <td>{practice.contact_name ? practice.contact_name : '-'}</td>
              <td>{practice.is_active}</td>
              <td>{moment(practice.date_created).format('MM-DD-YYYY - h:mm:ss A')}</td>
            </tr>
          ))}
        </Table>
        <ReactPaginate
          pageCount={practices.totalPages}
          marginPagesDisplayed={1}
          pageRangeDisplayed={5}
          onPageChange={this.onPageChange}
          containerClassName="pagination"
          activeClassName="active"
          pageClassName="page-item"
          previousClassName="page-item"
          nextClassName="page-item"
        />
      </div>
    )
  }
}

const mapStateToProps = ({ practices, ui }) => {
  return {
    loading: ui.loading,
    practices: practices,
  }
}

export default connect(
  mapStateToProps,
  { fetchItems }
)(PracticesIndex)
