import React, {Component} from 'react'
import {connect} from 'react-redux'
import {fetchItems} from '../../actions'
import {Table} from '../../components/table'
import {Link} from 'react-router-dom'
import {Checkmark} from '../../components/checkmark'
import ReactPaginate from 'react-paginate'
import PropTypes from 'prop-types'
import moment from 'moment'

class RegionsIndex extends Component {

  componentDidMount() {
    this.props.fetchItems('region')
  }

  onPageChange = ({selected}) => {
    this.props.fetchItems('region', { _page: selected + 1 })
  }

  render() {
    const { regions, loading } = this.props
    const tableHeaders = ['Name', 'ID', 'Active', 'Date Created']

    return (
      <div className="primary-content">
        <div className="controls">
          <Link to="/procedures/regions/new" className="btn btn-primary pull-right">Add New Region</Link>
          <h4>Procedure Regions</h4>
        </div>
        <Table items={regions.all} headers={tableHeaders} loading={loading}>
          {regions.all.map(region => (
            <tr key={region.id}>
              <td><Link to={`/procedures/regions/${region.id}`}>{region.name}</Link></td>
              <td>{region.id}</td>
              <td><Checkmark bool={!!region.is_active} /></td>
              <td>{moment(region.date_created).format('MM-DD-YYYY - h:mm:ss A')}</td>
            </tr>
          ))}
        </Table>
        <ReactPaginate
          pageCount={regions.totalPages}
          marginPagesDisplayed={1}
          pageRangeDisplayed={5}
          onPageChange={this.onPageChange}
          containerClassName="pagination"
          activeClassName="active"
          pageClassName="page-item"
          previousClassName="page-item"
          nextClassName="page-item"
        />
      </div>
    )
  }

  static propTypes = {
    regions: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired
  }
}


const mapStateToProps = ({ regions, ui }) => {
  return {
    regions: regions,
    loading: ui.loading
  }
}

export default connect(mapStateToProps,{ fetchItems })(RegionsIndex)
