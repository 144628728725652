import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { toastr } from 'react-redux-toastr'
import { Form, Input, Submit } from '@aedit/styleguide/dist/form.js'
import { authedRequest } from 'config/config'
import { useForm } from 'react-hook-form'
import './index.scss'

const ReviewForm = props => {
  const { review = {}, reviews = [], providerId } = props
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  // form stuff
  const { register, handleSubmit, reset } = useForm()

  const reviewsRedirect = {
    pathname: `/providers/${providerId}`,
    search: '?tab=17',
  }

  // Filter out existing review sources
  const sources = () => {
    let list = {
      google: 'Google',
      vitals: 'Vitals',
      facebook: 'Facebook',
      yelp: 'Yelp',
      ratingsmd: 'RatingsMD',
      health_grades: 'Health Grades',
    }

    reviews.forEach(review => delete list[review.source])

    return Object.entries(list)
  }

  const onSubmit = async data => {
    const formData = new FormData()

    setLoading(true)

    // Update to use arrays instead of FileList
    for (const [key, value] of Object.entries(data)) {
      formData.set(key, value)
    }

    if (review.id) {
      await authedRequest
        .put(`/provider/reviews/${review.id}`, formData)
        .then(() => toastr.success('Successfully edited Review'))
        .then(() => history.push(reviewsRedirect))
        .catch(err => toastr.error(err))
        .finally(() => setLoading(false))
    } else {
      await authedRequest
        .post(`/provider/${providerId}/reviews`, formData)
        .then(() => toastr.success('Successfully created Review'))
        .then(() => history.push(reviewsRedirect))
        .catch(err => toastr.error(err))
        .finally(() => setLoading(false))
    }
  }

  useEffect(() => {
    reset(review)

    return () => {}
  }, [review.id])

  return (
    <div className="provider-review-form">
      <div className="back-button">
        <Link to={`/providers/${providerId}?tab=17`} className="btn btn-primary pull-left">
          Return to Reviews
        </Link>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="review-input-field">
          <label>Source</label>
          <select {...register('source')}>
            {sources().map(source => (
              <option value={source[0]}>{source[1]}</option>
            ))}
          </select>
        </div>

        <div className="review-input-field">
          <label>Star Rating (0-5)</label>
          <input
            type="number"
            min={0}
            max={5}
            step={0.1}
            {...register('star_rating', { required: true })}
          />
        </div>

        <div className="review-input-field">
          <label>Review Count</label>
          <input type="number" max={999999} {...register('review_count', { required: true })} />
        </div>

        <div className="review-input-field">
          <label>URL</label>
          <input type="url" {...register('url', { required: false })} />
        </div>

        <input type="submit" />
      </form>
    </div>
  )
}

export default ReviewForm
