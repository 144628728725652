import React, { Fragment, Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { Link } from 'react-router-dom'
import {
  FormGroup,
  TextField,
  RadioField,
  SelectField,
  TypeAhead,
  FormFooter,
  TextArea,
  Base64FileInput,
} from '../../components/form'
import { validateRequiredFields } from '../../config/util'
import {
  RADIO_OPTIONS,
  AREA_APPROVED_IMAGES,
  AREA_THUMBNAIL_APPROVED_IMAGES,
  AREA_ICON_APPROVED_IMAGES,
} from '../../config/constants'

import { ReactSVG } from 'react-svg'


class AreaForm extends Component {
  deletePhoto = () => {
    this.props.change('primary_image', null)
  }
  deletePhotoThumbnail = () => {
    this.props.change('primary_image_thumbnail', null)
  }
  deleteIcon = () => {
    this.props.change('icon', null)
  }

  render() {
    const {
      loading,
      regions,
      concerns,
      handleSubmit,
      onFormSubmit,
      procedureCount,
      procedures,
      invalid,
      submitFailed,
      area,
      primaryImage,
      primaryImageThumbnail,
      primaryImageIcon,
    } = this.props

    return (
      <form
        onSubmit={handleSubmit(onFormSubmit)}
        className="flex-fill page-form well form-horizontal">
        <FormGroup label="Name" editable>
          <Field component={TextField} type="text" name="name" placeholder="Name" />
        </FormGroup>

        <FormGroup
          editable
          label={
            <div>
              Main Image <br />
              <a href={AREA_APPROVED_IMAGES} target="_blank" rel="noopener noreferrer">
                View Approved Images
              </a>
            </div>
          }>
          {primaryImage && area && area.primary_image_url && (
            <span className="delete-image" onClick={this.deletePhoto}>
              <img src={area.primary_image_url} className="pic-preview" alt="primary pic" />
              <p className="delete">X</p>
            </span>
          )}
          <Field component={Base64FileInput} name="primary_image" type="file" />
        </FormGroup>

        <FormGroup
          editable
          label={
            <div>
              Thumbnail Image <br />
              <a href={AREA_THUMBNAIL_APPROVED_IMAGES} target="_blank" rel="noopener noreferrer">
                View Approved Thumbnail Images
              </a>
            </div>
          }>
          {primaryImageThumbnail && area && area.primary_image_thumbnail_url && (
            <span className="delete-image" onClick={this.deletePhotoThumbnail}>
              <img
                src={area.primary_image_thumbnail_url}
                className="pic-preview"
                alt="thumbnail pic"
              />
              <p className="delete">X</p>
            </span>
          )}
          <Field component={Base64FileInput} name="primary_image_thumbnail" type="file" />
        </FormGroup>

        <FormGroup
          editable
          label={
            <div>
              Icon Imag (SVG) <br />
              <a href={AREA_ICON_APPROVED_IMAGES} target="_blank" rel="noopener noreferrer">
                View Approved SVG Icon Images
              </a>
            </div>
          }>
          {primaryImageIcon && area && area.icon_url && (
            <span className="delete-image" onClick={this.deleteIcon}>
              <ReactSVG 
                src={area.icon_url}
                className="pic-preview"
                alt="icon pic" /> 
              <p className="delete">X</p>
            </span>
          )}
          <Field component={Base64FileInput} name="icon" type="file" />
        </FormGroup>

        <FormGroup label="Is Active?" editable>
          <Field component={RadioField} name="is_active" items={RADIO_OPTIONS} />
        </FormGroup>

        <FormGroup label="Region" editable>
          <Field name="region_id" component={SelectField}>
            {regions.map(region => (
              <option value={region.id} key={region.id}>
                {region.name}
              </option>
            ))}
          </Field>
        </FormGroup>

        <FormGroup label="Concern" editable>
          <Field
            name="concern_ids"
            component={TypeAhead}
            options={concerns}
            labelKey="name"
            valueKey="id"
            multi
          />
        </FormGroup>

        <FormGroup label="Blurb" editable>
          <Field component={TextArea} name="blurb" placeholder="Blurb" />
        </FormGroup>

        {area && (
          <Fragment>
            <FormGroup label={`Associated Procedures: ${procedureCount}`}>
              <ul>
                {procedures.map(({ id, name }) => (
                  <li key={id}>
                    <Link to={`/procedures/${id}`}>{name}</Link>
                  </li>
                ))}
              </ul>
            </FormGroup>
            <FormGroup label="Date Created">
              <span>{area.date_created}</span>
            </FormGroup>
            <FormGroup label="Last Updated">
              <span>{area.date_updated}</span>
            </FormGroup>
          </Fragment>
        )}

        <FormFooter loading={loading} showFailure={invalid && submitFailed} />
      </form>
    )
  }
}

const validate = values => {
  const errors = {}
  const fields = [['name', 25]]

  if (!values.region_id) {
    errors.region_id = 'Please select a region'
  }

  return { ...validateRequiredFields(values, fields), ...errors }
}

AreaForm = reduxForm({
  validate: validate,
  form: 'areaForm',
  enableReinitialize: true,
})(AreaForm)

const selector = formValueSelector('areaForm')

AreaForm = connect(state => {
  const primaryImage = selector(state, 'primary_image')
  const primaryImageThumbnail = selector(state, 'primary_image_thumbnail')
  const primaryImageIcon = selector(state, 'icon')
  return {
    primaryImage,
    primaryImageThumbnail,
    primaryImageIcon,
  }
})(AreaForm)

export default AreaForm
