import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import {
  FormFooter,
  FormGroup,
  RadioField,
  SelectField,
  TextArea,
  TextField,
} from 'components/form'
import { FLAG_TYPES, RADIO_OPTIONS } from 'config/constants'
import {
  digitsOnly,
  setMaxCharLimit,
  setOnBeforeUnload,
  validateRequiredFields,
  formatDate,
  getDisplayNameForFlagType,
  getFlagEffectiveValue,
} from 'config/util'
import { createEntityValueFromProps } from '../_util'

const FlagForm = ({
  dirty,
  handleSubmit,
  initialValues,
  invalid,
  is_active,
  loading,
  onFormSubmit,
  submitFailed,
  type,
  value_max,
  value_min,
  value_value,
}) => {
  initialValues = initialValues || {}
  is_active = +is_active
  const editing = !!initialValues.date_created

  useEffect(() => {
    setOnBeforeUnload(dirty)
    return () => setOnBeforeUnload(false)
  }, [dirty])

  const effectiveValue = getFlagEffectiveValue({
    definition: initialValues.definition,
    allAccountTypesFlag: initialValues.allAccountTypesFlag,
    sameAccountTypeFlag: initialValues.sameAccountTypeFlag,
    accountFlag: {
      is_active,
      value: createEntityValueFromProps({ type, value_value, value_min, value_max }),
    },
  })

  return (
    <form
      onSubmit={handleSubmit(onFormSubmit)}
      className="flex-fill page-form well form-horizontal">
      <FormGroup label="Slug" editable={!editing}>
        {editing ? <span>{initialValues.slug}</span> : <Field name="slug" component={TextField} />}
      </FormGroup>

      <FormGroup label="Description" editable>
        <Field name="description" component={TextArea} />
      </FormGroup>

      <FormGroup label="Override Default Value?" editable>
        <Field name="is_active" items={RADIO_OPTIONS} component={RadioField} />
      </FormGroup>

      <FormGroup label="Type" editable={!editing}>
        {editing ? (
          <span>{getDisplayNameForFlagType(type)}</span>
        ) : (
          <Field name="type" component={SelectField} hideDisabledOption>
            <option value={null}>Select Flag Type ...</option>
            {Object.values(FLAG_TYPES).map(type => (
              <option key={type.id} value={type.id}>
                {type.display_name}
              </option>
            ))}
          </Field>
        )}
      </FormGroup>

      {type === FLAG_TYPES.BOOL.id && (
        <FormGroup label="Value" editable={is_active}>
          {is_active ? (
            <Field name="value_value" items={RADIO_OPTIONS} component={RadioField} />
          ) : (
            <span>{editing && findLabel(effectiveValue)}</span>
          )}
        </FormGroup>
      )}

      {type === FLAG_TYPES.INT_RANGE.id && (
        <>
          <FormGroup label="Minimum Value" editable={is_active}>
            {is_active ? (
              <Field name="value_min" component={TextField} parse={digitsOnly} type="number" />
            ) : (
              <span>{editing && effectiveValue.min}</span>
            )}
          </FormGroup>

          <FormGroup label="Maximum Value" editable={is_active}>
            {is_active ? (
              <Field name="value_max" component={TextField} parse={digitsOnly} type="number" />
            ) : (
              <span>{editing && effectiveValue.max}</span>
            )}
          </FormGroup>
        </>
      )}

      {editing && (
        <>
          <FormGroup label="Created At">
            <span>{formatDate(initialValues.date_created)}</span>
          </FormGroup>
          <FormGroup label="Updated At">
            <span>{formatDate(initialValues.date_updated)}</span>
          </FormGroup>
        </>
      )}

      <FormFooter loading={loading} showFailure={invalid && submitFailed} />
    </form>
  )
}

const findLabel = value => {
  const result = RADIO_OPTIONS.find(opt => +opt.value === +value)
  return result && result.labelText
}

const validate = values => {
  let errors = {}

  const requiredFields = [['slug', 255], ['type', 255], ['description'], ['is_active']]

  const optionalFields = [
    ['value_min', 3],
    ['value_max', 3],
  ]

  if (values.type === FLAG_TYPES.BOOL.id) {
    errors = {
      ...validateRequiredFields(values, ['value_value']),
      ...errors,
    }
  }

  return {
    ...validateRequiredFields(values, requiredFields),
    ...setMaxCharLimit(values, optionalFields),
    ...errors,
  }
}

const FlagReduxForm = reduxForm({
  validate,
  form: 'flagForm',
  enableReinitialize: true,
})(FlagForm)

const selector = formValueSelector('flagForm')

const mapStateToProps = state => {
  return {
    is_active: selector(state, 'is_active'),
    type: selector(state, 'type'),
    value_value: selector(state, 'value_value'),
    value_min: selector(state, 'value_min'),
    value_max: selector(state, 'value_max'),
  }
}

export default connect(mapStateToProps)(FlagReduxForm)
