import React, {Component} from 'react'
import {connect} from 'react-redux'
import ReferralProgramForm from './_program_form'
import {Link} from 'react-router-dom'
import {fetchItem, updateItem} from '../../actions'

class ReferralProgramsShow extends Component {

  onFormSubmit = ({name, blurb, is_active, id, ...rest}) => {
    this.props.updateItem('program', {...{}, name, blurb, is_active, id})
  }

  componentDidMount() {
    const id = this.props.match.params.id
    this.props.fetchItem('program', id)
  }

  render() {
    const {loading, program} = this.props
    return (
      <div className="primary-content">
        <div className="controls">
          <Link to="/referrals/programs/new" className="btn btn-primary pull-right">Add New Program</Link>
          <h4>{program.name}</h4>
        </div>
        <ReferralProgramForm
          onFormSubmit={this.onFormSubmit}
          initialValues={program}
          program={program}
          loading={loading}
        />
      </div>
    )
  }
}

const mapStateToProps = ({referrals, ui}) => {
  return {
    program: referrals.currentProgram,
    loading: ui.loading
  }
}

export default connect(mapStateToProps, {fetchItem, updateItem})(ReferralProgramsShow)
