import React, {Component} from 'react'
import {connect} from 'react-redux'
import BeautyBoardMemberForm from './_beauty_board_member_form'
import {fetchItem, fetchItems, updateItem, deleteItem} from '../../actions'
import {Link} from 'react-router-dom'

class BeautyBoardMembersShow extends Component {

  onFormSubmit = ({
    is_provider,
    profile_picture_url,
    profile_picture_relative_url,
    provider = {},
    entity_type,
    entity_id,
    ...values
  }) => {
    let providerObj = {}
    if (!!Number(is_provider)) {
      providerObj = {
        entity_type: 'provider',
        entity_id: provider.id,
        provider
      }
    }
    this.props.updateItem('beauty_board', {...values, ...providerObj})
  }

  onMemberDelete = () => {
    const id = this.props.match.params.id
    if (window.confirm('Are you sure you want to delete?')) {
      this.props.deleteItem('beauty_board', id)
    }
  }

  getInitialValues = () => {
    const { beautyBoardMember } = this.props
    let memberTypeValues
    let full_name
    if (beautyBoardMember.provider) {
      // Provider & Beauty Board Member
      full_name = beautyBoardMember.provider.full_name = beautyBoardMember.provider.first_name + ' ' + beautyBoardMember.provider.last_name
      memberTypeValues = {
        is_provider: 1,
        full_name
      }
    } else {
      // Beauty Board Member ONLY
      full_name = beautyBoardMember.first_name + ' ' + beautyBoardMember.last_name
      memberTypeValues = {
        is_provider: 0,
        full_name
      }
    }
    return {...beautyBoardMember, ...memberTypeValues}
  }

  componentDidMount() {
    const id = this.props.match.params.id
    this.props.fetchItem('beauty_board', id)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.beautyBoardMember.id && !this.props.beautyBoardMember.id) {
      this.props.history.push('/beauty-board')
    }
  }

  render() {
    const { providers, loading, fetchItems } = this.props

    const initialValues = this.getInitialValues()
    return (
      <div className="primary-content">
        <div className="controls">
          <Link className="pull-right btn btn-primary" to="/beauty-board/new">Add New Member</Link>
          <h4>{initialValues.full_name}</h4>
        </div>
        <BeautyBoardMemberForm
          onFormSubmit={this.onFormSubmit}
          loading={loading}
          fetchItems={fetchItems}
          providers={providers}
          initialValues={initialValues}
          onMemberDelete={this.onMemberDelete}
        />
      </div>
    )
  }
}

const mapStateToProps = ({providers, ui, beautyBoardMembers}) => {
  return {
    providers: providers.all.filter(item => item.is_beauty_board_member),
    loading: ui.loading,
    beautyBoardMember: beautyBoardMembers.current
  }
}

export default connect(mapStateToProps, { fetchItem, fetchItems, updateItem, deleteItem })(BeautyBoardMembersShow)
